const getRemoteOffset = (timeZone = 'Europe/Stockholm') => {
  // Get offset for given timezone relative to server time
  const localDate = new Date();
  localDate.setMilliseconds(0);
  const localOffset = localDate.getTimezoneOffset();
  const remoteLocaleStr = localDate.toLocaleString('en-US', { timeZone: timeZone });
  const remoteDate = new Date(remoteLocaleStr);
  const diff = (localDate.getTime() - remoteDate.getTime()) / 1000 / 60;
  return localOffset + diff;
};

const getRemoteStartOfDay = (timeZone: string, date = new Date()) => {
  const remoteOffset = getRemoteOffset(timeZone);

  // Create date and subtract offset
  const startOfDay = date;
  startOfDay.setUTCMilliseconds(0);
  startOfDay.setUTCSeconds(0);
  startOfDay.setUTCHours(0);
  startOfDay.setUTCMinutes(remoteOffset);
  return startOfDay;
};

const getStartOfDay = (date = new Date()) => {
  // Create date and subtract offset
  const startOfDay = new Date(date);
  startOfDay.setMilliseconds(0);
  startOfDay.setSeconds(0);
  startOfDay.setHours(0);
  startOfDay.setMinutes(0);
  return startOfDay;
};

const getStartOfMonth = (year: number, month: number) => new Date(year, month, 1);

const getEndOfMonth = (year: number, month: number) => new Date(year, month + 1, 0);

const getDaysInMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

const getMonthProgress = (date = new Date()) => {
  return getDaysInMonth(date) / date.getDate();
};

function monthDiff(d1: Date, d2: Date) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  if (months < 0) {
    return 0;
  }
  return months;
}

const addDays = function (_date: Date, days: number) {
  const date = new Date(_date);
  date.setDate(date.getDate() + days);
  return date;
};

/**
 * Converts number to date number, containing a
 * 0 if the number is less than 10
 * @param {number | string} num
 * @returns string
 */
const convertToDateNumber = (num: number) => {
  return num < 10 ? '0' + num.toString() : num.toString();
};

/**
 * @param {Date} date
 */
const getYearMonthDaysString = (date: Date) => {
  const getYearMonthDaysString =
    date.getFullYear() +
    '-' +
    convertToDateNumber(date.getMonth() + 1) +
    '-' +
    convertToDateNumber(date.getDate());
  return getYearMonthDaysString;
};

/**
 * @param {Date} date
 */
const getHumanFriendlyDate = (date: Date) => {
  if (!date || typeof date !== 'object') {
    return '';
  }
  const year = date.getFullYear();
  const month = convertToDateNumber(date.getMonth() + 1);
  const day = convertToDateNumber(date.getDate());

  return `${day}.${month}.${year}`;
};

/**
 * @param {Date} date
 */
const getHumanFriendlyDateTime = (date: Date) => {
  if (!date || typeof date !== 'object') {
    return '';
  }
  const year = date.getFullYear();
  const month = convertToDateNumber(date.getMonth() + 1);
  const day = convertToDateNumber(date.getDate());
  const hours = convertToDateNumber(date.getHours());
  const minutes = convertToDateNumber(date.getMinutes());

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const ONE_MINUTE_MS = 60 * 1000;
export const ONE_HOUR_MS = 60 * ONE_MINUTE_MS;
export const ONE_DAY_MS = 24 * ONE_HOUR_MS;
export {
  getRemoteOffset,
  getRemoteStartOfDay,
  getStartOfDay,
  getStartOfMonth,
  getEndOfMonth,
  getDaysInMonth,
  getMonthProgress,
  monthDiff,
  addDays,
  getHumanFriendlyDate,
  getHumanFriendlyDateTime,
  convertToDateNumber,
  getYearMonthDaysString,
};
