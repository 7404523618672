import axios from 'axios';
import Edition from 'types/Edition';

const editionService = {
  getAll: () => {
    return axios.get('/edition');
  },
  getLatest: (numberOfEdition = 5) => {
    return axios.get(`/edition/latest/${numberOfEdition}`);
  },
  get: (id: number) => {
    return axios.get(`/edition/${id}`);
  },
  getPrevious: (id: number) => {
    return axios.get(`/edition/previous/${id}`);
  },
  create: (edition: Edition) => {
    return axios.post('/edition', edition);
  },
  update: (edition: Edition) => {
    return axios.put(`/edition/${edition.id}`, edition);
  },
  delete: (id: number) => {
    return axios.delete(`/edition/${id}`);
  }
};

export default editionService;
