import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  SxProps,
  Stack
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PaperHeader } from 'components';
import { Edit } from '@mui/icons-material';
import Campaign from 'types/Campaign';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAlert from 'hooks/useAlert';
import CampaignStatusChip from 'components/CampaignStatusChip/CampaignStatusChip';

type CampaignListProps = {
  campaigns: Campaign[];
  onEdit: (index: number) => void;
  loading: boolean;
  action: ReactNode;
  myPageUrl?: string;
};

const CampaignList: FC<CampaignListProps> = ({ onEdit, campaigns, loading, action, myPageUrl }) => {
  const { t } = useTranslation();
  const { addAlert } = useAlert();

  const styles: { [k: string]: SxProps } = {
    row: {
      backround: 'red'
    }
  };

  return (
    <Box>
      <PaperHeader>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent={'space-between'}>
          <Typography variant={'h5'}>{t('common.campaign')}</Typography>
          {action}
        </Stack>
      </PaperHeader>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {campaigns && !loading && (
        <TableContainer>
          <Table sx={{ minWidth: 550 }}>
            <TableHead>
              <TableRow sx={styles.row}>
                <TableCell>
                  <Typography>{t('common.id')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.name')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.price')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.duration')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.status')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.purchaseLink')}</Typography>
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns.map((campaign, index) => (
                <TableRow key={campaign.id}>
                  <TableCell>
                    <Typography>{campaign.id}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{campaign.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{campaign.price + ' kr'}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {campaign.durationDays
                        ? `${campaign.durationDays}  ${t('common.days')}`
                        : t('common.outTheYear')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <CampaignStatusChip status={campaign.status} />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      disabled={!myPageUrl}
                      onClick={() => {
                        const url = `${myPageUrl}/order-subscription/select?subscriptionId=${campaign.subscriptionId}&campaignUuid=${campaign.uuid}`;
                        navigator.clipboard.writeText(url);
                        if (campaign.status !== 'INACTIVE') {
                          addAlert('success', t('alerts.copiedToClipboard'));
                        } else {
                          addAlert(
                            'warning',
                            t('alerts.copiedToClipboard') +
                              '\n' +
                              t('campaign.alerts.campaignNotActive')
                          );
                        }
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton edge="end" onClick={() => onEdit(index)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {campaigns && campaigns.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <Typography variant="caption">{t('campaign.none')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CampaignList;
