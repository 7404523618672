import axios from 'axios';
import { Invoice } from 'types/Invoice';
import OcrHistory from 'types/OcrHistory';

export type TextFile = {
  name: string;
  content: string;
};

export type Base64File = {
  name: string;
  base64: string;
};

export type UnhandledInvoices = {
  total: number;
  paper: number;
  directDebit: number;
  eInvoice: number;
};

const invoiceService = {
  getUnpaidInvoices: () => {
    return axios.get<Invoice[]>('invoice/unpaid');
  },
  getInvoicePdf: (id: number | string) => {
    return axios.get(`invoice/pdf/${id}`);
  },
  printInvoices: (ids: number[]) => {
    return axios.post<Base64File>('invoice/print-multiple', { paymentIds: ids });
  },
  getUnhandledInvoices: () => {
    return axios.get<UnhandledInvoices>('invoice/unhandled');
  },
  generateReminders: (ids: number[]) => {
    return axios.put('invoice/generate-reminders', { paymentIds: ids });
  },
  getEInvoiceProcessing: () => {
    return axios.get<Invoice[]>('invoice/einvoice-processing');
  },
  getPaperInvoicesProcessing: () => {
    return axios.get<Invoice[]>('invoice/paper-invoices-processing');
  },
  getDirectDebitProcessing: () => {
    return axios.get<Invoice[]>('invoice/direct-debit-processing');
  },
  generatePaperInvoices: (selectedPaymentIds: number[]) => {
    return axios.put<Base64File>('invoice/generate-paper-invoices', selectedPaymentIds);
  },
  generateEIvoiceEhfFiles: (selectedPaymentIds: number[]) => {
    return axios.put<TextFile[]>('invoice/generate-eivoice-ehf-files', selectedPaymentIds);
  },
  generateEIvoiceOCRFile: (selectedPaymentIds: number[]) => {
    return axios.put<TextFile>('invoice/generate-directdebit-ocr-file', selectedPaymentIds);
  },
  getOcrHistory: () => {
    const query = axios.get<OcrHistory[]>('invoice/ocr-history');
    // Convert the date strings to Date objects
    query.then((res) => {
      res.data.forEach((oh: OcrHistory) => {
        oh.created = new Date(oh.created);
        oh.show = false;
      });
    });
    return query;
  },
  uploadOcrFile: (file: File) => {
    const fd = new FormData();
    fd.append('file', file);
    return axios.post('invoice/upload-ocr-file/', fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default invoiceService;
