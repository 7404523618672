import adminSlice from './slices/adminSlice';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import alertSlice from './slices/alertsSlice';
import paymentSearchSlice, { PaymentSearch } from './slices/paymentSearchSlice';
import userSearchSlice, { UserSearch } from './slices/userSearchSlice';
import settingsSlice from './slices/settingSlice';
import Admin from 'types/Admin';
import Alert from 'types/Alert';
import Setting from 'types/Setting';

export type RootState = {
  admin: Admin;
  alerts: Alert[];
  paymentSearch: PaymentSearch;
  userSearch: UserSearch;
  settings: Setting[];
};

const store = configureStore({
  reducer: {
    admin: adminSlice.reducer,
    alerts: alertSlice.reducer,
    paymentSearch: paymentSearchSlice.reducer,
    userSearch: userSearchSlice.reducer,
    settings: settingsSlice.reducer
  },
  middleware: [thunk]
});

const adminActions = adminSlice.actions;
const alertActions = alertSlice.actions;
const paymentSearchActions = paymentSearchSlice.actions;
const userSearchActions = userSearchSlice.actions;
const settingsActions = settingsSlice.actions;

export { adminActions, alertActions, paymentSearchActions, userSearchActions, settingsActions };

export default store;
