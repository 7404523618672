import { Button } from '@mui/material';
import FlexBox from 'components/FlexBox/FlexBox';
import CheckboxFilterGroup from 'components/PrimarySearchFilter/CheckboxFilter/CheckboxFilter';
import { cloneDeep } from 'lodash';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SearchFilterType, { Filter } from 'types/SearchFilter';

type SearchFilterProps = {
  filterSubmit: (searchFilter: SearchFilterType) => void;
  searchFilter: SearchFilterType;
};

type UserFilterFields = { [k: string]: Filter[] };

const SearchFilter: FC<SearchFilterProps> = ({ filterSubmit, searchFilter }) => {
  const { t } = useTranslation();
  const { handleSubmit, setValue, control } = useForm<UserFilterFields>({
    defaultValues: getFormDefaultValues()
  });

  function getFormDefaultValues(): UserFilterFields {
    const fields: UserFilterFields = {};

    searchFilter.searchFilterGroups.forEach((sfg) => {
      fields[sfg.key] = sfg.filters;
    });

    return fields;
  }

  const handleFilterSubmit = (fields: UserFilterFields) => {
    const searchFilterCpy = cloneDeep(searchFilter);

    // Get fields array
    const fieldKeys = Object.keys(fields);
    const fieldsArray = Object.values(fields);

    // Add updated filters to SearhFilter
    searchFilterCpy.searchFilterGroups = searchFilterCpy.searchFilterGroups.map((s) => {
      fieldKeys.forEach((key, index) => {
        if (s.key === key) {
          s.filters = fieldsArray[index];
        }
      });

      return s;
    });

    filterSubmit(searchFilterCpy);
  };

  const controllers = searchFilter.searchFilterGroups.map((sfg) => (
    <Controller
      key={sfg.key}
      name={sfg.key}
      control={control}
      render={({ field }) => (
        <CheckboxFilterGroup
          {...field}
          onChange={(v) => setValue(sfg.key, v)}
          filterName={sfg.label}
          filterKey={sfg.key}
        />
      )}
    />
  ));

  return (
    <form onSubmit={handleSubmit(handleFilterSubmit)}>
      <FlexBox gap={15}>{controllers}</FlexBox>
      <FlexBox sx={{ marginTop: '10px' }} justifyContent="flex-end">
        <Button variant="contained" type={'submit'}>
          {t('common.useFilters')}
        </Button>
      </FlexBox>
    </form>
  );
};

export default SearchFilter;
