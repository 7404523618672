import { Chip, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserDistributionRouteStatus } from 'types/UserDistribution';

type UserDistributionRouteStatusChipProps = {
  routeStatus: UserDistributionRouteStatus;
};

const UserDistributionRouteStatusChip: FC<UserDistributionRouteStatusChipProps> = ({
  routeStatus
}) => {
  const { t } = useTranslation();

  const getRouteStatusColor = () => {
    switch (routeStatus) {
      case 'MANUAL':
        return 'success';
      case 'AUTOMATIC':
        return 'success';
      case 'NO_ADDRESS':
        return 'error';
      case 'NO_ROUTE':
        return 'warning';
      case 'NOT_HANDLED':
        return 'error';
      default:
        return 'default';
    }
  };

  const getRouteStatusTooltip = () => {
    switch (routeStatus) {
      case 'MANUAL':
        return t('distribution.route.tooltip.MANUAL');
      case 'AUTOMATIC':
        return t('distribution.route.tooltip.AUTOMATIC');
      case 'NO_ADDRESS':
        return t('distribution.route.tooltip.NO_ADDRESS');
      case 'NO_ROUTE':
        return t('distribution.route.tooltip.NO_ROUTE');
      case 'NOT_HANDLED':
        return t('distribution.route.tooltip.NOT_HANDLED');
      default:
        return '';
    }
  };

  const color = getRouteStatusColor();

  return (
    <Tooltip
      title={getRouteStatusTooltip()}
    >
      <Chip
        label={t(`userAddress.routeStatus.${routeStatus || 'NOT_HANDLED'}`).toUpperCase()}
        color={color}
        size='small'
      />
    </Tooltip>
  );
};

export default UserDistributionRouteStatusChip;
