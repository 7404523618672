import { Publish } from '@mui/icons-material';
import { Stack, Typography, CircularProgress, Button } from '@mui/material';
import { PaperCard, PaperHeader, PaperContent, PrimaryListItem } from 'components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import polarisDistributionAddressService from 'services/polaris-distribution-address-service';
import PolarisImportModal from '../PolarisImportModal/PolarisImportModal';
import InfoToggle from 'components/InfoToggle/InfoToggle';
import { dateString } from 'utils';
import useAlert from 'hooks/useAlert';

const PolarisCard: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const alert = useAlert();
  const [info, setInfo] = useState<{
    numberOfAddresses: number;
    lastImportedAt: string;
  }>();
  const [modal, setModal] = useState<number>();

  // init, get info
  const getInfo = async () => {
    setLoading(true);

    try {
      const { data: infoData } = await polarisDistributionAddressService.getInfo();
      setInfo(infoData);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <PaperCard variant='outlined' sx={{ width: '500px' }}>
      <PaperHeader>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography textAlign={'left'} variant='h5'>
            {t('distribution.polaris..')}
          </Typography>
        </Stack>
      </PaperHeader>
      <PaperContent>
        <InfoToggle text={t('distribution.polaris.addressDescription')} />
        {info && !loading && (
          <Stack>
            <PrimaryListItem label={t('common.numberOfAddresses')} value={info.numberOfAddresses} />
            <PrimaryListItem
              label={t('distribution.lastImported')}
              value={dateString(info.lastImportedAt)}
            />
            <PrimaryListItem
              label={t('distribution.importAddresses')}
              last
              value={
                <Button startIcon={<Publish />} onClick={() => setModal(1)} variant='outlined'>
                  {t('distribution.importAddresses')}
                </Button>
              }
            />
          </Stack>
        )}
        {loading && (
          <Stack>
            <CircularProgress />
          </Stack>
        )}
      </PaperContent>
      {modal === 1 && (
        <PolarisImportModal
          onCancel={() => setModal(null)}
          onUpdate={() => {
            setModal(null);
            alert.addAlert('success', t('file.importSuccess'));
            getInfo();
          }}
        />
      )}
    </PaperCard>
  );
};

export default PolarisCard;
