import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { EditDateModal, PrimaryListItem } from 'components';
import { isDate, format, startOfDay } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserDistribution from 'types/UserDistribution';

type EditHoldPaperProps = {
  userDistribution: UserDistribution;
  onUpdate: (userDistribution: UserDistribution) => void;
};

type EditHoldPaperModals = 'editHoldPaperFrom' | 'editHoldPaperTo';

const EditHoldPaper: FC<EditHoldPaperProps> = ({ userDistribution, onUpdate }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<EditHoldPaperModals>(null);

  const today = startOfDay(new Date());

  return (
    <>
      <PrimaryListItem
        label={t('userDistribution.holdPaperFromDate')}
        value={
          isDate(userDistribution.holdFromDate)
            ? format(userDistribution.holdFromDate, 'dd.MM.yyyy')
            : '--'
        }
        actions={
          <IconButton onClick={() => setModal('editHoldPaperFrom')}>
            <Edit />
          </IconButton>
        }
      />
      <PrimaryListItem
        label={t('userDistribution.holdPaperToDate')}
        value={
          isDate(userDistribution.holdToDate)
            ? format(userDistribution.holdToDate, 'dd.MM.yyyy')
            : '--'
        }
        actions={
          <IconButton onClick={() => setModal('editHoldPaperTo')}>
            <Edit />
          </IconButton>
        }
      />
      {modal === 'editHoldPaperFrom' && (
        <EditDateModal
          onCancel={() => setModal(null)}
          onSave={(updateDate: Date) => {
            onUpdate({ ...userDistribution, holdFromDate: updateDate });
            setModal(null);
          }}
          onDelete={() => {
            onUpdate({ ...userDistribution, holdFromDate: null });
            setModal(null);
          }}
          minDate={today}
          label={t('common.fromDate')}
          title={t('common.changeBlank', { blank: t('userDistribution.holdPaper').toLowerCase() })}
          value={userDistribution.holdFromDate}
        />
      )}
      {modal === 'editHoldPaperTo' && (
        <EditDateModal
          onCancel={() => setModal(null)}
          onSave={(updateDate: Date) => {
            onUpdate({ ...userDistribution, holdToDate: updateDate });
            setModal(null);
          }}
          onDelete={() => {
            onUpdate({ ...userDistribution, holdToDate: null });
            setModal(null);
          }}
          minDate={today}
          label={t('common.toDate')}
          title={t('common.changeBlank', { blank: t('userDistribution.holdPaper').toLowerCase() })}
          value={userDistribution.holdToDate}
        />
      )}
    </>
  );
};

export default EditHoldPaper;
