import COLORS from 'consts/colors';
import Highcharts, { PointOptionsObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationStatistic } from 'types/Notification';

type NotificationStatisticsProps = {
  statistics: NotificationStatistic;
};

const NotificationStatistics: FC<NotificationStatisticsProps> = ({ statistics }) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const getData = (statistics: NotificationStatistic) => {
    const result: PointOptionsObject[] = [];
    if (statistics.actedOn > 0) {
      result.push({ name: t('notification.status.ACTED_ON'), y: statistics.actedOn });
    }
    if (statistics.delivered > 0) {
      result.push({ name: t('notification.status.DELIVERED'), y: statistics.delivered });
    }
    if (statistics.unread > 0) {
      result.push({ name: t('notification.status.UNREAD'), y: statistics.unread });
    }
    if (statistics.dismissed > 0) {
      result.push({ name: t('notification.status.DISMISSED'), y: statistics.dismissed });
    }
    if (statistics.canceled > 0) {
      result.push({ name: t('notification.status.CANCELED'), y: statistics.canceled });
    }
    return result;
  };

  const options: Highcharts.Options = {
    colors: [COLORS.SUCCESS,  COLORS.INFO, COLORS.PRIMARY, COLORS.ERROR, COLORS.PRIMARY_DARK],
    chart: {
      backgroundColor: null,
      width: 260,
      height: 160
    },
    title: {
      text: null
    },
    tooltip: {
      pointFormat: '{series.data.name} <b>{point.y}</b>'
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          color: '#fff',
          enabled: false,
          format: '{point.name}'
        }
      }
    },
    series: [
      {
        type: 'pie',
        //colorByPoint: true,
        borderColor: null,
        data: statistics === null ? [] : getData(statistics)
      }
    ]
  };
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ display: 'inline-block' }}>
        {statistics !== null && (
          <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
        )}
      </div>
    </div>
  );
};

export default NotificationStatistics;
