import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { PageContainer, PaperCard, PaperHeader, PaperContent } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useNavigate } from 'react-router';
import REPORTS from 'consts/reports';

const ReportList: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageContainer>
      <PaperCard sx={{ width: '100%' }}>
        <PaperHeader>
          <Typography variant={'h6'}> {t('reports.chooseReport')}</Typography>
        </PaperHeader>
        <PaperContent np>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="150">
                  <QueryStatsIcon />
                </TableCell>
                <TableCell>{t('common.name')}</TableCell>
                <TableCell>{t('common.description')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {REPORTS.map((report) => (
                <TableRow key={report.slug}>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        navigate('/report/' + report.slug);
                      }}
                    >
                      {t('common.viewReport')}
                    </Button>
                  </TableCell>
                  <TableCell>{t('reports.' + report.name)}</TableCell>
                  <TableCell>
                    <i>{t('reports.' + report.name + 'Description')}</i>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PaperContent>
      </PaperCard>
    </PageContainer>
  );
};

export default ReportList;
