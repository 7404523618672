import { Info } from '@mui/icons-material';
import {
  Stack,
  Typography,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableBody,
  CircularProgress,
  Table
} from '@mui/material';
import { PaperCard, PaperHeader, PaperContent } from 'components';
import { ROUTE_PATHS } from 'consts/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import subscriptionService from 'services/subscription-service';
import userDistributionService from 'services/user-distribution-service';
import Subscription from 'types/Subscription';
import User from 'types/User';
import getActiveUserSubscription from 'utils/_getActiveUserSubscription';

const PaperUserManagementInvalidPostCode: FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>();
  const [users, setUsers] = useState<User[]>();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>();

  const getUsers = async (doLoading = false) => {
    if (doLoading) {
      setLoading(true);
    }

    try {
      const { data: usersData } = await userDistributionService.getUsersWithInvalidPostCode();
      console.log('usersData', usersData);
      setUsers(usersData);
    } catch (error) {
      console.error('error', error);
    } finally {
      if (doLoading) {
        setLoading(false);
      }
    }
  };

  const getSubscriptions = async (doLoading = false) => {
    if (doLoading) {
      setLoading(true);
    }

    try {
      const { data: subscriptionsData } = await subscriptionService.get();
      setSubscriptions(subscriptionsData);
    } catch (error) {
      console.error('error', error);
    } finally {
      if (doLoading) {
        setLoading(false);
      }
    }
  };

  const init = async () => {
    try {
      setLoading(true);
      await Promise.all([getUsers(), getSubscriptions()]);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <PaperCard>
      <PaperHeader>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Typography variant={'h6'} textAlign={'left'}>
              {t('common.noAddress')}
            </Typography>
            <Tooltip title={t('paperUserManagement.noAddressTooltip')}>
              <span>
                <IconButton disabled>
                  <Info />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
          {users && (
            <Typography>
              {t('common.quantity')} {users.length}
            </Typography>
          )}
        </Stack>
      </PaperHeader>
      <PaperContent np>
        {users && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>{t('common.name')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.userSubscription')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.postCode')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => {
                const activeUserSubscription = getActiveUserSubscription(user?.userSubscriptions);
                const activeSubscription = subscriptions?.find(
                  (subscription) => subscription.id === activeUserSubscription?.subscriptionId
                );

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Link
                        style={{ color: '#fff ' }}
                        to={ROUTE_PATHS.USER.replace(':id', user?.id.toString())}
                      >
                        <Typography
                          sx={{
                            textDecoration: 'none',
                            '&:hover': {
                              color: 'white'
                            }
                          }}
                        >
                          {user.name}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography>{activeSubscription?.name || '--'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{user?.userAddress?.postCode || '--'}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        {loading && (
          <Stack p={4} justifyContent={'center'}>
            <CircularProgress />
          </Stack>
        )}
        {(!users || users.length === 0) && !loading && (
          <Stack p={4} textAlign={'center'}>
            <Typography>{t('common.noResultsFound')}</Typography>
          </Stack>
        )}
      </PaperContent>
    </PaperCard>
  );
};

export default PaperUserManagementInvalidPostCode;
