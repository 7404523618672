import { SxProps, Tab, Tabs, Typography } from '@mui/material';
import PageContent from 'components/PageContent/PageContent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWrapper from 'components/PageWrapper/PageWrapper';
import { ROUTE_PATHS } from 'consts/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router';

const PostenPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tab, setTab] = useState<number>();
  const [sxStyles, setSxStyles] = useState<SxProps>({});

  useEffect(() => {
    // Get current path and set tab value
    if (location.pathname.includes(ROUTE_PATHS.POSTEN_DISTRIBUTION_ROUTES)) {
      setTab(0);
    } else if (location.pathname.includes(ROUTE_PATHS.POSTEN_POST_CODE_INFO)) {
      setTab(1);
    } else if (location.pathname.includes(ROUTE_PATHS.POSTEN_SETTINGS)) {
      setTab(2);
    } else {
      setTab(0);
    }
  }, [location]);

  useEffect(() => {
    if (tab === 0) {
      navigate(`${ROUTE_PATHS.POSTEN_DISTRIBUTION_ROUTES}`);
    } else if (tab === 1) {
      navigate(`${ROUTE_PATHS.POSTEN_POST_CODE_INFO}`);
    } else if (tab === 2) {
      navigate(`${ROUTE_PATHS.POSTEN_SETTINGS}`);
    }

    setSxStyles(tab === 0 ? { height: '100%' } : { height: 'auto' });
  }, [tab]);

  return (
    <PageWrapper sx={sxStyles}>
      <PageHeader backToUrl={ROUTE_PATHS.PAPER}>
        <Typography variant={'h6'}>{t('common.posten')}</Typography>
        {tab !== undefined && (
          <Tabs value={tab} onChange={(e, tabValue) => setTab(tabValue)}>
            <Tab label={t('common.postenDistribution')} value={0} />
            <Tab label={t('common.postCodeInfo')} value={1} />
            <Tab label={t('common.settings')} value={2} />
          </Tabs>
        )}
      </PageHeader>
      <PageContent
        sx={{ padding: '12px', overflow: 'hidden', display: 'inline-block', width: 'auto' }}
      >
        <Outlet />
      </PageContent>
    </PageWrapper>
  );
};

export default PostenPage;
