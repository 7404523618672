import { FC } from 'react';
import { Tab, Tabs, Typography } from '@mui/material';
import PageWrapper from 'components/PageWrapper/PageWrapper';
import { useTranslation } from 'react-i18next';
import { PaperCard, PaperContent, PaperHeader } from 'components';

import { ROUTE_PATHS } from 'consts/routes';
import RetailerList from './RetailerList';
import PageHeader from 'components/PageHeader/PageHeader';
import PageContent from 'components/PageContent/PageContent';

const PaperRetail: FC = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <PageHeader backToUrl={ROUTE_PATHS.PAPER}>
        <Typography variant={'h6'}>{t('distribution.paperRetail')}</Typography>
        <Tabs
          sx={{
            borderBottom: '1px solid rgba(255,255,255,0.12)'
          }}
          value={0}
        >
          <Tab label={t('common.retailers')} value={0} />
        </Tabs>
      </PageHeader>
      <PageContent>
        <PaperCard sx={{ minWidth: '300px' }}>
          <PaperHeader textAlign='left'>
            <Typography variant={'h6'}>{t('common.retailers')}</Typography>
          </PaperHeader>
          <PaperContent np>
            <RetailerList />
          </PaperContent>
        </PaperCard>
      </PageContent>
    </PageWrapper>
  );
};

export default PaperRetail;
