import { Button, CircularProgress, Divider, IconButton, Stack, Tooltip } from '@mui/material';
import axios, { AxiosError } from 'axios';
import useAlert from 'hooks/useAlert';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import invoiceService from 'services/invoice-service';
import { Invoice } from 'types/Invoice';
import InvoiceTable from '../InvoiceTable/InvoiceTable';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import encodeISO88591 from 'utils/encodeISO88591';
import normalizeLineBreaks from 'utils/normalizeLineBreaks';

type DirectDebitTabProps = {
  getUnhandledInvoices: () => void;
};

const DirectDebitTab: FC<DirectDebitTabProps> = ({getUnhandledInvoices}) => {
  const { t } = useTranslation();

  const axiosAlert = useAxiosAlert();
  const { addAlert } = useAlert();
  const [invoices, setInvoices] = useState<Invoice[]>(null);
  const [selectedPaymentIds, setSelectedPaymentIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  const generateOcrFile = async () => {
    setLoading(true);
    try {
      const ocrFileQuery = await invoiceService.generateEIvoiceOCRFile(selectedPaymentIds);
      const ocrFile = ocrFileQuery.data;

      // Ref Documentation: https://www.mastercardpaymentservices.com/norway/utvikler/paymentclaims
      // Input files to Mastercard Payment Services should use character set ISO-8859-1, 
      // and line breaks should be represented by 0D / CR (carriage return) + 0A / LF (line feed).
      const content = normalizeLineBreaks(ocrFile.content, '\r\n');
      const encodedContent = encodeISO88591(content);

      // Download the file
      const a = document.createElement('a');
      const blob = new Blob([encodedContent], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = ocrFile.name;
      a.click();

      addAlert('success', t('invoice.ocrFileGenerated'));
      getInvoices();
      getUnhandledInvoices();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        axiosAlert(error as AxiosError, []);
      } else {
        const message = (error as Error)?.message ?? '';
        addAlert('error', t('alerts.somethingWentWrong') + message);
      }
      setLoading(false);
    }
  };

  const selectNone = () => {
    setSelectedPaymentIds([]);
  };

  const selectAll = () => {
    const allInvoicesPaymentIds = invoices.map((i) => i.payment.id);
    setSelectedPaymentIds(allInvoicesPaymentIds);
  };

  const getInvoices = async () => {
    setLoading(true);
    try {
      // Get direct debit for processing
      const query = await invoiceService.getDirectDebitProcessing();
      setInvoices(query.data);
    } catch (error) {
      if ((error as AxiosError).response?.data?.message) {
        axiosAlert(error as AxiosError, []);
      } else {
        addAlert('error', 'alerts.somethingWentWrong');
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <div>
      {loading && <CircularProgress />}
      {!loading && (
        <InvoiceTable
          selectable={true}
          selectedPaymentIds={selectedPaymentIds}
          toggleSelected={(id) => {
            if (selectedPaymentIds.includes(id)) {
              setSelectedPaymentIds(selectedPaymentIds.filter((i) => i !== id));
            } else {
              setSelectedPaymentIds([...selectedPaymentIds, id]);
            }
          }}
          invoices={invoices}
        />
      )}
      <Divider />
      <Stack sx={{ padding: '20px' }} gap='20px'>
        <Stack direction='row' gap='20px'>
          <Button onClick={selectAll}>
            <CheckCircleOutlineIcon />
            &nbsp;
            {t('invoice.selectAll')}
          </Button>
          <Button onClick={selectNone}>
            <RadioButtonUncheckedIcon />
            &nbsp;
            {t('invoice.selectNone')}
          </Button>
        </Stack>
        <Stack direction='row' gap='20px'>
          <Button
            variant='contained'
            disabled={invoices?.length === 0}
            component='label'
            onClick={generateOcrFile}
          >
            {t('invoice.generateOcrTransactionFiles')}
          </Button>
          <Tooltip title={t('invoice.directDebitDescription')}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </div>
  );
};

export default DirectDebitTab;
