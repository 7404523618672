import { Alert, Box, Stack, SxProps, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import FormDialog from 'components/FormDialog/FormDialog';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userService from 'services/user-service';
import User from 'types/User';

type ArchiveUserModalProps = {
  user: User;
  onCancel?: () => void;
  onUserArchived?: (archivedUser: User) => void;
};

type ArchiveUserFields = {
  reasonForArchiving: string;
};

const ArchiveUserModal: FC<ArchiveUserModalProps> = ({ user, onCancel, onUserArchived }) => {
  const { register, handleSubmit } = useForm<ArchiveUserFields>({});
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const axiosAlert = useAxiosAlert();

  const onSubmit = async ({ reasonForArchiving }: ArchiveUserFields) => {
    setLoading(true);
    try {
      const { data: archivedUser } = await userService.archiveUser(user.id, reasonForArchiving);
      onUserArchived(archivedUser);
    } catch (error) {
      axiosAlert(error as AxiosError, [
        {
          status: 403,
          message: t('user.archive.alerts.userHasAccess')
        }
      ]);
    } finally {
      setLoading(false);
    }
  };

  const styledDialogContent: SxProps = {
    width: '400px'
  };

  return (
    <FormDialog
      title={t('user.archive.archiveUser')}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      submitButtonColor={'error'}
      submitText={t('user.archive.archiveUser')}
      loading={loading}
    >
      <Box sx={styledDialogContent}>
        <Stack gap={2}>
          <Alert severity={'info'}>{t('user.archive.description')}</Alert>
          <TextField
            fullWidth
            multiline
            label={`${t('user.archive.reason')} (${t('common.optional')})`}
            {...register('reasonForArchiving', {
              required: false,
              max: 300
            })}
          />
        </Stack>
      </Box>
    </FormDialog>
  );
};

export default ArchiveUserModal;
