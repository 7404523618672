/* eslint-disable max-lines */
/* eslint-disable indent */

import { Box, CircularProgress, Stack, SxProps, Typography } from '@mui/material';
import { PaperCard, PaperContent, PaperFooter, PaperHeader, PrimarySearch } from 'components';
import PrimarySearchFilter from 'components/PrimarySearchFilter/PrimarySearchFilter';
import useAlert from 'hooks/useAlert';
import logger from 'logger/logger';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import paymentService from 'services/payment-service';
import PageResults from 'types/PageResults';
import Payment, { paymentStatuses } from 'types/Payment';
import SearchFilterType, { Filter, SearchFilterGroup } from 'types/SearchFilter';
import SearchQueryType from 'types/SearchQueryType';
import { paymentSolutions } from 'types/PaymentSolution';

import SearchQuery from 'types/SearchQuery';
import getNumberOfActiveFilters from 'utils/_getNumberOfActiveFilters';
import PaymentSearchFilterType from 'types/PaymentSearchFilter';
import PrimarySearchPageNav from 'components/PrimarySearchPageNav/PrimarySearchPageNav';
import { orderTypes } from 'types/OrderType';

import SearchFilter from 'components/SearchFilter/SearchFilter';
import PaymentList from 'pages/Payments/components/PaymentsList/PaymentList';
import { useDispatch, useSelector } from 'react-redux';
import { paymentSearchActions, RootState } from 'store';

const LIMIT = 20;

const PaymentsSearch: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //const { searchFilter, setSearchFilter, searchQuery, setSearchQuery } = useSearchState();
  const { searchFilter, searchQuery } = useSelector((state: RootState) => state.paymentSearch);
  const dispatch = useDispatch();

  const { addAlert } = useAlert();

  const [pageResults, setPageResults] = useState<PageResults<Payment>>();

  const queryTypes: SearchQueryType[] = [
    { key: 'user', secondaryKey: 'name', label: t('common.username') },
    { key: 'id', label: t('payment.id') }
  ];

  useEffect(() => {
    init();
  }, []);

  const searchPayments = async (searchQueryP: SearchQuery, limit: number, offset: number) => {
    try {
      setLoading(true);
      // Loading
      const paymentQuery = await paymentService.search(searchQueryP, limit, offset);
      setPageResults(paymentQuery.data);
      dispatch(paymentSearchActions.setQuery(searchQueryP));
      // Stop loading
    } catch (error) {
      logger.error('Search payment failed', error);
      addAlert('error', t('payment.couldNotGet'));
    } finally {
      setLoading(false);
    }
  };

  const changePage = (value: number) => {
    const updatedSearchQuery = { ...searchQuery, page: value };
    dispatch(paymentSearchActions.setQuery(updatedSearchQuery));
    const offset = (value - 1) * LIMIT;
    searchPayments(updatedSearchQuery, LIMIT, offset);
  };

  const init = () => {
    if (!searchFilter) {
      const paymentStatusFilters: Filter[] = paymentStatuses.map((p) => ({
        label: t(`payment.status.${p}`),
        value: p,
        checked: true
      }));
      const paymentSolutionFilters: Filter[] = paymentSolutions.map((p) => ({
        label: t(`paymentSolution.${p}`),
        value: p,
        checked: true
      }));
      const orderTypeFilters: Filter[] = orderTypes.map((o) => ({
        label: t(`payment.orderTypes.${o}`),
        value: o,
        checked: true
      }));

      const tempSearchFilter: SearchFilterType = {
        active: false,
        numberOfActiveFilters: 0,
        searchFilterGroups: [
          {
            key: 'paymentStatuses',
            label: t('payment.paymentStatus'),
            filters: paymentStatusFilters
          },
          {
            key: 'paymentSolutions',
            label: t('payment.solution'),
            filters: paymentSolutionFilters
          },
          {
            key: 'orderTypes',
            label: t('payment.orderType'),
            filters: orderTypeFilters
          }
        ]
      };

      dispatch(paymentSearchActions.setFilter(tempSearchFilter));
    }

    const offset = searchQuery?.page ? LIMIT * (searchQuery?.page - 1) : 0;

    searchPayments(
      {
        query: searchQuery?.query || '',
        filters: searchQuery?.filters,
        queryType: searchQuery?.queryType || queryTypes[0],
        page: searchQuery?.page || 1
      },
      LIMIT,
      offset
    );
  };

  const getPaymentSearchFilter = (
    searchFilterGroupsP: SearchFilterGroup[]
  ): PaymentSearchFilterType => {
    return {
      paymentSolutions: searchFilterGroupsP.find((sfg) => sfg.key === 'paymentSolutions').filters,
      paymentStatuses: searchFilterGroupsP.find((sfg) => sfg.key === 'paymentStatuses').filters,
      orderTypes: searchFilterGroupsP.find((sfg) => sfg.key === 'orderTypes').filters
    };
  };

  const handleFilterSubmit = (_searchFilter: SearchFilterType) => {
    setAnchorEl(null);
    dispatch(
      paymentSearchActions.setFilter({
        ..._searchFilter,
        active: true,
        numberOfActiveFilters: getNumberOfActiveFilters(_searchFilter.searchFilterGroups)
      })
    );

    searchPayments(
      {
        query: searchQuery?.query || '',
        filters: getPaymentSearchFilter(_searchFilter?.searchFilterGroups),
        queryType: searchQuery?.queryType || queryTypes[0]
      },
      LIMIT,
      0
    );
  };

  const resetFilter = () => {
    const resetSearchFilter = { ...searchFilter, active: false };
    dispatch(paymentSearchActions.setFilter(resetSearchFilter));

    searchPayments(
      {
        query: searchQuery.query || '',
        filters: null,
        queryType: searchQuery.queryType || queryTypes[0]
      },
      LIMIT,
      searchQuery?.page ? searchQuery.page * LIMIT : 0
    );
  };

  const styledPaperCard: SxProps = {
    maxWidth: '1500px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  };

  const styledPaperContent: SxProps = {
    overflowY: 'auto',
    overflowX: 'auto',
    flex: 1,
    padding: 0
  };

  return (
    <PaperCard sx={styledPaperCard}>
      <PaperHeader>
        <Stack flexDirection="row" justifyContent={'space-between'} alignItems={'center'}>
          <Stack flexDirection="row" gap={2} alignItems={'center'}>
            <Typography variant="h5">{t('payment.title')}</Typography>
            <PrimarySearch
              defaultQuery={searchQuery?.query}
              queryTypes={queryTypes}
              onSearch={(query, queryType) => {
                dispatch(
                  paymentSearchActions.setQuery({ ...searchQuery, query, queryType, page: 1 })
                );
                searchPayments({ ...searchQuery, query, queryType, page: 1 }, LIMIT, 0);
              }}
            />
            <PrimarySearchFilter
              anchorEl={anchorEl}
              onClick={(element) => {
                anchorEl ? setAnchorEl(null) : setAnchorEl(element);
              }}
              isActive={searchFilter?.active}
              onRemoveFilter={resetFilter}
              numberOfActiveFilters={searchFilter?.numberOfActiveFilters}
              filterComponent={
                <SearchFilter filterSubmit={handleFilterSubmit} searchFilter={searchFilter} />
              }
            />
          </Stack>
          {pageResults && (
            <Typography sx={{ padding: '10px', textAlign: 'right' }}>
              {t('common.hits') + ': ' + pageResults.total}
            </Typography>
          )}
        </Stack>
      </PaperHeader>
      <PaperContent sx={styledPaperContent}>
        {!loading && <PaymentList payments={pageResults?.results || []} />}
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </PaperContent>
      <PaperFooter>
        {pageResults && pageResults?.total > 0 && (
          <PrimarySearchPageNav
            page={searchQuery?.page || 1}
            totalResults={pageResults.total}
            onChangePage={changePage}
          />
        )}
      </PaperFooter>
    </PaperCard>
  );
};

export default PaymentsSearch;
