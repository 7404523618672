import { FC, useState } from 'react';
import Edition from 'types/Edition';
import { IconButton, List, Stack, Typography } from '@mui/material';
import { PaperCard, PaperContent, PaperHeader, PrimaryListItem } from 'components';
import { useTranslation } from 'react-i18next';
import { dateString } from 'utils';
import { Edit } from '@mui/icons-material';
import EditionModal from 'modals/EditionModal/EditionModal';

type EditionDetailsProps = {
  edition: Edition;
  onEdit: (edition: Edition) => void;
};

const EditionDetails: FC<EditionDetailsProps> = ({ edition, onEdit }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<number>(null);

  const disableInputs = edition?.status === 'PUBLISHED';

  return (
    <>
      <PaperCard variant={'outlined'} sx={{ width: '450px' }}>
        <PaperHeader
          sx={{
            padding: '16px 20px'
          }}
        >
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant='h6'>{t('edition.details')}</Typography>
            <IconButton disabled={disableInputs} onClick={() => setModal(1)}>
              <Edit />
            </IconButton>
          </Stack>
        </PaperHeader>
        <PaperContent sx={{ pt: 0 }}>
          <Stack>
            <List>
              <PrimaryListItem label={t('common.editionNumber')} value={edition.editionNumber} />
              <PrimaryListItem label={t('edition.date')} value={dateString(edition.date)} />
              <PrimaryListItem label={t('edition.numberOfPages')} value={edition.numberOfPages} />
              {edition.useInserts && (
                <PrimaryListItem
                  label={t('common.insertsWeightGrams')}
                  value={(edition.insertsWeightGrams || 0) + ' ' + 'g'}
                />
              )}
              {edition.fullDistribution && (
                <PrimaryListItem label={t('common.fullDistribution')} value={t('common.yes')} />
              )}
            </List>
          </Stack>
        </PaperContent>
      </PaperCard>
      {modal === 1 && (
        <EditionModal
          edition={edition}
          onCancel={() => setModal(null)}
          onFinished={(editedEdition: Edition) => {
            onEdit(editedEdition);
            setModal(null);
          }}
        />
      )}
    </>
  );
};

export default EditionDetails;
