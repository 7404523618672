import './SubscriptionModal.scss';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import FormDialog from 'components/FormDialog/FormDialog';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import subscriptionService from 'services/subscription-service';
import Subscription, { SubscriptionStatus } from 'types/Subscription';
import useAlert from 'hooks/useAlert';
import useSettings from 'hooks/useSettings';

type SubscriptionFields = {
  name: string;
  order: string;
  description: string;
  price: string;
  status: string;
  duration: string;
  giftable: boolean;
  paper: boolean;
};

type SubscriptionModalProps = {
  onCancel: () => void;
  onConfirm: (subscription: Subscription) => void;
  subscription?: Subscription;
};

const SubscriptionModal: FC<SubscriptionModalProps> = ({ onCancel, onConfirm, subscription }) => {
  const { t } = useTranslation();
  const { addAlert } = useAlert();
  const { getValueByKeyAsBoolean } = useSettings();
  const nrsPaperSetting = getValueByKeyAsBoolean('NrsPaper');

  let defaultValues = null;
  if (subscription) {
    defaultValues = {
      name: subscription.name,
      order: subscription.order !== null ? subscription.order.toString() : '',
      description: subscription.description,
      price: subscription.price.toString(),
      status: subscription.status,
      duration: subscription.duration.toString(),
      giftable: subscription.giftable
    };
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<SubscriptionFields>({
    defaultValues
  });
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<SubscriptionFields> = async ({
    name,
    order,
    description,
    status,
    price,
    duration,
    giftable,
    paper
  }) => {
    const isNew = subscription ? false : true;
    const subscriptionTemp = {
      name,
      order: order !== '' ? parseInt(order, 10) : null,
      description,
      status,
      price: parseInt(price, 10),
      duration: parseInt(duration, 10),
      giftable,
      paper
    } as Subscription;

    setLoading(true);
    try {
      let subscriptionRes = null;
      if (isNew) {
        const { data } = await subscriptionService.post(subscriptionTemp);
        subscriptionRes = data;
      } else {
        subscriptionTemp.id = subscription.id;
        const { data } = await subscriptionService.update(subscriptionTemp);
        subscriptionRes = data;
      }

      setLoading(false);
      onConfirm(subscriptionRes);
    } catch (error) {
      addAlert('error', t('alert.somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  const editableStatuses: SubscriptionStatus[] = ['ACTIVE', 'INACTIVE'];

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t(`subscription.${subscription ? 'edit' : 'createNew'}`)}
      loading={loading}
      submitText={t(`subscription.${subscription ? 'edit' : 'createNew'}`)}
    >
      <div className="dialog-content">
        <TextField
          sx={{ m: '10px 0' }}
          fullWidth
          label={t('common.name')}
          {...register('name', {
            required: t('alerts.subscriptionNameIsRequired').toString(),
            minLength: 1,
            maxLength: 45
          })}
          error={errors.name ? true : false}
        />
        <TextField
          sx={{ m: '10px 0' }}
          fullWidth
          type="number"
          label={t('common.order')}
          {...register('order')}
          error={errors.price ? true : false}
        />
        <TextField
          multiline
          sx={{ m: '10px 0' }}
          fullWidth
          rows={2}
          label={t('common.description')}
          {...register('description', {
            required: t('alerts.descriptionIsRequired').toString(),
            minLength: 2,
            maxLength: 300
          })}
          error={errors.description ? true : false}
        />
        <TextField
          sx={{ m: '10px 0' }}
          fullWidth
          type="number"
          label={t('common.price') + ' kr'}
          {...register('price', {
            required: t('alerts.priceIsRequired').toString(),
            min: 1,
            max: 99999,
            onChange: (e) => setValue('price', Math.floor(parseInt(e.target.value, 10)).toString())
          })}
          error={errors.price ? true : false}
        />

        {!subscription && (
          <TextField
            sx={{ m: '10px 0' }}
            fullWidth
            type="number"
            label={t('common.duration') + ' mnd'}
            prefix={'mnd'}
            {...register('duration', {
              required: t('alerts.durationIsRequired').toString(),
              min: 1,
              max: 100,
              onChange: (e) =>
                setValue('duration', Math.floor(parseInt(e.target.value, 10)).toString())
            })}
            error={errors.duration ? true : false}
          />
        )}
        <div style={{ margin: '20px 0' }}>
          <FormControl fullWidth>
            <InputLabel>{t('common.status')}</InputLabel>
            <Select
              label={t('common.status')}
              defaultValue={subscription ? subscription.status : 'INACTIVE'}
              {...register('status', { required: true })}
            >
              {editableStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {t('subscriptionStatus.' + status)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Stack gap={2}>
          {nrsPaperSetting && (
            <FormControlLabel
              disabled={subscription ? true : false}
              control={<Checkbox defaultChecked={subscription ? subscription.paper : false} />}
              {...register('paper')}
              onChange={(e, checked) => {
                setValue('paper', checked);
              }}
              label={t('subscription.paperAccess') + ` (${t('subscription.onlyOnCreation')})`}
            />
          )}
          <FormControlLabel
            control={<Checkbox defaultChecked={subscription ? subscription.giftable : false} />}
            defaultChecked={subscription ? subscription.giftable : false}
            {...register('giftable')}
            onChange={(e, checked) => {
              setValue('giftable', checked);
            }}
            label={t('subscription.giftable')}
          />
        </Stack>
      </div>
    </FormDialog>
  );
};

export default SubscriptionModal;
