import moment from 'moment';
import i18n from '../i18n/index';

/* eslint-disable no-useless-escape */
const validate = {
  email: (email: string) => {
    const emailRegex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailRegex.test(email);
  },
  phone: (phone: string, countryLimit = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]) => {
    const phoneRegex = new RegExp(`^\\d{${countryLimit.join('}|\\d{')}}$`);
    return phoneRegex.test(phone);
  },
  length: (string: string, min: number, max: number, optional = false) => {
    if (string == null || string == undefined) {
      return;
    }

    if (optional && string.length == 0) {
      return true;
    }

    return string.length >= min && string.length <= max;
  },
  expired: (expire: number) => {
    const timeNow = new Date().getTime();
    return timeNow > expire;
  },
  password: (password: string) => {
    // Check empty
    if (password == '') {
      return i18n.t('password.passwordEmpty');
    }

    // Minimum length
    if (password.length < 6) {
      return i18n.t('password.passwordMustBeAtLeast6Characters');
    }

    // Characters
    if (!/[A-Za-z]/.test(password)) {
      return i18n.t('password.passwordMustContainAtLeastOneCharacter');
    }

    // Numbers
    if (!/\d/.test(password)) {
      return i18n.t('password.passwordMustContainAtLeastOneNumeric');
    }
    return true;
  },
  dateString: (dateString: string) => {
    const d = moment(dateString, 'DD.MM.YYYY', true);
    return d.isValid();
  },
  date: (date: Date) => {
    return date instanceof Date && !isNaN(date.getTime());
  },
  number: (val: number, min = 0, max = 99999999) => {
    if (typeof val === 'number') {
      return val >= min && val <= max;
    } else {
      return false;
    }
  },
  postCode: (postCode: string) => {
    const postCodeRegex = /^[0-9]{4,6}$/;

    return postCodeRegex.test(postCode);
  }
};

export default validate;
