/* eslint-disable max-lines */
import {
  ChangeEmail,
  ChangePassword,
  ForgotPassword,
  Login,
  Profile,
  Users,
  Subscriptions,
  Admins,
  ErrorPage
} from 'pages';
import { Dashboard } from 'templates';
import Route from 'types/Route';
import role from 'consts/role';
import RouteItem from 'types/RouteItem';
import Settings from 'pages/Settings/Settings';
import StartPage from 'pages/StartPage/StartPage';
import Payments from 'pages/Payments/Payments';
import UserTab from 'pages/Users/components/UserTab/UserTab';
import Report from 'pages/Reports/Report';
import ReportList from 'pages/Reports/ReportList';
import SubscriptionTabs from 'pages/Subscriptions/components/SubscriptionTabs/SubscriptionTabs';
import Invoices from 'pages/Invoices/Invoices';
import UserSearch from 'pages/Users/components/UserSearch/UserSearch';
import PaymentsSearch from 'pages/Payments/Children/PaymentSearch/PaymentSearch';
import Payment from 'pages/Payments/Children/Payment/Payment';
import Jobs from 'pages/Jobs/Jobs';
import EditionPage from 'pages/EditionPage/EditionPage';
import Paper from 'pages/Paper/PaperPage';
import CurrierMessages from 'pages/CurrierPage/pages/CurrierMessagesPage';
import { UserProvider } from 'contexts/UserContext';
import PostenPage from 'pages/PostenPage/PostenPage';
import PostenDistributionSearch from 'components/PostenDistributionSearch/PostenDistributionSearch';
import PostenDistributionRoutes from 'pages/PostenPage/compontents/PostenDistributionRoutes';
import PaperRetail from 'pages/PaperRetail/PaperRetail';
import CurrierPage from 'pages/CurrierPage/CurrierPage';
import CurrierRoutesPage from 'pages/CurrierPage/pages/CurrierRoutesPage';
import CurrierRouteUsersPage from 'pages/CurrierPage/pages/CurrierRouteUsersPage';
import PostenCard from 'pages/PostenPage/compontents/PostenCard/PostenCard';
import PolarisPage from 'pages/PolarisPage/PolarisPage';
import PolarisCard from 'pages/PolarisPage/components/PolarisCard/PolarisCard';
import PaperUserManagementPage from 'pages/PaperUserManagementPage/PaperUserManagementPage';
import PaperUserManagementNoAddress from 'pages/PaperUserManagementPage/components/PaperUserManagementNoAddress';
import PaperUserManagementInvalidPostCode from 'pages/PaperUserManagementPage/components/PaperUserManagementInvalidPostCode';
import UnhandledUserCurrierRoutes from 'pages/PaperUserManagementPage/components/UnhandledUserCurrierRoutes';
import PolarisAddressOverview from 'pages/PolarisPage/components/PolarisAddressOverview';

PaperUserManagementNoAddress;
import DataCheck from 'pages/DataCheck/DataCheck';
import FullDistributionPage from 'pages/FullDistributionPage/FullDistributionPage';
import NotificationContainer from 'pages/Notifications/NotificationContainer';
import NotificationList from 'pages/Notifications/components/NotificationList';
import Notification from 'pages/Notifications/components/Notification';
import BulkPage from 'pages/BulkPage/BulkPage';
import Texts from 'pages/Texts/Texts';

export const CHANGE_EMAIL: Route = {
  path: '/change-email/:token',
  component: <ChangeEmail />
};

export const ROUTE_PATHS = {
  CHANGE_EMAIL: '/change-email/:token',
  LOGIN: '/login',
  CHANGE_PASSWORD: '/change-password/:token',
  FORGOT_PASSWORD: '/forgot-password',
  SUBSCRIPTION: '/subscriptions/:id',
  SUBSCRIPTIONS: '/subscriptions',
  USERS: '/users',
  USER: '/users/:id',
  USER_SEARCH: '/users/search',
  ADMINS: '/admins',
  PROFILE: '/profile',
  TEXTS: '/texts',
  SETTINGS: '/settings',
  SETTINGS_TAB: '/settings/:category',
  // Paper
  PAPER: '/paper',
  EDITION: '/paper/editions/:id',
  DISTRIBUTION: '/paper/distribution',
  FULL_DISTRIBUTION: '/paper/distribution/full-distribution',
  PAPER_RETAIL: '/paper/retail',
  POSTEN: '/paper/posten',
  POSTEN_POST_CODE_INFO: '/paper/posten/post-code-info',
  POSTEN_DISTRIBUTION_ROUTES: '/paper/posten/distribution-route',
  POSTEN_SETTINGS: '/paper/posten/settings',
  POLARIS: '/paper/polaris',
  POLARIS_SETTINGS: '/paper/polaris/settings',
  POLARIS_ADDRESS_OVERVIEW: '/paper/polaris/address-overview',
  CURRIER: '/paper/currier',
  CURRIER_ROUTES: '/paper/currier/routes',
  CURRIER_ROUTE_USERS: '/paper/currier/route/:id/users',
  CURRIER_ROUTES_UNHANDLED_USERS: '/paper/currier/routes/uhandled-users',
  CURRIER_MESSAGES: '/paper/currier/messages',
  BULK: '/paper/bulk',
  PAPER_USER_MANAGEMENT: '/paper/user-management',
  PAPER_USER_MANAGEMENT_NO_ADDRESS: '/paper/user-management/no-address',
  PAPER_USER_MANAGEMENT_UNHANDLED_CURRIER_ROUTES: '/paper/user-management/unhandled-currier-routes',
  PAPER_USER_MANAGEMENT_INVALID_POST_CODE: '/paper/user-management/invalid-post-code',
  REPORTS: '/reports',
  REPORT: '/report/:reportSlug',
  INVOICES: '/invoices/',
  PAYMENT: '/payments/:id',
  PAYMENTS: '/payments',
  PAYMENT_SEARCH: '/payments/search',
  JOBS: '/jobs',
  ERROR: '/error',
  DATA_CHECK: '/data-check',
  NOTIFICATION: '/notification',
  NOTIFICATION_LIST: '/notification/list',
  NOTIFICATION_DETAILS: '/notification/:slug',
  DEFAULT: '/'
};

const routes: { [k: string]: RouteItem } = {
  // Public
  //--------------------------------------
  CHANGE_EMAIL: {
    path: ROUTE_PATHS.CHANGE_EMAIL,
    component: <ChangeEmail />,
    private: false
  },
  LOGIN: {
    path: ROUTE_PATHS.LOGIN,
    component: <Login />,
    private: false
  },
  CHANGE_PASSWORD: {
    path: ROUTE_PATHS.CHANGE_PASSWORD,
    component: <ChangePassword />,
    private: false
  },
  FORGOT_PASSWORD: {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    component: <ForgotPassword />,
    private: false
  },
  ERROR: {
    path: ROUTE_PATHS.ERROR,
    component: <ErrorPage />,
    private: false
  },

  // Private
  //--------------------------------------
  SUBSCRIPTIONS: {
    roles: [role.ADMINISTRATOR, role.HOST],
    path: ROUTE_PATHS.SUBSCRIPTIONS,
    menuTitle: 'common.subscriptions',
    component: (
      <Dashboard contentPadding title={'common.subscriptions'}>
        <Subscriptions />
      </Dashboard>
    ),
    private: true
  },
  SUBSCRIPTION: {
    roles: [role.ADMINISTRATOR, role.HOST],
    path: ROUTE_PATHS.SUBSCRIPTION,
    component: (
      <Dashboard>
        <SubscriptionTabs />
      </Dashboard>
    ),
    private: true
  },
  PAPER: {
    roles: Object.values(role),
    path: ROUTE_PATHS.PAPER,
    menuTitle: 'common.paper',
    component: (
      <Dashboard title={'common.paper'} sx={{ height: '100vh' }}>
        <Paper />
      </Dashboard>
    ),
    private: true,
    childRoutes: {
      EDITION: {
        roles: Object.values(role),
        path: ROUTE_PATHS.EDITION,
        component: <EditionPage />,
        private: true
      },
      CURRIER: {
        roles: Object.values(role),
        path: ROUTE_PATHS.CURRIER,
        component: <CurrierPage />,
        private: true,
        childRoutes: {
          CURRIER_ROUTES: {
            roles: Object.values(role),
            path: ROUTE_PATHS.CURRIER_ROUTES,
            component: <CurrierRoutesPage />,
            private: true
          },
          CURRIER_MESSAGES: {
            roles: Object.values(role),
            path: ROUTE_PATHS.CURRIER_MESSAGES,
            component: <CurrierMessages />,
            private: true
          },
          CURRIER_ROUTE_USERS: {
            roles: Object.values(role),
            path: ROUTE_PATHS.CURRIER_ROUTE_USERS,
            component: <CurrierRouteUsersPage />,
            private: true
          },
          CURRIER_ROUTES_UNHANDLED_USERS: {
            roles: Object.values(role),
            path: ROUTE_PATHS.CURRIER_ROUTES_UNHANDLED_USERS,
            component: <UnhandledUserCurrierRoutes />,
            private: true
          }
        }
      },
      BULK: {
        roles: Object.values(role),
        path: ROUTE_PATHS.BULK,
        component: <BulkPage />,
        private: true
      },
      PAPER_RETAIL: {
        roles: Object.values(role),
        path: ROUTE_PATHS.PAPER_RETAIL,
        component: <PaperRetail />,
        private: true
      },
      POLARIS: {
        roles: Object.values(role),
        path: ROUTE_PATHS.POLARIS,
        component: <PolarisPage />,
        private: true,
        childRoutes: {
          POLARIS_SETTINGS: {
            roles: Object.values(role),
            path: ROUTE_PATHS.POLARIS_SETTINGS,
            component: <PolarisCard />,
            private: true
          },
          POLARIS_ADDRESS_OVERVIEW: {
            roles: Object.values(role),
            path: ROUTE_PATHS.POLARIS_ADDRESS_OVERVIEW,
            component: <PolarisAddressOverview />,
            private: true
          }
        }
      },
      POSTEN: {
        roles: Object.values(role),
        path: ROUTE_PATHS.POSTEN,
        component: <PostenPage />,
        private: true,
        childRoutes: {
          POSTEN_POST_CODE_INFO: {
            roles: Object.values(role),
            path: ROUTE_PATHS.POSTEN_POST_CODE_INFO,
            component: <PostenDistributionSearch />,
            private: true
          },
          POSTEN_DISTRIBUTION_ROUTES: {
            roles: Object.values(role),
            path: ROUTE_PATHS.POSTEN_DISTRIBUTION_ROUTES,
            component: <PostenDistributionRoutes />,
            private: true
          },
          POSTEN_SETTINGS: {
            roles: Object.values(role),
            path: ROUTE_PATHS.POSTEN_SETTINGS,
            component: <PostenCard />,
            private: true
          }
        }
      },
      PAPER_USER_MANAGEMENT: {
        roles: Object.values(role),
        path: ROUTE_PATHS.PAPER_USER_MANAGEMENT,
        component: <PaperUserManagementPage />,
        private: true,
        childRoutes: {
          PAPER_USER_MANAGEMENT_NO_ADDRESS: {
            roles: Object.values(role),
            path: ROUTE_PATHS.PAPER_USER_MANAGEMENT_NO_ADDRESS,
            component: <PaperUserManagementNoAddress />,
            private: true
          },
          PAPER_USER_MANAGEMENT_UNHANDLED_CURRIER_ROUTES: {
            roles: Object.values(role),
            path: ROUTE_PATHS.PAPER_USER_MANAGEMENT_UNHANDLED_CURRIER_ROUTES,
            component: <UnhandledUserCurrierRoutes />,
            private: true
          },
          PAPER_USER_MANAGEMENT_INVALID_POST_CODE: {
            roles: Object.values(role),
            path: ROUTE_PATHS.PAPER_USER_MANAGEMENT_INVALID_POST_CODE,
            component: <PaperUserManagementInvalidPostCode />,
            private: true
          }
        }
      },
      FULL_DISTRIBUTION: {
        roles: Object.values(role),
        path: ROUTE_PATHS.FULL_DISTRIBUTION,
        component: <FullDistributionPage />,
        private: true
      }
    }
  },
  ADMINS: {
    roles: [role.ADMINISTRATOR, role.HOST],
    path: ROUTE_PATHS.ADMINS,
    menuTitle: 'common.admins',
    component: (
      <Dashboard contentPadding title={'common.admins'}>
        <Admins />
      </Dashboard>
    ),
    private: true
  },
  JOBS: {
    roles: [role.HOST],
    path: ROUTE_PATHS.JOBS,
    menuTitle: 'common.jobs',
    component: (
      <Dashboard contentPadding title={'common.jobs'}>
        <Jobs />
      </Dashboard>
    ),
    private: true
  },
  PROFILE: {
    path: ROUTE_PATHS.PROFILE,
    roles: Object.values(role),
    component: (
      <Dashboard contentPadding title={'common.myProfile'}>
        <Profile />
      </Dashboard>
    ),
    private: true
  },
  SETTINGS: {
    path: ROUTE_PATHS.SETTINGS,
    menuTitle: 'common.settings',
    roles: [role.HOST],
    component: (
      <Dashboard contentPadding title={'Settings'}>
        <Settings />
      </Dashboard>
    ),
    private: true
  },
  TEXTS: {
    path: ROUTE_PATHS.TEXTS,
    menuTitle: 'common.texts',
    roles: [role.HOST],
    component: (
      <Dashboard contentPadding title={'Texts'}>
        <Texts />
      </Dashboard>
    ),
    private: true
  },
  DATA_CHECK: {
    path: ROUTE_PATHS.DATA_CHECK,
    menuTitle: 'common.dataCheck',
    roles: [role.HOST],
    component: (
      <Dashboard contentPadding title={'common.dataCheck'}>
        <DataCheck />
      </Dashboard>
    ),
    private: true
  },
  NOTIFICATION: {
    path: ROUTE_PATHS.NOTIFICATION,
    component: (
      <Dashboard contentPadding title={'common.notifications'}>
        <NotificationContainer />
      </Dashboard>
    ),
    private: true,
    childRoutes: {
      LIST: {
        menuTitle: 'common.notifications',
        roles: [role.HOST],
        path: ROUTE_PATHS.NOTIFICATION_LIST,
        component: <NotificationList />,
        private: true
      },
      DETAILS: {
        roles: [role.HOST],
        path: ROUTE_PATHS.NOTIFICATION_DETAILS,
        component: <Notification />,
        private: true
      }
    }
  },
  REPORTS: {
    path: ROUTE_PATHS.REPORTS,
    menuTitle: 'common.reports',
    roles: [role.HOST, role.ADMINISTRATOR],
    component: (
      <Dashboard contentPadding title={'common.reports'}>
        <ReportList />
      </Dashboard>
    ),
    private: true
  },
  REPORT: {
    path: ROUTE_PATHS.REPORT,
    roles: [role.HOST, role.ADMINISTRATOR],
    component: (
      <Dashboard contentPadding title={'common.reports'}>
        <Report />
      </Dashboard>
    ),
    private: true
  },
  INVOICES: {
    path: ROUTE_PATHS.INVOICES,
    menuTitle: 'common.invoices',
    roles: [role.HOST, role.ADMINISTRATOR],
    component: (
      <Dashboard contentPadding title={'common.invoices'}>
        <Invoices />
      </Dashboard>
    ),
    private: true
  },
  PAYMENTS: {
    path: ROUTE_PATHS.PAYMENTS,
    menuTitle: 'common.payments',
    roles: Object.values(role),
    component: (
      <Dashboard contentPadding sx={{ height: '100vh' }} title={'common.payments'}>
        <Payments />
      </Dashboard>
    ),
    private: true,
    childRoutes: {
      PAYMENT: {
        path: ROUTE_PATHS.PAYMENT,
        roles: Object.values(role),
        component: <Payment />,
        private: true
      },
      PAYMENT_SEARCH: {
        path: ROUTE_PATHS.PAYMENT_SEARCH,
        roles: Object.values(role),
        component: <PaymentsSearch />,
        private: true
      }
    }
  },
  USERS: {
    path: ROUTE_PATHS.USERS,
    roles: [role.ADMINISTRATOR, role.HOST],
    menuTitle: 'common.users',
    component: (
      <Dashboard contentPadding sx={{ height: '100vh' }} title={'common.users'}>
        <Users />
      </Dashboard>
    ),
    private: true,
    childRoutes: {
      USER: {
        roles: [role.ADMINISTRATOR, role.HOST],
        path: ROUTE_PATHS.USER,
        component: (
          <UserProvider>
            <UserTab />
          </UserProvider>
        ),
        private: true
      },
      USER_SEARCH: {
        roles: [role.ADMINISTRATOR, role.HOST],
        path: ROUTE_PATHS.USER_SEARCH,
        component: <UserSearch />,
        private: true
      }
    }
  },
  DEFAULT: {
    path: ROUTE_PATHS.DEFAULT,
    menuTitle: 'common.dashboard',
    roles: Object.values(role),
    component: (
      <Dashboard contentPadding title={'Dashboard'}>
        <StartPage />
      </Dashboard>
    ),
    private: true
  }
};

export default routes;
