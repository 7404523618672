import { FC, useState } from 'react';
import { Alert, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import useEditAdmin from 'pages/Profile/useEditAdmin';
import routes from 'consts/routes';
import { PageContainer, PaperCard, PaperContent, PaperHeader } from 'components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { validate } from 'utils';
import axios, { AxiosError } from 'axios';
import logger from 'logger/logger';

type ChangeEmailParams = {
  token: string;
};

type ChangeEmailFields = {
  email: string;
};

type View = {
  loading?: boolean;
  form?: boolean;
  success?: boolean;
  errorMessage?: string;
  error?: boolean;
};

const ChangeEmail: FC = () => {
  const { changeEmail } = useEditAdmin();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams<ChangeEmailParams>();
  const { register, handleSubmit } = useForm<ChangeEmailFields>();
  const [view, setView] = useState<View>({
    loading: false,
    form: true,
    success: false,
    errorMessage: null,
    error: false
  });

  const onSubmit = async (fields: ChangeEmailFields) => {
    updateView({
      loading: true
    });
    const { email } = fields;

    try {
      await changeEmail(email, token);
      updateView({
        success: true
      });
    } catch (error) {
      let message = 'Client error';
      
      logger.error('Change email failed: ', error);

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;
        if (axiosError.response && axiosError.response.status === 401) {
          message = t('alerts.invalidToken');
        } else {
          message = axiosError.message;
        }
      }

      updateView({
        error: true,
        errorMessage: message
      });
    }
  };

  const updateView = (updatedView: View) => {
    setView({
      ...{
        loading: false,
        form: false,
        success: false,
        errorMessage: null,
        error: false
      },
      ...updatedView
    });
  };

  const signOutAndGoToLogin = async () => {
    navigate(routes.LOGIN.path);
  };

  return (
    <>
      <PageContainer>
        <PaperCard sx={{ minWidth: '400px' }}>
          <PaperHeader>
            <Typography variant={'h4'}>{t('profile.addYourNewEmail')}</Typography>
          </PaperHeader>
          <PaperContent>
            {view.form && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
              >
                <TextField
                  {...register('email', {
                    validate: (val) => validate.email(val),
                    required: true
                  })}
                  type={'email'}
                  placeholder={t('common.newEmail')}
                  fullWidth
                />
                <Button type="submit" variant={'contained'}>
                  {t('common.changeEmail')}
                </Button>
              </form>
            )}
            {view.error && (
              <>
                <Alert severity="error">{view.errorMessage}</Alert>
                <Button
                  sx={{ marginTop: '10px' }}
                  onClick={signOutAndGoToLogin}
                  variant="contained"
                >
                  {t('common.goToLogin')}
                </Button>
              </>
            )}
            {view.success && (
              <>
                <Alert severity="success">
                  <Typography>{t('alerts.emailWasSuccessfullyChanged')}</Typography>
                  <Button onClick={signOutAndGoToLogin} variant="contained">
                    {t('common.goToLogin')}
                  </Button>
                </Alert>
              </>
            )}
            {view.loading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress sx={{ margin: '10px 0' }} />
              </div>
            )}
          </PaperContent>
        </PaperCard>
      </PageContainer>
    </>
  );
};

export default ChangeEmail;
