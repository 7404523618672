import axios, { AxiosPromise } from 'axios';
import PageResults from 'types/PageResults';
import PostenDistributionData from 'types/PostenDistributionData';
import PostenDistributionRoute, { PostCodeInfo } from 'types/PostenDistributionRoute';
import SearchQuery from 'types/SearchQuery';

const postenDistributionService = {
  importPostenPostalCodeInfoTxtFile: (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post('distribution/posten/import-txt', formData);
  },
  getInfo: () => {
    return axios.get('distribution/posten/info');
  },
  searchPostCodeInfo: (
    searchQuery: SearchQuery,
    limit = 50,
    offset = 0
  ): AxiosPromise<PageResults<PostenDistributionData>> => {
    return axios.post(`distribution/posten/search?limit=${limit}&offset=${offset}`, searchQuery);
  },
  getPostCodesDataByFromTo: (
    postCodeFrom: string,
    postCodeTo: string
  ): AxiosPromise<PostCodeInfo[]> => {
    return axios.post('distribution/posten/post-codes-data', {
      postCodeFrom,
      postCodeTo
    });
  },
  // distribution routes
  getDistributionRoutes: () => {
    return axios.get('distribution/posten/route');
  },
  createDistributionRoute: (route: PostenDistributionRoute, postCodeInfoIds: number[]) => {
    return axios.post('distribution/posten/route', {
      postenDistributionRoute: route,
      postCodeInfoIds
    });
  },
  updateDistributionRoute: (
    id: number,
    route: PostenDistributionRoute,
    postCodeInfoIds: number[]
  ) => {
    return axios.put(`distribution/posten/route/${id}`, {
      postenDistributionRoute: route,
      postCodeInfoIds
    });
  },
  deleteDistributionRoute: (id: number) => {
    return axios.delete(`distribution/posten/route/${id}`);
  },
  getLocalPostenDistributionRoutes: () => {
    return axios.get('distribution/posten/route/local');
  },
  getLocalRouteFromPostCode: (postCode: string) => {
    return axios.get(`distribution/posten/route/local/post-code/${postCode}`);
  },
  downloadPostCodeFiles: (): AxiosPromise<{ base64: string; name: string }> => {
    return axios.get('distribution/posten/route/post-code-files');
  }
};

export default postenDistributionService;
