import axios, { AxiosPromise } from 'axios';
import UserAddress from 'types/UserAddress';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const userAddressService = {
  getUserAddresse: () => {
    return axios.get('user/address/:userId');
  },
  updateUserAddress: (userId: number, userAddress: UserAddress): AxiosPromise<UserAddress> => {
    return axios.put(`user/address/${userId}`, userAddress);
  },
  createUserAddress: (userId: number, userAddress: UserAddress): AxiosPromise<UserAddress> => {
    return axios.post(`user/address/${userId}`, userAddress);
  }
};

export default userAddressService;
