import './Admins.scss';
import { FC, useEffect, useState } from 'react';
import { PageContainer, PaperCard, PaperContent, PaperHeader } from 'components';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { EditAdminModal, ConsentModal } from 'modals';
import adminService from 'services/admin-service';
import Admin from 'types/Admin';
import useAlert from 'hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import logger from 'logger/logger';

const MODAL = {
  addAdmin: 0,
  editAdmin: 1
};

const Admins: FC = () => {
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [showModal, setShowModal] = useState<number>(-1);
  const [adminToDelete, setAdminToDelete] = useState<Admin>(null);
  const [adminToEdit, setAdminToEdit] = useState<Admin>(null);
  const { addAlert } = useAlert();
  const { t } = useTranslation();

  const deleteAdmin = async () => {
    try {
      await adminService.delete(adminToDelete.id);
      addAlert('success', `Admin ${adminToDelete.name} has been deleted`);
      const updatedAdmins = admins.filter((admin) => admin.id != adminToDelete.id);
      setAdmins(updatedAdmins);
    } catch (error) {
      logger.error('Delete admin failed:', error);
      addAlert('error', 'Could not delete admin');
    } finally {
      setAdminToDelete(null);
    }
  };

  const addNewAdmin = async (admin: Admin) => {
    try {
      const addedAdmin = (await adminService.add(admin)).data;
      setAdmins([...admins, addedAdmin]);

      setShowModal(-1);
      addAlert(
        'success',
        t('alerts.adminAddedMessage', {
          name: addedAdmin.name
        }),
        5000
      );
    } catch (error) {
      addAlert('error', (error as Error)?.message);
    }
  };
  const updateAdmin = async (admin: Admin) => {
    try {
      const updatedAdmin = (await adminService.update(admin)).data;
      const i = admins.findIndex((a) => a.id === updatedAdmin.id);
      admins[i] = updatedAdmin;
      setAdmins(admins);

      setShowModal(-1);
      addAlert(
        'success',
        t('alerts.adminUpdatedMessage', {
          name: updatedAdmin.name
        }),
        5000
      );
    } catch (error) {
      addAlert('error', (error as Error)?.message);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await adminService.get();
        setAdmins(data);
      } catch (error) {
        logger.error('Get admin failed: ',error);
      }
    })();
  }, []);

  return (
    <PageContainer>
      <PaperCard className='admins-container' /*sx={{ minWidth: '700px' }}*/>
        <PaperHeader >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant={'h4'}>{t('common.admins')}</Typography>
            <Button
              onClick={() => {
                setShowModal(MODAL.addAdmin);
              }}
            >
              {t('common.addAdmin')}
            </Button>
          </Box>
        </PaperHeader>
        <PaperContent np>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>
                  <Typography>{t('common.role')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.email')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.name')}</Typography>
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins.map((admin) => {
                return (
                  <TableRow key={admin.id}>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setAdminToEdit(admin);
                          setShowModal(MODAL.editAdmin);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Typography>{t(`role.${admin.role}`)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{admin.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{admin.name}</Typography>
                    </TableCell>
                    <TableCell align={'right'}>
                      <IconButton edge={'end'} onClick={() => setAdminToDelete(admin)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </PaperContent>
      </PaperCard>
      {showModal == MODAL.addAdmin && (
        <EditAdminModal
          admin={adminToEdit}
          onConfirm={addNewAdmin}
          onCancel={() => {
            setShowModal(-1);
          }}
        />
      )}
      {showModal == MODAL.editAdmin && (
        <EditAdminModal
          admin={adminToEdit}
          onConfirm={updateAdmin}
          onCancel={() => {
            setShowModal(-1);
          }}
        />
      )}
      {adminToDelete && (
        <ConsentModal
          title={'Delete admin'}
          content={`Are you sure you want to delete admin ${adminToDelete.name}`}
          onCancel={() => setAdminToDelete(null)}
          onConfirm={deleteAdmin}
        />
      )}
    </PageContainer>
  );
};

export default Admins;
