/* eslint-disable indent */
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import FormDialog from 'components/FormDialog/FormDialog';
import { ChangeEvent, FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import campaignService from 'services/campaign-service';
import Subscription from 'types/Subscription';
import Campaign from 'types/Campaign';
import CampaignFields from 'types/CampaignFields';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';
import ValLabel from 'types/ValLabel';

type CampaignModalProps = {
  onCancel: () => void;
  onConfirm: (subscription: Subscription) => void;
  onDelete?: () => void;
  subscriptionId: number;
  campaign?: Campaign;
};

const CampaignModal: FC<CampaignModalProps> = ({
  onCancel,
  onConfirm,
  onDelete,
  campaign,
  subscriptionId
}) => {
  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<CampaignFields>({
    defaultValues: campaign
      ? {
          name: campaign.name,
          description: campaign.description,
          price: campaign.price.toString(),
          durationDays: campaign.durationDays.toString(),
          outTheYear: campaign.outTheYear ? true : false,
          onlyNewUsers: campaign.onlyNewUsers ? true : false,
          status: campaign.status
        }
      : null
  });
  const [loading, setLoading] = useState(false);
  const [campaignType, setCampaignType] = useState(
    campaign?.outTheYear ? 'outTheYear' : 'durationDays'
  );

  const onSubmit: SubmitHandler<CampaignFields> = async (fields) => {
    setLoading(true);
    try {
      if (campaignType === 'outTheYear') {
        fields.durationDays = null;
      }
      fields.outTheYear = campaignType === 'outTheYear' ? true : false;

      let campaignRes = null;
      if (campaign) {
        const { data } = await campaignService.update(campaign.id, fields);
        campaignRes = data;
      } else {
        const { data } = await campaignService.add(subscriptionId, fields);
        campaignRes = data;
      }

      setLoading(false);
      onConfirm(campaignRes);
    } catch (error) {
      if (campaign) {
        axiosAlert(
          error as AxiosError,
          [
            {
              status: 400,
              message: t('campaign.couldNotEdit')
            }
          ],
          t('common.somethingWentWrong')
        );
      } else {
        axiosAlert(
          error as AxiosError,
          [
            {
              status: 400,
              message: t('campaign.couldNotAdd')
            }
          ],
          t('common.somethingWentWrong')
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCampaignTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCampaignType((event.target as HTMLInputElement).value);
  };
  const campaignStatusOptions: ValLabel[] = [
    { value: 'INACTIVE', label: t('campaign.status.INACTIVE') },
    { value: 'ACTIVE', label: t('campaign.status.ACTIVE') },
    { value: 'HIDDEN', label: t('campaign.status.HIDDEN') }
  ];

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={
        campaign
          ? t('campaign.editName', {
              name: campaign.name
            })
          : t('campaign.new')
      }
      loading={loading}
      submitText={campaign ? t('common.saveChanges') : t('campaign.createNew')}
      actions={
        campaign && (
          <Button color="error" onClick={() => onDelete()}>
            {t('common.remove')}
          </Button>
        )
      }
    >
      <div className="dialog-content">
        <TextField
          sx={{ m: '10px 0' }}
          fullWidth
          label={t('common.name')}
          {...register('name', {
            required: t('alerts.subscriptionNameIsRequired').toString(),
            minLength: 2,
            maxLength: 50
          })}
          error={errors.name ? true : false}
        />
        <TextField
          multiline
          sx={{ m: '10px 0' }}
          fullWidth
          rows={2}
          label={t('common.description')}
          {...register('description', {
            required: t('alerts.descriptionIsRequired').toString(),
            minLength: 2,
            maxLength: 300
          })}
          error={errors.description ? true : false}
        />
        <TextField
          sx={{ m: '10px 0' }}
          fullWidth
          type="number"
          label={t('common.price') + ' kr'}
          {...register('price', {
            required: t('alerts.priceIsRequired').toString(),
            min: 1,
            max: 99999,
            onChange: (e) => setValue('price', Math.floor(parseInt(e.target.value, 10)).toString())
          })}
          error={errors.price ? true : false}
        />
        <RadioGroup
          defaultValue="durationDays"
          name="campaignType"
          value={campaignType}
          onChange={handleCampaignTypeChange}
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <FormControlLabel
            value="durationDays"
            control={<Radio />}
            label={t('common.durationDays')}
          />
          <FormControlLabel value="outTheYear" control={<Radio />} label={t('common.outTheYear')} />
        </RadioGroup>
        {campaignType === 'durationDays' && (
          <TextField
            sx={{ m: '10px 0' }}
            fullWidth
            type="number"
            label={t('common.numberOfDays')}
            prefix={'mnd'}
            {...register('durationDays', {
              required:
                campaignType === 'durationDays'
                  ? t('alerts.blankIsRequired', {
                      blank: t('common.durationDays')
                    }).toString()
                  : false,
              min: 1,
              max: 900,
              onChange: (e) =>
                setValue('durationDays', Math.floor(parseInt(e.target.value, 10)).toString())
            })}
            error={errors.durationDays ? true : false}
          />
        )}
        <div style={{ margin: '20px 0' }}>
          <FormControl fullWidth>
            <InputLabel>{t('common.status')}</InputLabel>
            <Select
              label={t('common.status')}
              defaultValue={campaign ? campaign.status : 'INACTIVE'}
              {...register('status', { required: true })}
            >
              {campaignStatusOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </FormDialog>
  );
};

export default CampaignModal;
