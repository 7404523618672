import { PageContainer, PaperCard } from 'components';
import { InvalidUserSubscriptions } from './components/InvalidUserSubscriptions/InvalidUserSubscriptions';
import { InvalidPayments } from './components/InvalidPayments/InvalidPayments';
import { Button } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { InvalidOrders } from './components/InvalidOrders/InvalidOrders';
import { InvalidUsers } from './components/InvalidUsers/InvalidUsers';

const DataCheck = () => {
  const { t } = useTranslation();
  const [updated, setUpdated] = useState(new Date());

  return (
    <PageContainer>
      <PaperCard sx={{ width: '100%', padding: '20px' }}>
        <Button onClick={() => setUpdated(new Date())} variant={'outlined'}>
          <UpdateIcon /> &nbsp;{t('common.update')}
        </Button>
      </PaperCard>
      <InvalidUsers updated={updated} />
      <InvalidUserSubscriptions updated={updated} />
      <InvalidPayments updated={updated} />
      <InvalidOrders updated={updated} />
    </PageContainer>
  );
};

export default DataCheck;
