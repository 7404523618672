/* eslint-disable max-lines */
import { Edit } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PostenDistributionRoute, {
  PostCodeInfo,
  PostenDeliveryTask
} from 'types/PostenDistributionRoute';
import PostenDistributionPostCodesModal from './components/PostenDistributionPostCodesModal';
import getPostCodesFromTo from './utils/getPostCodesFromTo';
import postenDistributionService from 'services/posten-distribution-service';
import { ConsentModal } from 'modals';

type PostenDistributionRouteModalProps = {
  postenDistributionRoute?: PostenDistributionRoute;
  onCancel: () => void;
  onSaved: (postenDistributionRoute: PostenDistributionRoute) => void;
  onDeleted?: (postenDistributionRoute: PostenDistributionRoute) => void;
};

type PostenDistributionRouteFields = {
  name: string;
  transitNumber: string;
  alternateTransitNumber: string;
  carNumber: string;
  carText: string;
  dropNumber: string;
  dropText: string;
  named: boolean;
  dropText2: string;
  deliveryTask: PostenDeliveryTask;
  postCodeInfoList?: PostCodeInfo[];
  divideIntoBundles?: boolean;
};

const PostenDistributionRouteModal: FC<PostenDistributionRouteModalProps> = ({
  postenDistributionRoute,
  onCancel,
  onSaved,
  onDeleted
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, watch, control } =
    useForm<PostenDistributionRouteFields>({
      defaultValues: {
        deliveryTask: 'regional',
        named: true,
        ...postenDistributionRoute
      }
    });

  const [modal, setModal] = useState<number>();
  const [loading, setLoading] = useState<boolean>();
  const postCodeInfoList = watch('postCodeInfoList');

  const title = postenDistributionRoute
    ? `${t('common.edit')} `
    : `${t('common.add')} ` + (t('common.postenDistributionRoute') as string).toLowerCase();

  const postCodesFromTo = getPostCodesFromTo(postCodeInfoList);

  const onSubmit = async (fields: PostenDistributionRouteFields) => {
    setLoading(true);

    const postCodeInfoIds = postCodeInfoList?.map((postCodeData) => postCodeData.id);

    try {
      if (postenDistributionRoute) {
        // Update
        await postenDistributionService.updateDistributionRoute(
          postenDistributionRoute.id,
          fields,
          postCodeInfoIds
        );
      } else {
        // Create
        await postenDistributionService.createDistributionRoute(fields, postCodeInfoIds);
      }

      onSaved(fields);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      await postenDistributionService.deleteDistributionRoute(postenDistributionRoute?.id);
      onDeleted(postenDistributionRoute);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ width: '650px' }}>
          <Stack direction={'row'} gap={2}>
            <Stack spacing={2}>
              <TextField {...register('name')} label={t('common.name')} required={true} />
              <TextField
                {...register('transitNumber')}
                label={t('common.transitNumber')}
                required={true}
              />
              <Stack direction={'row'} gap={2}>
                <TextField
                  fullWidth
                  {...register('carNumber')}
                  label={t('common.carNumber')}
                  required={true}
                />
                <TextField
                  fullWidth
                  {...register('carText')}
                  label={t('common.carText')}
                  required={true}
                />
              </Stack>
              <TextField
                {...register('dropNumber')}
                label={t('common.dropNumber')}
                required={true}
              />
              <TextField {...register('dropText')} label={t('common.dropText')} required={true} />
              <TextField {...register('dropText2')} label={t('common.dropText2')} />
              <Stack
                gap={2}
                alignItems={'center'}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <Typography>{t('common.postCodes')}</Typography>
                  <Chip label={postCodeInfoList?.length ?? 0} />
                </Stack>
                <IconButton onClick={() => setModal(1)}>
                  <Edit />
                </IconButton>
              </Stack>
              <Stack
                sx={{
                  height: '70px',
                  padding: '10px',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  color: 'rgba(255,255,255,0.8)',
                  whiteSpace: 'pre',
                  overflowY: 'scroll',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  gap: 2
                }}
              >
                {postCodesFromTo?.map((postCodeData, index) => (
                  <Chip
                    key={index}
                    label={
                      postCodeData.postCodeFrom +
                      (postCodeData.postCodeTo ? ' - ' + postCodeData.postCodeTo : '')
                    }
                  />
                ))}
                {postCodeInfoList?.length < 1 && <Typography>{t('common.noPostCodes')}</Typography>}
              </Stack>
              <Typography
                sx={{
                  marginTop: '24px !important'
                }}
              >
                {t('common.distribution2')}
              </Typography>
              <Stack gap={2}>
                <Controller
                  name='deliveryTask'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t('common.distribution2').toString()}
                      sx={{ minWidth: '200px' }}
                    >
                      <MenuItem value={'local'}>{t('common.local')}</MenuItem>
                      <MenuItem value={'regional'}>{t('common.regional')}</MenuItem>
                      <MenuItem value={'national'}>{t('common.outsideOfRegion')}</MenuItem>
                    </TextField>
                  )}
                />
              </Stack>
            </Stack>
            <Divider orientation='vertical' flexItem />
            <Stack gap={1}>
              <Typography variant='h6'>{t('common.settings')}</Typography>
              <FormControlLabel
                sx={{
                  ['&.MuiFormControlLabel-root']: {
                    mt: 0
                  }
                }}
                control={
                  <Controller
                    name='divideIntoBundles'
                    control={control}
                    render={({ field }) => <Checkbox {...field} defaultChecked={field.value} />}
                  />
                }
                label={t('common.divideIntoBundles')}
              />
              <FormControlLabel
                sx={{
                  ['&.MuiFormControlLabel-root']: {
                    mt: 0
                  }
                }}
                control={
                  <Controller
                    name='named'
                    control={control}
                    render={({ field }) => <Checkbox {...field} defaultChecked={field.value} />}
                  />
                }
                label={t('common.named')}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          {postenDistributionRoute && (
            <Button disabled={loading} onClick={() => setModal(2)} color={'error'}>
              {t('common.delete')}
            </Button>
          )}
          <Button disabled={loading} onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button disabled={loading} type={'submit'}>
            {t('common.save')}
          </Button>
          {loading && <CircularProgress size={'small'} />}
        </DialogActions>
      </form>
      {modal === 1 && (
        <PostenDistributionPostCodesModal
          postCodeInfoList={postCodeInfoList}
          onCancel={() => setModal(undefined)}
          onConfirm={(_postCodeInfoList: PostCodeInfo[]) => {
            setModal(undefined);
            setValue('postCodeInfoList', _postCodeInfoList);
          }}
        />
      )}
      {modal === 2 && (
        <ConsentModal
          title={t('common.confirmDelete')}
          confirmText={t('common.delete')}
          onConfirm={handleDelete}
          onCancel={() => setModal(undefined)}
        />
      )}
    </Dialog>
  );
};

export default PostenDistributionRouteModal;
