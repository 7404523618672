import axios, { AxiosPromise } from 'axios';
import UserInvoiceSettings from 'types/UserInvoiceSettings';

const baseUrl = 'user/invoice-settings';

const userInvoiceSettingsService = {
  getByUserId: (userId: number): AxiosPromise<UserInvoiceSettings> => {
    return axios.get(`${baseUrl}/by-user-id/${userId}`);
  },
  update: (
    userInvoiceSettings: Partial<UserInvoiceSettings>
  ): AxiosPromise<UserInvoiceSettings> => {
    return axios.put(`${baseUrl}/${userInvoiceSettings.id}`, userInvoiceSettings);
  },
  refreshAutoPaymentMethod: (userId: number): AxiosPromise<UserInvoiceSettings> => {
    return axios.patch(`${baseUrl}/refresh-auto-payment-method/${userId}`);
  },
  refreshEInvoiceReference: (userId: number): AxiosPromise<UserInvoiceSettings> => {
    return axios.patch(`${baseUrl}/refresh-e-invoice-reference/${userId}`);
  },
  add: (userId: number): AxiosPromise<UserInvoiceSettings> => {
    return axios.post(`${baseUrl}`, {
      userId
    });
  }
};

export default userInvoiceSettingsService;
