import axios from 'axios';
import Admin from 'types/Admin';

const adminService = {
  get: () => {
    return axios.get<Admin[]>('admin/');
  },
  add: (admin: Admin) => {
    return axios.post<Admin>('admin/', admin);
  },
  update: (admin: Admin) => {
    return axios.put<Admin>(`admin/${admin.id}`, admin);
  },
  delete: (adminId: string) => {
    return axios.delete(`admin/${adminId}`);
  },
  sendPassword: (password: string) => {
    return axios.post('send-password', { password });
  }
};

export default adminService;
