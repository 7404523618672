import {  CircularProgress, IconButton, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { PaperCard, PaperContent, PaperFooter, PaperHeader } from 'components';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dataCheckService from 'services/data-check.service';
import InspectUsersModal from './InspectInvalidUsersModal';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import COLORS from 'consts/colors';
type DataCheckModal = 'InspectUsers';

type InvalidUsersProps = {
  updated: Date;
};

export const InvalidUsers: FC<InvalidUsersProps> = ({updated}) => {
  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();
  const [modal, setModal] = useState<DataCheckModal>(null);
  const [users, setUsers] = useState(null);

  const getInvalidUsers = async () => {
    try {
      const usersQuery = await dataCheckService.getInvalidUsers();
      setUsers(usersQuery.data);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    }
  };

  useEffect(() => {
    if(users !== null) {
      setUsers(null);
    }
    getInvalidUsers();
  }, [updated]);
  return (
    <PaperCard sx={{minWidth: '250px'}}>
      <PaperHeader>
        <Typography variant={'h6'}>{t('dataCheck.invalidUsers')}</Typography>
      </PaperHeader>
      <PaperContent>
        <div style={{ textAlign: 'center' }}>
          {users ? (
            users.length > 0 ? (
              <>
                <Typography sx={{ fontSize: '5rem' }} color={'error'}>
                  {users.length}
                </Typography>
                <IconButton onClick={() => setModal('InspectUsers')} color='error'>
                  <TroubleshootIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Typography sx={{ fontSize: '5rem', color: COLORS.SUCCESS }}>
                  {users.length}
                </Typography>
                <TaskAltIcon sx={{ margin: '6px' }} color={'success'} />
              </>
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </PaperContent>
      <PaperFooter>
        {users ? (
          users.length > 0 ? (
            <>
              <Typography color='error' fontStyle={'italic'}>
                {t('dataCheck.invalidDataFound')}
              </Typography>
            </>
          ) : (
            <Typography sx={{ color: COLORS.SUCCESS }}>{t('dataCheck.allGood')}</Typography>
          )
        ) : (
          <CircularProgress />
        )}
      </PaperFooter>
      {modal === 'InspectUsers' && (
        <InspectUsersModal
          users={users}
          exit={() => setModal(null)}
        />
      )}
    </PaperCard>
  );
};
