import StandardReport from 'types/StandardReport';
import ExcelJS from 'exceljs';
import { t } from 'i18next';

type ExcelReport = {
  header: string[];
  translation?: boolean[];
  excelNumber?: boolean[];
  footer: ExcelRow[];
  rows: ExcelRow[][];
};

type ExcelRow = string | number;

declare global {
  interface Navigator {
    msSaveBlob?: (blob: Blob, defaultName?: string) => boolean;
  }
}

const convertToNumber = (value: string): number | string => {
  if (value === undefined || value === null || value === '') {
    return value;
  }
  value = value.replace(' Kr', '');
  value = value.replace(',', '.');
  const num = Number(value);
  if (isNaN(num)) {
    return value;
  }
  return num;
};

const convertToExcelReport = (_report: StandardReport): ExcelReport => {
  const report: ExcelReport = { ..._report };

  // Convert string to number if excelNumber is true
  if (!report.excelNumber) {
    return report;
  }
  report.excelNumber.map((e, i) => {
    if (e) {
      report.rows = report.rows.map((r) => {
        r[i] = convertToNumber(r[i] as string);
        return r;
      });

      report.footer[i] = convertToNumber(report.footer[i] as string);
    }
  });
  return report;
};

const saveFileOnUserDevice = async function (blob: Blob, filename: string) {
  if (navigator.msSaveBlob) {
    // For ie and Edge
    return navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename + '.xlsx';
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    link.remove();
    window.URL.revokeObjectURL(link.href);
  }
};

const excelService = {
  exportStandardReport: (_report: StandardReport, sheetName: string, fileName: string) => {
    const report = convertToExcelReport(_report);

    const ExcelJSWorkbook = new ExcelJS.Workbook();
    const worksheet = ExcelJSWorkbook.addWorksheet(sheetName);

    worksheet.addRow(report.header.map((s) => t(s)));

    report.rows.map((r) => {
      worksheet.addRow(r);
    });

    worksheet.addRow(report.footer);

    ExcelJSWorkbook.xlsx.writeBuffer().then(function (buffer) {
      saveFileOnUserDevice(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
    });
  }
};

export default excelService;
