import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { format, parse } from 'date-fns';

const TIME_FORMAT = 'HH:mm';

type ReactHookTimePickerProps = {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  validate?: (value: string) => boolean | string;
  error?: boolean;
  helperText?: string;
  required?: boolean | string;
};

const ReactHookTimePicker: FC<ReactHookTimePickerProps> = ({
  required = false,
  name,
  label,
  validate,
  error,
  control,
  helperText,
  ...props
}) => {
  return (
    <FormControl {...props}>
      <Controller
        rules={{
          required: required,
          validate: validate
        }}
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                ampm={false}
                label={label}
                views={['hours', 'minutes']}
                inputFormat='HH:mm'
                onChange={(value: Date) => {
                  onChange(format(value, TIME_FORMAT));
                }}
                ignoreInvalidInputs
                value={parse(value, TIME_FORMAT, new Date())}
                mask='__:__'
                renderInput={(params: TextFieldProps) => (
                  <TextField {...params} error={error} helperText={helperText} />
                )}
              />
            </LocalizationProvider>
          );
        }}
      />
    </FormControl>
  );
};

export default ReactHookTimePicker;
