import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import { PrimaryListItem } from 'components';
import { FC } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UserDistributionMetadata } from 'types/UserDistribution';

type UserDistributionMetadataViewProps = {
  metadata: UserDistributionMetadata;
};
type KeyValueArrObj = {
  key: string;
  value?: string;
};

const UserDistributionMetadataView: FC<UserDistributionMetadataViewProps> = ({ metadata }) => {
  const metadataKeyValue: KeyValueArrObj[] = [];
  for (const key in metadata) {
    if (Object.prototype.hasOwnProperty.call(metadata, key)) {
      if (typeof metadata[key] === 'string' || metadata[key] !== null) {
        metadataKeyValue.push({
          key: key,
          value: metadata[key]
        });
      }
    }
  }

  return (
    <Accordion sx={{ backgroundColor: 'rgba(0,0,0,0.5)', marginTop: '20px' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Metadata</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {metadataKeyValue.map(({ key, value }, i) => (
          <PrimaryListItem key={i} label={key} value={value} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default UserDistributionMetadataView;
