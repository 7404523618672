import axios, { AxiosError } from 'axios';
import logger from 'logger/logger';
import { useDispatch } from 'react-redux';
import authService from 'services/admin-auth-service';
import { adminActions } from 'store';
import useAxiosAlert from './useAxiosAlert';
import useSettings from './useSettings';

const useAuth = () => {
  const dispatch = useDispatch();
  const axiosAlert = useAxiosAlert();
  const { syncSettings } = useSettings();

  const signInAdmin = async (email: string, password: string) => {
    const res = await authService.signIn(email, password);

    if (res?.data?.token) {
      const tokenString = res.data.token;
      localStorage.setItem('auth-token', tokenString);

      axios.defaults.headers.common['Authorization'] = res.data.token;

      // get settings
      await syncSettings();

      const adminInfoQuery = await authService.getAdminInfo();
      dispatch(adminActions.set(adminInfoQuery.data));
    }

    return true;
  };

  const getAdmin = async () => {
    try {
      const adminInfoQuery = await authService.getAdminInfo();
      dispatch(adminActions.set(adminInfoQuery.data));
    } catch (error) {
      axiosAlert(error as AxiosError);
      logger.error('Get admin failed: ', error);
    }
  };

  const signOut = async () => {
    dispatch(adminActions.remove());
    localStorage.removeItem('auth-token');
  };

  return { signInAdmin, getAdmin, signOut };
};

export default useAuth;
