import { CircularProgress, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PaymentList from 'pages/Payments/components/PaymentsList/PaymentList';

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import paymentService from 'services/payment-service';
import Payment from 'types/Payment';
import User from 'types/User';

type UserPaymentsProps = {
  user: User;
};

const UserPayments: FC<UserPaymentsProps> = ({ user }) => {
  const { t } = useTranslation();
  const [payments, setPayments] = useState<Payment[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const getUserPayments = async () => {
    setLoading(true);
    try {
      const { data: paymentsRes }: { data: Payment[] } = await paymentService.getUserPayments(
        user.id
      );

      const paymentsWithUser = paymentsRes.map((payment) => {
        payment.user = user;
        return payment;
      });

      setPayments(paymentsWithUser);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserPayments();
  }, []);

  return (
    <Box sx={{ p: 0 }}>
      <Stack flexDirection={'column'} alignItems={'center'}>
        <Box sx={{ width: '100%', textAlign: 'left' }}>
          <Typography
            sx={{ marginBottom: '8px', padding: '20px', paddingBottom: 0 }}
            variant={'h5'}
          >
            {t('common.payments')}
          </Typography>
        </Box>
        {!loading && payments && <PaymentList payments={payments} />}
        {loading && <CircularProgress />}
      </Stack>
    </Box>
  );
};

export default UserPayments;
