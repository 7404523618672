import { FC } from 'react';
import { CloseRounded, FilterList } from '@mui/icons-material';
import { Badge, Box, Divider, IconButton, Stack, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type FilterButtonProps = {
  isActive?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onRemoveFilter?: () => void;
  open?: boolean;
  numberOfActiveFilters?: number;
  title?: string
};

const FilterButton: FC<FilterButtonProps> = ({
  isActive,
  onClick,
  onRemoveFilter,
  open,
  numberOfActiveFilters,
  title
}) => {
  const { t } = useTranslation();

  const styledFilterButton: SxProps = {
    padding: '0px 12px',
    cursor: 'pointer',
    border: `0.5px solid ${
      isActive || open ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)'
    }`,
    color: isActive || open ? '#fff' : 'rgba(255, 255, 255, 0.5)',
    ['&:hover']: {
      transitionDelay: '0.1s',
      transition: 'color 0.1s ease-in, border-color 0.1s ease-in',
      color: '#fff',
      borderColor: 'rgba(255, 255, 255, 1)'
    }
  };

  return (
    <Stack flexDirection="row" gap={1} sx={styledFilterButton} alignItems={'center'}>
      <div style={{ padding: '14px 0' }} onClick={onClick}>
        <Box>
          <Stack flexDirection="row" alignItems={'center'} gap={1}>
            <Typography>{title || t('common.filter').toLocaleUpperCase()}</Typography>
            <Badge badgeContent={isActive ? numberOfActiveFilters : 0} color={'primary'}>
              <FilterList />
            </Badge>
          </Stack>
        </Box>
      </div>
      {isActive && (
        <>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              height: '30px'
            }}
          />
          <IconButton onClick={onRemoveFilter}>
            <CloseRounded />
          </IconButton>
        </>
      )}
    </Stack>
  );
};

export default FilterButton;
