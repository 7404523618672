import StandardReportView from '../pages/Reports/StandardReportView';
import Report from 'types/Report';
import ReportOption from 'types/ReportOption';

const createStandardReport = (name: string, slug: string, options?: ReportOption[]): Report => {
  return {
    name: name,
    slug: slug,
    component: <StandardReportView slug={slug} name={name} options={options} />
  };
};

const currentMonth = new Date().getMonth();
const previousMonth = currentMonth - 1;

const REPORTS: Report[] = [
  createStandardReport('subscribersPerMonth', 'subscribers-per-month', [
    {
      slug: 'month',
      type: 'month',
      value: previousMonth
    },
    {
      slug: 'year',
      type: 'year'
    }
  ]),
  createStandardReport('incomeForMonth', 'income-for-month', [
    {
      slug: 'month',
      type: 'month',
      value: previousMonth
    },
    {
      slug: 'year',
      type: 'year'
    }
  ]),
  createStandardReport('closingBalance', 'closing-balance', [
    {
      slug: 'month',
      type: 'month'
    },
    {
      slug: 'year',
      type: 'year'
    }
  ]),
  createStandardReport('paymentReceivedForMonth', 'payment-received-for-month', [
    {
      slug: 'month',
      type: 'month',
      value: previousMonth
    },
    {
      slug: 'year',
      type: 'year'
    }
  ]),
  createStandardReport('paymentsForMonth', 'payments-for-month', [
    {
      slug: 'month',
      type: 'month',
      value: previousMonth
    },
    {
      slug: 'year',
      type: 'year'
    }
  ]),
  createStandardReport('subscriptionsStarted', 'subscriptions-started', [
    {
      slug: 'month',
      type: 'month'
    },
    {
      slug: 'year',
      type: 'year'
    }
  ]),
  createStandardReport('campaignPurchases', 'campaign-purchases', [
    {
      slug: 'month',
      type: 'month'
    },
    {
      slug: 'year',
      type: 'year'
    }
  ]),
  createStandardReport('invoices', 'invoices', [
    {
      slug: 'month',
      type: 'month'
    },
    {
      slug: 'year',
      type: 'year'
    }
  ]),
  createStandardReport('invoiceCountPerYear', 'invoice-count-per-year', [
    {
      slug: 'year',
      type: 'year'
    }
  ]),

  // This report is not used anymore
  //createStandardReport('subscribersWithPaymentFailed', 'subscribers-with-payment-failed'),
  createStandardReport('usersWithExpiredSubscription', 'users-with-expired-subscription', [
    {
      slug: 'days',
      type: 'number',
      value: 1
    }
  ]),
  // This report is not used anymore
  //createStandardReport('subscribersWithPaymentFailed', 'subscribers-with-payment-failed'),
  createStandardReport('usersWithOutSubscription', 'users-without-subscription'),
  createStandardReport('subscribersPerMunicipality', 'subscribers-per-municipality'),
  createStandardReport('subscribersWithoutPayment', 'subscribers-without-payment'),
  createStandardReport('userEmails', 'user-emails')
];
export default REPORTS;
