import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditionStatus } from 'types/Edition';

type EditionStatusChipProps = {
  status: EditionStatus;
};

const EditionStatusChip: FC<EditionStatusChipProps> = ({ status }) => {
  const { t } = useTranslation();

  const getColor = () => {
    switch (status) {
      case 'DRAFT':
        return 'warning';
      case 'PUBLISHED':
        return 'success';
      default:
        return 'warning';
    }
  };
  const color = getColor();

  return <Chip label={t(`edition.status.${status}`)?.toLocaleUpperCase()} color={color} />;
};

export default EditionStatusChip;
