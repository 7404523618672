import { FC, useState } from 'react';

import { SxProps, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormDialog from 'components/FormDialog/FormDialog';
import { SubmitHandler, useForm } from 'react-hook-form';

type EditNumberModal = {
  onCancel: () => void;
  onConfirm: (value: number) => void;
  label?: string;
  title?: string;
  value?: number;
  min?: number;
  max?: number;
  sx?: SxProps;
  sxContent?: SxProps;
};
type FormValues = {
  value: number;
};

const EditNumberModal: FC<EditNumberModal> = ({
  onCancel,
  onConfirm,
  label = '',
  value,
  title = '',
  min,
  max,
  sx = {},
  sxContent = {}
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, formState, register } = useForm<FormValues>({
    defaultValues: { value: value }
  });
  const { errors } = formState;

  const onSubmit: SubmitHandler<FormValues> = async (fields) => {
    setLoading(true);
    onConfirm(fields.value);
  };

  return (
    <FormDialog
      title={title}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      submitText={t('common.confirmChanges')}
      loading={loading}
      sx={sx}
      sxContent={sxContent}
    >
      <TextField
        {...register('value', {
          required: min !== undefined && min > 0,
          minLength: min,
          maxLength: max
        })}
        fullWidth
        type={'number'}
        name={'value'}
        placeholder={t('Value')}
        label={label}
        error={errors?.value ? true : false}
      />
    </FormDialog>
  );
};

export default EditNumberModal;
