import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const EditUserDistributionHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <Box className={'header'}>
      <Box className="header-content">
        <Typography variant={'h6'} sx={{ marginBottom: '8px' }}>
          {t('userDistribution..')}
        </Typography>
      </Box>
    </Box>
  );
};

export default EditUserDistributionHeader;
