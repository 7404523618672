import { Box, Stack, Typography } from '@mui/material';
import PaymentSolutionChip from 'components/PaymentSolutionChip/PaymentSolutionChip';
import COLORS from 'consts/colors';
import useSettings from 'hooks/useSettings';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentSolution from 'types/PaymentSolution';

type SelectPaymentSolutionProps = {
  onNext: (paymentSolution: PaymentSolution) => void;
};

const SelectPaymentSolution: FC<SelectPaymentSolutionProps> = ({ onNext }) => {
  const { t } = useTranslation();

  let paymentSolutions: PaymentSolution[] = ['none', 'external'];

  const { getValueByKeyAsBoolean } = useSettings();
  const paymentSolutionInvoice = getValueByKeyAsBoolean('PaymentSolutionInvoice', 0);
  if (paymentSolutionInvoice) {
    paymentSolutions = ['none', 'invoice', 'external'];
  }

  const selectBox = (paymentSolution: PaymentSolution) => {
    return (
      <Box
        key={paymentSolution}
        sx={{
          p: '20px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#555',
          cursor: 'pointer',
          '&:hover': {
            borderColor: COLORS.PRIMARY
          }
        }}
        onClick={() => onNext(paymentSolution)}
      >
        <Stack direction={'row'} gap={'20px'}>
          <Typography variant='h5'>{t('paymentSolution.' + paymentSolution)}</Typography>
          <Box sx={{ pt: '7px' }}>
            <PaymentSolutionChip paymentSolution={paymentSolution} />
          </Box>
        </Stack>
        <Typography sx={{ fontStyle: 'italic', color: '#bbb', marginTop: '5px', fontSize: '14px' }}>
          {t('payment.' + paymentSolution + 'Description')}
        </Typography>
      </Box>
    );
  };
  return (
    <Stack gap={2} sx={{ p: '20px', width: '560px' }}>
      {paymentSolutions.map(selectBox)}
    </Stack>
  );
};

export default SelectPaymentSolution;
