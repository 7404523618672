import { Chip } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentSolution from 'types/PaymentSolution';
import getPaymentSoulutionLogo from 'utils/_getPaymentSolutionLogo';

type PaymentSolutionChipProps = { paymentSolution?: PaymentSolution };

const PaymentSolutionChip: FC<PaymentSolutionChipProps> = ({ paymentSolution }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      {paymentSolution === 'none' && (
        <Chip
          sx={{ margin: '-8px 0' }}
          color={'default'}
          label={t('paymentSolution.' + paymentSolution)}
        />
      )}
      {['invoice', 'gift', 'migration', 'external'].includes(paymentSolution) && (
        <Chip
          sx={{ margin: '-8px 0' }}
          color={'info'}
          label={t('paymentSolution.' + paymentSolution)}
        />
      )}
      {['vipps', 'swedbank'].includes(paymentSolution) && (
        <img
          style={{ height: '30px', maxWidth: '100%' }}
          src={getPaymentSoulutionLogo(paymentSolution)}
        />
      )}
    </Box>
  );
};

export default PaymentSolutionChip;
