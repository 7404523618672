import { createTheme } from '@mui/material';
import COLORS from 'consts/colors';

const defaultTheme = createTheme();

const theme = createTheme({
  typography: {
    fontFamily: ['Roboto', 'Arial', 'sans-serif'].join(','),
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 500,
    fontSize: 14
  },
  palette: {
    mode: 'dark',
    text: {
      primary: COLORS.TEXT_COLOR_LIGHT
    },
    primary: {
      main: COLORS.PRIMARY,
      contrastText: COLORS.PRIMARY_CONTRAST
    },
    secondary: {
      main: COLORS.SECONDARY,
      dark: COLORS.SECONDARY_DARK,
      light: COLORS.SECONDARY_LIGHT,
      contrastText: COLORS.SECONDARY_CONTRAST
    },
    info: {
      main: COLORS.INFO,
      contrastText: COLORS.INFO_CONTRAST
    },
    error: { main: COLORS.ERROR }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: COLORS.PRIMARY_CONTRAST,
          borderRadius: 0,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          paddingLeft: '16px',
          paddingRight: '16px',
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            maxWidth: '900px'
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: COLORS.INFO_BACKGROUND
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {}
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 1
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          background: COLORS.SECONDARY
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            background: COLORS.TABLE_HEADER,
            fontWeight: 700,
            borderBottom: 'none',

            ['td']: {
              fontWeight: '300',
              borderBottom: 'none'
            }
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: 99999
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          ['td']: {
            fontWeight: '300',
            borderBottom: 'none'
          },
          [':nth-of-type(even)']: {
            backgroundColor: '#2c373f'
          },
          [':nth-of-type(odd)']: {
            backgroundColor: '#273036'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '20px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          fontWeight: 300,
          background: COLORS.INFO_BACKGROUND,
          color: '#fff',
          padding: '16px'
        }
      }
    }
  }
});

// Assets
export const logo = '/assets/newsroom-logo.svg';
export const logoIcon = '/assets/newsroom-icon.svg';
export const logoWhite = '/assets/newsroom-logo-white.svg';
export const logoIconWhite = '/assets/newsroom-icon-white.svg';
export const klarnaLogo = '/assets/payment-solutions/klarna-logo.svg';
export const vippsLogo = '/assets/payment-solutions/vipps-logo.png';
export const swedbankPayLogo = '/assets/payment-solutions/swedbank-pay-logo.svg';
export const banderoleAddressExample = '/assets/distribution/banderole-address-example.png';

export default theme;
