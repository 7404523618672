import { Button, CircularProgress, Stack, Tab, Tabs, Typography } from '@mui/material';
import { PaperCard, PaperContent, PaperHeader } from 'components';
import PageContent from 'components/PageContent/PageContent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWrapper from 'components/PageWrapper/PageWrapper';
import { ROUTE_PATHS } from 'consts/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FullDistributionRouteModal from './components/FullDistributionRouteModal';
import distributionRouteService from 'services/distribution-route-service';
import DistributionRoute from 'types/DistributionRoute';
import fullDistributionService from 'services/full-distribution-service';
import FullDistributionRoutes from './components/FullDistributionRoutes';
import { ConsentModal } from 'modals';

const FullDistributionPage: FC = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<'fullDistributionRoute' | 'confirmDeletion'>();
  const [fullDistributionRouteInEdit, setFullDistributionRouteInEdit] =
    useState<FullDistributionRoute>();
  const [loading, setLoading] = useState<boolean>();
  const [distributionRoutes, setDistributionRoutes] = useState<DistributionRoute[]>([]);
  const [fullDistributionRoutes, setFullDistributionRoutes] = useState<FullDistributionRoute[]>();

  const getDistributionRoutes = (hasLoading = true) => {
    if (hasLoading) {
      setLoading(true);
    }

    distributionRouteService
      .getAll()
      .then((response) => {
        setDistributionRoutes(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        if (hasLoading) {
          setLoading(false);
        }
      });
  };

  const getFullDistributionRoutes = (hasLoading = true) => {
    if (hasLoading) {
      setLoading(true);
    }

    fullDistributionService
      .getAll()
      .then((response) => {
        setFullDistributionRoutes(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        if (hasLoading) {
          setLoading(false);
        }
      });
  };

  const handleDeletion = () => {
    setLoading(true);
    if (fullDistributionRouteInEdit) {
      fullDistributionService
        .delete(fullDistributionRouteInEdit.id)
        .then(() => {
          setFullDistributionRouteInEdit(undefined);
          getFullDistributionRoutes();
          setModal(undefined);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const init = async () => {
    setLoading(true);

    Promise.all([getDistributionRoutes(false), getFullDistributionRoutes(false)]).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <PageWrapper>
      <PageHeader backToUrl={ROUTE_PATHS.PAPER}>
        <Typography variant={'h6'}>{t('common.fullDistribution')}</Typography>
        <Tabs
          sx={{
            borderBottom: '1px solid rgba(255,255,255,0.12)'
          }}
          value={0}
        >
          <Tab label={t('common.routes')} value={0} />
        </Tabs>
      </PageHeader>
      <PageContent>
        <PaperCard sx={{ minWidth: '300px' }}>
          <PaperHeader textAlign='left'>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography variant={'h6'}>{t('common.fullDistributionRoutes')}</Typography>
              <Button
                disabled={loading && !distributionRoutes && !distributionRoutes?.length}
                onClick={() => setModal('fullDistributionRoute')}
                variant={'contained'}
              >
                {t('common.addNew')}
              </Button>
            </Stack>
          </PaperHeader>
          <PaperContent np>
            {!loading && fullDistributionRoutes && (
              <FullDistributionRoutes
                fullDistributionRoutes={fullDistributionRoutes}
                onEdit={(fullDistributionRoute) => {
                  setFullDistributionRouteInEdit(fullDistributionRoute);
                  setModal('fullDistributionRoute');
                }}
                onDelete={(fullDistributionRoute) => {
                  setFullDistributionRouteInEdit(fullDistributionRoute);
                  setModal('confirmDeletion');
                }}
              />
            )}
            {loading && (
              <Stack
                sx={{
                  width: '100%',
                  p: 4
                }}
                direction={'row'}
                justifyContent={'center'}
              >
                <CircularProgress />
              </Stack>
            )}
          </PaperContent>
        </PaperCard>
      </PageContent>
      {modal === 'fullDistributionRoute' && distributionRoutes && (
        <FullDistributionRouteModal
          distributionRoutes={distributionRoutes}
          fullDistributionRoute={fullDistributionRouteInEdit}
          onCancel={() => setModal(undefined)}
          onFinished={() => {
            setModal(undefined);
            getFullDistributionRoutes();
          }}
        />
      )}
      {modal === 'confirmDeletion' && (
        <ConsentModal
          onConfirm={handleDeletion}
          onCancel={() => {
            setModal(undefined);
            setFullDistributionRouteInEdit(undefined);
          }}
          title={
            t('distribution.fullDistribution.confirmDeletion') +
            ' ' +
            fullDistributionRouteInEdit?.distributionRouteId
          }
          content={t('distribution.fullDistribution.deletionText')}
          confirmText={t('common.delete')}
        />
      )}
    </PageWrapper>
  );
};

export default FullDistributionPage;
