import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Setting from 'types/Setting';

const initialState = [] as Setting[];

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Setting[]>) => {
      return action.payload;
    }
  }
});

export default settingsSlice;
