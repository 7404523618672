import { FC } from 'react';
import './ConsentModal.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type ConsentModalProps = {
  title?: string;
  content?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmText?: string;
  contentSx?: SxProps;
  confirmColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
};

const ConsentModal: FC<ConsentModalProps> = ({
  title,
  content,
  onConfirm,
  onCancel,
  confirmText,
  confirmColor,
  contentSx
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography sx={contentSx}>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <Button variant={'outlined'} onClick={onConfirm} color={confirmColor}>
          {confirmText || t('common.confirmChanges')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentModal;
