import { FC, useState } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import polarisDistributionAddressService from 'services/polaris-distribution-address-service';
import { useForm } from 'react-hook-form';
import { Publish } from '@mui/icons-material';

type PolarisImportModalProps = {
  onUpdate: () => void;
  onCancel: () => void;
};

type Fields = {
  filelist: FileList;
};

const PolarisImportModal: FC<PolarisImportModalProps> = ({ onUpdate, onCancel }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const { register, handleSubmit, watch } = useForm<Fields>();
  const filelist = watch('filelist');

  const importCsvFile = async (fields: Fields) => {
    setLoading(true);

    try {
      await polarisDistributionAddressService.importCsvFile(fields.filelist[0]);

      onUpdate();
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  // create a mui dialog
  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>{t('distribution.importAddresses')}</DialogTitle>
      <form onSubmit={handleSubmit(importCsvFile)}>
        <DialogContent>
          <Stack gap={2} sx={{ mb: 2 }}>
            <Alert severity='info'>{t('distribution.polaris.uploadInfo')}</Alert>
            {!loading && (
              <Stack gap={2}>
                <Typography>{t('distribution.polaris.uploadAddresses')}</Typography>
                <Input
                  type='file'
                  {...register('filelist', { required: true })}
                  inputProps={{ accept: '.csv' }}
                />
              </Stack>
            )}
          </Stack>
          {loading && (
            <Stack style={{ textAlign: 'center' }}>
              <CircularProgress sx={{ margin: '10px 0' }} />
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button
            disabled={filelist?.length > 0 ? false : true}
            startIcon={<Publish />}
            variant='contained'
            type='submit'
          >
            {t('common.importFile')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PolarisImportModal;
