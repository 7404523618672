import { PostCodeInfo, PostCodesFromTo } from 'types/PostenDistributionRoute';

const getPostCodesFromTo = (postCodesData: PostCodeInfo[]) => {
  const postCodesFromToArray: PostCodesFromTo[] = [];
  let currentId: number;
  let prevId: number;
  let currentPostCodeFrom: string;
  let currentPostCodeTo: string;

  if (!postCodesData) {
    return [];
  }

  postCodesData
    .sort((a, b) => {
      if (a.id > b.id) {
        return 1;
      }

      if (a.id < b.id) {
        return -1;
      }

      return 0;
    })
    .forEach((postCodeData, index) => {
      currentId = postCodeData.id;

      if (!currentPostCodeFrom) {
        currentPostCodeFrom = postCodeData.postenId;
      }

      // If next post code is consecutive
      if (prevId && currentId - prevId === 1) {
        currentPostCodeTo = postCodeData.postenId;
      } else if (index !== 0) {
        postCodesFromToArray.push({
          postCodeFrom: currentPostCodeFrom,
          postCodeTo: currentPostCodeTo
        });

        currentPostCodeFrom = postCodeData.postenId;
        currentPostCodeTo = undefined;
        prevId = currentId;
      }

      if (index === postCodesData.length - 1) {
        // Push on last elem
        postCodesFromToArray.push({
          postCodeFrom: currentPostCodeFrom,
          postCodeTo: currentPostCodeTo
        });

        return;
      }

      prevId = currentId;
    });

  return postCodesFromToArray;
};

export default getPostCodesFromTo;
