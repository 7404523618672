/* eslint-disable max-lines */
/* eslint-disable indent */
import { Button, CircularProgress, Typography } from '@mui/material';
import { PageContainer, PaperCard, PaperHeader, PaperContent } from 'components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import reportService from 'services/report-service';
import StandardReport from 'types/StandardReport';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import excelService from 'services/excel-service';
import ReportOption from 'types/ReportOption';
import ReportOptionMonth from './ReportOptionMonth';
import ReportOptionYear from './ReportOptionYear';
import ReportOptionNumber from './ReportOptionNumber';
import useAlert from 'hooks/useAlert';
import pdfService from 'services/pdf-service';
import settingService from 'services/setting-service';
import StandardReportTable from './components/StandardReportTable';
import axios from 'axios';

type StandardReportViewProps = {
  slug: string;
  name: string;
  options?: ReportOption[];
};

const getOptionsDefaults = (options: ReportOption[]) => {
  if (options === undefined || options === null) return [];

  let month: number | string = new Date().getMonth();
  let year: number | string = new Date().getFullYear();

  options.forEach((o) => {
    if (o.slug === 'month' && o.value !== undefined && o.value !== null) {
      month = o.value;
    } else if (o.slug === 'year' && o.value !== undefined && o.value !== null) {
      year = o.value;
    }
  });

  if (month === -1) {
    month = 11;
    year = year - 1;
  }
  return options.map((o) => {
    if (o.slug === 'month') {
      return { ...o, value: month };
    } else if (o.slug === 'year') {
      return { ...o, value: year };
    }
    return o;
  });
};

const StandardReportView: FC<StandardReportViewProps> = ({ slug, name, options }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<StandardReport>(null);
  const [optionValues, setOptionValues] = useState<ReportOption[]>(getOptionsDefaults(options));
  const [dataOptions, setDataOptions] = useState({ slug: '', print: '' });
  const [organization, setOrganization] = useState<string>('');

  const [loading, setLoading] = useState(true);
  const { addAlert } = useAlert();
  const getData = async () => {
    const month = optionValues.find((o) => o.slug === 'month')?.value;
    const year = optionValues.find((o) => o.slug === 'year')?.value;

    setLoading(true);
    try {
      let dataQuery = null;
      switch (slug) {
        case 'subscriptions-started':
          dataQuery = await reportService.getSubscriptionsStarted(year, month);
          break;
        case 'subscribers-with-payment-failed':
          dataQuery = await reportService.getSubscribersWithPaymentFailed();
          break;
        case 'income-for-month':
          dataQuery = await reportService.getIncomeForMonth(year, month);
          break;
        case 'payments-for-month':
          dataQuery = await reportService.getPaymentsForMonth(year, month);
          break;
        case 'invoices':
          dataQuery = await reportService.getInvoicesForMonth(year, month);
          break;
        case 'invoice-count-per-year':
          dataQuery = await reportService.getInvoiceCountPerYear(year);
          break;
        case 'subscribers-without-payment':
          dataQuery = await reportService.getSubscribersWithoutPayment();
          break;
        case 'campaign-purchases':
          dataQuery = await reportService.getCampaignPurchases(year, month);
          break;
        case 'closing-balance':
          dataQuery = await reportService.getClosingBalance(year, month);
          break;
        case 'users-with-expired-subscription':
          dataQuery = await reportService.usersWithExpiredSubscription(
            optionValues.find((o) => o.slug === 'days')?.value ?? 1
          );
          break;
        case 'subscribers-per-municipality':
          dataQuery = await reportService.getSubscribersPerMunicipality();
          break;
        case 'users-without-subscription':
          dataQuery = await reportService.getUsersWithOutSubscription();
          break;
        case 'subscribers-per-month':
          dataQuery = await reportService.getSubscribersPerMonth(year, month);
          break;
        case 'user-emails':
          dataQuery = await reportService.getUserEmails();
          break;
        case 'payment-received-for-month':
          dataQuery = await reportService.getPaymentReceivedForMonth(year, month);
          break;
      }
      if (dataQuery) {
        setData(dataQuery.data);
        if (year) {
          setDataOptions({
            print: ` - ${t('month.' + month)} ${year}`,
            slug: `-${year}-${(month as number) + 1 < 10 ? '0' : ''}${(month as number) + 1}`
          });
        }
      } else {
        addAlert('error', t('alerts.couldNotGetReport'));
        setData(null);
      }
    } catch (error) {
      let message = '';
      if (axios.isAxiosError(error)) {
        message = '. ' + t('common.message') + ': "' + error.response?.data?.message + '"';
      }
      addAlert('error', t('alerts.couldNotGetReport') + message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    settingService.getByKey('OrganizationName').then((res) => {
      setOrganization(res.data.value);
    });
    getData();
  }, []);

  const updateOptionHandler = (i: number) => (value: string | number) => {
    const updatedOptionValues = [...optionValues];
    updatedOptionValues[i].value = value;
    setOptionValues(updatedOptionValues);
  };

  return (
    <PageContainer>
      <PaperCard sx={{ width: '100%' }}>
        <PaperHeader sx={{ position: 'relative' }}>
          {data !== null && (
            <div style={{ position: 'absolute', left: '10px' }}>
              <Button
                size='small'
                onClick={() => {
                  excelService.exportStandardReport(
                    data,
                    t('reports.' + name) + dataOptions.print,
                    slug + dataOptions.slug
                  );
                }}
              >
                <FileDownloadIcon />
                excel
              </Button>
              <Button
                size='small'
                onClick={() => {
                  pdfService.exportStandardReport(
                    data,
                    t('reports.' + name) + dataOptions.print,
                    slug + dataOptions.slug,
                    organization
                  );
                }}
              >
                <FileDownloadIcon />
                pdf
              </Button>
            </div>
          )}
          <Typography variant={'h6'}> {t('reports.' + name)}</Typography>
          <Typography sx={{ textAlign: 'center', marginBottom: '20px' }}>
            <i>{t('reports.' + name + 'Description')}</i>
          </Typography>

          {options &&
            optionValues.map((o, i) => (
              <div key={o.slug} style={{ display: 'inline-block', marginLeft: '10px' }}>
                {o.type === 'month' && (
                  <ReportOptionMonth
                    year={optionValues.find((opt) => opt.type == 'year').value as number}
                    value={o.value as number}
                    label={t('common.' + o.slug)}
                    onChange={updateOptionHandler(i)}
                  />
                )}
                {o.type === 'year' && (
                  <ReportOptionYear
                    value={o.value as number}
                    label={t('common.' + o.slug)}
                    onChange={updateOptionHandler(i)}
                  />
                )}
                {o.type === 'number' && (
                  <ReportOptionNumber
                    value={o.value as number}
                    label={t('common.' + o.slug)}
                    onChange={updateOptionHandler(i)}
                  />
                )}
              </div>
            ))}
          <Button
            sx={
              options
                ? {
                    position: 'relative',
                    top: '18px',
                    marginLeft: '10px'
                  }
                : undefined
            }
            variant='outlined'
            onClick={() => {
              getData();
            }}
          >
            {t('common.update')}
          </Button>
        </PaperHeader>
        <PaperContent np>
          {loading ? (
            <Typography sx={{ textAlign: 'center', padding: '15px 0' }}>
              <i>{t('reports.fetchingData')}</i> <br />
              <CircularProgress sx={{ margin: '10px 0' }} />
            </Typography>
          ) : data === null || data?.rows?.length === 0 ? (
            <Typography textAlign={'center'} sx={{ padding: '15px 0' }}>
              {t(data === null ? 'alerts.somethingWentWrong' : 'common.noResultsFound')}
            </Typography>
          ) : (
            <StandardReportTable data={data} />
          )}
        </PaperContent>
      </PaperCard>
    </PageContainer>
  );
};

export default StandardReportView;
