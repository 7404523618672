import { Box, Typography } from '@mui/material';
import packageInfo from '../../../package.json';

interface WindowBuildNo extends Window {
  BUILD_NUMBER: string;
}
declare const window: WindowBuildNo;

const PrimaryFooter = () => {
  const buildNo: string = window.BUILD_NUMBER;
  const footerText = `version ${packageInfo.version}.${buildNo}`;
  return (
    <Box sx={{ position: 'absolute', bottom: '16px', left: '16px' }}>
      <Typography
        sx={{
          textAlign: 'left',
          color: '#fff',

          opacity: 0.3
        }}
      >
        <small>
          <i>{footerText}</i>
        </small>
      </Typography>
    </Box>
  );
};

export default PrimaryFooter;
