import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StandardReport from 'types/StandardReport';

type StandardReportTableProps = {
  data: StandardReport;
};

const StandardReportTable: FC<StandardReportTableProps> = ({data}) => {
  const { t } = useTranslation();
  return (
    <Table>
      <TableHead>
        <TableRow>
          {data.header.map((h, index) => (
            <TableCell key={index}>{t(h)}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.rows.map((r, indexRow) => (
          <TableRow key={indexRow}>
            {r.map((c, indexCell) => (
              <TableCell key={indexCell}>
                {data.translation ? (data.translation[indexCell] ? t(c) : c) : c}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
      {data.footer !== null && data.footer.length > 0 && (
        <TableFooter>
          <TableRow>
            {data.footer.map((f, index) => (
              <TableCell
                sx={{
                  fontWeight: '700 !important',
                  fontSize: '0.875rem',
                  borderTop: '2px solid rgba(255,255,255,0.4)',
                  borderBottom: '1px solid  rgba(255,255,255,0.4)'
                }}
                key={index}
              >
                {f}
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default StandardReportTable;
