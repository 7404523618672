import { SearchFilterGroup } from 'types/SearchFilter';

const getNumberOfActiveFilters = (searchFilterGroups: SearchFilterGroup[]) => {
  let numberOfActiveFilters = 0;
  searchFilterGroups.forEach((sfg) => {
    sfg.filters.forEach((f) => {
      if (f.checked) {
        numberOfActiveFilters++;
      }
    });
  });

  return numberOfActiveFilters;
};

export default getNumberOfActiveFilters;
