import axios, { AxiosPromise } from 'axios';
import DistributionRoute from 'types/DistributionRoute';
import PageResults from 'types/PageResults';
import SearchQuery from 'types/SearchQuery';
import User from 'types/User';

const distributionRouteService = {
  getAll: (): AxiosPromise<DistributionRoute[]> => {
    return axios.get('/distribution-routes');
  },
  get: (id: string): AxiosPromise<DistributionRoute> => {
    return axios.get(`/distribution-routes/${id}`);
  },
  add: (distributionRoute: DistributionRoute): AxiosPromise<DistributionRoute> => {
    return axios.post('/distribution-routes', distributionRoute);
  },
  update: (
    distributionRouteId: string,
    distributionRoute: DistributionRoute
  ): AxiosPromise<DistributionRoute> => {
    return axios.put(`/distribution-routes/${distributionRouteId}`, distributionRoute);
  },
  delete: (distributionRouteId: string): AxiosPromise<DistributionRoute> => {
    return axios.delete(`/distribution-routes/${distributionRouteId}`);
  },
  search: (searchQuery: SearchQuery, limit = 50, offset = 0) => {
    return axios.post<PageResults<DistributionRoute>>(
      `/distribution-routes/search?limit=${limit}&offset=${offset}`,
      searchQuery
    );
  },
  getUsers: (distributionRouteId: string): AxiosPromise<User[]> => {
    return axios.get(`/distribution-routes/${distributionRouteId}/user`);
  }
};

export default distributionRouteService;
