import './UserTab.scss';
import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TabPanel from 'components/TabPanel/TabPanel';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import EditUser from '../EditUser/EditUser';
import EditUserSubscription from '../EditUserSubscription/EditUserSubscription';
import AddUserSubscriptionModal from '../AddUserSubscriptionModal/AddUserSubscriptionModal';
import UserPayments from '../UserPayments/UserPayments';
import { useParams } from 'react-router';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';
import { PaperCard } from 'components';
import FlexBox from 'components/FlexBox/FlexBox';
import UserTabHeader from './components/UserTabHeader';
import UserTokens from 'components/UserTokens/UserTokens';
import useSettings from 'hooks/useSettings';
import UserSubscription from 'types/UserSubscription';
import userSubscriptionService from 'services/user-subscrption.service';
import EditUserDistribution from '../EditUserDistribution/EditUserDistribution';
import { useUserContext } from 'contexts/UserContext';
import CanceledUserSubscriptions from '../EditUserSubscription/components/CanceledUserSubscriptions';
import EditUserInvoice from '../EditUserInvoiceSettings/EditUserInvoiceSettings';

const UserTab: FC = () => {
  const params = useParams();
  const userId = parseInt(params.id);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState<boolean>();
  const { t } = useTranslation();
  const [modal, setModal] = useState<number>(null);
  const axiosAlert = useAxiosAlert();
  const { user, setUser, fetchUser } = useUserContext();
  const { getValueByKey, getValueByKeyAsBoolean } = useSettings();
  const [activeUserSubscription, setActiveUserSubscription] = useState<UserSubscription>(null);
  const nrsPaper = getValueByKey('NrsPaper');
  const paymentSolutionInvoice = getValueByKeyAsBoolean('PaymentSolutionInvoice', 0);

  const canceledUserSubscriptions = user?.userSubscriptions.filter(
    (userSubscription) => userSubscription.status === 'CANCELED'
  );

  const handleUserUpdate = (updatedUser: User) => {
    setUser(updatedUser);
  };

  const handleUserSubscriptionRemoval = () => {
    fetchUser(user.id);
    setActiveUserSubscription(null);
  };

  const getActiveUserSubscription = async () => {
    try {
      const { data: activeUserSubscription } = await userSubscriptionService.get(userId);
      setActiveUserSubscription(activeUserSubscription);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.couldNotGetUserSubscription'));
    }
  };

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    setLoading(true);
    const promises = [];

    if (!user) {
      promises.push(fetchUser(parseInt(params.id)));
    }

    promises.push(getActiveUserSubscription());

    try {
      return Promise.all(promises);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PaperCard sx={{ minWidth: '800px' }}>
      <div className='user-tab' style={{ width: '100%' }}>
        {user && !loading && (
          <>
            <UserTabHeader
              username={user?.name}
              actions={
                <Tabs value={tab} onChange={(e, tabValue) => setTab(tabValue)}>
                  <Tab label={t('common.user')} value={0} />
                  {paymentSolutionInvoice && <Tab label={t('common.invoice')} value={5} />}
                  {!user?.archived && <Tab value={1} label={t('common.subscription')} />}
                  {nrsPaper && <Tab label={t('userDistribution..')} value={2} />}
                  <Tab label={t('common.payments')} value={3} />
                  <Tab label={t('userTokens.logedInDevices')} value={4} />
                </Tabs>
              }
            />
            <Box>
              <Box>
                <TabPanel value={tab} index={0}>
                  <Typography variant={'h6'} sx={{ marginBottom: '8px' }}>
                    {t('common.user')}
                  </Typography>
                  <EditUser user={user} onUpdate={handleUserUpdate} />
                </TabPanel>
                <TabPanel value={tab} index={5}>
                  <EditUserInvoice user={user} />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  {activeUserSubscription ? (
                    <EditUserSubscription
                      onUserSubscriptionRemoval={handleUserSubscriptionRemoval}
                    />
                  ) : (
                    <FlexBox
                      direction='column'
                      gap={12}
                      justifyContent={'center'}
                      alignItems={'flex-start'}
                    >
                      <Stack
                        direction='row'
                        gap={2}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Typography variant='h6'>{t('activeSubscription.noActive')}</Typography>
                        <Button variant='outlined' onClick={() => setModal(0)}>
                          {t('common.giveUserSubscription')}
                        </Button>
                      </Stack>
                      {!loading && !user.archived && canceledUserSubscriptions?.length > 0 && (
                        <Box sx={{ mt: '20px' }}>
                          <CanceledUserSubscriptions
                            userSubscriptions={canceledUserSubscriptions}
                          />
                        </Box>
                      )}
                    </FlexBox>
                  )}
                </TabPanel>
                <TabPanel value={tab} index={2}>
                  <EditUserDistribution
                    onUpdate={handleUserUpdate}
                    userDistribution={user?.userDistribution}
                    activeUserSubscription={activeUserSubscription}
                  />
                </TabPanel>
                <TabPanel value={tab} index={3} sx={{ p: 0 }}>
                  <UserPayments user={user} />
                </TabPanel>
                <TabPanel value={tab} index={4}>
                  <UserTokens user={user} onUpdate={handleUserUpdate} />
                </TabPanel>
              </Box>
              {modal === 0 && (
                <AddUserSubscriptionModal
                  user={user}
                  onCancel={() => {
                    setModal(null);
                  }}
                  onConfirm={(userSubscription) => {
                    setModal(null);
                    setUser({
                      ...user,
                      userSubscriptions: [userSubscription]
                    });
                    setActiveUserSubscription(userSubscription);
                  }}
                />
              )}
            </Box>
          </>
        )}
      </div>
    </PaperCard>
  );
};

export default UserTab;
