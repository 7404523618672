import axios, { AxiosPromise } from 'axios';
import Campaign, { CampaignStatus } from 'types/Campaign';
import CampaignFields from 'types/CampaignFields';

const campaignService = {
  getAll: () => {
    return axios.get<Campaign[]>('campaign/');
  },
  getBySubscription: (subscriptionId: number) => {
    return axios.get<Campaign[]>(`campaign/subscription/${subscriptionId}`);
  },
  add: (subscriptionId: number, campaign: CampaignFields) => {
    return axios.post('campaign', {
      subscriptionId: subscriptionId,
      name: campaign.name,
      description: campaign.description,
      durationDays: campaign.durationDays ? parseInt(campaign.durationDays, 10) : false,
      price: parseInt(campaign.price, 10),
      status: campaign.status,
      outTheYear: campaign.outTheYear ? true : false,
      onlyNewUsers: campaign.onlyNewUsers
    });
  },
  update: (campaignId: number, campaignFields: CampaignFields) => {
    return axios.put(`campaign/${campaignId}`, {
      name: campaignFields.name,
      description: campaignFields.description,
      durationDays: campaignFields.durationDays ? parseInt(campaignFields.durationDays, 10) : false,
      price: parseInt(campaignFields.price, 10),
      outTheYear: campaignFields.outTheYear,
      status: campaignFields.status
    });
  },
  delete: (campaignId: number) => {
    return axios.delete(`campaign/${campaignId}`);
  },
  changeStatus: (campaignId: number, campaignStatus: CampaignStatus): AxiosPromise<Campaign> => {
    return axios.put(`campaign/${campaignId}/change-status`, {
      status: campaignStatus
    });
  }
};

export default campaignService;
