import UserSubscription from 'types/UserSubscription';

const hasNoSubscription = (userSubscriptions: UserSubscription[] = []) => {
  let hasNoSubscription = true;

  userSubscriptions.forEach((u) => {
    if (u.status !== 'CANCELED') {
      hasNoSubscription = false;
    }
  });

  return hasNoSubscription;
};

export default hasNoSubscription;
