import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignStatus } from 'types/Campaign';

type CampaignStatusChipProps = {
  status: CampaignStatus;
};

const CampaignStatusChip: FC<CampaignStatusChipProps> = ({ status }) => {
  const { t } = useTranslation();
  let color: 'default' | 'success' | 'warning' | 'info' = 'default';
  switch (status) {
    case 'ACTIVE':
      color = 'success';
      break;
    case 'HIDDEN':
      color = 'warning';
      break;
    case 'INACTIVE':
      color = 'default';
      break;
    default:
      break;
  }

  return <Chip color={color} label={t(`campaign.status.${status}`).toUpperCase()} />;
};

export default CampaignStatusChip;
