import { Box, SxProps } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import './Dashboard.scss';
import { NavDrawer, PrimaryHeader, ProfileMenu } from 'components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';

type DashboardProps = {
  children: ReactNode;
  title?: string;
  action?: ReactNode;
  contentFullWidth?: boolean;
  centerContent?: boolean;
  sx?: SxProps;
  contentPadding?: boolean;
};

const Dashboard: FC<DashboardProps> = ({
  title = '',
  children,
  contentFullWidth = true,
  centerContent = false,
  sx,
  contentPadding = false
}) => {
  const { t } = useTranslation();
  const admin = useSelector((state: RootState) => state.admin);
  const [displayDrawer, setDisplayDrawer] = useState(false);

  const centerContentClass = centerContent ? 'center-content' : '';

  return (
    <Box className={'dashboard'} sx={sx}>
      <PrimaryHeader
        actions={<ProfileMenu name={admin.name} />}
        title={t(title)}
        onDrawerClick={() => {
          setDisplayDrawer(!displayDrawer);
        }}
      />
      <NavDrawer
        displayDrawer={displayDrawer}
        onDrawerClose={() => {
          setDisplayDrawer(false);
        }}
        onDrawerOpen={() => {
          setDisplayDrawer(true);
        }}
      />
      <Box
        className={`dashboard-content ${centerContentClass}`}
        sx={{
          marginTop: '64px',
          width: contentFullWidth ? '100%' : 'auto',
          paddingBottom: '0px',
          overflowX: 'hidden',
          padding: contentPadding ? '12px' : '0px'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Dashboard;
