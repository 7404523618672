import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import Subscription from 'types/Subscription';
import { AxiosError } from 'axios';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import userSubscriptionService from 'services/user-subscrption.service';
import UserSubscription from 'types/UserSubscription';
import PaymentSolution from 'types/PaymentSolution';
import SelectPaymentSolution from './steps/SelectPaymentSolution';
import SelectSubscription from './steps/SelectSubscription';
import CloseIcon from '@mui/icons-material/Close';
import Campaign from 'types/Campaign';
import SummaryAndConfirm from './steps/SummaryAndConfirm';

type AddUserSubscriptionModalProps = {
  user: User;
  onCancel: () => void;
  onConfirm: (userSubscription: UserSubscription) => void;
};

const AddUserSubscriptionModal: FC<AddUserSubscriptionModalProps> = ({
  user,
  onCancel,
  onConfirm
}) => {
  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();

  const [activeStep, setActiveStep] = useState(0);
  const [subscription, setSubscription] = useState<Subscription>(null);
  const [campaign, setCampaign] = useState<Campaign>(null);
  const [paymentSolution, setPaymentSolution] = useState<PaymentSolution>(null);

  const onSubmit = async (
    _paymentSolution: PaymentSolution,
    _subscription: Subscription,
    _campaign?: Campaign,
    _renewDate?: Date
  ) => {
    try {
      const userSubscriptionQuery = await userSubscriptionService.add(
        _subscription.id,
        campaign?.id,
        user.id,
        _paymentSolution,
        _renewDate
      );

      onConfirm(userSubscriptionQuery.data);
    } catch (error) {
      axiosAlert(error as AxiosError, [
        {
          status: 400,
          message: t('alerts.invalidUserForUserSubscription')
        },
        {
          status: 500,
          message: t('alerts.somethingWentWrong')
        },
        {
          status: 403,
          message: t('user.archive.alerts.userIsArchivedCantGiveSubscription')
        }
      ]);
    }
  };

  return (
    <Dialog open={true}>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'space-between'}>
          {t('common.giveUserSubscription')}
          <IconButton onClick={() => onCancel()}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: '600px', maxWidth: '800px' }}>
        <Stepper activeStep={activeStep}>
          <Step index={0}>
            <StepLabel>{t('common.selectSubscription')}</StepLabel>
          </Step>
          <Step index={1}>
            <StepLabel>{t('payment.selectPaymentSolution')}</StepLabel>
          </Step>
          <Step index={2}>
            <StepLabel>{t('common.confirm')}</StepLabel>
          </Step>
        </Stepper>
        <Box sx={{ pt: '20px' }}>
          {activeStep === 0 && (
            <SelectSubscription
              onNext={(subscribtion: Subscription, campaign?: Campaign) => {
                setSubscription(subscribtion);
                if (campaign) {
                  setCampaign(campaign);
                }
                setActiveStep(1);
              }}
            />
          )}
          {activeStep === 1 && (
            <SelectPaymentSolution
              onNext={(paymentSolution: PaymentSolution) => {
                setPaymentSolution(paymentSolution);
                setActiveStep(2);
              }}
            />
          )}

          {activeStep === 2 && (
            <SummaryAndConfirm
              onConfirm={onSubmit}
              onCancel={onCancel}
              paymentSolution={paymentSolution}
              subscription={subscription}
              campaign={campaign}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddUserSubscriptionModal;
