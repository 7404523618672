import './UserPasswordModal.scss';
import { TextField } from '@mui/material';
import FormDialog from 'components/FormDialog/FormDialog';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userService from 'services/user-service';
import { validate } from 'utils';
import User from 'types/User';
import logger from 'logger/logger';

type UserPasswordModalProps = {
    user: User;
    onConfirm: () => void;
    onCancel: () => void;
};

type ChangePasswordFields = {
    password: string;
};

const UserPasswordModal: FC<UserPasswordModalProps> = ({
  user,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordFields>();
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<ChangePasswordFields> = async (fields) => {
    const { password } = fields;

    setLoading(true);
    try {
      await userService.updateUser(
        { ...user },
        password
      );
      onConfirm();
    } catch (error) {
      logger.error('UpdateUser failed: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t('common.changePassword')}
      loading={loading}
      submitText={t('common.change')}
    >
      <div className='dialog-content'>
        <TextField
          type={'password'}
          sx={{ m: '10px 0' }}
          fullWidth
          label={t('common.password')}
          {...register('password', {
            required: true,
            validate: (val) => validate.password(val),
          })}
          error={errors.password ? true : false}
        />
      </div>
    </FormDialog>
  );
};

export default UserPasswordModal;
