import {
  Dialog,
  Divider,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import UserSubscription from 'types/UserSubscription';
import dateString from 'utils/_dateString';
import CloseIcon from '@mui/icons-material/Close';
import { ROUTE_PATHS } from 'consts/routes';
import COLORS from 'consts/colors';

type InspectUserSubscriptionsModalProps = {
  userSubscriptions: UserSubscription[];
  exit: () => void;
};

const InspectUserSubscriptionsModal: FC<InspectUserSubscriptionsModalProps> = ({
  userSubscriptions,
  exit
}) => {
  const { t } = useTranslation();
  const today = new Date();

  const invalidRewnewDate = (userSubscription: UserSubscription) => {
    if (userSubscription.status === 'CANCELED') {
      return false;
    }
    const renewDate = new Date(userSubscription.renewDate);
    return renewDate < today;
  };

  const invalidCancelationDate = (userSubscription: UserSubscription) => {
    if (userSubscription.status !== 'CANCELED') {
      return false;
    }
    return !userSubscription.cancelationDate;
  };

  return (
    <Dialog
      open={true}
      fullWidth
      sx={{
        maxWidth: 'unset',
        ' .MuiDialog-paper': {
          maxWidth: '1300px'
        }
      }}
    >
      <Stack sx={{ padding: '10px' }} direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>{t('dataCheck.invalidUserSubscriptions')}</Typography>
        <IconButton color='primary' onClick={() => exit()}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('common.user')}</TableCell>
            <TableCell>{t('common.id')}</TableCell>
            <TableCell>{t('common.subscription')}</TableCell>
            <TableCell>{t('common.status')}</TableCell>
            <TableCell>{t('common.renewalDate')}</TableCell>
            <TableCell>{t('common.paymentSolution')}</TableCell>
            <TableCell>{t('common.cancelationType')}</TableCell>
            <TableCell>{t('common.cancelationDate')}</TableCell>
            <TableCell>{t('common.paper')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userSubscriptions.map((userSubscription) => (
            <TableRow key={userSubscription.id}>
              <TableCell>
                <Link href={ROUTE_PATHS.USER.replace(':id', userSubscription.userId.toString())}>
                  {userSubscription.userId}
                </Link>
              </TableCell>
              <TableCell>{userSubscription.id}</TableCell>
              <TableCell>{userSubscription.subscriptionId}</TableCell>
              <TableCell>{userSubscription.status}</TableCell>
              <TableCell sx={invalidRewnewDate(userSubscription) ? { color: COLORS.ERROR } : null}>
                {dateString(userSubscription.renewDate)}
              </TableCell>
              <TableCell>{userSubscription.paymentSolution}</TableCell>
              <TableCell>{userSubscription.cancelationType ?? <i>NULL</i>}</TableCell>
              <TableCell
                sx={invalidCancelationDate(userSubscription) ? { color: COLORS.ERROR } : null}
              >
                {userSubscription.cancelationDate ?? <i>NULL</i>}
              </TableCell>
              <TableCell>{userSubscription.paper ? t('common.yes') : t('common.no')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Dialog>
  );
};

export default InspectUserSubscriptionsModal;
