import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import { Checkbox, FormControlLabel, IconButton, Tooltip, Typography } from '@mui/material';
import FlexBox from 'components/FlexBox/FlexBox';
import { cloneDeep } from 'lodash';
import { FC, forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter } from 'types/SearchFilter';

type CheckboxFilterProps = {
  onChange?: (value: Filter[]) => void;
  value: Filter[];
  filterKey: string;
  filterName: string;
};

const CheckboxFilterGroup: FC<CheckboxFilterProps> = forwardRef(
  ({ value, filterKey, filterName, onChange }, ref: Ref<HTMLDivElement>) => {
    const { t } = useTranslation();

    const handleCheckboxChange = (checked: boolean, v: string | number) => {
      const filtersTemp = cloneDeep(value);
      const index = filtersTemp.findIndex((f) => f.value === v);

      filtersTemp[index].checked = checked;

      onChange(filtersTemp);
    };

    const getCheckedArr = () => {
      return value.map(({ checked }) => {
        return checked;
      });
    };

    const handleChangeAll = (allChecked: boolean) => {
      const checked = allChecked ? false : true;
      const changedFilters = value.map((filter) => ({
        ...filter,
        checked
      }));
      onChange(changedFilters);
    };

    const getAllCheckedArr = (checkedArr: boolean[]) => {
      let allChecked = true;

      checkedArr.forEach((val) => {
        if (val === false) {
          allChecked = false;
        }
      });

      return allChecked;
    };

    // values can have less elements than search value
    const checkedArr = getCheckedArr();
    const allChecked = getAllCheckedArr(checkedArr);

    const searchFilterCheckboxes = value.map((filter, index) => {
      return (
        <FormControlLabel
          key={`${filterKey}-${index}`}
          control={<Checkbox />}
          name={filter.label}
          checked={filter.checked}
          label={filter.label}
          onChange={(e, checked) => handleCheckboxChange(checked, filter.value)}
        />
      );
    });

    return (
      <div ref={ref}>
        <FlexBox sx={{ flexDirection: 'column' }}>
          <FlexBox alignItems="center">
            <Typography>{filterName}</Typography>
            <Tooltip title={allChecked ? t('tooltip.uncheckAll') : t('tooltip.checkAll')}>
              <IconButton onClick={() => handleChangeAll(allChecked)}>
                {allChecked ? <CheckCircle /> : <CheckCircleOutline />}
              </IconButton>
            </Tooltip>
          </FlexBox>
          {searchFilterCheckboxes}
        </FlexBox>
      </div>
    );
  }
);

CheckboxFilterGroup.displayName = 'CheckboxFilterGroup';

export default CheckboxFilterGroup;
