import { Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { PaperCard, PaperHeader, PaperContent, PaperFooter, PrimaryListItem } from 'components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import notificationService from 'services/notification.service';
import { NotificationStatistic } from 'types/Notification';
import NotificationStatistics from './NotificationStatistics';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAlert from 'hooks/useAlert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';

type NotificationDetailsProps = {
  slug: string;
  name: string;
  createAction: boolean;
};

const NotificationDetails: FC<NotificationDetailsProps> = ({ slug, name, createAction }) => {
  const { t } = useTranslation();
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [actionTitle, setActionTitle] = useState<string>(null);
  const [actionUri, setActionUri] = useState<string>(null);
  const [message, setMessage] = useState<string>(null);
  const [targetUsers, setTargetUsers] = useState<number>(null);
  const [statistics, setStatistics] = useState<NotificationStatistic>(null);
  const addAxiosAlert = useAxiosAlert();

  const createNewNotifications = async () => {
    setLoading(true);
    try {
      await notificationService.createNotificationsBySlug(slug);
    } catch (error) {}
    await checkAskForPostCode();
  };

  const checkAskForPostCode = async () => {
    setLoading(true);
    try {
      const {
        data: { message, actionUri, actionTitle, target, statistics }
      } = await notificationService.checkNotificationBySlug(slug);
      setTargetUsers(target);
      setStatistics(statistics);
      setMessage(message);
      setActionUri(actionUri);
      setActionTitle(actionTitle);
      setLoading(false);
    } catch (error) {
      addAxiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    }
  };

  useEffect(() => {
    checkAskForPostCode();
  }, []);

  return (
    <PaperCard sx={{ maxWidth: '800px' }}>
      <PaperHeader>
        <Stack direction={'row'} justifyContent={'start'} gap={'10px'}>
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <NotificationsIcon sx={{ position: 'relative', top: '2px', left: '5px' }} />
          <Typography variant={'h6'}>{t('notifications.' + name)}</Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'start'}>
          <Typography sx={{ fontStyle: 'italic', opacity: 0.8 }}>
            {t('notifications.' + name + 'Description')}
          </Typography>
        </Stack>
      </PaperHeader>

      <PaperContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography>{t('common.message')}</Typography>
            <Typography
              sx={{
                minHeight: '70px',
                padding: '10px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                color: 'rgba(255,255,255,0.8)'
              }}
            >
              {message}
            </Typography>
            <PrimaryListItem label={t('common.action')} value={actionTitle} />
            <PrimaryListItem
              label={t('common.link')}
              value={
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(actionUri);
                    addAlert('success', t('alerts.copiedToClipboard'));
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              }
            />
            <PrimaryListItem label={t('notifications.targetUsers')} value={targetUsers} />
            <PrimaryListItem
              label={t('notifications.sentLast30Days')}
              last
              value={statistics.total}
            />
            <NotificationStatistics statistics={statistics} />
          </>
        )}
      </PaperContent>

      <PaperFooter>
        {createAction ? (
          <Button onClick={() => createNewNotifications()} variant={'outlined'}>
            {t('notifications.createNewNotifications')}
          </Button>
        ) : (
          <Typography sx={{ fontStyle: 'italic', opacity: 0.8 }}>
            {t('notifications.createdAutomatically')}
          </Typography>
        )}
      </PaperFooter>
    </PaperCard>
  );
};

export default NotificationDetails;
