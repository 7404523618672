import { Close } from '@mui/icons-material';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { FC } from 'react';

type AlertsModalProps = {
  texts: string[];
  title: string;
  severity: 'success' | 'info' | 'warning' | 'error';
  onClose: () => void;
};

const AlertsModal: FC<AlertsModalProps> = ({ texts, severity = 'success', title, onClose }) => {
  return (
    <Dialog open color={severity}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
          {title && title}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: '400px', maxWidth: '800px' }}>
        <Stack gap={1}>
          {texts.map((text, index) => (
            <Alert key={index} severity={severity}>
              <Typography>{text}</Typography>
            </Alert>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AlertsModal;
