export type UserDistributionRouteStatus =
  | 'MANUAL'
  | 'AUTOMATIC'
  | 'AUTOMATIC_UNSURE'
  | 'NO_ADDRESS'
  | 'NO_ROUTE'
  | 'NOT_HANDLED';

export const userAddressRouteStatuses: {
  [k: string]: UserDistributionRouteStatus;
} = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC',
  AUTOMATIC_UNSURE: 'AUTOMATIC_UNSURE',
  NO_ADDRESS: 'NO_ADDRESS',
  NO_ROUTE: 'NO_ROUTE',
  NOT_HANDLED: 'NOT_HANDLED'
};

export type DistributionMessageCode = 'IFA';

export const distributionMessageCodes: { [k: string]: string } = { IFA: 'IFA' };

type UserDistribution = {
  id: number;
  userId: number;
  holdFromDate: Date;
  holdToDate: Date;
  distributionMessage: string;
  distributionMessageCode: DistributionMessageCode;
  distributionMessageDate: Date;
  distributionNamed: boolean;
  numberOfPapers: number;
  routeStatus: UserDistributionRouteStatus;
  routeId: string;
  metadata: UserDistributionMetadata;
};

export type UserDistributionMetadata = { [k: string]: string };

export default UserDistribution;
