import { AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';
import logger from 'logger/logger';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import authService from 'services/admin-auth-service';
import { RootState, adminActions } from 'store';
import Admin from 'types/Admin';

const useEditAdmin = () => {
  const admin = useSelector((state: RootState) => state.admin);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const axiosAlert = useAxiosAlert();

  const updateAdmin = async (updatedAdmin: Admin) => {
    try {
      await authService.updateAdmin(updatedAdmin);
      dispatch(adminActions.set(updatedAdmin));
    } catch (error) {
      logger.error('UpdateAdmin failed: ', error);
    }
  };

  const changeName = async (newName: string) => {
    try {
      const adminTemp = { ...admin };
      adminTemp.name = newName;
      await updateAdmin(adminTemp);
    } catch (error) {
      logger.error('Change admin name failed (profile)', error);
      axiosAlert(error as AxiosError, []);
    }
  };

  const changeEmail = async (email: string, password: string) => {
    try {
      await authService.changeEmail(email, password);
      dispatch(adminActions.set({ ...admin, email }));
    } catch (error) {
      logger.error('ChangeEmailRequest failed: ', error);
      axiosAlert(error as AxiosError, [{ status: 403, message: t('alert.invalidPassword') }]);
    }
  };

  const changePassword = async (newPassword: string, oldPassword: string) => {
    try {
      await authService.changePassword(newPassword, oldPassword);
    } catch (error) {
      logger.error('changePassword failed', error);
      axiosAlert(error as AxiosError, []);
    }
  };

  return {
    changeName,
    changeEmail,
    changePassword
  };
};

export default useEditAdmin;
