type OrderType =
  | 'SUBSCRIPTION'
  | 'SUBSCRIPTION_RENEWAL'
  | 'CHANGE_PAYMENT_SOLUTION'
  | 'GIFT_SUBSCRIPTION';

export const orderTypes: OrderType[] = [
  'SUBSCRIPTION',
  'SUBSCRIPTION_RENEWAL',
  'CHANGE_PAYMENT_SOLUTION',
  'GIFT_SUBSCRIPTION'
];

export default OrderType;
