import {  CircularProgress, IconButton, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { PaperCard, PaperContent, PaperFooter, PaperHeader } from 'components';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dataCheckService from 'services/data-check.service';
import InspectUserSubscriptionsModal from './InspectUserSubscriptionsModal';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import COLORS from 'consts/colors';
type DataCheckModal = 'InspectUserSubscriptions';

type InvalidUserSubscriptionsProps = {
  updated: Date;
};

export const InvalidUserSubscriptions: FC<InvalidUserSubscriptionsProps> = ({updated}) => {
  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();
  const [modal, setModal] = useState<DataCheckModal>(null);
  const [userSubscriptions, setUserSubscriptions] = useState(null);

  const getInvalidUserSubscriptions = async () => {
    try {
      const userSubscriptionsQuery = await dataCheckService.getInvalidUserSubscriptions();
      setUserSubscriptions(userSubscriptionsQuery.data);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    }
  };

  useEffect(() => {
    if(userSubscriptions !== null) {
      setUserSubscriptions(null);
    }
    getInvalidUserSubscriptions();
  }, [updated]);
  return (
    <PaperCard sx={{minWidth: '250px'}}>
      <PaperHeader>
        <Typography variant={'h6'}>{t('dataCheck.invalidUserSubscriptions')}</Typography>
      </PaperHeader>
      <PaperContent>
        <div style={{ textAlign: 'center' }}>
          {userSubscriptions ? (
            userSubscriptions.length > 0 ? (
              <>
                <Typography sx={{ fontSize: '5rem' }} color={'error'}>
                  {userSubscriptions.length}
                </Typography>
                <IconButton onClick={() => setModal('InspectUserSubscriptions')} color='error'>
                  <TroubleshootIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Typography sx={{ fontSize: '5rem', color: COLORS.SUCCESS }}>
                  {userSubscriptions.length}
                </Typography>
                <TaskAltIcon sx={{ margin: '6px' }} color={'success'} />
              </>
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </PaperContent>
      <PaperFooter>
        {userSubscriptions ? (
          userSubscriptions.length > 0 ? (
            <>
              <Typography color='error' fontStyle={'italic'}>
                {t('dataCheck.invalidDataFound')}
              </Typography>
            </>
          ) : (
            <Typography sx={{ color: COLORS.SUCCESS }}>{t('dataCheck.allGood')}</Typography>
          )
        ) : (
          <CircularProgress />
        )}
      </PaperFooter>
      {modal === 'InspectUserSubscriptions' && (
        <InspectUserSubscriptionsModal
          userSubscriptions={userSubscriptions}
          exit={() => setModal(null)}
        />
      )}
    </PaperCard>
  );
};
