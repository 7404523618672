import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import theme from 'styles/theme';

type PaperContentProps = {
  children: ReactNode;
  sx?: SxProps;
  np?: boolean;
};

const PaperContent: FC<PaperContentProps> = ({ children, sx, np = false }) => {

  const paperContentStyle: SxProps = {
    padding: np ? 0 : '20px',
    [theme.breakpoints.down('sm')]: {
      padding: np ? 0 : '12px'
    }

  };

  return <Box sx={{...paperContentStyle, ...sx }}>{children}</Box>;
};

export default PaperContent;
