/* eslint-disable indent */
import { KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { AxiosError, AxiosPromise } from 'axios';
import { EditDateModal } from 'components';
import useAxiosAlert from 'hooks/useAxiosAlert';
import logger from 'logger/logger';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import invoiceService from 'services/invoice-service';
import settingService from 'services/setting-service';
import Payment from 'types/Payment';
import Setting from 'types/Setting';
import RefundConsentModal from '../RefundConsentModal/RefundConsentModal';

type PaymentHeaderProps = {
  payment: Payment;
  onBack: () => void;
  setLoading: (loading: boolean) => void;
  onExternalSync: () => void;
  loading: boolean;
  tryCapture: () => void;
  manualCapture: (completed: Date) => void;
  onRefund: (refundDescription: string) => void;
};

const PaymentHeader: FC<PaymentHeaderProps> = ({
  payment,
  onBack,
  setLoading,
  loading,
  onExternalSync,
  tryCapture,
  manualCapture,
  onRefund
}) => {
  const [modal, setModal] = useState<number>();
  const { t } = useTranslation();
  const [externalPaymentUrl, setExternalPaymentUrl] = useState<string>();
  const axiosAlert = useAxiosAlert();

  const openInExternalAdmin = () => {
    window.open(`${externalPaymentUrl}`, '_blank');
  };

  const getSwedbankAdminInfo = async () => {
    try {
      setLoading(true);

      const ps: AxiosPromise<Setting>[] = [];

      ps.push(settingService.getPublicByKey('SwedbankPaymentsUrl'));

      (await Promise.all(ps)).forEach(({ data: setting }, index) => {
        if (index === 0) {
          setExternalPaymentUrl(`${setting.value};id=${payment.externalOrderId}`);
        }
      });
    } catch (error) {
      logger.error('Get Swedbank settings failed: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getVippsAdminInfo = async () => {
    try {
      setLoading(true);
      const { data: vippsPortalUrlSetting } = await settingService.getPublicByKey('VippsPortalUrl');
      setExternalPaymentUrl(`${vippsPortalUrlSetting.value}${payment.externalReference}`);
    } catch (error) {
      logger.warn('Failed to get setting: ', error);
    } finally {
      setLoading(false);
    }
  };

  const downloadInvoicePdf = async () => {
    try {
      const pdfInvoiceQuery = await invoiceService.getInvoicePdf(payment.id);
      const a = document.createElement('a');
      a.href = 'data:application/octet-stream;base64,' + pdfInvoiceQuery.data.base64;
      a.download = pdfInvoiceQuery.data.name;
      a.click();
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    }
  };

  const downloadCreditNotePdf = async () => {
    try {
      const pdfInvoiceQuery = await invoiceService.getInvoicePdf('R' + payment.id);
      const a = document.createElement('a');
      a.href = 'data:application/octet-stream;base64,' + pdfInvoiceQuery.data.base64;
      a.download = pdfInvoiceQuery.data.name;
      a.click();
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    }
  };

  useEffect(() => {
    if (payment.paymentSolution === 'swedbank') {
      getSwedbankAdminInfo();
    } else if (payment.paymentSolution === 'vipps') {
      getVippsAdminInfo();
    }
  }, []);

  return (
    <Box>
      <IconButton onClick={onBack} sx={{ position: 'relative', bottom: '4px', margin: '0 5px' }}>
        <KeyboardArrowLeft />
      </IconButton>
      <Typography variant={'h5'} sx={{ padding: '10px 0', display: 'inline-block' }}>
        {`${t('common.payment')} ${payment.id}`}
      </Typography>
      <Stack justifyContent={'right'} direction={'row'} gap={2} sx={{ padding: '10px' }}>
        {payment.paymentSolution === 'invoice' /* INVOICE 
            -------------*/ && (
          <>
            {(payment.status === 'DUE' ||
              payment.status === 'CAPTURED' ||
              payment.status === 'NOT_CAPTURED' ||
              payment.status === 'REFUNDED') && (
              <Button variant={'outlined'} onClick={downloadInvoicePdf}>
                {t('payment.invoicePdf')}
              </Button>
            )}
            {payment.status === 'REFUNDED' && (
              <Button variant={'outlined'} onClick={downloadCreditNotePdf}>
                {t('payment.creditNotePdf')}
              </Button>
            )}
            {(payment.status === 'DUE' || payment.status === 'NOT_CAPTURED') && (
              <Button variant={'contained'} onClick={() => setModal(1)}>
                {t('invoice.manualCapture')}
              </Button>
            )}
            {(payment.status === 'DUE' ||
              payment.status === 'CAPTURED' ||
              payment.status === 'NOT_CAPTURED' ||
              payment.status === 'PROCESSING') && (
              <Button variant={'contained'} onClick={() => setModal(0)}>
                {t('common.creditNote')} ({t('payment.refund')})
              </Button>
            )}
          </>
        )}
        {(payment.paymentSolution === 'swedbank' || payment.paymentSolution === 'vipps') && (
          /* PSP 
          -------------*/
          <>
            {payment.paymentSolution === 'swedbank' && payment.status !== 'DUE' && (
              <Button variant='outlined' disabled={loading} onClick={openInExternalAdmin}>
                {t('payment.swedbankManage')}
              </Button>
            )}

            {payment.paymentSolution === 'vipps' && (
              <Button variant='outlined' disabled={loading} onClick={openInExternalAdmin}>
                {t('payment.vippsManage')}
              </Button>
            )}

            {!(payment.paymentSolution === 'swedbank' && payment.status === 'DUE') && (
              <Button variant={'outlined'} disabled={loading} onClick={onExternalSync}>
                {t('payment.sync')}
              </Button>
            )}

            {payment.status === 'NOT_CAPTURED' && (
              <Button
                variant={'contained'}
                onClick={tryCapture}
                disabled={payment.paymentSolution === 'vipps'}
              >
                {t('payment.capture')}
              </Button>
            )}

            {payment.status === 'CAPTURED' && (
              <Button variant={'contained'} onClick={() => setModal(0)}>
                {t('payment.refund')}
              </Button>
            )}
          </>
        )}
        {modal === 0 && (
          <RefundConsentModal
            title={t('payment.refundConsent.title')}
            content={t('payment.refundConsent.content')}
            onConfirm={(refundDescription) => {
              onRefund(refundDescription);
              setModal(null);
            }}
            onCancel={() => setModal(null)}
          />
        )}
        {modal === 1 && (
          <EditDateModal
            title={t('invoice.manualCapture')}
            content={t('invoice.areYouSureYouWantToCapture') + t('invoice.setCompletedDate')}
            label={t('invoice.completedDate')}
            value={new Date()}
            submitText={t('invoice.manualCapture')}
            onSave={(completedDate: Date) => {
              manualCapture(completedDate);
              setModal(null);
            }}
            onCancel={() => setModal(null)}
          />
        )}
      </Stack>
    </Box>
  );
};

export default PaymentHeader;
