import { TableRow, TableCell, TableBody, IconButton, Checkbox } from '@mui/material';
import PaymentMethodChip from 'components/PaymentMethodChip';
import COLORS from 'consts/colors';
import { FC } from 'react';
import { Invoice } from 'types/Invoice';
import { InvoiceDisplayOptions } from 'types/InvoiceDisplayOptions';
import { dateString } from 'utils';

import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import routes, { ROUTE_PATHS } from 'consts/routes';
import PaymentStatusChip from 'components/PaymentStatusChip/PaymentStatusChip';

type InvoiceTableBodyProps = {
  invoicesDisplayOptions: InvoiceDisplayOptions;
  invoices: Invoice[];
  selectable?: boolean;
  selectedPaymentIds?: number[];
  toggleSelected?: (id: number) => void;
};

const InvoiceTableBody: FC<InvoiceTableBodyProps> = ({
  invoicesDisplayOptions,
  invoices,
  selectable,
  selectedPaymentIds,
  toggleSelected
}) => {
  const opt = invoicesDisplayOptions;
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const navigate = useNavigate();

  return (
    <TableBody>
      {invoices.map((invoice) => (
        <TableRow key={invoice.payment.id}>
          <TableCell sx={{ padding: '0' }}></TableCell>
          {selectable && (
            <TableCell sx={{ padding: '0 5px', textAlign: 'center' }}>
              <Checkbox
                checked={selectedPaymentIds.includes(invoice.payment.id)}
                onChange={() => toggleSelected(invoice.payment.id)}
              />
            </TableCell>
          )}
          {opt?.id && <TableCell>{invoice.payment.id}</TableCell>}
          {opt?.method && (
            <TableCell>
              <PaymentStatusChip status={invoice.payment.status} />
            </TableCell>
          )}
          {opt?.method && (
            <TableCell>
              <PaymentMethodChip paymentMethod={invoice.payment.method} />
            </TableCell>
          )}

          {opt?.userId && <TableCell>{invoice.payment.userId}</TableCell>}
          {opt?.userName && <TableCell>{invoice.payment.user.name}</TableCell>}
          {opt?.billingName && (
            <TableCell
              sx={{ opacity: invoice.payment.user.name === invoice.billingName ? 0.5 : 1 }}
            >
              {invoice.billingName}
            </TableCell>
          )}
          {opt?.issueDate && (
            <TableCell>{invoice.issueDate ? dateString(invoice.issueDate) : '--'}</TableCell>
          )}
          {opt?.dueDate && (
            <TableCell
              sx={{
                color: (invoice.payment.dueDate && new Date(invoice.payment.dueDate) < tomorrow) ? COLORS.ERROR : 'inherit'
              }}
            >
              {invoice.payment.dueDate ? dateString(invoice.payment.dueDate) : '--'}
            </TableCell>
          )}
          {opt?.kid && <TableCell>{invoice.payment.externalPaymentId}</TableCell>}
          {opt?.price && <TableCell>{invoice.payment.price} kr</TableCell>}
          {opt?.sent && <TableCell>{invoice.sent ? dateString(invoice.sent) : '--'}</TableCell>}
          {opt?.paymentReminderSent && (
            <TableCell>
              {invoice.paymentReminderSent ? dateString(invoice.paymentReminderSent) : '--'}
            </TableCell>
          )}
          {opt?.customerNo && <TableCell>{invoice.customerNo}</TableCell>}
          {opt?.message && <TableCell>{invoice.message}</TableCell>}
          {opt?.periodFrom && (
            <TableCell>{invoice.periodFrom ? dateString(invoice.periodFrom) : '--'}</TableCell>
          )}
          {opt?.periodTo && (
            <TableCell>{invoice.periodTo ? dateString(invoice.periodTo) : '--'}</TableCell>
          )}
          {opt?.creditNoteInvoiceNo && <TableCell>{invoice.creditNoteInvoiceNo}</TableCell>}
          {opt?.product && <TableCell>{invoice.product}</TableCell>}
          {opt?.invoiceFee && <TableCell>{invoice.invoiceFee}</TableCell>}
          {opt?.billingAddress && <TableCell>{invoice.billingAddress}</TableCell>}
          {opt?.billingPostCode && <TableCell>{invoice.billingPostCode}</TableCell>}
          {opt?.billingPostOffice && <TableCell>{invoice.billingPostOffice}</TableCell>}
          {opt?.billingEmail && <TableCell>{invoice.billingEmail}</TableCell>}
          {opt?.billingPhone && <TableCell>{invoice.billingPhone}</TableCell>}
          {opt?.sumVat && <TableCell>{invoice.sumVat}</TableCell>}
          {opt?.vatPercent && <TableCell>{invoice.vatPercent}</TableCell>}
          <TableCell
            sx={{
              width: '30px',
              padding: '0 5px',
              textAlign: 'center',
              position: 'sticky',
              right: 0,
              background: 'inherit'
            }}
          >
            <IconButton
              sx={{ margin: '-8px 0' }}
              onClick={() =>
                navigate(ROUTE_PATHS.PAYMENT.replace(':id', invoice.payment.id.toString()), {
                  state: { previousPage: routes.INVOICES.path }
                })
              }
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default InvoiceTableBody;
