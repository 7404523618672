import {
  Dialog,
  Divider,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dateString from 'utils/_dateString';
import CloseIcon from '@mui/icons-material/Close';
import { ROUTE_PATHS } from 'consts/routes';
import COLORS from 'consts/colors';
import Payment from 'types/Payment';

type InspectInvalidPaymentsModalProps = {
  payments: Payment[];
  exit: () => void;
};

const InspectInvalidPaymentsModal: FC<InspectInvalidPaymentsModalProps> = ({ payments, exit }) => {
  const { t } = useTranslation();

  const invalidRefundedDate = (payment: Payment) => {
    if (payment.status === 'REFUNDED' && payment.refundedDate === null) {
      return true;
    } else {
      return false;
    }
  };

  const invalidFailedDate = (payment: Payment) => {
    if (['CANCELED', 'FAILED'].indexOf(payment.status) !== -1 && payment.failedDate === null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Dialog
      open={true}
      fullWidth
      sx={{
        maxWidth: 'unset',
        ' .MuiDialog-paper': {
          maxWidth: '2500px'
        }
      }}
    >
      <Stack sx={{ padding: '20px' }} direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>{t('dataCheck.invalidPayments')}</Typography>
        <IconButton color='primary' onClick={() => exit()}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('common.user')}</TableCell>
            <TableCell>{t('common.paymentId')}</TableCell>
            <TableCell>{t('common.created')}</TableCell>
            <TableCell>{t('common.paymentSolution')}</TableCell>
            <TableCell>{t('common.status')}</TableCell>
            <TableCell>{t('common.orderType')}</TableCell>
            <TableCell>{t('common.fromDate')}</TableCell>
            <TableCell>{t('common.toDate')}</TableCell>
            <TableCell>{t('common.dueDate')}</TableCell>
            <TableCell>{t('common.refundedDate')}</TableCell>
            <TableCell>{t('common.failedDate')}</TableCell>
            <TableCell>{t('common.completed')}</TableCell>
            <TableCell>{t('common.captureAttemts')}</TableCell>
            <TableCell>{t('common.externalOrderId')}</TableCell>
            <TableCell>{t('common.externalReference')}</TableCell>
            <TableCell>{t('common.externalPaymentId')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <TableRow key={payment.id}>
              <TableCell>
                <Link href={ROUTE_PATHS.USER.replace(':id', payment.userId.toString())}>
                  {payment.userId}
                </Link>
              </TableCell>
              <TableCell>
                <Link href={ROUTE_PATHS.PAYMENT.replace(':id', payment.id.toString())}>
                  {payment.id}
                </Link>
              </TableCell>
              <TableCell>{dateString(payment.created)}</TableCell>
              <TableCell>{payment.paymentSolution}</TableCell>
              <TableCell>{payment.status}</TableCell>
              <TableCell>{payment.orderType}</TableCell>
              <TableCell>{dateString(payment.fromDate)}</TableCell>
              <TableCell>{dateString(payment.toDate)}</TableCell>
              <TableCell>
                {payment.dueDate === null ? <i>NULL</i> : dateString(payment.dueDate)}
              </TableCell>
              <TableCell sx={invalidRefundedDate(payment) ? { color: COLORS.ERROR } : null}>
                {payment.refundedDate === null ? <i>NULL</i> : dateString(payment.refundedDate)}
              </TableCell>
              <TableCell sx={invalidFailedDate(payment) ? { color: COLORS.ERROR } : null}>
                {payment.failedDate === null ? <i>NULL</i> : dateString(payment.failedDate)}
              </TableCell>
              <TableCell>
                {payment.completed === null ? <i>NULL</i> : dateString(payment.completed)}
              </TableCell>
              <TableCell>{payment.captureAttemts}</TableCell>
              <TableCell>{payment.externalOrderId}</TableCell>
              <TableCell>{payment.externalReference}</TableCell>
              <TableCell>{payment.externalPaymentId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Dialog>
  );
};

export default InspectInvalidPaymentsModal;
