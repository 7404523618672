import { FC } from 'react';
import {
  DialogContent,
  DialogTitle,
  SxProps,
  TextField,
  Stack,
  FormControl
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormDialog from 'components/FormDialog/FormDialog';
import { validate } from 'utils';
import theme from 'styles/theme';


type EditRefundDescriptionModalProps = {
  title?: string;
  content?: string;
  description?: string;
  onConfirm?: (refundDescription: string) => void;
  onCancel?: () => void;
  confirmText?: string;
  contentSx?: SxProps;
  confirmColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
};

type EditRefundDescriptionFields = {
  refundDescription: string;
};

const EditRefundDescriptionModal: FC<EditRefundDescriptionModalProps> = ({
  onConfirm,
  onCancel,
  description,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<EditRefundDescriptionFields>({
    defaultValues: {
      refundDescription: description
    }
  });

  const onSubmit: SubmitHandler<EditRefundDescriptionFields> = async (fields) => {
    if (onConfirm) {
      onConfirm(fields.refundDescription);
    }
  };

  return (
    <FormDialog onSubmit={handleSubmit(onSubmit)} onCancel={onCancel}>
      <DialogTitle>{t('common.confirmChanges')}</DialogTitle>
      <DialogContent>
        <Stack gap={2} sx={{ minWidth: '350px', [theme.breakpoints.down('sm')]:
        {
          minWidth: 'auto'
        } }}>
          <FormControl>
            <TextField
              label={t('payment.refundDescription')}
              defaultValue={description}
              fullWidth
              margin='normal'
              multiline
              minRows={3}
              {...register('refundDescription',  {validate: (v:string)=>validate.length(v, 0, 1000)})}
              error={!!errors.refundDescription}
            />
          </FormControl>
        </Stack>
      </DialogContent>
    </FormDialog>
  );
};

export default EditRefundDescriptionModal;
