import { Button, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import FormDialog from 'components/FormDialog/FormDialog';
import useAxiosAlert from 'hooks/useAxiosAlert';
import logger from 'logger/logger';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userService from 'services/user-service';
import User from 'types/User';
import { validate } from 'utils';
import './UserEmailModal.scss';

type UserEmailModalProps = {
  user: User;
  onConfirm: (email: string) => void;
  onCancel: () => void;
};

type ChangeEmailFields = {
  email: string;
};

const UserEmailModal: FC<UserEmailModalProps> = ({ user, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ChangeEmailFields>({
    defaultValues: { email: user.email || '' }
  });
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<ChangeEmailFields> = async (fields) => {
    const { email } = fields;

    setLoading(true);
    try {
      await userService.updateUser({
        ...user,
        email: email
      });
      onConfirm(email);
    } catch (error) {
      logger.error('Change user email failed', error);
      axiosAlert(error as AxiosError, [
        {
          status: 409,
          message: t('alerts.errorEmailAlreadyInUse')
        }
      ]);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await userService.updateUser({
        ...user,
        email: null
      });
      onConfirm(null);
    } catch (error) {
      logger.error('Delete user failed', error);
      axiosAlert(error as AxiosError, [], t('alerts.couldNotDeleteUser'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      actions={
        <Button sx={{ marginRight: 'auto' }} color={'error'} onClick={onDelete} disabled={loading}>
          {t('common.remove') + ' ' + t('common.email')}
        </Button>
      }
      title={t('common.changeEmail')}
      loading={loading}
      submitText={t('common.change')}
    >
      <div className='dialog-content'>
        <TextField
          sx={{ m: '10px 0' }}
          fullWidth
          label={t('common.email')}
          {...register('email', {
            required: true,
            validate: (val) => validate.email(val)
          })}
          error={errors.email ? true : false}
        />
      </div>
    </FormDialog>
  );
};

export default UserEmailModal;
