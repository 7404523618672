import UserSubscription from 'types/UserSubscription';
import { userSubscriptionActiveStatuses } from 'types/UsetSubscriptionStatus';

const getActiveUserSubscription = (userSubscriptions: UserSubscription[]) => {
  const activeUserSubscription = userSubscriptions.find((userSubscription) => {
    return userSubscriptionActiveStatuses.includes(userSubscription.status);
  });

  return activeUserSubscription;
};

export default getActiveUserSubscription;
