import { FC, useState } from 'react';
import { Button, Chip, Stack, TextField, Typography } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConsentModal } from 'modals';
import { useForm } from 'react-hook-form';
import { validate } from 'utils';
import useAlert from 'hooks/useAlert';
import { PostCodeInfo } from 'types/PostenDistributionRoute';
import { PrimaryLoading } from 'components';
import postenDistributionService from 'services/posten-distribution-service';
import getPostCodesFromTo from '../utils/getPostCodesFromTo';

type PostenDistributionPostCodesModalProps = {
  postCodeInfoList?: PostCodeInfo[];
  onCancel: () => void;
  onConfirm: (postCodeInfoList: PostCodeInfo[]) => void;
};

type PostCodeFromTo = {
  postCodeFrom: string;
  postCodeTo: string;
};

const PostenDistributionPostCodesModal: FC<PostenDistributionPostCodesModalProps> = ({
  postCodeInfoList: postCodesDataProp = [],
  onCancel,
  onConfirm
}) => {
  const { t } = useTranslation();
  const [postCodeInfoList, setPostCodesData] = useState<PostCodeInfo[]>(postCodesDataProp);
  const [modal, setModal] = useState<number>();
  const [postCodesToBeDeleted, setPostCodesToBeTeleted] = useState<PostCodeFromTo>();
  const [loading, setLoading] = useState<boolean>();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<PostCodeFromTo>();
  const [actionType, setActionType] = useState<'add' | 'delete'>();
  const alert = useAlert();
  const postCodeFrom = watch('postCodeFrom');
  const title =
    (postCodesDataProp.length ? t('common.edit') : t('common.add')) +
    ' ' +
    (t('common.postCodes') as string).toLowerCase();

  const deletePostCode = () => {
    if (!postCodesToBeDeleted?.postCodeTo) {
      // Delete single post code

      setPostCodesData(
        postCodeInfoList.filter(
          (postCodeData) => postCodeData.postenId !== postCodesToBeDeleted.postCodeFrom
        )
      );
    } else {
      setPostCodesData(
        postCodeInfoList.filter(
          (postCodeData) =>
            !(
              parseInt(postCodeData.postenId) >= parseInt(postCodesToBeDeleted.postCodeFrom) &&
              parseInt(postCodeData.postenId) <= parseInt(postCodesToBeDeleted.postCodeTo)
            )
        )
      );
    }

    const alertText =
      (postCodesToBeDeleted.postCodeTo ? 'common.postCodes' : 'common.postCode') +
      ' ' +
      postCodesToBeDeleted.postCodeFrom +
      (postCodesToBeDeleted.postCodeTo ? ' - ' + postCodesToBeDeleted.postCodeTo + ' ' : '') +
      t('common.removed');

    alert.addAlert('success', alertText);
    setPostCodesToBeTeleted(undefined);
  };

  const addPostCode = async (fields: PostCodeFromTo) => {
    setLoading(true);
    try {
      const { data } = await postenDistributionService.getPostCodesDataByFromTo(
        fields.postCodeFrom,
        fields.postCodeTo
      );
      if (data.length < 1) {
        alert.addAlert('warning', t('warning.noPostCodesFound'));
        return;
      }

      setPostCodesData(
        postCodeInfoList
          .concat(data)
          .filter(
            (postCodeData, index, self) => index === self.findIndex((t) => t.id === postCodeData.id) // remove duplicates
          )
          .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
      );

      const alertText =
        (fields.postCodeTo ? 'common.postCodes' : 'common.postCode') +
        ' ' +
        fields.postCodeFrom +
        (fields.postCodeTo ? ' - ' + fields.postCodeTo + ' ' : '') +
        t('common.added');

      alert.addAlert('success', alertText);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    setLoading(false);
    // reset form
    // Select all input elements
    const inputs = document.querySelectorAll('input');

    // For each input, invoke the blur method
    inputs.forEach((input) => input.blur());

    reset();
  };

  const onSubmit = (fields: PostCodeFromTo) => {
    if (actionType === 'add') {
      addPostCode(fields);
    } else if (actionType === 'delete') {
      setPostCodesToBeTeleted({
        postCodeFrom: fields.postCodeFrom,
        postCodeTo: fields.postCodeTo
      });
      setModal(1);
    }
  };

  const compressedPostCodeList = getPostCodesFromTo(postCodeInfoList);

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ width: '600px' }}>
        <Stack
          gap={2}
          sx={{
            paddingTop: '10px'
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <TextField
                {...register('postCodeFrom', {
                  validate: (v) => validate.postCode(v)
                })}
                label={t('common.postCodeFrom')}
                required={true}
                error={!!errors.postCodeFrom}
              />
              <TextField
                {...register('postCodeTo', {
                  validate: (v) =>
                    !v || (validate.postCode(v) && parseInt(v) > parseInt(postCodeFrom)),
                  required: false
                })}
                label={t('common.postCodeTo')}
                error={!!errors.postCodeTo}
              />
              <Button
                type={'submit'}
                onClick={() => {
                  setActionType('add');
                }}
              >
                {t('common.add')}
              </Button>
              <Button
                type={'submit'}
                onClick={() => {
                  setActionType('delete');
                }}
                color="error"
              >
                {t('common.remove')}
              </Button>
            </Stack>
          </form>
          <Stack gap={2}>
            <Typography>{t('common.postCodes')}</Typography>
            <Stack
              gap={1}
              direction={'row'}
              flexWrap={'wrap'}
              sx={{
                height: '150px',
                padding: '10px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                color: 'rgba(255,255,255,0.8)',
                whiteSpace: 'pre',
                overflowY: 'scroll'
              }}
            >
              {compressedPostCodeList.map((postCodeData, index) => (
                <Chip
                  key={index}
                  label={`${postCodeData.postCodeFrom}${
                    postCodeData.postCodeTo ? ' - ' + postCodeData.postCodeTo : ''
                  }`}
                />
              ))}
              {postCodeInfoList.length < 1 && <Typography>{t('common.noPostCodes')}</Typography>}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <Button onClick={() => onConfirm(postCodeInfoList)}>{t('common.confirm')}</Button>
      </DialogActions>
      {modal === 1 && (
        <ConsentModal
          title={t('common.confirm')}
          content={`${t('common.deleteConfirmation', {
            blank: t('common.postCode').toLowerCase()
          })} ${postCodesToBeDeleted?.postCodeFrom}${
            postCodesToBeDeleted?.postCodeTo ? ' - ' + postCodesToBeDeleted?.postCodeTo : ''
          }`}
          onConfirm={() => {
            setModal(undefined);
            deletePostCode();
          }}
          onCancel={() => setModal(undefined)}
        />
      )}
      {loading && <PrimaryLoading title={t('common.loading')} />}
    </Dialog>
  );
};

export default PostenDistributionPostCodesModal;
