import { TextField } from '@mui/material';
import { AxiosError } from 'axios';
import FormDialog from 'components/FormDialog/FormDialog';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userService from 'services/user-service';
import User from 'types/User';

type UserNoteModalProps = {
  user: User;
  onConfirm: (note: string) => void;
  onCancel: () => void;
};

type EditNoteFields = {
  note: string;
};

const UserNoteModal: FC<UserNoteModalProps> = ({ user, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<EditNoteFields>({
    defaultValues: { note: user.note || '' }
  });
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<EditNoteFields> = async (fields) => {
    const { note } = fields;

    setLoading(true);
    try {
      await userService.updateUser({
        ...user,
        note: note
      });
      onConfirm(note);
    } catch (error) {
      axiosAlert(error as AxiosError, [
        {
          status: 403,
          message: t('alerts.errorNoteAlreadyInUse')
        }
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t('common.editNote')}
      loading={loading}
      submitText={t('common.change')}
    >
      <div className="dialog-content">
        <TextField
          sx={{ m: '10px 0' }}
          fullWidth
          multiline
          rows={4}
          label={t('common.note')}
          {...register('note', {
            required: false
          })}
          error={errors.note ? true : false}
        />
      </div>
    </FormDialog>
  );
};

export default UserNoteModal;
