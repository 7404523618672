/* eslint-disable max-lines */
import { Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import invoiceService from 'services/invoice-service';
import PrintIcon from '@mui/icons-material/Print';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import useAlert from 'hooks/useAlert';
import { Invoice } from 'types/Invoice';
import InvoiceTable from '../InvoiceTable/InvoiceTable';

const UnpaidInvoicesTab: FC = () => {
  const [unpaidInvoices, setUnpaidInvoices] = useState<Invoice[]>(null);
  const [selectedPaymentIds, setSelectedPaymentIds] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const { t } = useTranslation();
  const { addAlert } = useAlert();
  const axiosAlert = useAxiosAlert();

  const selectNone = () => {
    setSelectedPaymentIds([]);
  };
  const selectDuePayments = () => {
    const dueInvoicesPaymentIds = unpaidInvoices
      .filter((i) => new Date(i.payment.dueDate) < new Date())
      .map((i) => i.payment.id);
    setSelectedPaymentIds(dueInvoicesPaymentIds);
  };

  const selectAll = () => {
    const allInvoicesPaymentIds = unpaidInvoices.map((i) => i.payment.id);
    setSelectedPaymentIds(allInvoicesPaymentIds);
  };

  const printSelectedAsPaymentReminders = async () => {
    if (selectedPaymentIds.length === 0) {
      addAlert('warning', t('invoice.noInvoicesSelected'));
      return;
    }
    setLoading(true);
    try {
      const pdfInvoicesQuery = await invoiceService.generateReminders(selectedPaymentIds);
      const a = document.createElement('a');
      a.href = 'data:application/octet-stream;base64,' + pdfInvoicesQuery.data.base64;
      a.download = pdfInvoicesQuery.data.name;
      a.click();
      getUnpaidInvoices(); // Sets loading to false
    } catch (e) {
      axiosAlert(e as AxiosError, [], t('invoice.printingFailed'));
      setLoading(false);
    }
  };

  const printSelected = async () => {
    if (selectedPaymentIds.length === 0) {
      addAlert('warning', t('invoice.noInvoicesSelected'));
      return;
    }
    setLoading(true);
    try {
      const pdfInvoicesQuery = await invoiceService.printInvoices(selectedPaymentIds);
      const a = document.createElement('a');
      a.href = 'data:application/octet-stream;base64,' + pdfInvoicesQuery.data.base64;
      a.download = pdfInvoicesQuery.data.name;
      a.click();
      getUnpaidInvoices(); // Sets loading to false
    } catch (e) {
      axiosAlert(e as AxiosError, [], t('invoice.printingFailed'));
      setLoading(false);
    }
  };

  const getUnpaidInvoices = async () => {
    setLoading(true);

    try {
      const unpaidInvoicesQuery = await invoiceService.getUnpaidInvoices();
      setUnpaidInvoices(unpaidInvoicesQuery.data);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUnpaidInvoices();
  }, []);

  return (
    <div>
      {!loading && unpaidInvoices?.length > 0 && (
        <>
          <InvoiceTable
            invoices={unpaidInvoices}
            selectable={true}
            selectedPaymentIds={selectedPaymentIds}
            toggleSelected={(id) => {
              if (selectedPaymentIds.includes(id)) {
                setSelectedPaymentIds(selectedPaymentIds.filter((i) => i !== id));
              } else {
                setSelectedPaymentIds([...selectedPaymentIds, id]);
              }
            }}
          />
          <Divider />
          <Stack sx={{ padding: '20px' }} gap='20px'>
            <Stack direction='row' gap='20px'>
              <Button onClick={selectAll}>
                <CheckCircleOutlineIcon />
                &nbsp;
                {t('invoice.selectAll')}
              </Button>
              <Button onClick={selectNone}>
                <RadioButtonUncheckedIcon />
                &nbsp;
                {t('invoice.selectNone')}
              </Button>
              <Button onClick={selectDuePayments}>
                <CheckCircleOutlineIcon />
                &nbsp;
                {t('invoice.selectDuePayments')}
              </Button>
            </Stack>
            <Stack direction='row' gap='20px'>
              <Button variant={'contained'} onClick={printSelected}>
                <PrintIcon />
                &nbsp;
                {t('invoice.printInvoices')}
              </Button>
              <Button variant={'outlined'} onClick={printSelectedAsPaymentReminders}>
                <PrintIcon />
                &nbsp;
                {t('invoice.printPaymentReminders')}
              </Button>
            </Stack>
          </Stack>
        </>
      )}
      {!loading && unpaidInvoices?.length === 0 && (
        <Stack direction='row' justifyContent='center' p={4}>
          <Typography>{t('common.noResultsFound')}</Typography>
        </Stack>
      )}
      {loading && (
        <Stack direction='row' justifyContent='center' p={4}>
          <CircularProgress />
        </Stack>
      )}
    </div>
  );
};

export default UnpaidInvoicesTab;
