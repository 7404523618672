import { Box, Divider, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

type PaperHeaderProps = {
  children: ReactNode;
  sx?: SxProps;
  divider?: boolean;
  textAlign?: 'center' | 'left' | 'right';
};

const PaperHeader: FC<PaperHeaderProps> = ({
  children,
  sx = {},
  textAlign = 'center',
  divider = true
}) => {
  return (
    <>
      <Box sx={{ padding: '20px', textAlign, ...sx }}>{children}</Box>
      {divider && <Divider />}
    </>
  );
};

export default PaperHeader;
