import axios from 'axios';
import Subscription from 'types/Subscription';

const subscriptionService = {
  get: () => {
    return axios.get<Subscription[]>('subscription');
  },
  getById: async (id: number) => {
    return axios.get<Subscription>(`subscription/${id}`);
  },
  post: (subscription: Subscription) => {
    return axios.post('subscription', {
      name: subscription.name,
      order: subscription.order,
      description: subscription.description,
      status: subscription.status,
      price: subscription.price,
      duration: subscription.duration,
      paper: subscription.paper
    });
  },
  update: (subscription: Subscription) => {
    return axios.put(`subscription/${subscription.id}`, subscription);
  },
  delete: (subscriptionId: number) => {
    return axios.put(`subscription/delete/${subscriptionId}`);
  }
};

export default subscriptionService;
