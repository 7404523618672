import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { ConsentModal } from 'modals';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import campaignService from 'services/campaign-service';
import Campaign from 'types/Campaign';
import CampaignList from '../CampaignList/CampaignList';
import CampaignModal from '../CampaignModal/CampaignModal';

type CampaignsProps = {
  subscriptionId: number;
  myPageUrl?: string;
};

const Campaigns: FC<CampaignsProps> = ({ subscriptionId, myPageUrl }) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>(null);
  const [modal, setModal] = useState<number>();
  const [modalData, setModalData] = useState<Campaign>();
  const [loading, setLoading] = useState<boolean>();
  const axiosAlert = useAxiosAlert();

  const { t } = useTranslation();

  const getCampaigns = async () => {
    setLoading(true);
    try {
      const res = await campaignService.getBySubscription(subscriptionId);
      setCampaigns(res.data);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.couldNotGetCampaigns'));
    } finally {
      setLoading(false);
    }
  };

  const deleteHandler = async () => {
    setLoading(true);
    try {
      await campaignService.delete(modalData.id);
      setLoading(false);
      resetModal();
      getCampaigns();
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.couldNotGetCampaigns'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!campaigns) {
      getCampaigns();
    }
  }, []);

  const resetModal = () => {
    setModal(null);
    setModalData(null);
  };

  return (
    <div>
      <CampaignList
        action={<Button onClick={() => setModal(0)}>{t('campaign.createNew')}</Button>}
        onEdit={(index) => {
          if (campaigns) {
            setModalData(campaigns[index]);
            setModal(0);
          }
        }}
        campaigns={campaigns}
        loading={loading}
        myPageUrl={myPageUrl}
      />
      {modal === 0 && (
        <CampaignModal
          subscriptionId={subscriptionId}
          onConfirm={() => {
            getCampaigns();
            resetModal();
          }}
          onDelete={() => {
            setModal(1);
          }}
          onCancel={resetModal}
          campaign={modalData}
        />
      )}
      {modal === 1 && (
        <ConsentModal
          title={`${t('campaign.delete')} ${t(modalData.name)}`}
          content={`${t('campaign.deleteConfirmation')}`}
          onCancel={resetModal}
          onConfirm={deleteHandler}
          confirmColor={'error'}
        />
      )}
    </div>
  );
};

export default Campaigns;
