import axios, { AxiosPromise } from 'axios';

const postenBundleInfoService = {
  importPostenBundleInfoTxtFile: (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post('distribution/posten/bundle-info/import', formData);
  },
  getBundleInfoLastImportedDate: (): AxiosPromise<string> => {
    return axios.get('distribution/posten/bundle-info/last-updated');
  }
};

export default postenBundleInfoService;
