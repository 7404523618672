import './Profile.scss';
import { FC, useState } from 'react';

import { IconButton, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { Edit, Person } from '@mui/icons-material';
import { InfoContainer, PageContainer, PrimaryListItem } from 'components';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { ChangePasswordModal, EditSingleStringModal } from 'modals';
import useEditAdmin from './useEditAdmin';
import { useTranslation } from 'react-i18next';
import i18n, { languages } from 'i18n';
import ChangeEmailModal from './ChangeEmailModal/ChangeEmailModal';

const modals = {
  changeNameModal: 0,
  changePasswordModal: 1,
  changeEmailModal: 2
};

const Profile: FC = () => {
  const admin = useSelector((state: RootState) => state.admin);
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(-1);
  const [language, setLanguage] = useState(i18n.language);
  const { changeName, changeEmail, changePassword } = useEditAdmin();

  const closeModal = () => {
    setOpenModal(-1);
  };

  return (
    <PageContainer>
      <div className="profile">
        <InfoContainer maxWidth={'sm'} fullWidth={true}>
          <div className={'admin-info-container'}>
            <div className={'profile-card-header'}>
              <div className="header-content">
                <Person />
                <Typography variant={'h6'}>{admin.name}</Typography>
              </div>
            </div>
            <div className={'admin-info'}>
              <PrimaryListItem
                label={t('common.email')}
                value={admin.email}
                actions={
                  <IconButton onClick={() => setOpenModal(modals.changeEmailModal)}>
                    <Edit />
                  </IconButton>
                }
              />
              <PrimaryListItem
                label={t('common.name')}
                value={admin.name}
                actions={
                  <IconButton onClick={() => setOpenModal(modals.changeNameModal)}>
                    <Edit />
                  </IconButton>
                }
              />
              <PrimaryListItem
                label={t('common.password')}
                value={'********'}
                actions={
                  <IconButton onClick={() => setOpenModal(modals.changePasswordModal)}>
                    <Edit />
                  </IconButton>
                }
              />
              <PrimaryListItem
                label={t('common.role')}
                value={t(`role.${admin.role}`).toString()}
              />
              <Stack direction={'row'} justifyContent={'space-between'} sx={{ padding: '10px 0' }}>
                <Typography>
                  {t('common.language')}{' '}
                  <small>
                    <i style={{ textTransform: 'lowercase' }}>({t('common.browser')})</i>
                  </small>
                </Typography>
                <Select
                  label={t('common.language')}
                  onChange={(e: SelectChangeEvent) => {
                    const newLanguage = e.target.value;
                    setLanguage(newLanguage);
                    i18n.changeLanguage(newLanguage);
                  }}
                  value={language}
                  variant={'standard'}
                >
                  {languages.map((l) => (
                    <MenuItem key={l.value} value={l.value}>
                      {t(l.name)}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </div>
          </div>
        </InfoContainer>
      </div>
      {openModal === modals.changeNameModal && (
        <EditSingleStringModal
          value={admin.name}
          label={t('common.name')}
          min={2}
          max={50}
          onCancel={closeModal}
          onConfirm={(newName) => {
            closeModal();
            changeName(newName);
          }}
        />
      )}
      {openModal === modals.changeEmailModal && (
        <ChangeEmailModal
          onCancel={closeModal}
          onConfirm={(email, password) => {
            closeModal();
            changeEmail(email, password);
          }}
        />
      )}
      {openModal === modals.changePasswordModal && (
        <ChangePasswordModal
          onCancel={closeModal}
          onConfirm={(newPassword, oldPassword) => {
            closeModal();
            changePassword(newPassword, oldPassword);
          }}
        />
      )}
    </PageContainer>
  );
};

export default Profile;
