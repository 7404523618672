import { Alert, Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import './ForgotPassword.scss';
import useAuth from 'hooks/useAuth';
import authService from 'services/admin-auth-service';
import routes from 'consts/routes';
import { PageContainer, PaperCard, PaperContent, PaperHeader } from 'components';
import logger from 'logger/logger';

type FormValues = {
  email: string;
};

type View = {
  loading?: boolean;
  form?: boolean;
  success?: boolean;
  errorMessage?: string;
  error?: boolean;
};

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, handleSubmit, getValues } = useForm<FormValues>();
  const { signOut } = useAuth();

  const signOutAndGoToLogin = async () => {
    signOut();
    navigate(routes.LOGIN.path);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    updateView({
      loading: true
    });
    try {
      await authService.forgotPassword(data.email);
      updateView({
        success: true
      });
    } catch (error) {
      let message = 'Client error';

      logger.error('ForgotPassword failed: ', error);

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;
        if (axiosError.response && axiosError.response.status === 400) {
          message = t('Email is not correct format');
        } else {
          message = axiosError.message;
        }
      }

      updateView({
        error: true,
        errorMessage: message
      });
    }
  };

  const [view, setView] = useState<View>({
    loading: false,
    form: true,
    success: false,
    errorMessage: null,
    error: false
  });

  const updateView = (updatedView: View) => {
    setView({
      ...{
        loading: false,
        form: false,
        success: false,
        errorMessage: null,
        error: false
      },
      ...updatedView
    });
  };

  return (
    <PageContainer centerHorizontal centerVertical>
      <PaperCard>
        <PaperHeader>
          <Typography variant="h6">{t('password.forgotPassword')}</Typography>
        </PaperHeader>
        <PaperContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}
            >
              <Typography>{t('forgotPassword.writeEmailMessage')}</Typography>
              {view.success && (
                <Alert severity="success">
                  <p>
                    {t('alerts.forgotPasswordEmailSent', {
                      email: getValues('email')
                    })}
                  </p>
                  <Button onClick={signOutAndGoToLogin} variant="contained">
                    {t('goToLogin')}
                  </Button>
                </Alert>
              )}
              {view.loading && (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress sx={{ margin: '10px 0' }} />
                </div>
              )}

              {view.form && (
                <>
                  <TextField sx={{ flex: 1 }} label={'Email'} {...register('email')} />
                  <Button type="submit" variant="contained" size="large">
                    {t('common.sendLink')}
                  </Button>
                </>
              )}
              {view.error && <Alert severity="error">{view.errorMessage}</Alert>}
            </Box>
          </form>
        </PaperContent>
      </PaperCard>
    </PageContainer>
  );
};

export default ForgotPassword;
