import { KeyboardArrowLeft } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { PaperCard, PaperContent, PaperFooter, PaperHeader } from 'components';
import routes from 'consts/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import editionService from 'services/edition-service';
import Edition, { EditionStatus } from 'types/Edition';
import { dateString, getNameOfDay } from 'utils';
import EditionDetails from './components/EditionDetails';
import EditionStatusChip from 'components/EditionStatusChip/EditionStatusChip';
import EditionSync from './components/EditionSync';
import EditionFiles from './components/EditionFiles';
import { ConsentModal } from 'modals';
import AlertType from 'types/Alert';

const EditionPage: FC = () => {
  const navigate = useNavigate();
  // get edition id from url
  const params = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>();
  const [edition, setEdition] = useState<Edition>();
  const [previousEdition, setPreviousEdition] = useState<Edition>();
  const [modal, setModal] = useState<number>();

  const getEditions = async () => {
    setLoading(true);

    try {
      const [editionRes, previousEditionRes] = await Promise.all([
        editionService.get(Number(params.id)),
        editionService.getPrevious(Number(params.id))
      ]);

      setEdition(editionRes.data);
      setPreviousEdition(previousEditionRes.data);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEditions();
  }, []);

  const getTitle = (date: Date) => {
    return getNameOfDay(date) + ' ' + dateString(date);
  };

  const onBack = () => {
    navigate(routes.PAPER.path);
  };

  const changeEditionStatus = async (editionStatus: EditionStatus) => {
    setLoading(true);
    try {
      const { data: publishedEdition } = await editionService.update({
        ...edition,
        status: editionStatus
      });

      setEdition(publishedEdition);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteEdition = async () => {
    setLoading(true);
    try {
      await editionService.delete(edition.id);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
      navigate(routes.PAPER.path, {
        state: {
          alert: {
            text: t('edition.success.deleted'),
            type: 'success'
          } as AlertType
        }
      });
    }
  };

  return (
    <>
      <PaperCard sx={{ margin: 2 }}>
        {edition && (
          <>
            <PaperHeader>
              <Stack direction={'row'} alignItems={'center'} gap={2}>
                <IconButton onClick={onBack} sx={{ position: 'relative' }}>
                  <KeyboardArrowLeft />
                </IconButton>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  flex={1}
                >
                  <Typography variant={'h5'} sx={{ padding: '10px 0', display: 'inline-block' }}>
                    {edition.editionNumber} {getTitle(edition.date)}
                  </Typography>
                  <EditionStatusChip status={edition.status} />
                </Stack>
              </Stack>
            </PaperHeader>
            <PaperContent>
              <Stack gap={2}>
                <Stack gap={2} direction={'row'}>
                  <EditionDetails edition={edition} onEdit={() => getEditions()} />
                  <EditionSync edition={edition} onSynced={setEdition} />
                </Stack>
                <EditionFiles
                  edition={edition}
                  previousEdition={previousEdition}
                  subscribersSynced={edition?.lastSynced ? true : false}
                />
              </Stack>
            </PaperContent>
            <PaperFooter>
              <Stack flexDirection={'row'} gap={1.5} justifyContent={'flex-end'}>
                {edition?.status === 'DRAFT' && (
                  <Button color={'error'} onClick={() => setModal(2)} variant={'outlined'}>
                    {t('common.delete')}
                  </Button>
                )}
                {edition?.status === 'DRAFT' && (
                  <Button onClick={() => setModal(1)} variant={'contained'}>
                    {t('common.publish')}
                  </Button>
                )}
                {edition?.status === 'PUBLISHED' && (
                  <Button onClick={() => changeEditionStatus('DRAFT')} variant={'outlined'}>
                    {t('common.changeBackToDraft')}
                  </Button>
                )}
              </Stack>
            </PaperFooter>
          </>
        )}
        {loading && (
          <Stack direction={'row'} justifyContent={'center'}>
            <CircularProgress />
          </Stack>
        )}
      </PaperCard>
      {modal === 1 && (
        <ConsentModal
          title={t('edition.publishConfirmTitle')}
          content={t('edition.publishConfirmMessage')}
          onCancel={() => setModal(undefined)}
          onConfirm={() => {
            setModal(undefined);
            changeEditionStatus('PUBLISHED');
          }}
        />
      )}
      {modal === 2 && (
        <ConsentModal
          title={t('edition.deleteConfirmTitle')}
          content={t('edition.deleteConfirmMessage')}
          onCancel={() => setModal(undefined)}
          onConfirm={() => {
            setModal(undefined);
            deleteEdition();
          }}
        />
      )}
    </>
  );
};

export default EditionPage;
