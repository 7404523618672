/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosError } from 'axios';
import config from 'utils/_config';

/* eslint-disable indent */
const apiUrl = new URL(config.API_URL);
type Log = {
  level: string;
  message: string;
  errorMessage?: string;
  name?: string;
  stack?: string;
  status?: string;
  splat?: any;
  timestamp?: Date;
};

const logToApi = async (...args: any) => {
  if (args.length === 0) {
    return;
  }
  try {
    const body: Log = {
      level: args[0],
      message: 'Unknown message',
      name: 'Message'
    };

    // Check for splats
    const splat: any = args.find(
      (a: any) => typeof a === 'object' && !(a instanceof Error) && !axios.isAxiosError(a)
    );
    if (splat) {
      body.splat = splat;
      body.name = 'Splat';
    }

    // Check for AxiosError
    const axiosError: AxiosError = args.find((a: any) => axios.isAxiosError(a));
    if (axiosError) {
      body.name = 'Axios Error';
      body.message = axiosError.message;
      body.errorMessage = axiosError.message;
      body.stack = axiosError.stack;
      body.splat = axiosError.config;

      // Sanitize sensitive data
      if (body.splat) {
        if (body.splat.data && body.splat.data.toLowerCase().includes('password')) {
          body.splat.data = 'DATA SANITIZED';
        }
        if (body.splat.headers && body.splat.headers.Authorization) {
          body.splat.headers.Authorization = 'DATA SANITIZED';
        }
      }
    }

    // Check for Error
    const error: Error = args.find((a: any) => a instanceof Error && !axios.isAxiosError(a));
    if (error) {
      body.stack = error.stack;
      body.name = error.name;
      body.message = error.message;
      body.errorMessage = error.message;
    }

    // Check for Message, overwrite previous message
    if (typeof args[1] === 'string') {
      body.message = args[1];
    }

    // POST request using fetch() in case axios has issues
    await fetch(`${apiUrl}logger`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
  } catch (loggerError) {
    // Fail silently
    console.error('Error reporing failed', loggerError);
  }
};

const logger = {
  error: async (...args: any) => {
    console.error(...args);
    await logToApi('error', ...args);
  },
  debug: async (...args: any) => {
    console.debug(...args);
    await logToApi('debug', ...args);
  },
  info: async (...args: any) => {
    console.info(...args);
    await logToApi('info', ...args);
  },
  warn: async (...args: any) => {
    console.warn(...args);
    await logToApi('warn', ...args);
  }
};

export default logger;
