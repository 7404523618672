import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type PrimarySearchStyles = {
  filterMenu: SxProps<Theme>;
  divider: SxProps<Theme>;
  filterHeader: SxProps<Theme>;
  filtersContainer: SxProps<Theme>;
  actions: SxProps<Theme>;
};

const styles: PrimarySearchStyles = {
  filterMenu: {
    padding: '10px'
  },
  divider: {
    height: '30px',
    marginRight: '12px',
    borderColor: '#fff'
  },
  filterHeader: {
    marginBottom: '10px'
  },
  filtersContainer: {
    maxWidth: '300px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '7px',

    '& .MuiMenuItem-root': {
      padding: 0
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
};

export default styles;
