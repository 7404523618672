/* eslint-disable max-lines */
/* eslint-disable indent */
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import ReactHookTimePicker from 'components/ReactHookTimePicker/ReactHookTimePicker';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import distributionRouteService from 'services/distribution-route-service';
import postenDistributionService from 'services/posten-distribution-service';
import DistributionRoute, {
  DistributionRouteType,
  distributionRouteTypes
} from 'types/DistributionRoute';
import PostenDistributionRoute from 'types/PostenDistributionRoute';
import PostenDistributionRouteInfo from './components/PostenDistributionRouteInfo';
import { Edit } from '@mui/icons-material';
import useAlert from 'hooks/useAlert';
import logger from 'logger/logger';
import { ConsentModal } from 'modals';

type DistributionRoutesProps = {
  distributionRoute?: DistributionRoute;
  onCancel: () => void;
  onFinished: (distributionRoute: DistributionRoute) => void;
  onDelete: () => void;
};

type DistributionRouteFields = {
  id: string;
  name: string;
  postCodeFrom: string;
  postCodeTo: string;
  type: DistributionRouteType;
  departureTime: string;
  transportRoute?: string;
  deliveryPlace?: string;
  carText?: string;
  dropText?: string;
  dropText2?: string;
  postenDeliveryRoute?: boolean;
  airSendt?: boolean;
  dontWritePackageSlip?: boolean;
  disrictCode?: string;
  extraNewspapers?: number;
  toPostenOrderBook?: boolean;
  named?: boolean;
  toDIOrderBook?: boolean;
  postenDistRouteId: number;
};

const DistributionRouteModal: FC<DistributionRoutesProps> = ({
  distributionRoute,
  onCancel,
  onFinished,
  onDelete
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const [modal, setModal] = useState<'confirmDelete'>();
  const [postenRoutesLoading, setPostenRoutesLoading] = useState<boolean>();
  const [localPostRoute, setLocalPostRoute] = useState<PostenDistributionRoute>();
  const [editPostenDistributionRoute, setEditPostenDistributionRoute] = useState<boolean>(
    !!distributionRoute
  );

  const { addAlert } = useAlert();

  const { register, handleSubmit, watch, control, setValue, getValues } =
    useForm<DistributionRouteFields>({
      defaultValues: {
        id: distributionRoute?.id,
        name: distributionRoute?.name,
        postCodeFrom: distributionRoute?.postCodeFrom,
        postCodeTo: distributionRoute?.postCodeTo,
        departureTime: distributionRoute?.departureTime ?? '00:00',
        type: distributionRoute?.type ?? 'POLARIS',
        transportRoute: distributionRoute?.transportRoute,
        deliveryPlace: distributionRoute?.deliveryPlace,
        carText: distributionRoute?.carText,
        dropText: distributionRoute?.dropText,
        dropText2: distributionRoute?.dropText2,
        airSendt: distributionRoute?.airSendt ?? false,
        dontWritePackageSlip: distributionRoute?.dontWritePackageSlip ?? false,
        disrictCode: distributionRoute?.disrictCode ?? '',
        extraNewspapers: distributionRoute?.extraNewspapers ?? 0,
        toDIOrderBook: distributionRoute?.toDIOrderBook ?? false,
        named: distributionRoute?.named ?? false,
        toPostenOrderBook: distributionRoute?.toPostenOrderBook ?? false
      }
    });

  const distributor = watch('type');
  const postCodeFrom = watch('postCodeFrom');

  //const validatePosten

  const onSubmit = async (fields: DistributionRouteFields) => {
    const distributionRouteFields: DistributionRoute = {
      ...distributionRoute,
      ...fields,
      id: fields.id,
      name: fields.name,
      postCodeFrom: fields.postCodeFrom,
      postCodeTo: fields.postCodeTo
    };

    setLoading(true);

    try {
      if (distributionRoute) {
        const { data: updatedDistributionRoute } = await distributionRouteService.update(
          distributionRoute.id,
          distributionRouteFields
        );
        onFinished(updatedDistributionRoute);
      } else {
        const { data: newDistributionRoute } = await distributionRouteService.add(
          distributionRouteFields
        );
        onFinished(newDistributionRoute);
      }
    } catch (error) {
      addAlert('error', t('distribution.errorSavingDistributionRoute'));
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (distributionRoute) {
      setLoading(true);
      distributionRouteService
        .delete(distributionRoute.id)
        .then(() => {
          setLoading(false);

          onDelete();
        })
        .catch((error) => {
          addAlert('error', t('distribution.errorDeletingDistributionRoute'));
          logger.error(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (distributionRoute?.postenDistRouteId) {
      getPostenDistributionRouteFromPostNr();
    }
  }, []);

  const title = distributionRoute ? t('distribution.edit') : t('distribution.createNew');

  const distributionTypesItems = Object.values(distributionRouteTypes).map((drv) => {
    return { label: t(`distribution.distributors.${drv}`), val: drv };
  });

  const getPostenDistributionRouteFromPostNr = async () => {
    setPostenRoutesLoading(true);
    try {
      const { data } = await postenDistributionService.getLocalRouteFromPostCode(postCodeFrom);
      setLocalPostRoute(data);
      setValue('postenDistRouteId', data.id);
    } catch (error) {
      console.error(error);
    } finally {
      setPostenRoutesLoading(false);
    }
  };

  return (
    <Dialog open={true}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <DialogTitle>{title}</DialogTitle>
        {distributionRoute && (
          <Box p={2}>
            <Typography>
              {t('distribution.route.numberOfUsers')}: {distributionRoute.numberOfUsers ?? '--'}
            </Typography>
          </Box>
        )}
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '670px' }}>
        <DialogContent>
          <Stack gap={2}>
            <Controller
              name='type'
              control={control}
              defaultValue={distributionTypesItems[0].val}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={t('distribution.distributor').toString()}
                  sx={{ minWidth: '200px' }}
                >
                  {distributionTypesItems.map((dt) => (
                    <MenuItem key={dt.val} value={dt.val}>
                      {dt.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            {distributor === 'LOCAL_POSTEN_ROUTE' && (
              <Stack direction={'column'} gap={2}>
                <TextField label={t('distribution.ID')} {...register('id', { required: true })} />
                <Stack direction={'row'} gap={2}>
                  <TextField
                    disabled={postenRoutesLoading}
                    sx={{ flex: 1 }}
                    label={t('common.postCode')}
                    {...register('postCodeFrom', {
                      required: true
                    })}
                  />
                  {!editPostenDistributionRoute && (
                    <Button onClick={getPostenDistributionRouteFromPostNr}>
                      {t('common.find')} {t('common.postRoute')}
                    </Button>
                  )}
                  {editPostenDistributionRoute && (
                    <IconButton
                      sx={{ flexShrink: 0 }}
                      onClick={() => setEditPostenDistributionRoute(true)}
                    >
                      <Edit />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
            )}
            <Divider />
            <Stack direction={'row'} gap={2}>
              {(distributor === 'POLARIS' || distributor === 'POSTEN') && (
                <Stack direction={'row'} gap={2}>
                  <Stack gap={2}>
                    <TextField label={t('distribution.ID')} required={true} {...register('id')} />
                    <TextField
                      required={true}
                      label={t('common.name')}
                      {...register('name', {
                        required: true
                      })}
                    />
                    <Stack direction={'row'} gap={2} width={'100%'}>
                      <TextField
                        sx={{ flex: 1 }}
                        required={true}
                        label={t('distribution.postCodeFrom')}
                        {...register('postCodeFrom', {})}
                      />
                      <TextField
                        sx={{ flex: 1 }}
                        label={t('distribution.postCodeTo')}
                        {...register('postCodeTo')}
                      />
                    </Stack>
                    <Stack direction={'row'} gap={2} width={'100%'}>
                      <TextField
                        required={true}
                        sx={{ flex: 1 }}
                        label={t('distribution.route.transportRoute')}
                        {...register('transportRoute')}
                      />
                      <ReactHookTimePicker
                        name={'departureTime'}
                        label={t('distribution.route.departureTime')}
                        control={control}
                      />
                    </Stack>
                    <TextField
                      label={t('distribution.route.deliveryPlace')}
                      {...register('deliveryPlace')}
                    />
                    <TextField
                      required={true}
                      label={t('distribution.route.carText')}
                      {...register('carText')}
                    />
                    <TextField label={t('distribution.route.dropText')} {...register('dropText')} />
                    <TextField
                      label={t('distribution.route.dropText2')}
                      {...register('dropText2')}
                    />
                  </Stack>
                </Stack>
              )}
              {distributor === 'LOCAL_POSTEN_ROUTE' && (
                <Stack flex={1} gap={2}>
                  {localPostRoute && (
                    <PostenDistributionRouteInfo postenDistributionRoute={localPostRoute} />
                  )}
                  {localPostRoute === null && (
                    <Typography>
                      {t('alerts.couldNotFindLocalPostenRouteOnPostCode', {
                        postCode: postCodeFrom
                      })}
                    </Typography>
                  )}
                  {postenRoutesLoading && (
                    <Stack direction={'row'} justifyContent={'center'}>
                      <CircularProgress />
                    </Stack>
                  )}
                  {localPostRoute === undefined && (
                    <Typography>
                      {t('distribution.route.addPostCodeAndPressFindPostenRoute')}
                    </Typography>
                  )}
                  <Alert severity='info'>{t('alerts.postenRouteNeedsToBeLocal')}</Alert>
                </Stack>
              )}
              <Divider orientation={'vertical'} flexItem />
              <Stack gap={2}>
                <PaperCard variant={'outlined'}>
                  <PaperContent>
                    <Stack>
                      {distributor === 'POLARIS' && (
                        <FormControlLabel
                          control={<Checkbox defaultChecked={getValues('toDIOrderBook')} />}
                          {...register('toDIOrderBook')}
                          onChange={(e, checked) => {
                            setValue('toDIOrderBook', checked);
                          }}
                          label={t('distribution.route.toDIOrderBook')}
                        />
                      )}
                      {(distributor === 'LOCAL_POSTEN_ROUTE' || distributor === 'POSTEN') && (
                        <>
                          <FormControlLabel
                            control={<Checkbox defaultChecked={getValues('toPostenOrderBook')} />}
                            {...register('toPostenOrderBook')}
                            onChange={(e, checked) => {
                              setValue('toPostenOrderBook', checked);
                            }}
                            label={t('distribution.route.toPostenOrderBook')}
                          />
                        </>
                      )}
                      <FormControlLabel
                        control={<Checkbox defaultChecked={getValues('named')} />}
                        {...register('named')}
                        onChange={(e, checked) => {
                          setValue('named', checked);
                        }}
                        label={t('common.named')}
                      />
                      <FormControlLabel
                        control={<Checkbox defaultChecked={getValues('dontWritePackageSlip')} />}
                        {...register('dontWritePackageSlip')}
                        onChange={(e, checked) => {
                          setValue('dontWritePackageSlip', checked);
                        }}
                        label={t('distribution.route.dontWritePackageSlip')}
                      />
                    </Stack>
                  </PaperContent>
                </PaperCard>
                <TextField
                  label={t('distribution.route.extraNewspapers')}
                  type={'number'}
                  {...register('extraNewspapers')}
                />
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          {distributionRoute && (
            <Button color={'error'} disabled={loading} onClick={() => setModal('confirmDelete')}>
              {t('common.delete')}
            </Button>
          )}
          <Button disabled={loading} onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          {loading && <CircularProgress />}
          {!loading && (
            <Button type={'submit'}>
              {distributionRoute ? t('distribution.update') : t('distribution.add')}
            </Button>
          )}
        </DialogActions>
      </form>
      {modal === 'confirmDelete' && (
        <ConsentModal
          onCancel={() => setModal(undefined)}
          onConfirm={handleDelete}
          title={t('distribution.route.confirmDeletion')}
          confirmText={t('common.delete')}
        />
      )}
    </Dialog>
  );
};

export default DistributionRouteModal;
