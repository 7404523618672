/* eslint-disable indent */
import axios, { AxiosPromise } from 'axios';
import userSubscriptionCancelationType from 'consts/cancelationType';
import PaymentSolution from 'types/PaymentSolution';
import UserSubscription, { CancelationType } from 'types/UserSubscription';

const userSubscriptionService = {
  get: (userId: number) => {
    return axios.get(`user-subscription/by-user-id/${userId}`);
  },
  getById: (id: number) => {
    return axios.get<UserSubscription>(`user-subscription/${id}`);
  },
  update: (userSubscription: UserSubscription) => {
    return axios.put(`user-subscription/${userSubscription.id}`, {
      renewDate: userSubscription.renewDate,
      status: userSubscription.status,
      cancelationType: userSubscription.cancelationType,
      cancelationReason: userSubscription.cancelationReason,
      previousRenewDate: userSubscription.previousRenewDate,
    });
  },
  cancel: (userSubscription: UserSubscription) => {
    return axios.put(`user-subscription/${userSubscription.id}/cancel`, {
      cancelationType:
        userSubscription.cancelationType || userSubscriptionCancelationType.CANCELED_BY_ADMIN,
        cancelationReason: userSubscription.cancelationReason
    } as {
      cancelationType: CancelationType;
      cancelationReason: string;
    });
  },
  add: (subscriptionId: number, campaignId: number, userId: number, paymentSolution: PaymentSolution, renewDate?: Date) => {
    return axios.post('user-subscription', {
      subscriptionId: subscriptionId,
      campaignId: campaignId,
      userId: userId,
      paymentSolution: paymentSolution,
      renewDate: renewDate
    });
  },
  getNumberOfActiveUsers: (subscriptionId: number): AxiosPromise<number> => {
    return axios.get(`user-subscription/count-active-users/${subscriptionId}`);
  },
  retryRenewal: (userSubscriptionId: number): AxiosPromise<UserSubscription> => {
    return axios.post(`user-subscription/renewal/retry/${userSubscriptionId}`);
  },
  syncRenewalPayment: (userSubscriptionId: number): AxiosPromise<UserSubscription> => {
    return axios.get(`user-subscription/renewal/sync/${userSubscriptionId}`);
  }
};

export default userSubscriptionService;
