import { FC } from 'react';
import { Stack } from '@mui/material';
import EditionsOverview from 'components/EditionsOverview/EditionsOverview';
import PageWrapper from 'components/PageWrapper/PageWrapper';
import DistributionOverview from 'components/DistributionOverview/DistributionOverview';

const PaperOverview: FC = () => {
  return (
    <PageWrapper padding>
      <Stack gap={2} flexWrap={'wrap'} direction={'row'}>
        <EditionsOverview />
        <DistributionOverview />
      </Stack>
    </PageWrapper>
  );
};

export default PaperOverview;
