import { PaperCard } from 'components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NOTIFICATIONS from 'consts/notifications';
import { useNavigate, useParams } from 'react-router-dom';
import NotificationDetails from './NotificationDetails';
import { IconButton, Typography } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';

const Reports: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { slug } = useParams();
  const [notification, setNotification] = useState<{ name: string; slug: string, createAction: boolean }>(undefined);
  useEffect(() => {
    if (slug) {
      const notificationQuery = NOTIFICATIONS.find((r) => r.slug === slug);
      if (notificationQuery) {
        setNotification(notificationQuery);
      }
    }
  }, [slug]);

  return (
    <>
      {notification ? (
        <NotificationDetails name={notification.name} slug={notification.slug} createAction={notification.createAction} />
      ) : (
        <PaperCard sx={{ padding: '20px' }}>
          <Typography>
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <KeyboardArrowLeft />
            </IconButton>
            {t('notificaions.invalidNotification')}
          </Typography>
        </PaperCard>
      )}
    </>
  );
};

export default Reports;
