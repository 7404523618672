import { FC } from 'react';
import FormDialog from 'components/FormDialog/FormDialog';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import UserDistribution, {
  distributionMessageCodes as distributionMessageCodesObject
} from 'types/UserDistribution';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { ReactHookDatePicker } from 'components';

type DistributionMessageModalProps = {
  userDistribution: UserDistribution;
  onCancel: () => void;
  onEdit: (userDistribution: UserDistribution) => void;
};

type DistributionMessageFields = {
  distributionMessage: string;
  distributionMessageCode: 'IFA';
  distributionMessageDate: Date;
};

const DistributionMessageModal: FC<DistributionMessageModalProps> = ({
  userDistribution,
  onCancel,
  onEdit
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<DistributionMessageFields>({
    defaultValues: {
      distributionMessage: userDistribution.distributionMessage || '',
      distributionMessageCode: userDistribution.distributionMessageCode || 'IFA',
      distributionMessageDate: userDistribution.distributionMessageDate
        ? new Date(userDistribution.distributionMessageDate)
        : null
    }
  });

  const distributionMessageCodes = Object.values(distributionMessageCodesObject);

  const onSubmit: SubmitHandler<DistributionMessageFields> = async (fields) => {
    const { distributionMessage, distributionMessageCode, distributionMessageDate } = fields;

    onEdit({
      ...userDistribution,
      distributionMessage,
      distributionMessageCode,
      distributionMessageDate
    });
  };

  const deleteDistributionMessage = async () => {
    onEdit({
      ...userDistribution,
      distributionMessage: null,
      distributionMessageCode: null,
      distributionMessageDate: null
    });
  };

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t('common.editNote')}
      submitText={t('common.change')}
      actions={
        userDistribution?.distributionMessage && (
          <Button color={'error'} onClick={deleteDistributionMessage}>
            {t('common.delete')}
          </Button>
        )
      }
    >
      <div className='dialog-content'>
        <Stack spacing={2}>
          <ReactHookDatePicker
            required={true}
            name={'distributionMessageDate'}
            label={t('common.date')}
            control={control}
            error={errors.distributionMessageDate ? true : false}
          />
          <FormControl fullWidth>
            <InputLabel>{t('userDistribution.distributionMessageCode')}</InputLabel>
            <Select
              defaultValue={userDistribution.distributionMessageCode || 'IFA'}
              {...register('distributionMessageCode', {
                required: true
              })}
              label={t('userDistribution.distributionMessageCode')}
              error={errors.distributionMessageCode ? true : false}
            >
              {distributionMessageCodes.map((distributionMessageCode) => (
                <MenuItem key={distributionMessageCode} value={distributionMessageCode}>
                  {distributionMessageCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            multiline
            rows={4}
            label={t('userDistribution.distributionMessage')}
            {...register('distributionMessage', {
              required: true
            })}
            error={errors.distributionMessage ? true : false}
          />
        </Stack>
      </div>
    </FormDialog>
  );
};

export default DistributionMessageModal;
