import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { PaperCard, PaperContent, PaperHeader, PrimaryListItem } from 'components';
import PaymentSolutionChip from 'components/PaymentSolutionChip/PaymentSolutionChip';
import COLORS from 'consts/colors';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import subscriptionService from 'services/subscription-service';
import Subscription from 'types/Subscription';
import UserSubscription from 'types/UserSubscription';
import { dateString, getNameOfDay } from 'utils';

type CanceledUserSubscriptionsProps = {
  userSubscriptions: UserSubscription[];
};

const CanceledUserSubscriptions: FC<CanceledUserSubscriptionsProps> = ({ userSubscriptions }) => {
  const { t } = useTranslation();
  const [displaySubscriptions, setDisplaySubscriptions] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>();

  const getSubscriptions = async () => {
    try {
      const { data: subscriptionsData } = await subscriptionService.get();
      setSubscriptions(subscriptionsData);
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  const sortedUserSubscriptions = userSubscriptions
    .sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    })
    .reverse();

  return (
    <Stack gap={2} minWidth={'600px'}>
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        <Typography variant='h6'>{t('common.oldUserSubscriptions')}</Typography>
        <IconButton onClick={() => setDisplaySubscriptions(!displaySubscriptions)}>
          {(displaySubscriptions && <KeyboardArrowDown />) || <KeyboardArrowRight />}
        </IconButton>
      </Stack>
      {displaySubscriptions &&
        subscriptions &&
        sortedUserSubscriptions.map((userSubscription) => {
          const cancelationDate = userSubscription?.cancelationDate
            ? new Date(userSubscription?.cancelationDate) || new Date()
            : null;
          const subscription = subscriptions.find((s) => s.id === userSubscription.subscriptionId);

          return (
            <PaperCard variant='outlined' key={userSubscription.id}>
              <PaperHeader sx={{ background: COLORS.BACKGROUND_DARK }}>
                <Stack direction={'row'}>
                  <Typography>{t('userSubscription.id') + ' ' + userSubscription.id}</Typography>
                </Stack>
              </PaperHeader>
              <PaperContent sx={{ pt: 0 }}>
                <PrimaryListItem
                  label={t('common.subscription')}
                  value={<Typography>{subscription.name}</Typography>}
                />
                <PrimaryListItem
                  label={t('common.subscriptionId')}
                  value={<Typography>{userSubscription.subscriptionId}</Typography>}
                />
                <PrimaryListItem
                  label={t('common.paymentSolution')}
                  actions={
                    <PaymentSolutionChip paymentSolution={userSubscription.paymentSolution} />
                  }
                />
                <PrimaryListItem
                  label={t('common.expired')}
                  value={
                    <Typography>
                      {cancelationDate
                        ? getNameOfDay(cancelationDate) + ' ' + dateString(cancelationDate)
                        : '--'}
                    </Typography>
                  }
                />
                <PrimaryListItem
                  label={t('common.cancelationType')}
                  value={
                    <Typography>
                      {t('cancelationType.' + userSubscription.cancelationType)}
                    </Typography>
                  }
                />
                <PrimaryListItem
                  label={t('common.cancelationReason')}
                  value={
                    <Typography>
                      {t(userSubscription.cancelationReason)}
                    </Typography>
                  }
                />
              </PaperContent>
            </PaperCard>
          );
        })}
    </Stack>
  );
};

export default CanceledUserSubscriptions;
