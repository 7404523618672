import { FC } from 'react';
import { Stack, SxProps } from '@mui/material';

type PageContentProps = {
  sx?: SxProps;
  children?: React.ReactNode;
};

const PageContent: FC<PageContentProps> = ({ children, sx }) => {
  const pageContentStyle: SxProps = {
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    flex: 1,
    padding: 2
  };

  return (
    <Stack direction={'column'} sx={{ ...pageContentStyle, ...sx }}>
      {children}
    </Stack>
  );
};

export default PageContent;
