import {
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PostenDistributionData from 'types/PostenDistributionData';

type PostenDistributionListProps = {
  postenDistributionDataArr: PostenDistributionData[];
};

const PostenDistributionList: FC<PostenDistributionListProps> = ({
  postenDistributionDataArr = []
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs
        onChange={(event, value) => {
          setTab(value);
        }}
        value={tab}
      >
        <Tab label='Lokalt' value={0} />
        <Tab label='Regionalt' value={1} />
      </Tabs>
      {(postenDistributionDataArr.length > 0 && (
        <TableContainer sx={{ overflowX: 'unset', minHeight: '50vh', overflowY: 'auto' }}>
          <Table sx={{ minWidth: 350 }} stickyHeader>
            {tab === 0 && (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography>P.nr</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.postCode')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.postOffice')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.deliveryAddress')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.deadline')}</Typography>
                    </TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {postenDistributionDataArr.map((postenPostCodeOverivew) => (
                    <TableRow key={postenPostCodeOverivew.id}>
                      <TableCell>
                        <Typography>{postenPostCodeOverivew.postenId}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{postenPostCodeOverivew.postCode}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{postenPostCodeOverivew.postOffice}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{postenPostCodeOverivew.deliveryAddress}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{postenPostCodeOverivew.deadlineLocal}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
            {tab === 1 && (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography>P.nr</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.postCode')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.postOffice')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.deliveryAddress')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.deadline')}</Typography>
                    </TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {postenDistributionDataArr.map((postenPostCodeOverivew) => (
                    <TableRow key={postenPostCodeOverivew.id}>
                      <TableCell>
                        <Typography>{postenPostCodeOverivew.postenId}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{postenPostCodeOverivew.postCodeRegional}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{postenPostCodeOverivew.postOfficeRegional}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{postenPostCodeOverivew.deliveryAddressRegional}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{postenPostCodeOverivew.deadlineRegional}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      )) || (
        <Stack sx={{ p: 4 }} direction={'row'} justifyContent={'center'}>
          <Typography>{t('common.theListIsEmpty')}</Typography>
        </Stack>
      )}
    </>
  );
};

export default PostenDistributionList;
