import { Add } from '@mui/icons-material';
import { SxProps, Stack, Typography, Button, CircularProgress } from '@mui/material';
import { PaperHeader, PrimarySearch, PaperContent, PaperFooter, PaperCard } from 'components';
import DistributionRouteList from 'components/DistributionRouteList/DistributionRouteList';
import DistributionRouteModal from 'components/DistributionRouteModal/DistributionRouteModal';
import PrimarySearchPageNav from 'components/PrimarySearchPageNav/PrimarySearchPageNav';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import distributionRouteService from 'services/distribution-route-service';
import DistributionRoute from 'types/DistributionRoute';
import PageResults from 'types/PageResults';
import SearchQuery from 'types/SearchQuery';
import SearchQueryType from 'types/SearchQueryType';

const LIMIT = 20;

const CurrierRoutesPage: FC = () => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<SearchQuery>();
  const [pageResults, setPageResults] = useState<PageResults<DistributionRoute>>();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState<number>();

  const queryTypes: SearchQueryType[] = [{ key: 'id', label: t('common.routeId') }];

  const init = async () => {
    const offset = searchQuery?.page ? LIMIT * (searchQuery?.page - 1) : 0;

    searchDistributionRoutes(
      {
        query: searchQuery?.query || null,
        filters: searchQuery?.filters || undefined,
        queryType: searchQuery?.queryType || queryTypes[0],
        page: searchQuery?.page || 1
      },
      LIMIT,
      offset
    );
  };

  const changePage = (value: number) => {
    const updatedSearchQuery = { ...searchQuery, page: value };
    setSearchQuery(updatedSearchQuery);

    const offset = (value - 1) * LIMIT;
    searchDistributionRoutes(updatedSearchQuery, LIMIT, offset);
  };

  const searchDistributionRoutes = async (
    searchQueryP: SearchQuery,
    limit: number,
    offset: number
  ) => {
    setLoading(true);
    try {
      const { data } = await distributionRouteService.search(searchQueryP, limit, offset);
      setPageResults(data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const styledPaperContent: SxProps = {
    overflowY: 'auto',
    flex: 1,
    padding: 0,
    p: 0
  };

  return (
    <>
      <PaperCard
        sx={{
          width: '1200px'
        }}
      >
        <PaperHeader>
          <Stack gap={2}>
            <Stack
              direction={'row'}
              gap={2}
              alignItems={'center'}
              flex={1}
              justifyContent={'space-between'}
            >
              <Stack direction={'row'} gap={2} alignItems={'center'} flex={4}>
                <Typography variant='h5'>{t('distribution.route.*')}</Typography>
                <PrimarySearch
                  defaultQuery={searchQuery?.query}
                  queryTypes={queryTypes}
                  onSearch={(query, queryType) => {
                    searchDistributionRoutes(
                      { ...searchQuery, query, queryType, page: 1 },
                      LIMIT,
                      0
                    );
                  }}
                />
                {pageResults && (
                  <Typography sx={{ textAlign: 'right', whiteSpace: 'nowrap' }} flex={1}>
                    {t('common.hits') + ': ' + pageResults.total}
                  </Typography>
                )}
              </Stack>
              <Stack flex={2}>
                <Button
                  variant={'outlined'}
                  onClick={() => setModal(1)}
                  sx={{ whiteSpace: 'nowrap', p: '10px 20px', alignSelf: 'flex-end' }}
                  startIcon={<Add />}
                >
                  {t('distribution.add')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </PaperHeader>
        <PaperContent sx={styledPaperContent}>
          {!loading && (
            <DistributionRouteList distributionRoutes={pageResults?.results} onUpdate={init} />
          )}
          {loading && (
            <Stack style={{ alignItems: 'center' }}>
              <CircularProgress sx={{ margin: '10px 0' }} />
            </Stack>
          )}
        </PaperContent>
        <PaperFooter>
          {pageResults && (
            <PrimarySearchPageNav
              page={searchQuery?.page || 1}
              totalResults={pageResults?.total}
              onChangePage={changePage}
            />
          )}
        </PaperFooter>
        {modal === 1 && (
          <DistributionRouteModal
            onDelete={() => {
              setModal(undefined);
              setLoading(true);
              init();
            }}
            onCancel={() => setModal(undefined)}
            onFinished={() => {
              setModal(undefined);
              setLoading(true);
              init();
            }}
          />
        )}
      </PaperCard>
    </>
  );
};

export default CurrierRoutesPage;
