import COLORS from 'consts/colors';
import { FC, ReactNode, useEffect, useState, useRef, CSSProperties } from 'react';

type PaperFooterProps = {
  children: ReactNode;
  style?: CSSProperties;
};

const PaperFooter: FC<PaperFooterProps> = ({ children, style }) => {
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef(null);
  const defaultStyle: CSSProperties = {
    padding: '20px',
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    background: COLORS.SECONDARY,
    width: 'calc(100% - 40px)'
  };
  const elementStyle = { ...defaultStyle, ...style };

  useEffect(() => {
    setFooterHeight(footerRef.current.clientHeight);
  }, [footerRef, children]); //empty dependency array so it only runs once at render

  return (
    <>
      <div style={{ height: `${footerHeight}px` }}></div>
      <div ref={footerRef} style={elementStyle}>
        {children}
      </div>
    </>
  );
};

export default PaperFooter;
