import { TableRow, TableCell, Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import UserToken from 'types/UserToken';
import { getHumanFriendlyDateTime } from 'utils/_dateUtil';

type UserTokenRowProps = {
  userToken: UserToken;
  updateUserToken: (userToken: UserToken) => void;
};

const UserTokenRow: FC<UserTokenRowProps> = ({ userToken, updateUserToken }) => {
  const { t } = useTranslation();
  const toggleActiveDevice = () => {
    updateUserToken({ ...userToken, activeDevice: !userToken.activeDevice });
  };
  return (
    <TableRow>
      <TableCell>{userToken.name}</TableCell>
      <TableCell>{userToken.browser}</TableCell>
      <TableCell>{getHumanFriendlyDateTime(userToken.expires)}</TableCell>
      <TableCell>{getHumanFriendlyDateTime(userToken.lastUsed)}</TableCell>
      <TableCell>
        {userToken.activeDevice ? (
          <Button size="small" color='error' onClick={toggleActiveDevice}>{t('common.deactivate')}</Button>
        ) : (
          <Button size="small" onClick={toggleActiveDevice}>{t('common.activate')}</Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default UserTokenRow;
