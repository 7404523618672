import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';

type TooltipProps = {
  text: string;
};

const PrimaryTooltip: FC<TooltipProps> = ({ text }) => {
  return (
    <Tooltip title={text}>
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );
};

export default PrimaryTooltip;
