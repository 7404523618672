import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import MuiColorProp from 'types/MuiColorProp';
import './FormDialog.scss';

type FormDialogProps = {
  title?: string;
  onSubmit?: () => void;
  submitText?: string;
  onCancel?: () => void;
  cancelText?: string;
  children: ReactNode;
  className?: string;
  loading?: boolean;
  sx?: SxProps;
  sxContent?: SxProps;
  actions?: ReactNode;
  submitButtonVariant?: 'contained' | 'outlined' | 'text';
  submitButtonColor?: MuiColorProp;
};
const FormDialog: FC<FormDialogProps> = ({
  title,
  onCancel,
  onSubmit,
  submitText,
  cancelText,
  children,
  loading,
  sx = {},
  sxContent = {},
  className,
  actions = [],
  submitButtonColor = 'primary',
  submitButtonVariant = 'outlined'
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true} className={className} maxWidth="lg" sx={sx}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent sx={{ padding: '10px 24px 10px 24px', ...sxContent }}>
          {children}
        </DialogContent>
        <DialogActions>
          {actions}
          <Button onClick={onCancel} disabled={loading}>
            {cancelText || t('common.cancel')}
          </Button>
          <Button
            color={submitButtonColor}
            variant={submitButtonVariant}
            type={'submit'}
            disabled={loading}
          >
            {loading ? <CircularProgress size={'30px'} /> : submitText || t('common.confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormDialog;
