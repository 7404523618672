/* eslint-disable indent */
import './UserList.scss';
import {
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import EditIcon from '@mui/icons-material/Edit';
import getUserSubscriptionStatusColor from 'utils/_getUserSubscriptionStatusColor';
import { Box } from '@mui/system';
import PaymentSolutionChip from 'components/PaymentSolutionChip/PaymentSolutionChip';
import { COUNTRY_INFO } from 'types/CountryCode';

type UserListProps = {
  users: User[];
  onEdit: (user: User) => void;
};

const UserList: FC<UserListProps> = ({ users, onEdit }) => {
  const { t } = useTranslation();

  return (
    <div>
      {users === null || users === undefined ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress sx={{ margin: '10px 0' }} />
        </div>
      ) : (
        users?.length > 0 && (
          <TableContainer sx={{ overflowX: 'unset' }}>
            <Table sx={{ width: '100%' }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '50px' }}>
                    <Typography>{t('common.id')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('common.name')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('common.phone')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('common.email')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('common.postCode')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('common.subscription')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('userSubscription.status')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('payment.solution')}</Typography>
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => {
                  const { userSubscriptions } = user;
                  const nonCancelledUserSubscriptions =
                    userSubscriptions.length > 0
                      ? userSubscriptions.filter((u) => u.status !== 'CANCELED')
                      : [];
                  const userSubscription =
                    nonCancelledUserSubscriptions.length > 0
                      ? nonCancelledUserSubscriptions[0]
                      : null;

                  return (
                    <TableRow key={user.id}>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Typography>{user.id}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {user?.archived ? <i>{t('user.archive.archived')}</i> : user?.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {user.phoneCountryCode !== 'NO' &&
                            '(' + COUNTRY_INFO[user.phoneCountryCode].phonePrefix + ')'}
                          {user.phone}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{user.email || '--'}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {user?.userAddress?.postCode ? user?.userAddress.postCode + ' ' : '-'}
                          {user?.userAddress?.postOffice
                            ? user?.userAddress.postOffice.toUpperCase()
                            : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{userSubscription?.subscription?.name || '--'}</Typography>
                      </TableCell>
                      <TableCell>
                        {(userSubscription && userSubscription.status !== 'CANCELED' && (
                          <Chip
                            sx={{ margin: '-8px 0' }}
                            color={getUserSubscriptionStatusColor(userSubscription.status)}
                            label={t(
                              `userSubscription.statuses.${userSubscription.status}`
                            ).toLocaleUpperCase()}
                          />
                        )) ||
                          '--'}
                      </TableCell>
                      <TableCell>
                        {(userSubscription?.paymentSolution &&
                          userSubscription?.status !== 'CANCELED' && (
                            <PaymentSolutionChip
                              paymentSolution={userSubscription?.paymentSolution}
                            />
                          )) ||
                          '--'}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '30px',
                          textAlign: 'center',
                          position: 'sticky',
                          right: 0,
                          background: 'inherit'
                        }}
                      >
                        <IconButton sx={{ margin: '-8px 0' }} onClick={() => onEdit(user)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )
      )}
      {users?.length === 0 && (
        <Box textAlign={'center'} padding={'16px'}>
          <Typography fontSize={'16px'} variant={'caption'}>
            {t('common.noResultsFound')}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default UserList;
