import { Navigate, Routes, Route, useLocation } from 'react-router';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import { Alerts } from 'components';
import { ErrorPage } from 'pages';
import routes from 'consts/routes';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import COLORS from 'consts/colors';
import { createRoute } from 'utils';
import useSettings from 'hooks/useSettings';
import useAlert from 'hooks/useAlert';
import Alert from 'types/Alert';
import { useNavigate } from 'react-router-dom';

const App: FC = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const { t } = useTranslation();
  const { getAdmin } = useAuth();
  const { addAlert } = useAlert();
  const { syncSettings } = useSettings();
  const admin = useSelector((state: RootState) => state.admin);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { alert: Alert };

  // Fires a alert on navigation if sendt from another page
  useEffect(() => {
    if (locationState && locationState['alert']) {
      const alert = locationState['alert'] as Alert;
      addAlert(alert?.type || 'success', alert.text || '', (alert.timeout = 5000));

      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { alert: ignoredAlert, ...restState } = location.state as { alert: Alert };
      navigate(location.pathname, { state: restState, replace: true });
    }

    // Scroll to top on navigation
    const app = document.querySelector('.App');
    if (app) {
      app.scrollTo(0, 0);
    }
  }, [location, navigate]);

  useEffect(() => {
    const authToken = localStorage.getItem('auth-token');

    const handleAdminAuthentication = async () => {
      if (authToken) {
        axios.defaults.headers.common['Authorization'] = authToken;

        await getAdmin();
        await syncSettings();
      }

      setInitialLoading(false);
    };

    handleAdminAuthentication();
  }, []);

  if (initialLoading) {
    return <div>{t('loading.loading')}</div>;
  } else {
    return (
      <Box
        className="App"
        sx={{
          background: COLORS.SECONDARY_DARK,
          height: '100%',
          position: 'relative',
          minHeight: '100vh'
        }}
      >
        <ScrollToTop />
        <Alerts />
        <Routes>
          {Object.values(routes).map((routeItem) => {
            return createRoute(routeItem, admin);
          })}
          <Route path="/" element={<Navigate to={routes.DEFAULT.path} />}></Route>
          <Route path="*" element={<ErrorPage code={'404'} />}></Route>
        </Routes>
      </Box>
    );
  }
};

export default App;
