import { ReactHookDatePicker } from 'components';
import FormDialog from 'components/FormDialog/FormDialog';
import { cloneDeep } from 'lodash';
import logger from 'logger/logger';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userSubscriptionService from 'services/user-subscrption.service';
import UserSubscription from 'types/UserSubscription';
import { validate } from 'utils';
import './UserSubscriptionRenewalDateModal.scss';

type UserSubscriptionRenewalDateModalProps = {
  userSubscription: UserSubscription;
  onCancel: () => void;
  onConfirm: (userSubscription: UserSubscription) => void;
};

type ExpiryDateFields = {
  expiryDate: Date;
};

const UserSubscriptionRenewalDateModal: FC<UserSubscriptionRenewalDateModalProps> = ({
  userSubscription,
  onCancel,
  onConfirm
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ExpiryDateFields>({
    defaultValues: {
      expiryDate: new Date(userSubscription.renewDate)
    }
  });

  const currentDate = new Date();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const onSubmit = async (fields: ExpiryDateFields) => {
    const { expiryDate } = fields;

    try {
      await userSubscriptionService.update({
        ...userSubscription,
        renewDate: expiryDate
      });

      const UserSubscription = {
        ...cloneDeep(userSubscription),
        renewDate: expiryDate
      };

      onConfirm(UserSubscription);
    } catch (error) {
      logger.error('Update renewalDate failed: ', error);
    }
  };

  const minDate = new Date(currentDate);

  return (
    <FormDialog
      title={
        userSubscription.status === 'ACTIVE'
          ? t('common.changeRenewalDate')
          : t('common.changeExpiryDate')
      }
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ReactHookDatePicker
        required={true}
        name={'expiryDate'}
        label={
          userSubscription.status === 'ACTIVE' ? t('common.renewalDate') : t('common.expiryDate')
        }
        control={control}
        error={errors.expiryDate ? true : false}
        minDate={minDate}
        validate={(val: Date) => {
          return validate.date(val) && currentDate <= val;
        }}
      />
    </FormDialog>
  );
};

export default UserSubscriptionRenewalDateModal;
