/* eslint-disable indent */
import { AxiosError } from 'axios';
import { PrimaryCard, PrimaryLoading } from 'components';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { cloneDeep } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import userSubscriptionService from 'services/user-subscrption.service';
import UserSubscription, { CancelationType } from 'types/UserSubscription';
import UserSubscriptionRenewalDateModal from '../UserSubscriptionRenewalDateModal/UserSubscriptionRenewalDateModal';
import CancelUserSubscriptionModal from '../CancelUserSubscriptionModal/CancelUserSubscriptionModal';
import logger from 'logger/logger';
import EditUserSubscriptionHeader from './components/EditUserSubscriptionHeader';
import EditUserSubscriptionsListItems from './components/EditUserSubscriptionsListItems';
import { ConsentModal } from 'modals';
import { useUserContext } from 'contexts/UserContext';
import CanceledUserSubscriptions from './components/CanceledUserSubscriptions';
import { Box } from '@mui/material';

type EditUserSubscriptionProps = {
  onUserSubscriptionRemoval: () => void;
};

const EditUserSubscription: FC<EditUserSubscriptionProps> = ({ onUserSubscriptionRemoval }) => {
  const { t } = useTranslation();
  const [userSubscription, setUserSubscription] = useState<UserSubscription>();

  const axiosAlert = useAxiosAlert();
  const [loading, setLoading] = useState<boolean>();
  const [modal, setModal] = useState<number>(null);
  const { user } = useUserContext();

  const canceledUserSubscriptions = user.userSubscriptions.filter(
    (userSubscription) => userSubscription.status === 'CANCELED'
  );

  useEffect(() => {
    const getUserSubscription = async () => {
      setLoading(true);

      try {
        const { data: activeSubscriptionRes } = await userSubscriptionService.get(user.id);

        setUserSubscription(activeSubscriptionRes);
      } catch (error) {
        axiosAlert(error as AxiosError, []);
      } finally {
        setLoading(false);
      }
    };

    if (userSubscription === undefined) {
      getUserSubscription();
    }
  }, [user.id]);

  const cancelRenewal = async (cancelationType: CancelationType, cancelationReason: string) => {
    setModal(null);
    setLoading(true);
    try {
      const updatedUserSubscription = cloneDeep(userSubscription);
      updatedUserSubscription.cancelationType = cancelationType;
      updatedUserSubscription.cancelationReason = cancelationReason;
      updatedUserSubscription.status = 'TO_BE_CANCELED';
      await userSubscriptionService.update(updatedUserSubscription);
      setUserSubscription(updatedUserSubscription);
    } catch (error) {
      logger.error('SearchUsers failed: ', error);
    } finally {
      setLoading(false);
    }
  };

  const activateRenewal = async () => {
    setLoading(true);
    try {
      const updatedUserSubscription = cloneDeep(userSubscription);
      updatedUserSubscription.cancelationType = null;
      updatedUserSubscription.cancelationReason = null;
      updatedUserSubscription.status = 'ACTIVE';
      await userSubscriptionService.update(updatedUserSubscription);
      setUserSubscription(updatedUserSubscription);
    } catch (error) {
      logger.error('ActivateRenewal failed: ', error);
    } finally {
      setLoading(false);
    }
  };

  const retryRenewal = async () => {
    setModal(null);
    setLoading(true);
    try {
      const { data: updatedUserSubscription } = await userSubscriptionService.retryRenewal(
        userSubscription.id
      );

      setUserSubscription(updatedUserSubscription);
    } catch (error) {
      logger.error('Could not retry renewal: ', error);
    } finally {
      setLoading(false);
    }
  };

  const removeUserSubscription = async (cancelationType: CancelationType, cancelationReason: string) => {
    setModal(null);
    setLoading(true);
    try {
      userSubscription.status = 'CANCELED';
      userSubscription.cancelationType = cancelationType;
      userSubscription.cancelationReason = cancelationReason;

      await userSubscriptionService.cancel(userSubscription);
      setUserSubscription(null);
      onUserSubscriptionRemoval();
    } catch (error) {
      logger.error('cancelUserSubscription failed: ', error);
    } finally {
      setLoading(false);
    }
  };

  const syncRenewalPayment = async () => {
    setLoading(true);

    try {
      const { data: syncedUserSubscription } = await userSubscriptionService.syncRenewalPayment(
        userSubscription.id
      );

      setUserSubscription(syncedUserSubscription);
    } catch (error) {
      logger.error('Could not sync recurring payment: ', error);
    } finally {
      setLoading(false);
    }
  };

  const isActive = userSubscription
    ? ['ACTIVE', 'AWAITING_PAYMENT'].includes(userSubscription.status)
    : false;
  return (
    <div className='edit-user' style={{ maxWidth: '1000px' }}>
      {!loading && userSubscription && !user.archived && (
        // Active subscribtion
        <div
          style={{
            display: 'flex',
            gap: '20px'
          }}
        >
          <PrimaryCard sx={{ p: 0, width: '100%' }}>
            <EditUserSubscriptionHeader />
            <EditUserSubscriptionsListItems
              activateRenewal={activateRenewal}
              setModal={setModal}
              isActive={isActive}
              userSubscription={userSubscription}
              onRenewalPaymentSync={syncRenewalPayment}
            />
          </PrimaryCard>
        </div>
      )}
      {!loading && !user.archived && canceledUserSubscriptions?.length > 0 && (
        <Box sx={{ mt: '20px' }}>
          <CanceledUserSubscriptions userSubscriptions={canceledUserSubscriptions} />
        </Box>
      )}
      {modal == 0 && (
        <UserSubscriptionRenewalDateModal
          userSubscription={userSubscription}
          onConfirm={(userSubscription: UserSubscription) => {
            setUserSubscription(userSubscription);
            setModal(null);
          }}
          onCancel={() => setModal(null)}
        />
      )}
      {modal == 1 && (
        <CancelUserSubscriptionModal
          onConfirm={removeUserSubscription}
          onCancel={() => setModal(null)}
        />
      )}
      {modal == 2 && (
        <CancelUserSubscriptionModal onConfirm={cancelRenewal} onCancel={() => setModal(null)} />
      )}
      {modal == 3 && (
        <ConsentModal
          title={t('userSubscription.retryRenewalTitle')}
          content={t('userSubscription.retryRenewalContent')}
          onConfirm={retryRenewal}
          onCancel={() => setModal(null)}
        />
      )}
      {loading && <PrimaryLoading title={t('common.loading')} />}
    </div>
  );
};

export default EditUserSubscription;
