import { Box, Button, Checkbox, Stack, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PaymentSolutionChip from 'components/PaymentSolutionChip/PaymentSolutionChip';
import nb from 'date-fns/locale/nb';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Campaign from 'types/Campaign';
import PaymentSolution from 'types/PaymentSolution';
import Subscription from 'types/Subscription';

type SummaryAndConfirmProps = {
  onCancel: () => void;
  onConfirm(
    paymentSolution: PaymentSolution,
    subscription: Subscription,
    campaign?: Campaign,
    renewDate?: Date
  ): void;
  paymentSolution: PaymentSolution;
  subscription: Subscription;
  campaign: Campaign;
};

const SummaryAndConfirm: FC<SummaryAndConfirmProps> = ({
  onCancel,
  onConfirm,
  paymentSolution,
  subscription,
  campaign
}) => {
  const { t } = useTranslation();

  const [renewDate, setRenewDate] = useState<Date>(null);

  return (
    <Stack gap={2}>
      <Stack sx={{ padding: '20px 0', borderBottom: '1px solid #aaa' }}>
        <Typography variant={'h5'} sx={{ marginBottom: '10px' }}>
          {t('common.subscription')}
        </Typography>
        <Typography sx={{ fontWeight: '700' }}>{subscription.name}</Typography>
        {campaign ? (
          <>
            <Typography>
              {t('common.campaign')}: {campaign.name}
            </Typography>
            <Typography>
              {t('common.duration')}{': '}
              {campaign.outTheYear ? (
                t('common.outTheYear')
              ) : (
                <span>
                  {campaign.durationDays}{' '}
                  {campaign.durationDays === 1
                    ? t('common.day').toLowerCase()
                    : t('common.days').toLowerCase()}
                </span>
              )}
              <span style={{ fontStyle: 'italic' }}>
                {' — '} {t('common.renewedWith').toLowerCase()} {subscription.duration}{' '}
                {subscription.duration === 1
                  ? t('common.month').toLowerCase()
                  : t('common.months').toLowerCase()}
              </span>
            </Typography>
            <Typography>
              {t('common.price')}: {campaign.price} kr
              <span style={{ fontStyle: 'italic' }}>
                {' — '} {t('common.renewedWith').toLowerCase()} {subscription.price} kr
              </span>
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              {t('common.duration')}: {subscription.duration}{' '}
              {subscription.duration === 1
                ? t('common.month').toLowerCase()
                : t('common.months').toLowerCase()}
            </Typography>
            <Typography>
              {t('common.price')}: {subscription.price} kr
            </Typography>
          </>
        )}
      </Stack>

      <Box sx={{ paddingBottom: '30px', borderBottom: '1px solid #aaa' }}>
        <Typography variant={'h5'} sx={{ marginBottom: '30px' }}>
          {t('common.paymentSolution')}
        </Typography>
        <Box sx={{ marginTop: '-10px', paddingBottom: '20px' }}>
          <PaymentSolutionChip paymentSolution={paymentSolution} />
        </Box>
        {paymentSolution === 'invoice' && campaign && (
          <Typography
            sx={{ fontStyle: 'italic', color: '#bbb', marginTop: '5px', fontSize: '14px' }}
          >
            {t('invoice.sendInvoiceNowCampaign')}
          </Typography>
        )}
        {paymentSolution === 'invoice' && !campaign && (
          <Stack direction='row' gap={'20px'}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
              <DatePicker
                label={t('common.nextRenewal')}
                onChange={(value) => {
                  setRenewDate(value);
                }}
                value={renewDate}
                format='dd.MM.yyyy'
                minDate={renewDate ? new Date() : null}
                disabled={renewDate === null}
              />
            </LocalizationProvider>
            <div style={{ position: 'relative', top: '5px' }}>
              <Checkbox
                checked={renewDate === null}
                onChange={() => {
                  if (renewDate === null) {
                    setRenewDate(new Date());
                  } else {
                    setRenewDate(null);
                  }
                }}
              />
              <Typography sx={{ display: 'inline' }}>{t('invoice.sendInvoiceNow')}</Typography>
            </div>
          </Stack>
        )}
      </Box>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <Button
          variant='outlined'
          onClick={() => onConfirm(paymentSolution, subscription, campaign, renewDate)}
        >
          {t('common.confirm')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default SummaryAndConfirm;
