import { InvoiceDisplayOptions } from 'types/InvoiceDisplayOptions';

export const defaultInvoiceDisplayOptions : InvoiceDisplayOptions = {
  id: true,
  status: true,
  method: true,
  userId: true,
  userName: true,
  billingName: true,
  issueDate: true,
  dueDate: true,
  kid: true,
  price: true,
  sent: true,
  paymentReminderSent: true,
  customerNo: false,
  message: false,
  periodFrom: false,
  periodTo: false,
  creditNoteInvoiceNo: false,
  product: false,
  invoiceFee: false,
  billingAddress: false,
  billingPostCode: false,
  billingPostOffice: false,
  billingEmail: false,
  billingPhone: false,
  sumVat: false,
  vatPercent: false,
};
