import { Box, SxProps } from '@mui/system';
import { FC } from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    sx?: SxProps;
}

const TabPanel: FC<TabPanelProps> = ({ children, index, value, sx }) => {
  return (
    <div role='tabpanel' hidden={value !== index}>
      {value === index && <Box sx={{ p: 3, ...sx }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
