import {  CircularProgress, IconButton, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { PaperCard, PaperContent, PaperFooter, PaperHeader } from 'components';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dataCheckService from 'services/data-check.service';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import COLORS from 'consts/colors';
import InspectInvalidOrdersModal from './InspectInvalidOrdersModal';

type InvalidOrdersModal = 'InspectOrders';

type InvalidOrdersProps = {
  updated: Date;
};

export const InvalidOrders:FC<InvalidOrdersProps> = ({updated}) => {
  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();
  const [modal, setModal] = useState<InvalidOrdersModal>(null);
  const [orders, setOrders] = useState(null);

  const getInvalidOrders = async () => {
    try {
      const ordersQuery = await dataCheckService.getInvalidOrders();
      setOrders(ordersQuery.data);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    }
  };

  useEffect(() => {
    if(orders !== null) {
      setOrders(null);
    }
    getInvalidOrders();
  }, [updated]);
  return (
    <PaperCard sx={{minWidth: '250px'}}>
      <PaperHeader>
        <Typography variant={'h6'}>{t('dataCheck.invalidOrders')}</Typography>
      </PaperHeader>
      <PaperContent>
        <div style={{ textAlign: 'center' }}>
          {orders ? (
            orders.length > 0 ? (
              <>
                <Typography sx={{ fontSize: '5rem' }} color={'error'}>
                  {orders.length}
                </Typography>
                <IconButton onClick={() => setModal('InspectOrders')} color='error'>
                  <TroubleshootIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Typography sx={{ fontSize: '5rem', color: COLORS.SUCCESS }}>
                  {orders.length}
                </Typography>
                <TaskAltIcon sx={{ margin: '6px' }} color={'success'} />
              </>
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </PaperContent>
      <PaperFooter>
        {orders ? (
          orders.length > 0 ? (
            <>
              <Typography color='error' fontStyle={'italic'}>
                {t('dataCheck.invalidDataFound')}
              </Typography>
            </>
          ) : (
            <Typography sx={{ color: COLORS.SUCCESS }}>{t('dataCheck.allGood')}</Typography>
          )
        ) : (
          <CircularProgress />
        )}
      </PaperFooter>
      {modal === 'InspectOrders' && (
        <InspectInvalidOrdersModal
          orders={orders}
          exit={() => setModal(null)}
        />
      )}
    </PaperCard>
  );
};
