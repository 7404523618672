import './UserPhoneModal.scss';
import FormDialog from 'components/FormDialog/FormDialog';
import { FC, useState } from 'react';
import { SubmitHandler, useForm, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userService from 'services/user-service';
import { AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';
import User from 'types/User';
import PhoneNumberInputCountry from 'components/PhoneNumber/PhoneNumberCountry';
import CountryCode from 'types/CountryCode';

type UserPhoneModalProps = {
  user: User;
  onConfirm: (phone: string, phoneCountryCode: CountryCode) => void;
  onCancel: () => void;
};

type ChangePhoneNumberFields = {
  phone: string;
  phoneCountryCode: CountryCode;
};

const UserPhoneModal: FC<UserPhoneModalProps> = ({ user, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();
  const { register, handleSubmit, watch, setValue, getValues, formState } =
    useForm<ChangePhoneNumberFields>({
      defaultValues: {
        phone: user?.phone?.toString() || '',
        phoneCountryCode: (user?.phoneCountryCode as CountryCode) || 'NO'
      }
    });
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSubmit: SubmitHandler<ChangePhoneNumberFields> = async (fields) => {
    const { phone } = fields;

    const phoneNumber = phone.split(' ').join('');

    setLoading(true);
    try {
      await userService.updateUser({
        ...user,
        phone: phoneNumber,
        phoneCountryCode: fields.phoneCountryCode
      });
      setFormSubmitted(true);
      onConfirm(phoneNumber, fields.phoneCountryCode);
    } catch (error) {
      axiosAlert(error as AxiosError, [
        {
          status: 403,
          message: t('alerts.phoneNumberTaken')
        }
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t('common.changePhoneNumber')}
      loading={loading}
      submitText={t('common.change')}
    >
      <div className='dialog-content'>
        <PhoneNumberInputCountry
          fieldName='phone'
          register={register}
          focusInput={false}
          watch={watch}
          formState={formState}
          setValue={setValue}
          formSubmitted={formSubmitted}
          getValues={getValues as UseFormGetValues<ChangePhoneNumberFields>}
          user={user}
        />
      </div>
    </FormDialog>
  );
};

export default UserPhoneModal;
