/* eslint-disable indent */
import { Divider, Typography, SxProps, Box, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import './PrimaryListItem.scss';

type PrimaryListItemProps = {
  actions?: ReactNode;
  label?: string | number;
  value?: string | number | ReactNode;
  placeholder?: string;
  vertical?: boolean;
  last?: boolean;
  sx?: SxProps;
  description?: string;
};

const PrimaryListItem: FC<PrimaryListItemProps> = ({
  label,
  value,
  actions,
  placeholder,
  vertical = false,
  sx,
  last,
  description
}) => {
  const verticalStyle: SxProps = vertical
    ? {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    : null;

  const descriptionclass = description ? 'description' : '';

  return (
    <Box>
      <Box className={`primary-list-item ${descriptionclass}`} sx={verticalStyle}>
        <Box className='label'>
          <Stack>
            <Typography>{label}</Typography>
            {description && (
              <Box className='primary-list-item-description'>
                <Typography sx={{ fontStyle: 'italic', fontSize: '0.7rem' }}>
                  {description}
                </Typography>
              </Box>
            )}
          </Stack>
        </Box>
        <Box className='value'>
          {((typeof value === 'string' || typeof value === 'number') && (
            <Typography sx={sx}>{value ?? placeholder}</Typography>
          )) ||
            value}
          {actions && <Box className='actions'>{actions}</Box>}
        </Box>
      </Box>
      {!last && <Divider />}
    </Box>
  );
};

export default PrimaryListItem;
