export type UserSubscriptionStatus =
  | 'ACTIVE'
  | 'AWAITING_PAYMENT'
  | 'PAYMENT_FAILED'
  | 'TO_BE_CANCELED'
  | 'CANCELED';

const userSubscriptionStatus: { [k: string]: UserSubscriptionStatus } = {
  ACTIVE: 'ACTIVE',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  TO_BE_CANCELED: 'TO_BE_CANCELED',
  CANCELED: 'CANCELED'
};

export const userSubscriptionActiveStatuses = ['ACTIVE', 'AWAITING_PAYMENT', 'TO_BE_CANCELED'];

export default userSubscriptionStatus;
