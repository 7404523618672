/* eslint-disable max-lines */
import { Close, Edit } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableRow,
  Alert,
  Box
} from '@mui/material';
import { PaperCard, PaperContent, PaperHeader } from 'components';
import EditCheckboxModal from 'components/EditCheckboxModal/EditChecboxModal';
import { ROUTE_PATHS } from 'consts/routes';
import useAlert from 'hooks/useAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import distributionRouteService from 'services/distribution-route-service';
import userDistributionService from 'services/user-distribution-service';
import DistributionRoute from 'types/DistributionRoute';
import User from 'types/User';

const CurrierRouteUsersPage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const [users, setUsers] = useState<User[]>();
  const [distributionRoute, setDistributionRoute] = useState<DistributionRoute>();
  const [modal, setModal] = useState<number>();
  const [selectedUser, setSelectedUser] = useState<User>();
  const alert = useAlert();

  useEffect(() => {
    init();
  }, []);

  const getUsers = async () => {
    try {
      const { data } = await distributionRouteService.getUsers(id);
      setUsers(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDistributionRoute = async () => {
    try {
      const { data } = await distributionRouteService.get(id);
      setDistributionRoute(data);
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    // promise all
    setLoading(true);
    try {
      await Promise.all([getUsers(), getDistributionRoute()]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateUserDistributionNamed = async (named: boolean) => {
    setLoading(true);
    try {
      await userDistributionService.update(selectedUser?.userDistribution.id, {
        ...selectedUser?.userDistribution,
        distributionNamed: named
      });
      alert.addAlert('success', t('common.updated'));
    } catch (error) {
      console.error(error);
    } finally {
      init();
    }
  };

  const getNumberOfUnamed = () => {
    let numberOfUnamed = 0;
    users?.forEach((user) => {
      if (!user.userDistribution.distributionNamed) {
        numberOfUnamed++;
      }
    });
    return numberOfUnamed;
  };

  const getNumberOfNamed = () => {
    let numberOfNamed = 0;
    users?.forEach((user) => {
      if (user.userDistribution.distributionNamed) {
        numberOfNamed++;
      }
    });
    return numberOfNamed;
  };

  const numberOfUnamed = getNumberOfUnamed();
  const numberOfNamed = getNumberOfNamed();

  return (
    <>
      <PaperCard>
        <PaperHeader
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
            <Typography variant={'h6'}>
              {id} {t('common.subscribers')}
            </Typography>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <Close />
            </IconButton>
          </Stack>
          {distributionRoute && distributionRoute.named && (
            <Box
              sx={{
                display: 'inline-flex'
              }}
            >
              <Alert severity='info'>
                {t('alerts.routeIsNamedUsersCantChangeNamedDistribution')}
              </Alert>
            </Box>
          )}
        </PaperHeader>
        <PaperContent sx={{ p: 0 }}>
          {loading && (
            <Stack p={2} justifyContent={'center'}>
              <CircularProgress />
            </Stack>
          )}
          {!loading && users?.length > 0 && (
            <>
              <Box sx={{ p: 2 }}>
                <PaperCard variant='outlined'>
                  <Stack
                    direction={'column'}
                    gap={2}
                    sx={{
                      p: 2,
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                    }}
                  >
                    <Typography>
                      {t('common.total')}: {users.length}
                    </Typography>
                    <Typography>
                      {t('common.numberOfUnamed')}: {numberOfUnamed}
                    </Typography>
                    <Typography>
                      {t('common.numberOfNamed')}: {numberOfNamed}
                    </Typography>
                  </Stack>
                </PaperCard>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography>{t('common.name')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('userDistribution.numberOfPapers')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.named')}</Typography>
                    </TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>
                        <Link
                          style={{ color: '#fff ' }}
                          to={ROUTE_PATHS.USER.replace(':id', user.id.toString())}
                        >
                          <Typography>{user.name}</Typography>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Typography>{user?.userDistribution.numberOfPapers}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {user?.userDistribution.distributionNamed
                            ? t('common.named')
                            : t('common.unamed')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          disabled={distributionRoute?.named}
                          onClick={() => {
                            setSelectedUser(user);
                            setModal(1);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          {!loading && users?.length === 0 && (
            <Stack p={4} justifyContent={'center'} textAlign={'center'}>
              <Typography>{t('common.noResultsFound')}</Typography>
            </Stack>
          )}
        </PaperContent>
        {modal === 1 && selectedUser && (
          <EditCheckboxModal
            onCancel={() => {
              setModal(undefined);
              setSelectedUser(null);
            }}
            onConfirm={(named: boolean) => {
              updateUserDistributionNamed(named);
              setModal(undefined);
              setSelectedUser(null);
            }}
            label={t('userDistribution.named')}
            title={t('common.changeBlank', {
              blank: t('userDistribution.named')
            })}
            value={selectedUser.userDistribution.distributionNamed}
          />
        )}
      </PaperCard>
    </>
  );
};

export default CurrierRouteUsersPage;
