import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import { Route } from 'react-router';
import Admin from 'types/Admin';
import RouteItem from 'types/RouteItem';

function createRoute(routeItem: RouteItem, admin: Admin) {
  let childRouteElements;

  if (routeItem?.childRoutes) {
    childRouteElements = Object.values(routeItem.childRoutes).map((routeItem) => {
      return createRoute(routeItem, admin);
    });
  }

  if (routeItem.private) {
    if (routeItem.childRoutes) {
      return (
        <Route
          key={routeItem?.path}
          path={routeItem.path}
          element={
            <ProtectedRoute admin={admin} roles={routeItem.roles}>
              {routeItem.component}
            </ProtectedRoute>
          }
        >
          {childRouteElements}
        </Route>
      );
    } else {
      return (
        <Route
          key={routeItem?.path}
          path={routeItem.path}
          element={
            <ProtectedRoute admin={admin} roles={routeItem.roles}>
              {routeItem.component}
            </ProtectedRoute>
          }
        />
      );
    }
  } else {
    return <Route key={routeItem?.path} path={routeItem.path} element={routeItem.component} />;
  }
}

export default createRoute;
