import { SxProps, Tab, Tabs, Typography } from '@mui/material';
import PageContent from 'components/PageContent/PageContent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWrapper from 'components/PageWrapper/PageWrapper';
import { ROUTE_PATHS } from 'consts/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router';

const PolarisPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tab, setTab] = useState<number>();
  const [sxStyles, setSxStyles] = useState<SxProps>({});

  useEffect(() => {
    // Get current path and set tab value
    if (location.pathname.includes(ROUTE_PATHS.POLARIS_SETTINGS)) {
      setTab(0);
    } else if (location.pathname.includes(ROUTE_PATHS.POLARIS_ADDRESS_OVERVIEW)) {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [location]);

  useEffect(() => {
    if (tab === 0) {
      navigate(`${ROUTE_PATHS.POLARIS_SETTINGS}`);
    } else if (tab === 1) {
      navigate(`${ROUTE_PATHS.POLARIS_ADDRESS_OVERVIEW}`);
    }

    setSxStyles(tab === 1 ? { height: '100%' } : { height: 'auto' });
  }, [tab]);

  return (
    <PageWrapper sx={sxStyles}>
      <PageHeader backToUrl={ROUTE_PATHS.PAPER}>
        <Typography variant={'h6'}>{t('common.polaris')}</Typography>
        {tab !== undefined && (
          <Tabs value={tab} onChange={(e, tabValue) => setTab(tabValue)}>
            <Tab label={t('common.settings')} value={0} />
            <Tab label={t('common.addressOverview')} value={1} />
          </Tabs>
        )}
      </PageHeader>
      <PageContent sx={{ padding: '12px', overflow: 'hidden' }}>
        <Outlet />
      </PageContent>
    </PageWrapper>
  );
};

export default PolarisPage;
