import { Typography, CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { PaperCard, PaperHeader, PaperContent, PaperFooter } from 'components';
import COLORS from 'consts/colors';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import reportService from 'services/report-service';

const ActiveSubscriptions: FC = () => {
  const { t } = useTranslation();
  const [latestSubscriberCount, setLatestSubscriberCount] = useState(null);
  const axiosAlert = useAxiosAlert();

  useEffect(() => {
    (async () => {
      try {
        const latestSubscriberCountQuery = await reportService.getLatestSubscriberCount();
        setLatestSubscriberCount(latestSubscriberCountQuery.data);
      } catch (error) {
        axiosAlert(error as AxiosError, []);
      }
    })();
  }, []);

  return (
    <PaperCard sx={{ textAlign: 'center', width: '100%', maxWidth: '300px' }}>
      <PaperHeader>
        <Typography variant={'h6'}>{t('dashboard.activeSubscribers')}</Typography>
      </PaperHeader>
      <PaperContent>
        {latestSubscriberCount ? (
          <Typography sx={{ fontSize: '5rem' }}>
            {latestSubscriberCount.count}
            <span
              style={{
                color: latestSubscriberCount.changeCount < 0 ? COLORS.ERROR : COLORS.PRIMARY,
                fontSize: '2rem',
                position: 'relative',
                bottom: '1rem'
              }}
            >
              {latestSubscriberCount.changeCount < 0
                ? latestSubscriberCount.changeCount
                : '+' + latestSubscriberCount.changeCount}
            </span>
          </Typography>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress sx={{ margin: '10px 0' }} />
          </div>
        )}
      </PaperContent>

      <PaperFooter>
        {latestSubscriberCount && (
          <Typography
            sx={{
              color: latestSubscriberCount.changeCount < 0 ? COLORS.ERROR : COLORS.PRIMARY,
              fontStyle: 'italic'
            }}
          >
            {t('dashboard.changesLast7Days')}
          </Typography>
        )}
      </PaperFooter>
    </PaperCard>
  );
};

export default ActiveSubscriptions;
