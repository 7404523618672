import { PrimaryListItem } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import UserSubscription from 'types/UserSubscription';
import { Button, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Edit } from '@mui/icons-material';
import getUserSubscriptionStatusColor from 'utils/_getUserSubscriptionStatusColor';
import PaymentSolutionChip from 'components/PaymentSolutionChip/PaymentSolutionChip';
import CardInfo from 'components/CardInfo/CardInfo';
import { dateString, getNameOfDay } from 'utils';

type EditUserSubscriptionsListItemsProps = {
  userSubscription: UserSubscription;
  setModal: (modalNumber: number) => void;
  isActive: boolean;
  activateRenewal: () => void;
  onRenewalPaymentSync: () => void;
};

const EditUserSubscriptionsListItems: FC<EditUserSubscriptionsListItemsProps> = ({
  userSubscription,
  setModal,
  isActive,
  activateRenewal,
  onRenewalPaymentSync
}) => {
  const { t } = useTranslation();

  const onRenewalChange = () => {
    if (isActive) {
      setModal(2);
    } else {
      activateRenewal();
    }
  };

  const getRenewalAction = () => {
    if (
      userSubscription.paymentSolution === 'none' ||
      userSubscription.paymentSolution === 'gift'
    ) {
      return <Chip label='N/A' />;
    }

    let renewalAction;

    switch (userSubscription.status) {
      case 'ACTIVE':
        renewalAction = (
          <Button variant={'outlined'} onClick={onRenewalChange}>
            {t('userSubscription.stopRenewal')}
          </Button>
        );
        break;
      case 'TO_BE_CANCELED':
        renewalAction = (
          <Button variant={'contained'} onClick={onRenewalChange}>
            {t('userSubscription.continueRenewal')}
          </Button>
        );
        break;
      case 'PAYMENT_FAILED':
        renewalAction = (
          <Button variant={'outlined'} onClick={() => setModal(3)}>
            {t('userSubscription.retryRenewal')}
          </Button>
        );
        break;
      case 'AWAITING_PAYMENT':
        renewalAction =
          userSubscription.paymentSolution === 'invoice' ? (
            <Typography sx={{ opacity: 0.7, fontStyle: 'italic' }}>
              {t('invoice.registerWithOcrOrSetPaymentToCapturedManually')}
            </Typography>
          ) : (
            <Button variant={'outlined'} onClick={onRenewalPaymentSync}>
              {t('userSubscription.syncRenewalPayment')}
            </Button>
          );
        break;
      default:
        break;
    }

    return renewalAction;
  };

  const RenewalActions = getRenewalAction();

  const editRenewDatePaymentSolution = ['none', 'external', 'invoice'].includes(
    userSubscription.paymentSolution
  );
  const editRenewDateStatus =
    ['none', 'external', 'invoice'].includes(userSubscription.paymentSolution) &&
    ['ACTIVE', 'TO_BE_CANCELED'].includes(userSubscription.status);

  const renewDate = new Date(userSubscription.renewDate);

  return (
    <>
      <PrimaryListItem
        label={t('userSubscription.id')}
        value={<Typography>{userSubscription.id}</Typography>}
      />
      <PrimaryListItem
        label={t('common.status')}
        value={
          <Chip
            color={getUserSubscriptionStatusColor(userSubscription.status)}
            label={t(`userSubscription.statuses.${userSubscription.status}`).toLocaleUpperCase()}
          />
        }
      />
      <PrimaryListItem
        label={t('common.subscription')}
        value={<Typography>{userSubscription.subscription.name}</Typography>}
      />
      <PrimaryListItem
        label={isActive ? t('common.renewalDate') : t('common.expiryDate')}
        value={getNameOfDay(renewDate) + ' ' + dateString(renewDate)}
        actions={
          <Tooltip
            title={
              editRenewDatePaymentSolution
                ? editRenewDateStatus
                  ? ''
                  : t('userSubscription.tooltip.renewalDateDisabledStatus')
                : t('userSubscription.tooltip.renewalDateDisabledPaymentSolution')
            }
          >
            <Box
              onClick={() => {
                if (editRenewDatePaymentSolution && editRenewDateStatus) {
                  setModal(0);
                }
              }}
            >
              <IconButton disabled={!editRenewDatePaymentSolution || !editRenewDateStatus}>
                <Edit />
              </IconButton>
            </Box>
          </Tooltip>
        }
      />
      <PrimaryListItem
        label={t('common.paymentSolution')}
        actions={<PaymentSolutionChip paymentSolution={userSubscription.paymentSolution} />}
      />
      {userSubscription.card && (
        <PrimaryListItem
          label={t('common.card')}
          actions={<CardInfo card={userSubscription.card} />}
        />
      )}
      <PrimaryListItem label={t('common.renewal')} actions={RenewalActions} />
      <PrimaryListItem
        label={t('common.subscription')}
        actions={
          <Button variant={'outlined'} color={'error'} onClick={() => setModal(1)}>
            {t('common.cancel2')}
          </Button>
        }
      />
    </>
  );
};

export default EditUserSubscriptionsListItems;
