import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Languages
import no from './no.json';
import en from './en.json';

const resources = {
  no: { translation: no },
  en: { translation: en }
};

export const defaultLanguage = 'no';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false
    }
  });

export const languages = [
  {
    value: 'no',
    name: 'common.norwegian'
  },
  {
    value: 'en',
    name: 'common.english'
  }
];

export default i18n;
