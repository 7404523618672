import { KeyboardArrowLeft, Person } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import FlexBox from 'components/FlexBox/FlexBox';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router';

type UserTabHeaderProps = {
  username: string;
  actions?: ReactNode;
};

const UserTabHeader: FC<UserTabHeaderProps> = ({ username, actions }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ widht: '100%', borderBottom: '1px solid rgba(255,255,255,0.12)' }}>
      <FlexBox direction='column' sx={{ paddingBottom: 0 }} gap={12}>
        <FlexBox
          gap={16}
          sx={{ padding: '16px', paddingBottom: 0 }}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <FlexBox gap={4}>
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <KeyboardArrowLeft />
            </IconButton>
          </FlexBox>
          <FlexBox alignItems={'center'} justifyContent={'center'} gap={8}>
            <Person />
            <Typography variant={'h6'}>{username}</Typography>
          </FlexBox>
        </FlexBox>
        <FlexBox justifyContent={'flex-start'}>{actions}</FlexBox>
      </FlexBox>
    </Box>
  );
};

export default UserTabHeader;
