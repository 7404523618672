import './Alerts.scss';
import { FC } from 'react';
import AlertType from 'types/Alert';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Close } from '@mui/icons-material';
import { Alert, Chip } from '@mui/material';
import useAlert from 'hooks/useAlert';

const Alerts: FC = () => {
  const alerts = useSelector((state: RootState) => state.alerts);
  const { removeAlert } = useAlert();

  function removeDuplicates(_alerts: AlertType[]): AlertType[] {
    const uniqueAlerts: AlertType[] = [];
    const seenTexts = new Set<string>();

    _alerts.forEach((alert) => {
      if (!seenTexts.has(alert.text)) {
        seenTexts.add(alert.text);
        uniqueAlerts.push(alert);
      }
    });

    return uniqueAlerts;
  }

  const uniqueAlerts = removeDuplicates(alerts);

  const alertComponents = uniqueAlerts.map((alert: AlertType) => {
    return (
      <div key={alert.id} className={'alert-container'}>
        <Alert
          className={'alert'}
          elevation={4}
          severity={alert.type}
          action={
            <div onClick={() => removeAlert(alert.id)}>
              <Close />
            </div>
          }
        >
          {alert.text} {alert.count && alert.count > 1 && <Chip size="small" label={alert.count} />}
        </Alert>
      </div>
    );
  });

  return <div className="alerts-container">{alertComponents}</div>;
};

export default Alerts;
