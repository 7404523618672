import { TableHead, TableCell, TableBody, TableRow, Table, Tabs, Tab } from '@mui/material';
import { TabPanel } from 'components';
import COLORS from 'consts/colors';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import campaignService from 'services/campaign-service';
import subscriptionService from 'services/subscription-service';
import Campaign from 'types/Campaign';
import Subscription from 'types/Subscription';

type SelectSubscriptionProps = {
  onNext: (subscribtion: Subscription, campaign?: Campaign) => void;
};

const SelectSubscription: FC<SelectSubscriptionProps> = ({ onNext }) => {
  const { t } = useTranslation();

  const [tabs, setTabs] = useState<number>(0);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>(null);
  const [campaigns, setCampaigns] = useState<Campaign[]>(null);

  const getSubscriptions = async () => {
    const subscriptionsQuery = await subscriptionService.get();
    setSubscriptions(subscriptionsQuery.data);
    await getCampaigns(subscriptionsQuery.data);
  };
  const getCampaigns = async (subscriptionsData: Subscription[]) => {
    const campaignsQuery = await campaignService.getAll();
    const campaignsData = campaignsQuery.data.map((c) => ({
      ...c,
      subscription: subscriptionsData.find((s) => s.id === c.subscriptionId)
    }));
    setCampaigns(campaignsData);
  };

  useEffect(() => {
    getSubscriptions();
  }, []);
  return (
    <div>
      <Tabs
        value={tabs}
        onChange={(event: React.SyntheticEvent, newValue: number) => setTabs(newValue)}
      >
        <Tab label={t('common.subscriptions')} value={0} />
        <Tab label={t('common.campaigns')} value={1} />
      </Tabs>
      <TabPanel value={tabs} index={0} sx={{ p: 0, margin: '0 -24px -20px -24px' }}>
        {!!subscriptions && (
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>{t('common.name')}</TableCell>
                <TableCell>{t('common.duration')}</TableCell>
                <TableCell>{t('common.price')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((subscription) => (
                <TableRow
                  key={subscription.id}
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: COLORS.PRIMARY_DARK
                    }
                  }}
                  onClick={() => onNext(subscription)}
                >
                  <TableCell>
                    <strong>{subscription.name}</strong>
                  </TableCell>
                  <TableCell>
                    {subscription.duration}{' '}
                    {subscription.duration === 1
                      ? t('common.month').toLowerCase()
                      : t('common.months').toLowerCase()}
                  </TableCell>
                  <TableCell>{subscription.price} kr</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TabPanel>
      <TabPanel value={tabs} index={1} sx={{ p: 0, margin: '0 -24px -20px -24px' }}>
        {!!campaigns && (
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>{t('common.name')}</TableCell>
                <TableCell>{t('common.duration')}</TableCell>
                <TableCell>{t('common.price')}</TableCell>
                <TableCell>{t('common.renewedWith')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns.map((c) => (
                <TableRow
                  key={c.id}
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: COLORS.PRIMARY_DARK
                    }
                  }}
                  onClick={() => onNext(c.subscription, c)}
                >
                  <TableCell>
                    <strong>{c.name}</strong>
                  </TableCell>
                  <TableCell>
                    {c.outTheYear
                      ? t('common.outTheYear')
                      : c.durationDays +
                        ' ' +
                        (c.durationDays === 1
                          ? t('common.day').toLowerCase()
                          : t('common.days').toLowerCase())}
                  </TableCell>
                  <TableCell>{c.price} kr</TableCell>
                  <TableCell>{c.subscription.name} kr</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TabPanel>
    </div>
  );
};

export default SelectSubscription;
