import { ChangeEvent, FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, validate } from 'utils';
import useAlert from 'hooks/useAlert';

type ChangeEmailModalProps = {
  onCancel: () => void;
  onConfirm: (email: string, password: string) => void;
};

const ChangeEmailModal: FC<ChangeEmailModalProps> = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();
  const { addAlert } = useAlert();

  const [fields, setFields] = useState({
    password: '',
    email: ''
  });

  const confirmChangesHandler = () => {
    if (!validate.email(fields.email)) {
      addAlert('error', t('alert.invalidEmail'));
      return;
    }

    onConfirm(fields.email, fields.password);
  };

  const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  return (
    <Dialog open={true}>
      <div>
        <DialogTitle>{capitalizeFirstLetter(t('common.changeEmail'))}</DialogTitle>
        <DialogContent>
          <Stack rowGap="20px" sx={{ paddingTop: '20px' }}>
            <TextField
              label={capitalizeFirstLetter(t('common.newEmail'))}
              name={'email'}
              type={'email'}
              value={fields.email}
              onChange={inputChangeHandler}
              fullWidth
            />
            <TextField
              label={capitalizeFirstLetter(t('common.password'))}
              name={'password'}
              type={'password'}
              value={fields.password}
              onChange={inputChangeHandler}
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{t('common.cancel')}</Button>
          <Button onClick={confirmChangesHandler}>{t('common.confirmChanges')}</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ChangeEmailModal;
