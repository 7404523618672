import routes from 'consts/routes';
import { FC, ReactElement } from 'react';
import { Navigate } from 'react-router';
import Admin from 'types/Admin';

type ProtectedRouteProps = {
  admin: Admin;
  children: ReactElement;
  roles?: number[];
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ admin, children, roles }) => {
  if (!admin) {
    return <Navigate to={routes.LOGIN.path} />;
  }

  if (roles && !roles.includes(admin?.role)) {
    return <Navigate to={routes.ERROR.path} state={{ code: 401 }} />;
  }

  return children;
};

export default ProtectedRoute;
