import { CircularProgress, IconButton, Typography } from '@mui/material';
import { PaperCard, PaperHeader, PaperContent, PaperFooter } from 'components';
import COLORS from 'consts/colors';
import StandardReportTable from 'pages/Reports/components/StandardReportTable';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import analyticsService from 'services/analytics-service';
import RefreshIcon from '@mui/icons-material/Refresh';
import logger from 'logger/logger';

const PurchasesAnalytics = () => {
  const { t } = useTranslation();
  const [report, setReport] = useState(null);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const fetchReport = async () => {
    try {
      if (errorMessage) {
        setErrorMessage(null);
      }
      setReport(null);
      const reportQuery = await analyticsService.getPurchases();
      for (const row of reportQuery.data.rows) {
        row[0] = shortenString(row[0], 80);
      }
      setReport(reportQuery.data);
    } catch (error) {
      logger.error(error);
      setErrorMessage(t('alerts.somethingWentWrong'));
    }
  };

  const shortenString = (str: string, maxLen: number) => {
    if (str.length <= maxLen) {
      return str;
    }
    return str.substring(0, maxLen) + '...';
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <PaperCard sx={{ textAlign: 'center', width: '100%', maxWidth: '923px' }}>
      <PaperHeader>
        <Typography variant={'h6'}>
          Newsroom Analytics &nbsp;
          <span style={{ color: COLORS.PRIMARY, fontSize: '0.8em', position: 'absolute' }}>
            (beta)
          </span>
        </Typography>
        <Typography
          sx={{
            color: COLORS.PRIMARY,
            fontStyle: 'italic'
          }}
        >
          {t('analytics.purchasesLast7Days')}
        </Typography>
      </PaperHeader>

      <PaperContent np>
        {errorMessage ? (
          <Typography sx={{ fontStyle: 'italic' }}>{errorMessage}</Typography>
        ) : report ? (
          <StandardReportTable data={report} />
        ) : (
          <CircularProgress />
        )}
      </PaperContent>
      <PaperFooter>
        <Typography
          sx={{
            color: COLORS.PRIMARY,
            fontStyle: 'italic'
          }}
        >
          {t('common.update')}{' '}
          <IconButton sx={{ color: COLORS.PRIMARY }} onClick={fetchReport}>
            <RefreshIcon />
          </IconButton>
        </Typography>
      </PaperFooter>
    </PaperCard>
  );
};

export default PurchasesAnalytics;
