import axios from 'axios';
import Text from 'types/Text';
import TextCategory from 'types/TextCategory';

const textService = {
  getAll: () => {
    return axios.get<Text[]>('text');
  },
  getByKey: (key: string) => {
    return axios.get<Text>(`text/${key}`);
  },
  getPublicByKey: (key: string) => {
    return axios.get<Text>(`text/public/${key}`);
  },
  getByCategory: (category: TextCategory) => {
    return axios.get<Text[]>(`text/category/${category}`);
  },
  translateDefaultsToNn: () => {
    return axios.post('text/translate-defaults-to-nn');
  },
  post: (text: Text) => {
    return axios.post<Text>('text', text);
  },
  update: (text: Text) => {
    return axios.put<Text>(`text/${text.key}`, text);
  },
  delete: (textKey: string) => {
    return axios.delete(`text/${textKey}`);
  },
  deleteAll: () => {
    return axios.delete('text/all');
  }
};

export default textService;
