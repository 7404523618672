import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SearchFilter from 'types/SearchFilter';
import SearchQuery from 'types/SearchQuery';

export type PaymentSearch = {
  searchQuery: SearchQuery;
  searchFilter: SearchFilter;
};

const search: PaymentSearch = {
  searchFilter: null,
  searchQuery: null
};

export const paymentSearch = createSlice({
  name: 'paymentSearch',
  initialState: search,
  reducers: {
    setFilter: (state, { payload: searchFilter }: PayloadAction<SearchFilter>) => {
      state.searchFilter = searchFilter;
      return state;
    },
    setQuery: (state, { payload: searchQuery }: PayloadAction<SearchQuery>) => {
      state.searchQuery = searchQuery;
      return state;
    },
    clear: () => {
      return search;
    }
  }
});

export default paymentSearch;
