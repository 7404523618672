import {
  Button,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PrimarySearch from 'components/PrimarySearch/PrimarySearch';
import PrimarySearchPageNav from 'components/PrimarySearchPageNav/PrimarySearchPageNav';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchQuery from 'types/SearchQuery';
import SearchQueryType from 'types/SearchQueryType';
import PageResults from 'types/PageResults';
import DistributionRoute from 'types/DistributionRoute';
import distributionRouteService from 'services/distribution-route-service';
import PaperCard from 'components/PaperCard/PaperCard';
import DistributorChip from 'components/DistributorChip/DistributorChip';
import useAlert from 'hooks/useAlert';
import logger from 'logger/logger';

const LIMIT = 20;

type SelectDistributionRouteProps = {
  onAddRouteId?: (id: string) => void;
};

const SelectDistributionRoute: FC<SelectDistributionRouteProps> = ({ onAddRouteId }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<SearchQuery>();
  const [loading, setLoading] = useState<boolean>();

  const queryTypes: SearchQueryType[] = [{ key: 'id', label: t('common.routeId') }];
  const [pageResults, setPageResults] = useState<PageResults<DistributionRoute>>();
  
  const { addAlert } = useAlert();

  const changePage = (value: number) => {
    const updatedSearchQuery = { ...searchQuery, page: value };
    setSearchQuery(updatedSearchQuery);

    const offset = (value - 1) * LIMIT;
    searchDistributionRoutes(updatedSearchQuery, LIMIT, offset);
  };

  const init = async () => {
    const offset = searchQuery?.page ? LIMIT * (searchQuery?.page - 1) : 0;

    searchDistributionRoutes(
      {
        query: searchQuery?.query || null,
        filters: searchQuery?.filters || undefined,
        queryType: searchQuery?.queryType || queryTypes[0],
        page: searchQuery?.page || 1
      },
      LIMIT,
      offset
    );
  };

  useEffect(() => {
    init();
  }, []);

  const searchDistributionRoutes = async (
    searchQueryP: SearchQuery,
    limit: number,
    offset: number
  ) => {
    setLoading(true);
    try {
      const { data } = await distributionRouteService.search(searchQueryP, limit, offset);
      setPageResults(data);
    } catch (error) {
      addAlert('error', t('alert.somethingWentWrong'));
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PrimarySearch
        defaultQuery={searchQuery?.query}
        queryTypes={queryTypes}
        onSearch={(query, queryType) => {
          searchDistributionRoutes({ ...searchQuery, query, queryType, page: 1 }, LIMIT, 0);
        }}
      />
      <PaperCard sx={{ p: 0 }} variant={'outlined'}>
        {!loading && (
          <TableContainer
            sx={{
              flexDirection: 'column',
              height: '50vh',
              overflowY: 'auto',
              width: '100%'
            }}
          >
            <Table stickyHeader sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>{t('common.routeId')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('distribution.distributor')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('distribution.route.name')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('common.postCode')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('common.named')}</Typography>
                  </TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {pageResults?.results.map((distributionRoute: DistributionRoute) => {
                  let postCodeText = distributionRoute.postCodeFrom
                    ? distributionRoute.postCodeFrom
                    : '';
                  postCodeText +=
                    distributionRoute.postCodeFrom && distributionRoute.postCodeTo
                      ? ' - ' + distributionRoute.postCodeTo
                      : '';

                  return (
                    <TableRow key={distributionRoute.id}>
                      <TableCell>
                        <Typography>{distributionRoute.id}</Typography>
                      </TableCell>
                      <TableCell>
                        <DistributorChip distributor={distributionRoute.type} />
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {distributionRoute?.postenDistributionRoute
                            ? distributionRoute?.postenDistributionRoute?.name
                            : distributionRoute.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{postCodeText}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {distributionRoute.named ? t('common.named') : t('common.unamed')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => onAddRouteId(distributionRoute.id)}>
                          {t('distribution.choose')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </PaperCard>
      {pageResults && (
        <PrimarySearchPageNav
          page={searchQuery?.page || 1}
          totalResults={pageResults?.total}
          onChangePage={changePage}
        />
      )}

      {loading && (
        <Stack>
          <CircularProgress />
        </Stack>
      )}
    </>
  );
};

export default SelectDistributionRoute;
