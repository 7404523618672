import { AxiosError } from 'axios';
import logger from 'logger/logger';
import { useDispatch, useSelector } from 'react-redux';
import settingService from 'services/setting-service';
import { RootState, settingsActions } from 'store';
import useAxiosAlert from './useAxiosAlert';

const useSettings = () => {
  const settings = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();
  const axiosAlert = useAxiosAlert();

  const syncSettings = async () => {
    try {
      const { data: initSettings } = await settingService.getInitialSettings();

      dispatch(settingsActions.set(initSettings));
    } catch (error) {
      logger.error('Get init settings failed', error);
      axiosAlert(error as AxiosError);
    }
  };

  function getValueByKey(key: string, defaultValue?: string): string {
    return settings?.find((s) => s.key === key)?.value || defaultValue;
  }

  function getValueByKeyAsBoolean(key: string, defaultValue?: number): boolean {
    const value = getValueByKey(key, defaultValue?.toString());
    return value === '1';
  }

  return { settings, syncSettings, getValueByKey, getValueByKeyAsBoolean };
};

export default useSettings;
