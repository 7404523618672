/* eslint-disable max-lines */
/* eslint-disable indent */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PaperRetailer from 'types/PaperRetailer';

type AddPaperRetailerProps = {
  paperRetailer?: PaperRetailer;
  cancel: () => void;
  save: (updatedPaperRetailer: PaperRetailer) => void;
  deleteRetailer?: (paperRetailer: PaperRetailer) => void;
};

const EditPaperRetailerModal: FC<AddPaperRetailerProps> = ({
  paperRetailer,
  cancel,
  save,
  deleteRetailer
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm<PaperRetailer>({
    defaultValues: {
      name: paperRetailer?.name,
      address: paperRetailer?.address,
      postCode: paperRetailer?.postCode,
      postOffice: paperRetailer?.postOffice,
      transportRoute: paperRetailer?.transportRoute,
      carText: paperRetailer?.carText,
      dropNumber: paperRetailer?.dropNumber,
      dropText: paperRetailer?.dropText,
      dropText2: paperRetailer?.dropText2,
      quantity: paperRetailer?.quantity,
      routeId: paperRetailer?.routeId,
      routeName: paperRetailer?.routeName
    }
  });

  const onSubmit = async (fields: PaperRetailer) => {
    const updatedPaperRetailer: PaperRetailer = {
      ...paperRetailer,
      ...fields
    };
    save(updatedPaperRetailer);
    setLoading(true);
  };

  const title = paperRetailer
    ? t('common.edit')
    : t('common.add') + ' ' + t('common.retailer').toString().toLowerCase();

  return (
    <Dialog open={true}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <DialogTitle>{title}</DialogTitle>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '500px' }}>
        <DialogContent>
          <Stack gap={2}>
            {paperRetailer && (
              <TextField label={t('common.id')} disabled={true} value={paperRetailer.id} />
            )}
            <TextField
              label={t('common.name')}
              {...register('name', {
                required: true
              })}
            />
            <TextField label={t('common.routeId')} {...register('routeId')} />
            <TextField label={t('common.routeName')} {...register('routeName')} />
            <TextField
              sx={{ flex: 1 }}
              label={t('common.address')}
              {...register('address', {
                required: true
              })}
            />
            <Stack direction={'row'} gap={2}>
              <TextField sx={{ flex: 1 }} label={t('common.postCode')} {...register('postCode')} />
              <TextField
                sx={{ flex: 1 }}
                label={t('common.postOffice')}
                {...register('postOffice')}
              />
            </Stack>
            <TextField label={t('common.transportRoute')} {...register('transportRoute')} />
            <TextField label={t('common.carText')} {...register('carText')} />
            <TextField label={t('common.dropText') + ' 1'} {...register('dropText')} />
            <TextField label={t('common.dropText') + ' 2'} {...register('dropText2')} />
            <TextField label={t('common.numberOfPapers')} {...register('quantity')} />
          </Stack>
        </DialogContent>
        <DialogActions>
          {paperRetailer && (
            <Button
              disabled={loading}
              color={'error'}
              variant='outlined'
              sx={{ marginRight: 'auto' }}
              onClick={() => {
                setLoading(true);
                deleteRetailer(paperRetailer);
              }}
            >
              {t('common.delete')}
            </Button>
          )}
          {loading && <CircularProgress />}
          <Button disabled={loading} onClick={cancel}>
            {t('common.cancel')}
          </Button>
          <Button disabled={loading} type={'submit'} variant='outlined'>
            {paperRetailer ? t('common.update') : t('common.add')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditPaperRetailerModal;
