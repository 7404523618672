import { FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import FormDialog from 'components/FormDialog/FormDialog';
import userSubscriptionCancelationType from 'consts/cancelationType';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CancelationType } from 'types/UserSubscription';

type CancelUserSubscriptionModalProps = {
  onConfirm: (cancelationType: CancelationType, cancelationReason: string) => Promise<void>;
  onCancel: () => void;
};

type CancelUserSubscriptionFields = {
  cancelationType: CancelationType;
  cancelationReason: string;
};

const CancelUserSubscriptionModal: FC<CancelUserSubscriptionModalProps> = ({
  onConfirm,
  onCancel
}) => {
  const { t } = useTranslation();

  const admin = useSelector((state: RootState) => state.admin);

  const onSubmit: SubmitHandler<CancelUserSubscriptionFields> = async (fields) => {
    const { cancelationType, cancelationReason } = fields;
    onConfirm(cancelationType, cancelationReason);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues, 
  } = useForm<CancelUserSubscriptionFields>({
    defaultValues: {
      cancelationType: userSubscriptionCancelationType.CANCELED_BY_ADMIN,
      cancelationReason: `${t('common.canceledBy')} ${admin.name}`
    }
  });

  return (
    <FormDialog onSubmit={handleSubmit(onSubmit)} onCancel={onCancel}>
      <Typography sx={{ fontSize: '1.2em', marginBottom: '20px' }} component='h2'>
        {t('common.cancelSubscription')}
      </Typography>
      <Stack gap={2} sx={{ minWidth: '350px' }}>
        <FormControl>
          <InputLabel>{t('common.cancelationType')}</InputLabel>

          <Select
            label={t('common.cancelationType')}
            fullWidth
            defaultValue={getValues('cancelationType')}
            {...register('cancelationType')}
            error={errors.cancelationType ? true : false}
          >
            {Object.values(userSubscriptionCancelationType).map((ct, index) => {
              return (
                <MenuItem key={index} value={ct}>
                  {t('cancelationType.' + ct)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          label={t('common.cancelationReason')}
          sx={{ width: '100%', mt: 1 }}
          placeholder={t('common.cancelationReason')}
          multiline
          minRows={3}
          {...register('cancelationReason'/*, {validate: (v:string)=>validate.length(v, 0, 1000)}*/)}
          error={!!errors.cancelationReason}
        ></TextField>
      </Stack>
    </FormDialog>
  );
};

export default CancelUserSubscriptionModal;
