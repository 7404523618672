export type ThemeColors = {
  PRIMARY?: string;
  PRIMARY_LIGHT?: string;
  PRIMARY_DARK?: string;
  PRIMARY_CONTRAST?: string;
  SECONDARY?: string;
  SECONDARY_CONTRAST?: string;
  SECONDARY_DARK?: string;
  SECONDARY_LIGHT?: string;
  INFO?: string;
  INFO_LIGHT?: string;
  INFO_DARK?: string;
  INFO_BACKGROUND?: string;
  INFO_CONTRAST?: string;
  ERROR?: string;
  ERROR_DARK?: string;
  BACKGROUND_LIGHT?: string;
  BACKGROUND_DARK?: string;
  TEXT_COLOR_LIGHT?: string;
  TEXT_COLOR_DARK?: string;
  TABLE_HEADER?: string;
  SUCCESS?: string;
  SUCCESS_LIGHT?:string;
  SUCCESS_DARK?: string;
};

const COLORS: ThemeColors = {
  PRIMARY: '#1db6b4',
  PRIMARY_LIGHT: '#8afcfb',
  PRIMARY_CONTRAST: '#ffffff',
  PRIMARY_DARK: '#095e5d',
  TABLE_HEADER: '#212426',
  SECONDARY: '#273036',
  SECONDARY_DARK: '#242828',
  SECONDARY_CONTRAST: '#ffffff',
  SECONDARY_LIGHT: '#89cbe0',
  INFO: '#89cbe0',
  INFO_LIGHT: '#74ddff',
  INFO_DARK: '#326f82',
  INFO_CONTRAST: '#242828',
  INFO_BACKGROUND: '#1a1d1f',
  ERROR: '#ff5979',
  ERROR_DARK: '#5d0605',
  BACKGROUND_LIGHT: '#efefef',
  BACKGROUND_DARK: '#242828',
  TEXT_COLOR_DARK: '#484848',
  TEXT_COLOR_LIGHT: '#fff',
  SUCCESS: '#66bb6a',
  SUCCESS_LIGHT: '#74ef7a',
  SUCCESS_DARK: '#37783a'
};

export default COLORS;
