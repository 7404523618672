import { Download, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Button, IconButton, Paper, Stack, Typography } from '@mui/material';
import { PaperCard, PaperContent, PaperHeader } from 'components';
import COLORS from 'consts/colors';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import invoiceService from 'services/invoice-service';
import OcrHistory from 'types/OcrHistory';
import { dateString } from 'utils';
import { getYearMonthDaysString } from 'utils/_dateUtil';
import axios from 'axios';

const OcrHistoryList: FC = () => {
  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();

  const [ocrHistory, setOcrHistory] = useState<OcrHistory[]>(null);
  const [displayOcrHistory, setDisplayOcrHistory] = useState<boolean>(false);

  const getOcrHistory = async () => {
    try {
      const { data: subscriptionsData } = await invoiceService.getOcrHistory();
      setOcrHistory(subscriptionsData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        axiosAlert(error, []);
      } else {
        console.error('getOcrHistory failed', error);
      }
    }
  };

  const handleOcrDownload = async (ocr: OcrHistory) => {
    try {
      // Create a Blob for the text file
      const file = new Blob([ocr.ocr], { type: 'text/plain' });

      // Create a temporary download link and trigger the download
      const element = document.createElement('a');
      element.href = URL.createObjectURL(file);
      element.download = `ocr-${getYearMonthDaysString(ocr.created)}.txt`;
      document.body.appendChild(element); // Required for Firefox
      element.click();

      // Clean up after download
      document.body.removeChild(element);
    } catch (error) {
      console.error('Error generating text download:', error);
    }
  };
  return (
    <div>
      <Typography variant='h6' sx={{ marginBottom: '10px' }}>
        {' '}
        {t('invoice.ocrHistory')}
      </Typography>
      {!displayOcrHistory && (
        <Button
          variant='outlined'
          onClick={() => {
            getOcrHistory();
            setDisplayOcrHistory(true);
          }}
        >
          {t('invoice.getOcrHistory')}
        </Button>
      )}
      {ocrHistory &&
        (ocrHistory.length > 0 ? (
          ocrHistory.map((ocr) => (
            <PaperCard variant='outlined' key={ocr.id} sx={{ margin: '10px 0' }}>
              <PaperHeader sx={{ background: COLORS.BACKGROUND_DARK, padding: '5px 15px' }}>
                <Stack
                  direction={'row'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <Typography>
                    {t('invoice.ocrId') + ' ' + ocr.id} - {dateString(ocr.created, true)} -{' '}
                    {ocr.admin}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      ocr.show = !ocr.show;
                      setOcrHistory([...ocrHistory]);
                    }}
                  >
                    {ocr.show ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                  </IconButton>
                </Stack>
              </PaperHeader>
              {ocr.show && (
                <PaperContent sx={{ pt: 0 }}>
                  <Paper
                    elevation={0}
                    sx={{ backgroundColor: '#000', padding: '20px', margin: '20px 0 ' }}
                  >
                    <pre>{ocr.result}</pre>
                  </Paper>
                  <Button
                    variant='outlined'
                    onClick={() => handleOcrDownload(ocr)}
                    sx={{ marginLeft: 'auto', justifyContent: 'flex-end' }}
                  >
                    <Download />
                    {t('invoice.downloadOcr')}
                  </Button>
                </PaperContent>
              )}
            </PaperCard>
          ))
        ) : (
          <Typography>{t('invoice.noOcrHistory')}</Typography>
        ))}
    </div>
  );
};

export default OcrHistoryList;