/* eslint-disable max-lines */
import { Edit } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { PrimaryCard, PrimaryListItem } from 'components';
import useAlert from 'hooks/useAlert';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import UserEmailModal from '../UserEmailModal/UserEmailModal';
import UserNameModal from '../UserNameModal/UserNameModal';
import UserNoteModal from '../UserNoteModal/UserNoteModal';
import UserPasswordModal from '../UserPasswordModal/UserPasswordModal';
import UserPhoneModal from '../UserPhoneModal/UserPhoneModal';
import UserAddressModal from '../UserAddressModal/UserAddressModal';
import UserMetaData from './UserMetaData/UserMetaData';
import { hasNoSubscription } from 'utils';
import ArchiveUserModal from '../ArchiveUserModal/ArchiveUserModal';
import { ConsentModal } from 'modals';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';
import userService from 'services/user-service';
import UserAddress from 'types/UserAddress';
import PrimaryTooltip from 'components/PrimaryTooltip/PrimaryTooltip';
import { formatPhoneNumber } from 'utils/_formatPhoneNumber';

type EditUserProps = {
  user: User;
  onUpdate: (user: User) => void;
};

type EditUserModal =
  | 'phone'
  | 'name'
  | 'email'
  | 'password'
  | 'note'
  | 'address'
  | 'archive'
  | 'deleteMetadata';

const EditUser: FC<EditUserProps> = ({ user, onUpdate }) => {
  const { t } = useTranslation();
  const { addAlert } = useAlert();
  const [modal, setModal] = useState<EditUserModal>(null);
  const canArchiveUser = hasNoSubscription(user.userSubscriptions);
  const [loading, setLoading] = useState<boolean>();
  const axiosAlert = useAxiosAlert();

  const deleteMetadata = async () => {
    setLoading(true);
    try {
      const { data: updatedUser } = await userService.deleteMetadata(user.id);
      onUpdate(updatedUser);
    } catch (error) {
      axiosAlert(error as AxiosError, [
        { status: 403, message: t('user.alerts.userIsNotArchived', { userId: user.id }) },
        { status: 404, message: t('user.alerts.couldNotFind', { userId: user.id }) }
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        {user !== null && user !== undefined && (
          <div>
            {(!loading && (
              <PrimaryCard sx={{ p: 0 }}>
                <PrimaryListItem label={t('common.userId')} value={user.id} />
                {(!user.archived && (
                  <>
                    <PrimaryListItem
                      label={t('common.name')}
                      value={user.name}
                      actions={
                        <IconButton onClick={() => setModal('name')}>
                          <Edit />
                        </IconButton>
                      }
                    />
                    <PrimaryListItem
                      label={t('common.phone')}
                      value={formatPhoneNumber(user.phone)}
                      placeholder={t('common.notAdded')}
                      actions={
                        <IconButton onClick={() => setModal('phone')}>
                          <Edit />
                        </IconButton>
                      }
                    />
                    <PrimaryListItem
                      label={t('common.email')}
                      value={user.email || '--'}
                      placeholder={t('common.notAdded')}
                      actions={
                        <IconButton onClick={() => setModal('email')}>
                          <Edit />
                        </IconButton>
                      }
                    />
                    <PrimaryListItem
                      label={t('common.address')}
                      sx={{ whiteSpace: 'pre-line', textAlign: 'right' }}
                      value={
                        (user?.userAddress?.address ? `${user.userAddress.address}\n` : '') +
                        (user?.userAddress?.postCode || '') +
                        ' ' +
                        (user?.userAddress?.postOffice || '--')
                      }
                      placeholder={t('common.notAdded')}
                      actions={
                        <IconButton onClick={() => setModal('address')}>
                          <Edit />
                        </IconButton>
                      }
                    />
                    <PrimaryListItem
                      label={t('common.password')}
                      value={'********'}
                      actions={
                        <IconButton onClick={() => setModal('password')}>
                          <Edit />
                        </IconButton>
                      }
                    />

                    <Box
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        margin: '15px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '0',
                        boxSizing: 'border-box'
                      }}
                    >
                      <Typography>{t('common.note')}</Typography>
                      <IconButton onClick={() => setModal('note')}>
                        <Edit />
                      </IconButton>
                    </Box>
                    <Typography
                      sx={{
                        minHeight: '70px',
                        padding: '10px',
                        backgroundColor: 'rgba(255,255,255,0.05)',
                        color: 'rgba(255,255,255,0.8)',
                        whiteSpace: 'pre'
                      }}
                    >
                      {user.note}
                    </Typography>
                    <Divider sx={{ margin: '10px 0' }} />
                    <Typography variant={'h6'} sx={{ marginTop: '10px' }}>
                      {t('common.other')}
                    </Typography>
                  </>
                )) || (
                  // If user is archived
                  <Stack gap={2} direction={'row'} sx={{ margin: '10px 0' }}>
                    <Alert severity='error' sx={{ flex: 1, padding: '0 20px' }}>
                      {t('user.archive.archivedDescription')}
                    </Alert>
                    {user.metadata && (
                      <Button
                        onClick={() => setModal('deleteMetadata')}
                        variant={'outlined'}
                        color={'error'}
                      >
                        {t('user.archive.deleteMetadata')}
                      </Button>
                    )}
                  </Stack>
                )}

                {user.metadata && (
                  <>
                    <UserMetaData metadata={user.metadata} />
                    <Divider sx={{ margin: '10px 0' }} />
                  </>
                )}
                {!user.archived && (
                  <PrimaryListItem
                    last
                    label={t('user.archive.archiveUser')}
                    actions={
                      <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                        <Tooltip
                          title={canArchiveUser ? '' : t('user.archive.disabledDescription')}
                        >
                          <span>
                            <Button
                              color={'error'}
                              disabled={!canArchiveUser}
                              onClick={() => setModal('archive')}
                              variant={'outlined'}
                            >
                              {t('user.archive.archiveUser')}
                            </Button>
                          </span>
                        </Tooltip>
                        <PrimaryTooltip text={t('user.archive.description')} />
                      </Stack>
                    }
                  />
                )}
              </PrimaryCard>
            )) || (
              <Stack flexDirection={'row'} justifyContent={'center'}>
                <CircularProgress />
              </Stack>
            )}
          </div>
        )}
      </div>
      {modal == 'phone' && (
        <UserPhoneModal
          user={user}
          onCancel={() => setModal(null)}
          onConfirm={(newPhoneNumber, newPhoneNumberCountry) => {
            setModal(null);
            onUpdate({ ...user, phone: newPhoneNumber, phoneCountryCode: newPhoneNumberCountry });
          }}
        />
      )}
      {modal === 'name' && (
        <UserNameModal
          user={user}
          onCancel={() => setModal(null)}
          onConfirm={(newName) => {
            setModal(null);
            onUpdate({ ...user, name: newName });
          }}
        />
      )}
      {modal === 'email' && (
        <UserEmailModal
          user={user}
          onCancel={() => setModal(null)}
          onConfirm={(email) => {
            setModal(null);
            onUpdate({ ...user, email: email });
            addAlert(
              'success',
              t('alerts.emailUpdated', {
                email
              })
            );
          }}
        />
      )}
      {modal === 'address' && (
        <UserAddressModal
          user={user}
          onCancel={() => setModal(null)}
          onConfirm={(userAddress: UserAddress) => {
            setModal(null);
            onUpdate({ ...user, userAddress });
          }}
        />
      )}
      {modal === 'password' && (
        <UserPasswordModal
          user={user}
          onCancel={() => setModal(null)}
          onConfirm={() => setModal(null)}
        />
      )}
      {modal === 'note' && (
        <UserNoteModal
          user={user}
          onCancel={() => setModal(null)}
          onConfirm={(newNote) => {
            setModal(null);
            onUpdate({ ...user, note: newNote });
          }}
        />
      )}
      {modal === 'archive' && (
        <ArchiveUserModal
          user={user}
          onCancel={() => setModal(null)}
          onUserArchived={(archivedUser: User) => {
            setModal(null);
            onUpdate({ ...archivedUser });
          }}
        />
      )}
      {modal === 'deleteMetadata' && (
        <ConsentModal
          title={t('user.archive.deleteMetadataConfirm')}
          content={t('user.archive.deleteMetadataDescription')}
          onCancel={() => {
            setModal(null);
          }}
          confirmColor={'error'}
          confirmText={t('user.archive.deleteMetadata')}
          onConfirm={() => {
            setModal(null);
            deleteMetadata();
          }}
        />
      )}
    </>
  );
};

export default EditUser;
