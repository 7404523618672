import { FormControl, TextField } from '@mui/material';
import { FC } from 'react';

type ReportOptionNumberProps = {
  value: number;
  label: string;
  onChange: (value: number) => void;
};

const ReportOptionNumber: FC<ReportOptionNumberProps> = ({ value, label, onChange }) => {
  return (
    <FormControl>
      <TextField
        
        label={label}
        value={value}
        type="number"
        onChange={(e) => onChange(+e.target.value)}
      />
    </FormControl>
  );
};

export default ReportOptionNumber;
