import { Edit } from '@mui/icons-material';
import {
  CircularProgress,
  Stack,
  SxProps,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table,
  IconButton,
  TableHead
} from '@mui/material';
import { Box } from '@mui/system';
import { PaperCard, PaperContent, PaperHeader } from 'components';
import { ROUTE_PATHS } from 'consts/routes';
import DistributionMessageModal from 'pages/Users/components/EditUserDistribution/components/DistributionMessageModal';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import userDistributionService from 'services/user-distribution-service';
import User from 'types/User';
import UserDistribution from 'types/UserDistribution';

const CurrierMessagesPage: FC = () => {
  const { t } = useTranslation();

  const [usersWithDistributionMessage, setUsersWithDistributionMessage] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState<number>();
  const [userDistributionInEdit, setUserDistributionInEdit] = useState<UserDistribution>();

  const getUsersWithDistributionMessage = async () => {
    setLoading(true);
    try {
      const { data } = await userDistributionService.getUsersWithDistributionMessage();
      setUsersWithDistributionMessage(data);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsersWithDistributionMessage();
  }, []);

  const styles: { [k: string]: SxProps } = {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px',
      alignItems: 'center'
    }
  };

  return (
    <>
      <PaperCard sx={{ width: 'auto', maxWidth: '800px' }}>
        <PaperHeader>
          <Box sx={styles.header}>
            <Typography variant='h5'>{t('distribution.messages')}</Typography>
          </Box>
        </PaperHeader>
        <PaperContent sx={{ mb: 2 }} np>
          {usersWithDistributionMessage?.length > 0 && (
            <Table
              sx={{
                width: '100%'
              }}
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>{t('common.username')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t('userDistribution.distributionMessage')}</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  usersWithDistributionMessage.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>
                        <Link
                          style={{ color: '#fff ' }}
                          to={ROUTE_PATHS.USER.replace(':id', user?.id.toString())}
                        >
                          <Typography
                            sx={{
                              textDecoration: 'none',
                              '&:hover': {
                                color: 'white'
                              }
                            }}
                          >
                            {user?.name}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            minHeight: '50px',
                            padding: '10px',
                            backgroundColor: 'rgba(255,255,255,0.05)',
                            color: 'rgba(255,255,255,0.8)',
                            whiteSpace: 'pre'
                          }}
                        >
                          {user?.userSubscriptions[0].paper?.distributionMessage}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setUserDistributionInEdit(user.userSubscriptions[0].paper);
                            setModal(1);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          {loading && (
            <Stack style={{ textAlign: 'center' }}>
              <CircularProgress sx={{ margin: '10px 0' }} />
            </Stack>
          )}
          {!loading && !usersWithDistributionMessage.length && (
            <Stack p={4}>
              <Typography sx={{ textAlign: 'center' }}>
                {t('userDistribution.noMessages')}
              </Typography>
            </Stack>
          )}
        </PaperContent>
      </PaperCard>
      {modal === 1 && (
        <DistributionMessageModal
          userDistribution={userDistributionInEdit}
          onCancel={() => {
            setModal(undefined);
            setUserDistributionInEdit(undefined);
          }}
          onEdit={() => {
            setModal(undefined);
            getUsersWithDistributionMessage();
            setUserDistributionInEdit(undefined);
          }}
        />
      )}
    </>
  );
};

export default CurrierMessagesPage;
