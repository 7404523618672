import { Paper, Typography } from '@mui/material';
import { Box, Breakpoint, SxProps } from '@mui/system';
import { FC, ReactNode } from 'react';

import './InfoContainer.scss';

type InfoContainerProps = {
  title?: string;
  fullWidth?: boolean;
  actions?: ReactNode;
  maxWidth?: Breakpoint;
  sx?: SxProps;
  children?: ReactNode
};

const InfoContainer: FC<InfoContainerProps> = ({
  fullWidth = false,
  title,
  children,
  actions,
  maxWidth = 'md',
  sx,
}) => {
  const fullWidthClass = fullWidth ? 'full-width' : '';

  return (
    <Box maxWidth={maxWidth} sx={{ display: 'flex', ...sx }}>
      <Paper className={`info-container ${fullWidthClass}`}>
        {title && (
          <div className={'info-container-header'}>
            <Typography variant={'h6'}>{title}</Typography>

            <div className='actions'>{actions}</div>
          </div>
        )}
        {children}
      </Paper>
    </Box>
  );
};

export default InfoContainer;
