import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DistributionRouteType } from 'types/DistributionRoute';

type DistributorChipProps = {
  distributor: DistributionRouteType;
};

const DistributorChip: FC<DistributorChipProps> = ({ distributor }) => {
  const { t } = useTranslation();

  const getChipColor = () => {
    switch (distributor) {
      case 'POSTEN':
        return 'error';
      case 'POLARIS':
        return 'primary';
      case 'LOCAL_POSTEN_ROUTE':
        return 'error';
      default:
        return 'default';
    }
  };

  const chipColor = getChipColor();

  return (
    <Chip color={chipColor} label={t(`distribution.distributors.${distributor}`).toUpperCase()} />
  );
};

export default DistributorChip;
