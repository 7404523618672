import { FC } from 'react';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type PolarisAddressListProps = {
  polarisAddresses: PolarisAddress[];
};

const PolarisAddressList: FC<PolarisAddressListProps> = ({ polarisAddresses }) => {
  const { t } = useTranslation();

  return (
    (polarisAddresses?.length > 0 && (
      <TableContainer sx={{ overflowX: 'unset', minHeight: '50vh', overflowY: 'auto' }}>
        <Table sx={{ minWidth: 350 }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>{t('common.routeId')}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{t('common.streetName')}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{t('common.houseNumber')}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{t('common.appartmentShort')}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{t('common.postCode')}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{t('common.postOffice')}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {polarisAddresses.map((polarisAddress) => (
              <TableRow key={polarisAddress.id}>
                <TableCell>
                  <Typography>{polarisAddress.routeId ?? '--'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{polarisAddress.street ?? '--'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{polarisAddress.number ?? '--'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{polarisAddress?.appartment || '--'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{polarisAddress.postCode ?? '--'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{polarisAddress.postOffice ?? '--'}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )) || (
      <Stack sx={{ p: 4 }} direction={'row'} justifyContent={'center'}>
        <Typography>{t('common.theListIsEmpty')}</Typography>
      </Stack>
    )
  );
};

export default PolarisAddressList;
