import {
  AdminPanelSettings,
  Article,
  Dashboard,
  Newspaper,
  Payments,
  People,
  Receipt,
  Settings,
  Subscriptions,
  Work
} from '@mui/icons-material';
import NavItem from 'types/NavItem';
import RouteItem from 'types/RouteItem';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

const createNavItems = (
  routes: { [k: string]: RouteItem },
  usingPaper: boolean,
  usingInvoice: boolean
): NavItem[] => {
  const navItems = [
    {
      icon: <Dashboard />,
      roles: routes.DEFAULT.roles,
      path: routes.DEFAULT.path,
      title: routes.DEFAULT.menuTitle
    },
    {
      icon: <People />,
      roles: routes.USERS.roles,
      path: routes.USERS.path,
      title: routes.USERS.menuTitle
    },
    {
      icon: <Payments />,
      roles: routes.PAYMENTS.roles,
      path: routes.PAYMENTS.path,
      title: routes.PAYMENTS.menuTitle
    },
    {
      icon: <Article />,
      roles: routes.REPORTS.roles,
      path: routes.REPORTS.path,
      title: routes.REPORTS.menuTitle
    },
    {
      icon: <AdminPanelSettings />,
      roles: routes.ADMINS.roles,
      path: routes.ADMINS.path,
      title: routes.ADMINS.menuTitle
    },
    {
      icon: <Subscriptions />,
      roles: routes.SUBSCRIPTIONS.roles,
      path: routes.SUBSCRIPTIONS.path,
      title: routes.SUBSCRIPTIONS.menuTitle
    },
    {
      icon: <Settings />,
      roles: routes.SETTINGS.roles,
      path: routes.SETTINGS.path,
      title: routes.SETTINGS.menuTitle
    },
    {
      icon: <TextSnippetIcon />,
      roles: routes.TEXTS.roles,
      path: routes.TEXTS.path,
      title: routes.TEXTS.menuTitle
    },
    {
      icon: <TroubleshootIcon />,
      roles: routes.DATA_CHECK.roles,
      path: routes.DATA_CHECK.path,
      title: routes.DATA_CHECK.menuTitle
    },
    {
      icon: <Work />,
      roles: routes.JOBS.roles,
      path: routes.JOBS.path,
      title: routes.JOBS.menuTitle
    },
    {
      icon: <NotificationsIcon />,
      roles: routes.NOTIFICATION.childRoutes.LIST.roles,
      path: routes.NOTIFICATION.childRoutes.LIST.path,
      title: routes.NOTIFICATION.childRoutes.LIST.menuTitle
    }
  ];

  if (usingInvoice) {
    navItems.push({
      icon: <Receipt />,
      roles: routes.INVOICES.roles,
      path: routes.INVOICES.path,
      title: routes.INVOICES.menuTitle
    });
  }

  if (usingPaper) {
    navItems.push({
      icon: <Newspaper />,
      roles: routes.PAPER.roles,
      path: routes.PAPER.path,
      title: routes.PAPER.menuTitle
    });
  }

  return navItems;
};
export default createNavItems;
