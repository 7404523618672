import { FC } from 'react';
import {
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  SxProps,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormDialog from 'components/FormDialog/FormDialog';
import { validate } from 'utils';
import theme from 'styles/theme';

type RefundConsentModalProps = {
  title?: string;
  content?: string;
  onConfirm?: (refundDescription: string) => void;
  onCancel?: () => void;
  confirmText?: string;
  contentSx?: SxProps;
  confirmColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
};

type RefundConsentFields = {
  refundDescription: string;
};

const RefundConsentModal: FC<RefundConsentModalProps> = ({
  title,
  onConfirm,
  onCancel,
}) => {
  const admin = useSelector((state: RootState) => state.admin);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RefundConsentFields>({
    defaultValues: {
      refundDescription: `${t('common.refundedBy')} ${admin.name}`
    }
  });
  const onSubmit: SubmitHandler<RefundConsentFields> = async (fields) => {
    if (onConfirm) {
      onConfirm(fields.refundDescription);
    }
  };

  return (
    <FormDialog onSubmit={handleSubmit(onSubmit)} onCancel={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack gap={2} sx={{ minWidth: '350px', [theme.breakpoints.down('sm')]:
        {
          minWidth: 'auto'
        } }}>
          <FormControl>
            <TextField
              label={t('payment.refundDescription')}
              fullWidth
              margin='normal'
              multiline
              minRows={3}
              {...register('refundDescription', {
                validate: (v: string) => validate.length(v, 0, 1000)
              })}
              error={!!errors.refundDescription}
            />
          </FormControl>
        </Stack>
      </DialogContent>
    </FormDialog>
  );
};

export default RefundConsentModal;
