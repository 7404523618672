import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Stack,
  Box
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Admin from 'types/Admin';
import { validate } from 'utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import role from 'consts/role';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type EditAdminModalProps = {
  admin?: Admin;
  onConfirm: (admin: Admin) => void;
  onCancel: () => void;
};

type FormValues = {
  name: string;
  email: string;
  role: number;
};

const EditAdminModal: FC<EditAdminModalProps> = ({ onCancel, onConfirm, admin }) => {
  const { role: currentAdminRole } = useSelector((state: RootState) => state.admin);
  const adminRoles = [role.ADMINISTRATOR, role.EDITOR];
  if (currentAdminRole == role.HOST) {
    adminRoles.unshift(role.HOST);
  }

  const addNew = admin === null || admin === undefined;
  let defaultValues: FormValues;
  if (addNew) {
    defaultValues = {
      name: '',
      email: '',
      role: role.EDITOR
    };
  } else {
    defaultValues = {
      name: admin.name,
      email: admin.email,
      role: admin.role
    };
  }

  const { register, formState, handleSubmit } = useForm<FormValues>({
    defaultValues
  });
  const { errors } = formState;

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    const result: Admin = {
      ...data
    };
    if (!addNew) {
      result.id = admin.id;
    }

    onConfirm(result);
  };

  const { t } = useTranslation();

  return (
    <Dialog open={true}>
      <Box sx={{ width: '540px', maxWidth: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{addNew ? t('common.addAdmin') : t('common.editAdmin')}</DialogTitle>
          <DialogContent>
            <FormGroup>
              <Stack spacing={2} sx={{ padding: '10px 0' }}>
                <TextField
                  {...register('name', {
                    required: true,
                    minLength: 3,
                    maxLength: 50
                  })}
                  name={'name'}
                  placeholder={'Name'}
                  label="Name"
                  error={errors.name ? true : false}
                  helperText={errors.email ? errors.email.message : ''}
                />
                <TextField
                  {...register('email', {
                    required: true,
                    validate: validate.email
                  })}
                  placeholder={'Email'}
                  label="Email"
                  name="email"
                  error={errors.email ? true : false}
                  helperText={errors.email ? errors.email.message : ''}
                />
                <Typography variant={'h6'}>Roller</Typography>
                <RadioGroup defaultValue={defaultValues.role} name="role">
                  {adminRoles.map((adminRole, index) => {
                    return (
                      <FormControlLabel
                        {...register('role')}
                        key={index}
                        control={<Radio />}
                        label={t('role.' + adminRole).toString()}
                        value={adminRole}
                      />
                    );
                  })}
                </RadioGroup>
              </Stack>
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onCancel()}>{t('common.cancel')}</Button>
            <Button type={'submit'}>{t('common.confirmChanges')}</Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default EditAdminModal;
