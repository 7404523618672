import { UserSubscriptionStatus } from 'types/UserSubscription';

const getUserSubscriptionStatusColor = (userSubscriptionStatus: UserSubscriptionStatus) => {
  let color: 'primary' | 'secondary' | 'success' | 'error' | 'default' | 'info';
  switch (userSubscriptionStatus) {
    case 'ACTIVE':
      color = 'success';
      break;
    case 'CANCELED':
    case 'TO_BE_CANCELED':
      color = 'default';
      break;
    case 'PAYMENT_FAILED':
      color = 'error';
      break;
    case 'AWAITING_PAYMENT':
      color = 'info';
      break;
    default:
      color = 'default';
      break;
  }

  return color;
};

export default getUserSubscriptionStatusColor;
