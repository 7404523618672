import { Box, CircularProgress, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PaperCard, PaperContent, PaperHeader, TabPanel } from 'components';
import Subscription from 'types/Subscription';
import SubscriptionDetails from '../SubscriptionDetails/SubscriptionDetails';
import Campaigns from '../Campaigns/Campaigns';
import { useLocation, useNavigate, useParams } from 'react-router';
import { cloneDeep } from 'lodash';
import subscriptionService from 'services/subscription-service';
import useAlert from 'hooks/useAlert';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import routes from 'consts/routes';
import settingService from 'services/setting-service';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';

type SubscriptionState = {
  subscription: Subscription;
};

const SubscriptionTabs: FC = () => {
  const { t } = useTranslation();

  const param = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosAlert = useAxiosAlert();
  const { subscription: subscriptionState } = (location.state as SubscriptionState) || {
    subscription: null
  };
  const [subscription, setSubscription] = useState<Subscription>(
    subscriptionState ? cloneDeep(subscriptionState) : null
  );
  const [myPageUrl, setMyPageUrl] = useState(null);
  const getMyPageUrl = async () => {
    try {
      const myPageUrlQuery = await settingService.getByKey('MyPageUrl');
      setMyPageUrl(myPageUrlQuery.data.value);
    } catch (error) {
      // Alert Axios error
      axiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    }
  };
  const { addAlert } = useAlert();

  const [tab, setTab] = useState(0);
  const getSubscription = async () => {
    try {
      const idAsNumber = parseInt(param.id, 10);
      if (isNaN(idAsNumber)) {
        addAlert('error', 'invalid id');
        return;
      }
      const subscriptionQuery = await subscriptionService.getById(idAsNumber);
      setSubscription(subscriptionQuery.data);
      getMyPageUrl();
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    }
  };

  useEffect(() => {
    if (!subscription) {
      getSubscription();
    } else {
      getMyPageUrl();
    }
  }, []);

  return (
    <PageContainer>
      <PaperCard sx={{ minWidth: '800px' }}>
        {!subscription ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress sx={{ margin: '10px 0' }} />
          </div>
        ) : (
          <>
            <PaperHeader sx={{ p: 0 }}>
              <Stack p={2} direction={'row'} gap={2}>
                <IconButton sx={{ padding: 0 }} onClick={() => navigate(routes.SUBSCRIPTIONS.path)}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <Box>
                  <Typography variant="h5">{`${subscription.name}`}</Typography>
                </Box>
              </Stack>
              <Tabs
                value={tab}
                onChange={(e, tabValue) => setTab(tabValue)}
                aria-label="basic tabs example"
              >
                <Tab label={t('common.subscription')} />
                <Tab label={t('common.campaigns')} />
              </Tabs>
            </PaperHeader>
            <PaperContent np>
              <TabPanel value={tab} index={0} sx={{ p: '15px 0' }}>
                <SubscriptionDetails
                  onEdit={setSubscription}
                  subscription={subscription}
                  myPageUrl={myPageUrl}
                />
              </TabPanel>
              <TabPanel value={tab} index={1} sx={{ p: '15px 0' }}>
                <Campaigns subscriptionId={subscription.id} myPageUrl={myPageUrl} />
              </TabPanel>
            </PaperContent>
          </>
        )}
      </PaperCard>
    </PageContainer>
  );
};

export default SubscriptionTabs;
