import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type ReportOptionMonthProps = {
  value: number;
  year: number;
  label: string;
  onChange: (value: number) => void;
};

const ReportOptionMonth: FC<ReportOptionMonthProps> = ({ value, year, label, onChange }) => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const hideMonths = year === currentYear;
  const { t } = useTranslation();
  let months = [
    { val: 0, label: t('month.jan') },
    { val: 1, label: t('month.feb') },
    { val: 2, label: t('month.mar') },
    { val: 3, label: t('month.apr') },
    { val: 4, label: t('month.may') },
    { val: 5, label: t('month.jun') },
    { val: 6, label: t('month.jul') },
    { val: 7, label: t('month.aug') },
    { val: 8, label: t('month.sep') },
    { val: 9, label: t('month.oct') },
    { val: 10, label: t('month.nov') },
    { val: 11, label: t('month.dec') }
  ];
  
  if (hideMonths) {
    months = months.filter((m) => m.val <= currentMonth);
  }

  if (!months.find((m) => m.val === value)) {
    onChange(months[months.length - 1].val);
  }

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select value={value} label={label} onChange={(e) => onChange(+e.target.value)}>
        {months.map((m) => (
          <MenuItem key={m.val} value={m.val}>
            {m.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReportOptionMonth;
