const role = {
  HOST: 0,
  ADMINISTRATOR: 1,
  EDITOR: 2,
};

export const getRoleName = (role: number) => {
  let roleName = '';
  switch (role) {
    case 0:
      roleName = 'Host';
      break;
    case 1:
      roleName = 'Administrator';
      break;
    case 2:
      roleName = 'Editor';
      break;
    default:
      break;
  }

  return roleName;
};

export default role;
