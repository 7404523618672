import axios from 'axios';
import Admin from 'types/Admin';

const authService = {
  signIn: (email: string, password: string) => {
    return axios.post('admin-auth/login', {
      email: email,
      password: password
    });
  },
  getAdminInfo: () => {    
    // Add no cache header to prevent caching from 410 response
    return axios.get<Admin>('admin-auth/admin-info', { headers: { 'Cache-Control': 'no-cache' } });
  },
  updateAdmin: (admin: Admin) => {
    return axios.put<Admin>('admin-auth/admin-info', { admin: admin });
  },
  changeEmail: (email: string, password: string) => {
    return axios.post('admin-auth/change-email', {
      email,
      password
    });
  },
  forgotPassword: (email: string) => {
    return axios.post('admin-auth/forgot-password', {
      email: email
    });
  },
  changePassword: (newPassword: string, oldPassword: string) => {
    return axios.post('admin-auth/change-password', {
      newPassword: newPassword,
      oldPassword: oldPassword
    });
  },
  setNewPassword: (newPassword: string, token: string) => {
    return axios.post('admin-auth/reset-password', {
      newPassword: newPassword,
      token: token
    });
  }
};

export default authService;
