import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import EditionList from 'components/EdtitionList/EditionList';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperFooter from 'components/PaperFooter/PaperFooter';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import { ROUTE_PATHS } from 'consts/routes';
import EditionModal from 'modals/EditionModal/EditionModal';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import editionService from 'services/edition-service';
import Edition from 'types/Edition';
import EditionLocationState from 'types/EdtitionLocationState';

const EditionsOverview: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>();
  const [modal, setModal] = useState<number>();
  const [editions, setEditions] = useState<Edition[]>([]);

  const getEditions = async () => {
    setLoading(true);
    try {
      const { data: editionsData } = await editionService.getLatest();
      setEditions(editionsData);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEditions();
  }, []);

  return (
    <>
      <PaperCard sx={{ minWidth: '350px' }}>
        <PaperHeader>
          <Typography variant={'h5'}>{t('edition.*')}</Typography>
        </PaperHeader>
        <PaperContent sx={{ p: 0 }}>
          {!loading && editions.length > 0 && (
            <EditionList
              editions={editions}
              onEdit={(edition) =>
                navigate(ROUTE_PATHS.EDITION.replace(':id', edition.id.toString()), {
                  state: { edition } as EditionLocationState
                })
              }
            />
          )}
          {!loading && editions.length === 0 && (
            <Stack textAlign={'center'} p={2}>
              <Typography>{t('edition.errors.noEditions')}</Typography>
            </Stack>
          )}
          {loading && (
            <Stack direction={'row'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>
          )}
        </PaperContent>
        <PaperFooter>
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <Button
              onClick={() => {
                setModal(0);
              }}
            >
              {t('edition.add')}
            </Button>
          </Stack>
        </PaperFooter>
      </PaperCard>
      {modal === 0 && (
        <EditionModal
          onCancel={() => setModal(null)}
          onFinished={() => {
            getEditions();
            setModal(null);
          }}
        />
      )}
    </>
  );
};

export default EditionsOverview;
