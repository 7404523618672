import { PageContainer } from 'components';
import { Outlet } from 'react-router';

const NotificationContainer = () => {
  return (
    <PageContainer>
      <Outlet />
    </PageContainer>
  );
};

export default NotificationContainer;
