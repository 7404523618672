import StandardReport from 'types/StandardReport';
import jsPDF from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { t } from 'i18next';
import dateString from 'utils/_dateString';

const pdfService = {
  exportStandardReport: (
    report: StandardReport,
    title: string,
    fileName: string,
    organization: string
  ) => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size);
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();

    const content: UserOptions = {
      startY: 70,
      theme: 'striped',
      bodyStyles: { textColor: [0, 0, 0] },
      headStyles: { fillColor: [39, 48, 54], textColor: [255, 255, 255] },
      footStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontStyle: 'bold',
        lineWidth: { top: 0.5, bottom: 0.5 },
        lineColor: [39, 48, 54]
      },
      head: [report.header.map((s) => t(s).toString())],
      body: report.rows,
      foot: [report.footer]
    };
    // make text italic
    doc.setFont('helvetica', 'italic');


    doc.setFontSize(18);
    doc.text(title, 40, 30);
    doc.setFontSize(12);
    doc.text(organization, 40, 50);

    doc.setFontSize(10);
    autoTable(doc, content);
    doc.setFont('helvetica', 'italic').setFontSize(8).setTextColor(100);
    doc.text(
      t('common.exportedFrom') + ' Newsroom Subscription ' + dateString(new Date()),
      width - 20,
      height - 20,
      { align: 'right' }
    );
    doc.save(fileName);
  }
};

export default pdfService;
