/* eslint-disable indent */
import { RemoveCircle } from '@mui/icons-material';
import { Stack, Typography, TextField, InputAdornment, Button, Chip } from '@mui/material';
import { PaperCard, PaperContent } from 'components';
import { FC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ManageBulkDistributionRoutesProps = {
  distributionRouteIds: string[];
  onAddDistributionRouteId: (distributionRouteId: string) => void;
  onRemoveDistributionRouteId: (distributionRouteId: string) => void;
};

const ManageBulkDistributionRoutes: FC<ManageBulkDistributionRoutesProps> = ({
  distributionRouteIds,
  onAddDistributionRouteId,
  onRemoveDistributionRouteId
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<{
    distributionRouteId: string;
  }>();

  const inputRef = useRef<HTMLInputElement>();

  const onSubmit = async (data: { distributionRouteId: string }) => {
    onAddDistributionRouteId(data.distributionRouteId);
    setValue('distributionRouteId', null);
    if (inputRef.current) {
      console.log('defined');
      inputRef.current.blur();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <Typography variant={'subtitle1'}>{t('common.currierRoutes')}</Typography>
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <TextField
            ref={inputRef}
            label={t('common.addBlank', {
              blank: t('common.currierRoute')
            })}
            {...register('distributionRouteId', {
              required: true,
              minLength: 1,
              maxLength: 5,
              validate: (value) => {
                return !distributionRouteIds.includes(value);
              }
            })}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Button type={'submit'}>{t('common.add')}</Button>
                </InputAdornment>
              )
            }}
            error={!!errors.distributionRouteId}
          />
        </Stack>
        <PaperCard
          variant='outlined'
          sx={{
            width: '300px'
          }}
        >
          <PaperContent>
            <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
              {distributionRouteIds &&
                distributionRouteIds.map((distributionRouteId, index) => (
                  <Chip
                    key={index}
                    label={distributionRouteId}
                    deleteIcon={<RemoveCircle color={'error'} />}
                    onDelete={() => {
                      onRemoveDistributionRouteId(distributionRouteId);
                    }}
                  />
                ))}
              {(!distributionRouteIds || distributionRouteIds?.length === 0) && (
                <Typography>{t('common.noCurrierRoutes')}</Typography>
              )}
            </Stack>
          </PaperContent>
        </PaperCard>
      </Stack>
    </form>
  );
};

export default ManageBulkDistributionRoutes;
