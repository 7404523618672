/* eslint-disable indent */
/* eslint-disable max-lines */
import { Alert, Button, CircularProgress, Divider } from '@mui/material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { PrimaryCard, PrimaryListItem } from 'components';
import { FC, useEffect, useState } from 'react';
import UserDistribution from 'types/UserDistribution';
import EditUserDistributionHeader from './components/EditUserDistributionHeader';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import DistributionMessageModal from './components/DistributionMessageModal';
import { useUserContext } from 'contexts/UserContext';
import EditNumberModal from 'components/EditNumberModal/EditNumberModal';
import userDistributionService from 'services/user-distribution-service';
import useAlert from 'hooks/useAlert';
import EditCheckboxModal from 'components/EditCheckboxModal/EditChecboxModal';
import User from 'types/User';
import DistributionRoute from 'types/DistributionRoute';
import distributionRouteService from 'services/distribution-route-service';
import UserSubscription from 'types/UserSubscription';
import EditUserDistributionRoute from './components/EditUserDistributionRoute';
import EditHoldPaper from './components/EditHoldPaper';
import UserDistributionMetadataView from './components/UserDistributionMetadataView';
import PostenDistributionRoute from 'types/PostenDistributionRoute';
import logger from 'logger/logger';
import { dateString } from 'utils';

type EditUserDistributionProps = {
  userDistribution: UserDistribution;
  activeUserSubscription: UserSubscription;
  onUpdate: (user: User) => void;
};

type EditUserDistributionModals =
  | 'editNumberOfPapers'
  | 'editDistributionNamed'
  | 'editDistributionMessage';

const EditUserDistribution: FC<EditUserDistributionProps> = ({
  userDistribution: initialUserDistribution,
  activeUserSubscription,
  onUpdate
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<EditUserDistributionModals>(null);
  const [userDistribution, setUserDistribution] = useState<UserDistribution>(
    userDistributionService.parseDates(initialUserDistribution)
  );
  const [distributionRoute, setDistributionRoute] = useState<DistributionRoute>();
  const [loading, setLoading] = useState<boolean>();
  const [postenDistributionRoute, setPostenDistributionRoute] = useState<PostenDistributionRoute>();
  const { user } = useUserContext();
  const { addAlert } = useAlert();

  const updateUserDistribution = async (_userDistribution: UserDistribution) => {
    setLoading(true);
    try {
      const updatedUserDistribution = await userDistributionService.update(
        _userDistribution.id,
        _userDistribution
      );
      setUserDistribution(userDistributionService.parseDates(updatedUserDistribution.data));
      onUpdate({
        ...user,
        userDistribution: updatedUserDistribution.data
      });

      if (updatedUserDistribution.data.routeId) {
        const { data: distributionRouteData } = await distributionRouteService.get(
          updatedUserDistribution.data.routeId
        );
        setDistributionRoute(distributionRouteData);
      }

      addAlert('success', t('common.updated'));
      setLoading(false);
    } catch (error) {
      addAlert('error', t('alert.somethingWentWrong'));
      logger.error(error);
    }
  };

  const createUserDistribution = async () => {
    try {
      const { data: userDistribution } = await userDistributionService.create(user.id);
      setUserDistribution(userDistributionService.parseDates(userDistribution));
      onUpdate({
        ...user,
        userDistribution
      });
      addAlert('success', t('common.created'));
    } catch (error) {
      addAlert('error', t('alert.somethingWentWrong'));
      logger.error(error);
    }
  };

  const getNamedText = (): string => {
    if (distributionRoute?.named) {
      return t('userDistribution.namedRoute');
    }
    if (userDistribution?.distributionNamed === true && distributionRoute?.named === false) {
      return t('userDistribution.overwrittenNamed');
    }
    if (userDistribution?.distributionNamed) {
      return t('userDistribution.named');
    }
    return t('userDistribution.unamed');
  };

  const getDistributionRoute = async () => {
    if (userDistribution?.routeId)
      try {
        setLoading(true);
        const { data: distributionRouteData } = await distributionRouteService.get(
          userDistribution.routeId
        );
        setDistributionRoute(distributionRouteData);
        setLoading(false);
      } catch (error) {
        addAlert('error', t('alert.somethingWentWrong'));
        logger.error(error);
      }
  };

  const getPostRouteDistribution = async () => {
    try {
      const { data: _postenDistributionRoute } =
        await userDistributionService.getPostenDistributionRoute(userDistribution.id);
      setPostenDistributionRoute(_postenDistributionRoute);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDistributionRoute();
    getPostRouteDistribution();
  }, []);
  return (
    <div style={{ maxWidth: '1000px' }}>
      <Stack gap={2}>
        <PrimaryCard sx={{ p: 0, width: '100%' }}>
          {userDistribution && !loading && (
            <>
              <Stack gap={2}>
                {!!user && !activeUserSubscription?.subscription?.paper && (
                  <Alert severity='info'>{t('userDistribution.noPaperSubscriptionWarning')}</Alert>
                )}
                <EditUserDistributionHeader />
              </Stack>
              {!user?.userAddress && <Alert severity='info'>{t('alerts.userNoAddress')}</Alert>}
              <EditUserDistributionRoute
                user={user}
                onUpdate={updateUserDistribution}
                distributionRoute={distributionRoute}
              />
              <PrimaryListItem
                label={t('common.postRoute')}
                value={
                  user?.userAddress ? (
                    postenDistributionRoute === null ? (
                      <Alert severity='warning'>
                        {t('alerts.noPostenRoutesOnPostCode', {
                          postCode: user?.userAddress?.postCode
                        })}
                      </Alert>
                    ) : (
                      <Typography>{postenDistributionRoute?.name}</Typography>
                    )
                  ) : (
                    '--'
                  )
                }
              />
              <PrimaryListItem
                label={t('common.postInfo')}
                value={
                  user?.userAddress
                    ? `${user?.userAddress.postCode} ${user?.userAddress.postOffice}`
                    : '--'
                }
              />
              <PrimaryListItem
                label={t('userDistribution.numberOfPapers')}
                value={userDistribution.numberOfPapers ?? '--'}
                actions={
                  <IconButton onClick={() => setModal('editNumberOfPapers')}>
                    <Edit />
                  </IconButton>
                }
              />
              <PrimaryListItem
                label={t('userDistribution.named')}
                value={getNamedText()}
                actions={
                  <Tooltip
                    title={
                      distributionRoute?.named ? t('userDistribution.namedDisabledTooltip') : ''
                    }
                  >
                    <span>
                      <IconButton
                        disabled={!!distributionRoute?.named}
                        onClick={() => setModal('editDistributionNamed')}
                      >
                        <Edit />
                      </IconButton>
                    </span>
                  </Tooltip>
                }
              />
              <EditHoldPaper
                userDistribution={userDistribution}
                onUpdate={updateUserDistribution}
              />

              <PrimaryListItem
                label={t('userDistribution.distributionMessage')}
                actions={
                  <IconButton onClick={() => setModal('editDistributionMessage')}>
                    <Edit />
                  </IconButton>
                }
                last
              />
              <Stack gap={2}>
                <Stack direction={'row'} gap={2}>
                  <Typography>{dateString(userDistribution?.distributionMessageDate)}</Typography>
                  <Typography>{userDistribution?.distributionMessageCode}</Typography>
                </Stack>
                <Typography
                  sx={{
                    minHeight: '70px',
                    padding: '10px',
                    backgroundColor: 'rgba(255,255,255,0.05)',
                    color: 'rgba(255,255,255,0.8)',
                    whiteSpace: 'pre'
                  }}
                >
                  {userDistribution.distributionMessage}
                </Typography>
              </Stack>
              {userDistribution.metadata && (
                <>
                  <UserDistributionMetadataView metadata={userDistribution.metadata} />
                  <Divider sx={{ margin: '10px 0' }} />
                </>
              )}
            </>
          )}
          {!userDistribution && !loading && (
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <Typography>{t('userDistribution.notAdded')}</Typography>
              <Button variant='contained' onClick={createUserDistribution}>
                {t('userDistribution.create')}
              </Button>
            </Stack>
          )}
          {loading && (
            <Stack sx={{ p: 2 }} direction={'row'} justifyContent='center'>
              <CircularProgress />
            </Stack>
          )}
        </PrimaryCard>
      </Stack>
      {modal === 'editNumberOfPapers' && (
        <EditNumberModal
          onCancel={() => setModal(null)}
          onConfirm={(numberOfPapers: number) => {
            setModal(null);
            updateUserDistribution({ ...userDistribution, numberOfPapers });
          }}
          label={t('userDistribution.numberOfPapers')}
          title={t('common.changeBlank', {
            blank: t('userDistribution.numberOfPapers')
          })}
          value={userDistribution.numberOfPapers ?? 0}
        />
      )}
      {modal === 'editDistributionNamed' && (
        <EditCheckboxModal
          onCancel={() => setModal(null)}
          onConfirm={(named: boolean) => {
            setModal(null);
            updateUserDistribution({ ...userDistribution, distributionNamed: named });
          }}
          label={t('userDistribution.named')}
          title={t('common.changeBlank', { blank: t('userDistribution.named') })}
          value={userDistribution.distributionNamed ?? false}
        />
      )}

      {modal === 'editDistributionMessage' && (
        <DistributionMessageModal
          userDistribution={userDistribution}
          onCancel={() => setModal(null)}
          onEdit={(updatedUserDistribution: UserDistribution) => {
            updateUserDistribution(updatedUserDistribution);
            setModal(null);
          }}
        />
      )}
    </div>
  );
};

export default EditUserDistribution;
