import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SwipeableDrawer
} from '@mui/material';
import PrimaryFooter from 'components/PrimaryFooter/PrimaryFooter';
import role from 'consts/role';
import routes from 'consts/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'store';

import './styles.scss';
import NavItem from 'types/NavItem';
import createNavItems from './createNavItems';
import useSettings from 'hooks/useSettings';

type NavDrawerProps = {
  onDrawerOpen: () => void;
  onDrawerClose: () => void;
  displayDrawer: boolean;
};

const NavDrawer: FC<NavDrawerProps> = ({ onDrawerClose, onDrawerOpen, displayDrawer }) => {
  const isMobile = window.innerWidth < 768;
  const admin = useSelector((state: RootState) => state.admin);
  const navigate = useNavigate();
  const { getValueByKeyAsBoolean } = useSettings();
  const nrsPaper = getValueByKeyAsBoolean('NrsPaper', 0);
  const paymentSolutionInvoice = getValueByKeyAsBoolean('PaymentSolutionInvoice', 0);

  const { t } = useTranslation();

  const listItemSelected = (path: string, pathname: string) => {
    const emptyPath = path === '/';

    if (emptyPath && path === pathname) {
      return true;
    }

    if (pathname.includes(path) && !emptyPath) {
      return true;
    }
  };

  const navItems = createNavItems(routes, nrsPaper, paymentSolutionInvoice);

  const createNavItemList = (list: NavItem[]) => {
    return list
      .filter((navItem: NavItem) => {
        if (!navItem?.roles) {
          return true;
        } else {
          return navItem.roles.includes(admin.role);
        }
      })
      .map((navItem: NavItem, index: number) => (
        <ListItemButton
          key={index}
          selected={listItemSelected(navItem.path, location.pathname)}
          onClick={() => navigate(navItem.path)}
        >
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <ListItemIcon sx={{ minWidth: '0px' }}>{navItem.icon}</ListItemIcon>
            <ListItemText primary={t(navItem.title)} />
          </Stack>
        </ListItemButton>
      ));
  };

  const privateRoutes = createNavItemList(navItems);

  const drawer = (
    <List>
      {[role.HOST, role.ADMINISTRATOR, role.EDITOR].includes(admin.role) && <>{privateRoutes}</>}
    </List>
  );

  const DesktopDrawer = (
    <Drawer // Tablet/desktop drawer
      className={'drawer'}
      classes={{
        paper: 'drawer-paper'
      }}
      variant="permanent"
      open
    >
      <div className={'drawer-container'}>
        <div className={'toolbar'}></div>
        {drawer}
        <PrimaryFooter />
      </div>
    </Drawer>
  );

  const MobileDrawer = (
    <SwipeableDrawer // Mobile drawer
      className={'drawer'}
      variant="temporary"
      anchor={'left'}
      open={displayDrawer}
      onClose={onDrawerClose}
      onOpen={onDrawerOpen}
      classes={{
        paper: 'drawer-paper'
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
    >
      <div className={'drawer-container'}>{drawer}</div>
    </SwipeableDrawer>
  );

  const DrawerComponent = isMobile ? MobileDrawer : DesktopDrawer;

  return <>{DrawerComponent}</>;
};

export default NavDrawer;
