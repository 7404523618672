type PaymentSolution =
  | 'vipps'
  | 'invoice'
  | 'swedbank'
  | 'none'
  | 'migration'
  | 'external'
  | 'gift';

export default PaymentSolution;

export const paymentSolutions: PaymentSolution[] = [
  'vipps',
  'invoice',
  'swedbank',
  'none',
  'migration',
  'external',
  'gift'
];
