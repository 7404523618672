const getNameOfDay = (date: Date, variant: 'long' | 'short' = 'long'): string => {
  const options = { weekday: variant };
  const userLocale = navigator.language;
  const nameOfDay = new Date(date).toLocaleDateString(userLocale, options);

  // First letter to uppercase
  return nameOfDay.charAt(0).toUpperCase() + nameOfDay.slice(1);
};

export default getNameOfDay;
