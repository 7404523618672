import {  CircularProgress, IconButton, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { PaperCard, PaperContent, PaperFooter, PaperHeader } from 'components';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dataCheckService from 'services/data-check.service';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import COLORS from 'consts/colors';
import InspectInvalidPaymentsModal from './InspectInvalidPaymentsModal';

type InvalidPaymentsModal = 'InspectPayments';

type InvalidPaymentsProps = {
  updated: Date;
};

export const InvalidPayments:FC<InvalidPaymentsProps> = ({updated}) => {
  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();
  const [modal, setModal] = useState<InvalidPaymentsModal>(null);
  const [payments, setPayments] = useState(null);

  const getInvalidPayments = async () => {
    try {
      const paymentsQuery = await dataCheckService.getInvalidPayments();
      setPayments(paymentsQuery.data);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    }
  };

  useEffect(() => {
    if(payments !== null) {
      setPayments(null);
    }
    getInvalidPayments();
  }, [updated]);
  return (
    <PaperCard sx={{minWidth: '250px'}}>
      <PaperHeader>
        <Typography variant={'h6'}>{t('dataCheck.invalidPayments')}</Typography>
      </PaperHeader>
      <PaperContent>
        <div style={{ textAlign: 'center' }}>
          {payments ? (
            payments.length > 0 ? (
              <>
                <Typography sx={{ fontSize: '5rem' }} color={'error'}>
                  {payments.length}
                </Typography>
                <IconButton onClick={() => setModal('InspectPayments')} color='error'>
                  <TroubleshootIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Typography sx={{ fontSize: '5rem', color: COLORS.SUCCESS }}>
                  {payments.length}
                </Typography>
                <TaskAltIcon sx={{ margin: '6px' }} color={'success'} />
              </>
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </PaperContent>
      <PaperFooter>
        {payments ? (
          payments.length > 0 ? (
            <>
              <Typography color='error' fontStyle={'italic'}>
                {t('dataCheck.invalidDataFound')}
              </Typography>
            </>
          ) : (
            <Typography sx={{ color: COLORS.SUCCESS }}>{t('dataCheck.allGood')}</Typography>
          )
        ) : (
          <CircularProgress />
        )}
      </PaperFooter>
      {modal === 'InspectPayments' && (
        <InspectInvalidPaymentsModal
          payments={payments}
          exit={() => setModal(null)}
        />
      )}
    </PaperCard>
  );
};
