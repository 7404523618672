import './ChangePassword.scss';
import axios, { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { validate } from 'utils';
import authService from 'services/admin-auth-service';
import routes from 'consts/routes';
import useAuth from 'hooks/useAuth';
import { PaperCard, PaperContent, PaperHeader } from 'components';
import logger from 'logger/logger';

type ChangePasswordProps = {
    token: string;
};

type FormValues = {
    password: string;
    repeatPassword: string;
};

type View = {
    loading?: boolean;
    form?: boolean;
    success?: boolean;
    errorMessage?: string;
    error?: boolean;
};

const ChangePassword: FC = () => {
  const { token } = useParams<ChangePasswordProps>();
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const  navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormValues>();

  const [view, setView] = useState<View>({
    loading: false,
    form: true,
    success: false,
    errorMessage: null,
    error: false,
  });

  const updateView = (updatedView: View) => {
    setView({
      ...{
        loading: false,
        form: false,
        success: false,
        errorMessage: null,
        error: false,
      },
      ...updatedView,
    });
  };

  const onSubmit: SubmitHandler<FormValues> = async (fieldData) => {
    updateView({
      loading: true,
    });
    try {
      await authService.setNewPassword(fieldData.password, token);
      updateView({
        success: true,
      });
    } catch (error) {
      let message = 'Client error';

      logger.error('SetNewPassword failed: ', error);

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;
        if (axiosError.response && axiosError.response.status === 401) {
          message = t('alerts.invalidToken');
        } else {
          message = axiosError.message;
        }
      }

      updateView({
        error: true,
        errorMessage: message,
      });
    }
  };

  const passwordsMustMatch = (repeatPassword: string) => {
    const password = getValues('password');
    if (password !== repeatPassword) {
      return t('password.passwordsMustMatch');
    }
    return true;
  };

  const signOutAndGoToLogin = async () => {
    signOut();
    navigate(routes.LOGIN.path);
  };

  return (
    <div className='order-evaluation-page'>
      <PaperCard sx={{ maxWidth: '400px;', margin: '0 auto' }}>
        <PaperHeader>
          <Typography variant={'h6'}>
            {t('password.setNewPassword')}
          </Typography>
        </PaperHeader>
        <PaperContent>
          {view.form && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <TextField
                  label={t('password.newPassword')}
                  type={'password'}
                  {...register('password', {
                    validate: validate.password,
                  })}
                  error={errors.password ? true : false}
                  helperText={
                    errors.password
                      ? errors.password.message
                      : ''
                  }
                />
                <TextField
                  label={t('password.repeatPassword')}
                  type={'password'}
                  {...register('repeatPassword', {
                    validate: passwordsMustMatch,
                  })}
                  error={errors.repeatPassword ? true : false}
                  helperText={
                    errors.repeatPassword
                      ? errors.repeatPassword.message
                      : ''
                  }
                />
                <Button type='submit' variant='contained'>
                  {t('password.setNewPassword')}
                </Button>
              </Stack>
            </form>
          )}
          {view.error && (
            <>
              <Alert severity='error'>{view.errorMessage}</Alert>
              <Button
                sx={{ marginTop: '10px' }}
                onClick={signOutAndGoToLogin}
                variant='contained'
              >
                {t('common.goToLogin')}
              </Button>
            </>
          )}
          {view.success && (
            <>
              <Alert severity='success'>
                <Typography>
                  {t('alerts.passwordWasSuccessfullyChanged')}
                </Typography>
                <Button
                  onClick={signOutAndGoToLogin}
                  variant='contained'
                >
                  {t('common.goToLogin')}
                </Button>
              </Alert>
            </>
          )}
          {view.loading && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress sx={{ margin: '10px 0' }} />
            </div>
          )}
        </PaperContent>
      </PaperCard>
    </div>
  );
};

export default ChangePassword;
