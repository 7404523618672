import { Tab, Tabs } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SettingCategory, { settingCategories } from 'types/SettingCategories';

type SettingCategoryTabsProps = {
  category: SettingCategory;
  onCategoryChange: (category: SettingCategory) => void;
};

const SettingCategoryTabs: FC<SettingCategoryTabsProps> = ({ category, onCategoryChange }) => {
  const { t } = useTranslation();

  const settingCategoryTabs = settingCategories.map((category, index) => {
    return <Tab key={index} label={t(`setting.categories.${category}`)} value={category} />;
  });

  return (
    <Tabs value={category} onChange={(e, v) => onCategoryChange(v)}>
      {settingCategoryTabs}
    </Tabs>
  );
};

export default SettingCategoryTabs;
