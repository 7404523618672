import { FC } from 'react';

const ErrorSvg: FC = () => {
  const cls6 = '#afe0e8';
  const cls1 = '#eaeaea';
  const cls2 = '#00353d';
  const cls3 = '#194e4f';
  const cls4 = '#d9f4f2';
  const cls5 = '#67acbb';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 1900">
      <path
        stroke="0"
        fill={cls6}
        d="M378.2,726.03v-.24s-.02.08-.03.12c-.01-.04-.02-.08-.03-.12v.24c-3.14,11.39-10.4,19.54-21.78,24.44,11.38,4.9,18.64,13.05,21.78,24.44v.24s.02-.08.03-.12c.01.04.02.08.03.12v-.24c3.14-11.39,10.4-19.54,21.78-24.44-11.38-4.9-18.64-13.05-21.78-24.44Z"
      />
      <path
        stroke="0"
        fill={cls6}
        d="M1668.1,535.43v-.24s-.02.08-.03.12c-.01-.04-.02-.08-.03-.12v.24c-3.14,11.39-10.4,19.54-21.78,24.44,11.38,4.9,18.64,13.05,21.78,24.44v.24s.02-.08.03-.12c.01.04.02.08.03.12v-.24c3.14-11.39,10.4-19.54,21.78-24.44-11.38-4.9-18.64-13.05-21.78-24.44Z"
      />
      <path
        stroke="0"
        fill={cls6}
        d="M1386.36,349.13v-.24s-.02.08-.03.12c-.01-.04-.02-.08-.03-.12v.24c-3.14,11.39-10.4,19.54-21.78,24.44,11.38,4.9,18.64,13.05,21.78,24.44v.24s.02-.08.03-.12c.01.04.02.08.03.12v-.24c3.14-11.39,10.4-19.54,21.78-24.44-11.38-4.9-18.64-13.05-21.78-24.44Z"
      />
      <path
        stroke="0"
        fill={cls6}
        d="M721.74,244.39v-.24s-.02.08-.03.12c-.01-.04-.02-.08-.03-.12v.24c-3.14,11.39-10.4,19.54-21.78,24.44,11.38,4.9,18.64,13.05,21.78,24.44v.24s.02-.08.03-.12c.01.04.02.08.03.12v-.24c3.14-11.39,10.4-19.54,21.78-24.44-11.38-4.9-18.64-13.05-21.78-24.44Z"
      />
      <path
        stroke="0"
        fill={cls6}
        d="M1646.32,208.98v-.48c-.02.08-.05.16-.07.24-.02-.08-.05-.16-.07-.24v.48c-6.28,22.78-20.8,39.08-43.56,48.88,22.76,9.8,37.28,26.1,43.56,48.88v.48c.02-.08.05-.16.07-.24.02.08.05.16.07.24v-.48c6.28-22.78,20.8-39.08,43.56-48.88-22.76-9.8-37.28-26.1-43.56-48.88Z"
      />
      <path
        stroke="0"
        fill={cls6}
        d="M263.5,353.26v-.48c-.02.08-.05.16-.07.24-.02-.08-.05-.16-.07-.24v.48c-6.28,22.78-20.8,39.08-43.56,48.88,22.76,9.8,37.28,26.1,43.56,48.88v.48c.02-.08.05-.16.07-.24.02.08.05.16.07.24v-.48c6.28-22.78,20.8-39.08,43.56-48.88-22.76-9.8-37.28-26.1-43.56-48.88Z"
      />
      <g>
        <circle
          stroke="0"
          fill={cls6}
          cx="868.29"
          cy="585.42"
          r="142.15"
          transform="translate(-159.64 785.44) rotate(-45)"
        />
        <path
          stroke="0"
          fill={cls5}
          d="M653.09,711.91c-12.74,0-20.2-2.84-22.74-8.61-3.62-8.23,4.02-19.96,23.35-35.87,17.13-14.1,43.17-31.14,75.3-49.3,1.6-.91,3.64-.34,4.54,1.26.91,1.6.34,3.64-1.26,4.54-81.91,46.29-98.78,69.98-95.83,76.68,2.65,6.03,24.7,8.08,78.1-5.99,48.27-12.72,110.03-35.2,173.92-63.3,63.89-28.1,122.2-58.43,164.2-85.4,20.3-13.04,35.63-24.55,45.57-34.22,12.72-12.37,12.37-17.61,11.63-19.29-2.91-6.63-31.37-10.24-119.55,18.24-1.76.57-3.63-.4-4.2-2.15-.57-1.75.4-3.63,2.15-4.2,34.64-11.19,64.39-18.69,86.03-21.69,24.45-3.38,38.07-1.06,41.67,7.11,2.91,6.61-1.37,15.36-13.09,26.75-10.25,9.97-25.93,21.77-46.61,35.05-42.28,27.16-100.92,57.66-165.12,85.9-64.2,28.23-126.31,50.83-174.9,63.64-23.76,6.26-43.05,9.85-57.33,10.67-2.05.12-3.99.18-5.83.18Z"
        />
      </g>
      <g>
        <path
          stroke="0"
          fill={cls6}
          d="M1148.67,1758.41c30.06-65.46,46.83-138.3,46.83-215.05,0-284.92-230.97-515.89-515.89-515.89s-515.89,230.97-515.89,515.89c0,76.75,16.77,149.59,46.83,215.05h938.11Z"
        />
        <path
          stroke="0"
          fill={cls5}
          d="M1111.61,1261.27c-44.48,12.37-109.23,21.28-200.11,15.11-178.34-12.1-392.01-114.61-559.93-131.2-104.25,85.98-174.14,212.12-186.04,354.73,50.64-42.22,124.83-87.8,227.39-114.25,228.54-58.95,483.35,8.43,618.17,0,57.39-3.59,107.35-20.35,145.81-38.43-12.42-30.2-27.63-58.96-45.3-85.96Z"
        />
        <path
          stroke="0"
          fill={cls5}
          d="M1065.17,1591.49c-84.51-3.74-382.03-156.11-623.88-96.9-135.19,33.1-222.88,77.53-273.94,110.28,6.44,54.21,21.29,105.83,43.2,153.55h938.11c29.23-63.66,45.9-134.29,46.79-208.71-39.83,22.33-89.17,43.61-130.29,41.79Z"
        />
      </g>
      <circle
        stroke="0"
        fill={cls4}
        cx="378.16"
        cy="1056.65"
        r="114.73"
        transform="translate(-636.4 576.89) rotate(-45)"
      />
      <g>
        <path
          stroke="0"
          fill={cls5}
          d="M1588.26,1383.26l163.61-65.15c23.91-9.52,44.37-26.07,58.68-47.45l114.65-171.32c8.22-12.28,3.33-29.01-10.22-34.93l-93.95-41.04c-9.12-3.98-19.76-1.85-26.64,5.35l-78.5,82.12-113.55,18.16-14.1,254.26Z"
        />
        <path
          stroke="0"
          fill={cls5}
          d="M1188.92,1208.84l-63.39-164.3c-9.26-24.01-11.03-50.26-5.07-75.3l47.77-200.53c3.42-14.38,19.02-22.16,32.57-16.24l93.95,41.04c9.12,3.98,14.79,13.24,14.18,23.18l-6.9,113.39,63.84,95.64-176.95,183.13Z"
        />
        <path
          stroke="0"
          fill={cls2}
          d="M1464.44,761.76l264.57,115.56c14.6,6.38,20.12,24.25,11.64,37.75l-70.59,112.4-275.71-120.43,34.49-128.17c4.14-15.39,21-23.49,35.61-17.11Z"
        />
        <path
          stroke="0"
          fill={cls1}
          d="M1348.43,1758.41c15.48-14.93,31.49-31.6,48.31-50.17,123.07-135.89,259.15-392.83,299.38-669.38l-163.93-71.6-163.93-71.6c-175.54,217.45-271.56,491.88-287.62,674.52-6.86,78.02-5.51,137.62,6.51,188.24h261.27Z"
        />
        <circle stroke="0" fill={cls6} cx="1307.4" cy="1481.93" r="129.03" />
        <circle
          stroke="0"
          fill={cls2}
          cx="1307.4"
          cy="1481.93"
          r="82.05"
          transform="translate(-664.95 1358.52) rotate(-45)"
        />
        <path
          stroke="0"
          fill={cls5}
          d="M1557.89,908.44h0c13.75,6,20.02,22.02,14.02,35.77l-147.56,337.83c-6,13.75-22.02,20.02-35.76,14.02h0c-13.75-6-20.02-22.02-14.02-35.77l147.56-337.83c6-13.75,22.02-20.02,35.76-14.02Z"
        />
        <path
          stroke="0"
          fill={cls3}
          d="M1348.43,1758.41c4.83-4.66,9.72-9.49,14.66-14.5-78.02-76.58-173.29-117.69-285.81-123.33-2.32,54.21.59,98.7,9.88,137.83h261.27Z"
        />
      </g>
      <path
        stroke="0"
        fill={cls5}
        d="M1880.21,1761.75H109.13c-1.84,0-3.33-1.49-3.33-3.33s1.49-3.33,3.33-3.33h1771.08c1.84,0,3.33,1.49,3.33,3.33s-1.49,3.33-3.33,3.33Z"
      />
    </svg>
  );
};

export default ErrorSvg;
