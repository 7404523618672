import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fullDistributionService from 'services/full-distribution-service';
import DistributionRoute from 'types/DistributionRoute';

type FullDistributionRouteModalProps = {
  fullDistributionRoute: FullDistributionRoute;
  distributionRoutes: DistributionRoute[];
  onCancel: () => void;
  onFinished: (fullDistributionRoute: FullDistributionRoute) => void;
};

const FullDistributionRouteModal: FC<FullDistributionRouteModalProps> = ({
  fullDistributionRoute,
  distributionRoutes,
  onCancel,
  onFinished
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const axiosAlert = useAxiosAlert();

  const { register, handleSubmit } = useForm<FullDistributionRoute>({
    defaultValues: fullDistributionRoute ? { ...fullDistributionRoute } : null
  });

  const onSubmit = async (data: FullDistributionRoute) => {
    // Make sure numberOfPapers is a number
    const parsedData = {
      ...data,
      numberOfPapers: parseInt(data.numberOfPapers.toString())
    };

    setLoading(true);
    try {
      if (fullDistributionRoute) {
        await fullDistributionService.update({ ...parsedData, id: fullDistributionRoute.id });
        onFinished(parsedData);
      } else {
        await fullDistributionService.post(parsedData);
        onFinished(parsedData);
      }
    } catch (error) {
      console.error(error);
      axiosAlert(error as AxiosError, [
        {
          status: 409,
          message: t('alerts.fullDistributionRouteAlreadyExists')
        }
      ]);
    } finally {
      setLoading(false);
    }
  };

  const title = fullDistributionRoute
    ? t('common.edit')
    : t('common.add') + ' ' + t('common.fullDistribution');

  // Key labels for routes select
  const routesSelectLabels = distributionRoutes.map((route) => ({
    key: route.id,
    label: route.id
  }));

  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel required>{t('common.route')}</InputLabel>
              <Select
                label={t('common.route')}
                defaultValue={
                  fullDistributionRoute ? fullDistributionRoute.distributionRouteId : ''
                }
                fullWidth
                {...register('distributionRouteId', { required: true })}
                required
              >
                {routesSelectLabels.map((route) => (
                  <MenuItem key={route.key} value={route.key}>
                    {route.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              defaultValue={fullDistributionRoute ? fullDistributionRoute.numberOfPapers : 0}
              {...register('numberOfPapers')}
              label={t('common.numberOfPapers')}
              fullWidth
              required
              type={'number'}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          {loading && <CircularProgress />}
          {!loading && (
            <Button type={'submit'}>
              {fullDistributionRoute ? t('common.update') : t('common.add')}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FullDistributionRouteModal;
