import { Box, Menu, Typography } from '@mui/material';
import FilterButton from 'components/FilterButton/FilterButton';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type PrimarySearchFilter = {
  filterComponent?: ReactNode;
  numberOfActiveFilters?: number;
  isActive?: boolean;
  anchorEl?: null | HTMLElement;
  onClick: (clickedElement: null | HTMLElement) => void;
  onRemoveFilter?: () => void;
  title?: string;
};

const PrimarySearchFilter: FC<PrimarySearchFilter> = ({
  onClick,
  anchorEl,
  filterComponent,
  numberOfActiveFilters,
  isActive = false,
  onRemoveFilter,
  title
}) => {
  const { t } = useTranslation();

  const handleFilterButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick(event.currentTarget);
  };

  return (
    <Box>
      <FilterButton
        title={title}
        open={anchorEl ? true : false}
        onClick={handleFilterButtonClick}
        isActive={isActive}
        onRemoveFilter={onRemoveFilter}
        numberOfActiveFilters={numberOfActiveFilters}
      />
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          open={true}
          sx={{ paddingBottom: 0 }}
          onClose={() => onClick(null)}
        >
          <PaperHeader textAlign="left" divider={false} sx={{ paddingBottom: '5px' }}>
            <Typography variant="h5">{t('common.filters')}</Typography>
          </PaperHeader>
          <PaperContent sx={{ padding: '10px 20px' }}>{filterComponent}</PaperContent>
        </Menu>
      )}
    </Box>
  );
};

export default PrimarySearchFilter;

/* type FilterWrapperProps = {
  isActive: boolean;
  children: ReactNode;
};

const FilterWrapper: FC<FilterWrapperProps> = ({ isActive, children }) => {
  if (isActive) {
    return <Chip>{children}</Box>;
  } else {
    return <Box>{children}</Box>;
  }
};

export default PrimarySearchFilter;
 */
