import { Menu } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';
import useIsMobile from 'hooks/useIsMobile';
import theme, { logoWhite, logoIconWhite } from 'styles/theme';
import { Box } from '@mui/system';
import { SxProps } from '@mui/material';
import COLORS from 'consts/colors';

type HeaderProps = {
  actions?: ReactElement;
  title?: string;
  onDrawerClick?: () => void;
  disableDrawer?: boolean;
  displayDrawer?: boolean;
};

const PrimaryHeader: FC<HeaderProps> = ({
  title = '',
  actions,
  onDrawerClick,
  disableDrawer = false,
  displayDrawer = true
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const styles: { [k: string]: SxProps } = {
    headerTitle: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        marginRight: disableDrawer ? '180px' : 0
      }
    },
    headerActions: {
      position: 'absolute',
      height: '64px',
      display: 'flex',
      alignItems: 'center',
      right: '16px',
      [theme.breakpoints.up('sm')]: {
        right: '24px'
      }
    }
  };

  return (
    <header className={'app-bar'}>
      <AppBar
        position="fixed"
        sx={{
          borderRadius: 0,
          height: '64px',
          borderBottom: '1px solid rgba(255, 255, 255, 0.24)'
        }}
        className={'app-bar'}
      >
        <Toolbar
          className={'toolbar'}
          sx={{
            width: '100%',
            paddingLeft: 0,
            [theme.breakpoints.up('sm')]: {
              paddingLeft: 0
            }
          }}
        >
          <Box
            sx={{
              height: '100%',
              boxSizing: 'border-box',
              padding: '7px 7px 7px 7px',
              display: 'flex',
              justifyContent: 'center',
              background: COLORS.PRIMARY,
              marginRight: '16px',
              [theme.breakpoints.up('sm')]: {
                width: '180px'
              }
            }}
          >
            {displayDrawer && isMobile && (
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={onDrawerClick}
                className={'menu-button'}
              >
                <Menu />
              </IconButton>
            )}
            <Box
              sx={{
                height: 50,
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                [theme.breakpoints.up('sm')]: {
                  width: 'auto',
                  maxWidth: '100%'
                }
              }}
            >
              <img
                style={{ height: '100%', width: 'auto', maxWidth: '100%', cursor: 'pointer' }}
                src={isMobile ? logoIconWhite : logoWhite}
                onClick={() => {
                  window.open('https://newsroom.dyplink.no/', '_blank').focus();
                }}
              />
            </Box>
          </Box>
          <Box sx={styles.headerTitle}>
            <Typography className={'app-title'} variant="h6" noWrap>
              {title || capitalizeFirstLetter(t('common.appTitle'))}
            </Typography>
          </Box>
          <Box sx={styles.headerActions}>{actions}</Box>
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default PrimaryHeader;
