import { merge } from 'lodash';

interface Config {
  API_URL: string;
  NEWSPAPER_NAME: string;  
}

interface CustomWindow extends Window {
  // add you custom properties and methods
  CONFIG: Config;
}
declare const window: CustomWindow;

const defaults: Config = {
  API_URL: 'http://localhost:2000',
  NEWSPAPER_NAME: 'Dypnytt'
};

const config = merge<Config, Config>(defaults, window.CONFIG);

export default config;
