import { Edit } from '@mui/icons-material';
import { Stack, Typography, IconButton, Tooltip } from '@mui/material';
import { PrimaryListItem } from 'components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserDistribution, { UserDistributionRouteStatus } from 'types/UserDistribution';
import User from 'types/User';
import DistributionRouteSelectModal from 'components/DistributionRouteSelectModal/DistributionRouteSelectModal';
import DistributionRoute from 'types/DistributionRoute';

type EditUserDistributionRouteProps = {
  user: User;
  distributionRoute?: DistributionRoute;
  onUpdate: (user: UserDistribution) => void;
};
type EditUserDistributionRouteModal = 'editRoute' | 'editRouteStatus';

const EditUserDistributionRoute: FC<EditUserDistributionRouteProps> = ({
  user,
  onUpdate,
  distributionRoute
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<EditUserDistributionRouteModal>(null);

  const routeIdText =
    user.userDistribution?.routeId && distributionRoute
      ? user.userDistribution?.routeId +
        ', ' +
        (distributionRoute.named ? t('userDistribution.namedRoute') : t('userDistribution.unamedRoute'))
      : t('common.notAdded');

  return (
    <div>
      <PrimaryListItem
        label={t('distribution.route..')}
        sx={{ whiteSpace: 'pre-line', textAlign: 'right' }}
        value={
          <Stack direction={'row'} gap={2} alignItems={'center'}>
            <Typography>{routeIdText}</Typography>
          </Stack>
        }
        placeholder={t('common.notAdded')}
        actions={
          <Tooltip title={user?.userAddress ? '' : t('distribution.disabledTooltip')}>
            <span>
              <IconButton onClick={() => setModal('editRoute')} disabled={!user?.userAddress}>
                <Edit />
              </IconButton>
            </span>
          </Tooltip>
        }
      />
      {modal === 'editRoute' && user?.userAddress && (
        <DistributionRouteSelectModal
          id={user?.userDistribution?.routeId}
          onCancel={() => {
            setModal(null);
          }}
          onDelete={() => {
            setModal(null);
            onUpdate({ ...user?.userDistribution, routeId: null, routeStatus: null });
          }}
          username={user.name}
          userAddress={user?.userAddress}
          userDistribution={user?.userDistribution}
          onRouteSelected={(id: string, routeStatus: UserDistributionRouteStatus) => {
            setModal(null);
            onUpdate({ ...user?.userDistribution, routeId: id, routeStatus });
          }}
        />
      )}
    </div>
  );
};

export default EditUserDistributionRoute;
