import { FC } from 'react';
import FormDialog from '../FormDialog/FormDialog';
import ReactHookDatePicker from '../ReactHookDatePicker/ReactHookDatePicker';
import { useForm } from 'react-hook-form';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

type EditDateModalProps = {
  value?: Date;
  label?: string;
  title?: string;
  content?: string;
  validate?: (val: Date) => boolean;
  onDelete?: () => void;
  minDate?: Date;
  onCancel: () => void;
  submitText?: string;
  onSave: (date: Date) => void;
};

type EditDateFields = {
  date: Date;
};

const EditDateModal: FC<EditDateModalProps> = ({
  value,
  label,
  title,
  content,
  validate,
  onCancel,
  onSave,
  onDelete,
  submitText,
  minDate
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<EditDateFields>({
    defaultValues: {
      date: value
    }
  });

  const { t } = useTranslation();

  const currentDate = new Date();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const onSubmit = async (fields: EditDateFields) => {
    const { date } = fields;
    onSave(date);
  };

  const deleteButton = onDelete ? (
    <Button onClick={onDelete} color='error' sx={{ marginRight: 'auto' }}>
      {t('common.delete')}
    </Button>
  ) : null;

  let validation = validate;
  if (minDate) {
    validation = (dateValue: Date) => {
      if (dateValue < minDate) {
        return t('validation.dateTooEarly', { minDate: format(minDate, 'dd.MM.yyyy') });
      }
      return validate ? validate(dateValue) : true;
    };
  }

  return (
    <FormDialog
      title={title}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      submitText={submitText || t('common.save')}
      actions={deleteButton}
    >
      {content && (
        <Typography fontSize={'small'} sx={{ whiteSpace: 'pre-line', marginBottom: '20px' }}>
          {content}
        </Typography>
      )}

      <ReactHookDatePicker
        required={true}
        name={'date'}
        label={label}
        control={control}
        error={errors.date ? true : false}
        helperText={errors.date ? errors.date.message : ''}
        minDate={minDate}
        validate={validation}
      />
    </FormDialog>
  );
};

export default EditDateModal;
