import { useEffect, useState } from 'react';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const resizeW = () => setIsMobile(window.innerWidth < 768);

    window.addEventListener('resize', resizeW); // Update the width on resize

    return () => window.removeEventListener('resize', resizeW);
  });

  return isMobile;
};

export default useIsMobile;
