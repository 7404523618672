import { Delete, Edit } from '@mui/icons-material';
import {
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type FullDistributionRoutesProps = {
  fullDistributionRoutes: FullDistributionRoute[];
  onEdit: (fullDistributionRoute: FullDistributionRoute) => void;
  onDelete: (fullDistributionRoute: FullDistributionRoute) => void;
};

const FullDistributionRoutes: FC<FullDistributionRoutesProps> = ({
  fullDistributionRoutes = [],
  onEdit,
  onDelete
}) => {
  const { t } = useTranslation();

  return (
    <>
      {fullDistributionRoutes?.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('common.route')}</TableCell>
              <TableCell>{t('common.numberOfPapers')}</TableCell>
              <TableCell
                align='right'
                sx={{
                  width: '48px'
                }}
              ></TableCell>
              <TableCell
                align='right'
                sx={{
                  width: '48px'
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fullDistributionRoutes?.map((fullDistributionRoute) => (
              <TableRow key={fullDistributionRoute.id}>
                <TableCell>{fullDistributionRoute.distributionRouteId}</TableCell>
                <TableCell>{fullDistributionRoute.numberOfPapers}</TableCell>
                <TableCell
                  align='right'
                  sx={{
                    width: '48px'
                  }}
                >
                  <IconButton
                    color='error'
                    onClick={() => {
                      onDelete(fullDistributionRoute);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
                <TableCell
                  align='right'
                  sx={{
                    width: '48px'
                  }}
                >
                  <IconButton
                    onClick={() => {
                      onEdit(fullDistributionRoute);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!fullDistributionRoutes?.length && (
        <Stack
          direction={'row'}
          justifyContent={'center'}
          sx={{
            p: 4
          }}
        >
          <Typography>{t('distribution.fullDistribution.noRoutes')}</Typography>
        </Stack>
      )}
    </>
  );
};

export default FullDistributionRoutes;
