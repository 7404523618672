import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import UserAddressRouteStatusChip from 'components/UserDistributionRouteStatusChip/UserDistributionRouteStatusChip';
import { ROUTE_PATHS } from 'consts/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UnhandledUserRoute from 'types/UnhandledUserRoute';

type UnhandledUserRouteListProps = {
  unhandeledUserRoutes: UnhandledUserRoute[];
  onAddRoute: (unhandledRoute: UnhandledUserRoute) => void;
};

const UnhandledUserRouteList: FC<UnhandledUserRouteListProps> = ({
  unhandeledUserRoutes,
  onAddRoute
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>{t('common.username')}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{t('common.address')}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{t('common.postInfo')}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{t('common.errorCode')}</Typography>
            </TableCell>
            <TableCell align='right'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unhandeledUserRoutes.map((unhandledUserRoute, index) => (
            <TableRow key={index}>
              <TableCell>
                <Link
                  style={{ color: '#fff ' }}
                  to={ROUTE_PATHS.USER.replace(':id', unhandledUserRoute.userId.toString())}
                >
                  <Typography
                    sx={{
                      textDecoration: 'none',
                      '&:hover': {
                        color: 'white'
                      }
                    }}
                  >
                    {unhandledUserRoute.username || '--'}
                  </Typography>
                </Link>
              </TableCell>
              <TableCell>
                <Typography>{unhandledUserRoute?.userAddress.address}</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {unhandledUserRoute?.userAddress.postCode}{' '}
                  {unhandledUserRoute?.userAddress.postOffice}
                </Typography>
              </TableCell>
              <TableCell>
                <UserAddressRouteStatusChip routeStatus={unhandledUserRoute.routeStatus} />
              </TableCell>
              <TableCell align='right' onClick={() => onAddRoute(unhandledUserRoute)}>
                <Button>{t('userAddress.addRoute')}</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {unhandeledUserRoutes.length === 0 && (
        <Stack sx={{ p: 4, width: '100%' }} justifyContent={'center'} direction={'row'}>
          <Typography>{t('distribution.route.noUnhandledRoutes')}</Typography>
        </Stack>
      )}
    </>
  );
};

export default UnhandledUserRouteList;
