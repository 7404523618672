import PostenDistributionRoute from './PostenDistributionRoute';

export type DistributionRouteType = 'POLARIS' | 'POSTEN' | 'LOCAL_POSTEN_ROUTE';

export const distributionRouteTypes: { [k: string]: DistributionRouteType } = {
  POLARIS: 'POLARIS',
  POSTEN: 'POSTEN',
};

type DistributionRoute = {
  id?: string;
  type?: DistributionRouteType;
  name?: string;
  postCodeFrom?: string;
  postCodeTo?: string;
  departureTime?: string;
  transportRoute?: string;
  deliveryPlace?: string;
  carText?: string;
  dropText?: string;
  dropText2?: string;
  airSendt: boolean;
  dontWritePackageSlip: boolean;
  disrictCode: string;
  extraNewspapers: number;
  toDIOrderBook: boolean;
  named?: boolean;
  postenDistRouteId?: number;
  toPostenOrderBook?: boolean;

  // include
  postenDistributionRoute?: PostenDistributionRoute;

  // number of users
  numberOfUsers?: number;
};

export default DistributionRoute;
