import { FC } from 'react';
import { PageContainer } from 'components';
import { Outlet, useLocation } from 'react-router';
import { Stack, SxProps } from '@mui/material';
import PaperOverview from 'pages/PaperOverview/PaperOverview';

const Paper: FC = () => {
  // get current route

  const location = useLocation();

  const styledPageContainer: SxProps = {
    height: '100%'
  };

  return (
    <PageContainer sx={styledPageContainer}>
      {location.pathname === '/paper' && (
        <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
          <PaperOverview />
        </Stack>
      )}
      <Outlet />
    </PageContainer>
  );
};

export default Paper;
