import axios, { AxiosPromise } from 'axios';
import PageResults from 'types/PageResults';
import SearchQuery from 'types/SearchQuery';

const polarisDistributionAddressService = {
  get: () => {
    return axios.get('polaris-distribution-address');
  },
  importCsvFile: (file: File) => {
    // 'distribution/polaris-address/import-csv'
    const formData = new FormData();
    formData.append('file', file);
    return axios.post('distribution/polaris-address/import-csv', formData);
  },
  getInfo: (): AxiosPromise<{
    numberOfAddresses: number;
    lastImportedAt: string;
  }> => {
    return axios.get('distribution/polaris-address/info');
  },
  search: (
    searchQuery: SearchQuery,
    limit = 50,
    offset = 0
  ): AxiosPromise<PageResults<PolarisAddress>> => {
    return axios.get(
      `distribution/polaris-address/search?limit=${limit}&offset=${offset}&query=${searchQuery.query || ''}&key=${searchQuery?.queryType?.key}`
    );
  }
};

export default polarisDistributionAddressService;
