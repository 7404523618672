/* eslint-disable max-lines */
/* eslint-disable indent */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField
} from '@mui/material';
import FormDialog from 'components/FormDialog/FormDialog';
import InfoToggle from 'components/InfoToggle/InfoToggle';
import useSettings from 'hooks/useSettings';
import logger from 'logger/logger';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userAddressService from 'services/user-address.service';
import CountryCode, { countryCodes } from 'types/CountryCode';
import User from 'types/User';
import UserAddress from 'types/UserAddress';
import { validate } from 'utils';
import getCountryCodeFlag from 'utils/_getCountryCodeFlag';

type UserAddressModalProps = {
  user: User;
  onConfirm: (userAddress: UserAddress) => void;
  onCancel: () => void;
};

type UserAddressFields = {
  address?: string;
  address2?: string;
  postCode?: string;
  postOffice?: string;
  country?: CountryCode;
  careOf?: string;
  entrance?: string;
  floor?: string;
  state?: string;
  foreignAddress?: string;
};

const UserAddressModal: FC<UserAddressModalProps> = ({ user, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const { getValueByKeyAsBoolean } = useSettings();
  const nrsPaper = getValueByKeyAsBoolean('NrsPaper');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm<UserAddressFields>({
    defaultValues: {
      country: 'NO',
      ...user?.userAddress
    }
  });

  const country = watch('country');
  const [loading, setLoading] = useState(false);

  const styledSelect: SxProps = {
    minWidth: 'auto',
    flex: '1',
    zIndex: 100002
  };

  useEffect(() => {
    if (!country) setValue('country', 'NO');
  }, []);

  const onSubmit: SubmitHandler<UserAddressFields> = async (fields) => {
    setLoading(true);
    try {
      if (user?.userAddress) {
        await userAddressService.updateUserAddress(user?.id, {
          ...fields,
          id: user?.userAddress?.id
        });
      } else {
        await userAddressService.createUserAddress(user?.id, fields);
      }

      onConfirm(fields);
    } catch (error) {
      logger.error('Update user address failed: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t('common.changeAddress')}
      loading={loading}
      submitText={
        user?.userAddress
          ? t('common.changeBlank', {
              blank: t('common.address').toLowerCase()
            })
          : t('common.addNewBlank', {
              blank: t('common.address').toLowerCase()
            })
      }
    >
      <div className='dialog-content'>
        <Stack gap={2}>
          <TextField
            fullWidth
            label={t('common.address') + ' *'}
            {...register('address', {
              required: true
            })}
            error={errors.address ? true : false}
          />
          <Stack direction={'row'} spacing={2}>
            <TextField
              sx={{ width: '30%' }}
              fullWidth
              label={t('common.postCode') + ' *'}
              {...register('postCode', {
                minLength: 2,
                maxLength: 50,
                required: true
              })}
              error={errors.postCode ? true : false}
            />
            <TextField
              sx={{ width: '70%' }}
              fullWidth
              label={t('common.postOffice') + ' *'}
              {...register('postOffice', {
                required: true,
                validate: (value) => validate.length(value, 2, 50)
              })}
              onChange={(event) => {
                event.target.value = event.target.value.toUpperCase();
                setValue('postOffice', event.target.value);
              }}
              error={!!errors.postOffice}
            />
          </Stack>
          {nrsPaper && (
            <>
              <TextField
                fullWidth
                label={t('common.address') + ' 2'}
                {...register('address2', {
                  validate: (val) => validate.length(val, 2, 50, true)
                })}
                error={!!errors.address2}
              />
              <TextField
                fullWidth
                label={t('common.entranceDescription')}
                {...register('entrance', {
                  validate: (val) => validate.length(val, 2, 50, true)
                })}
                error={!!errors.entrance}
              />
              <TextField
                fullWidth
                label={t('common.floor')}
                {...register('floor', {
                  validate: (val) => validate.length(val, 1, 50, true)
                })}
                error={!!errors.floor}
              />
              <TextField
                fullWidth
                name='careOf'
                label={t('common.careOf')}
                {...register('careOf', {
                  validate: (val) => validate.length(val, 2, 50, true)
                })}
                error={!!errors.careOf}
              />
              {country && (
                <FormControl>
                  <InputLabel>{t('common.country')}</InputLabel>
                  <Select
                    {...register('country', { required: true })}
                    defaultValue={country}
                    sx={styledSelect}
                    value={country}
                    label={t('common.country')}
                    MenuProps={{
                      sx: {
                        ['.MuiPaper-root']: {
                          margin: '0',
                          minWidth: 'auto !important'
                        }
                      }
                    }}
                  >
                    {countryCodes.map((countryCode, index) => (
                      <MenuItem key={index} value={countryCode}>
                        {getCountryCodeFlag(countryCode)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {country && country !== 'NO' && (
                <Stack gap={2}>
                  <TextField
                    name={'state'}
                    label={t('common.state-region') + ' (' + t('common.ifNecessary') + ')'}
                    {...register('state', {
                      validate: (val) => {
                        return validate.length(val, 2, 50) || !val || val === '';
                      },
                      required: false
                    })}
                    error={!!errors.country}
                  />
                  <TextField
                    multiline
                    fullWidth
                    rows={4}
                    name={'foreignAddress'}
                    label={
                      t('common.foreignAddressDistribution') + ' (' + t('common.ifNecessary') + ')'
                    }
                    {...register('foreignAddress', {
                      validate: (val) => {
                        return validate.length(val, 2, 50) || !val || val === '';
                      },
                      required: false
                    })}
                    error={!!errors.country}
                  />
                  <Stack justifyContent={'flex-start'} direction={'row'}>
                    <InfoToggle
                      label={t('common.foreignAddressDistribution')}
                      sx={{ wordWrap: 'break-word', maxWidth: '370px' }}
                      text={t('tooltip.foreignAddressDescription')}
                      img={'/assets/distribution/banderole-address-example.png'}
                    />
                  </Stack>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </div>
    </FormDialog>
  );
};

export default UserAddressModal;
