import { TableHead, TableRow, TableCell, IconButton } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceDisplayOptions } from 'types/InvoiceDisplayOptions';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

type InvoiceTableHeaderProps = {
  invoicesDisplayOptions: InvoiceDisplayOptions;
  toggleSelectColumns: () => void;
  selectable?: boolean;
};

const InvoiceTableHeader: FC<InvoiceTableHeaderProps> = ({
  invoicesDisplayOptions,
  toggleSelectColumns,
  selectable
}) => {
  const opt = invoicesDisplayOptions;
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ padding: 0 }}>
          <IconButton
            sx={{ position: 'relative', left: '5px', top: '2px' }}
            onClick={() => toggleSelectColumns()}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
        {selectable && (
          <TableCell sx={{ textAlign: 'center', padding: '0 5px' }}>
            <CheckBoxIcon sx={{ position: 'relative', top: '5px' }} />
          </TableCell>
        )}
        {opt?.id && <TableCell>{t('common.invoiceNo')}</TableCell>}
        {opt?.status && <TableCell>{t('common.status')}</TableCell>}
        {opt?.method && <TableCell>{t('invoice.invoicePaymentMethod')}</TableCell>}
        {opt?.userId && <TableCell>{t('common.user')}</TableCell>}
        {opt?.userName && <TableCell>{t('common.name')}</TableCell>}
        {opt?.billingName && <TableCell>{t('common.billingContact')}</TableCell>}
        {opt?.issueDate && <TableCell>{t('common.invoiceDate')}</TableCell>}
        {opt?.dueDate && <TableCell>{t('common.dueDate')}</TableCell>}
        {opt?.kid && <TableCell>{t('common.kidNo')}</TableCell>}
        {opt?.price && <TableCell>{t('common.price')}</TableCell>}
        {opt?.sent && <TableCell>{t('common.sent')}</TableCell>}
        {opt?.paymentReminderSent && <TableCell>{t('invoice.paymentReminderSent')}</TableCell>}
        {opt?.customerNo && <TableCell>{t('invoiceTable.customerNo')}</TableCell>}
        {opt?.message && <TableCell>{t('invoiceTable.message')}</TableCell>}
        {opt?.periodFrom && <TableCell>{t('invoiceTable.periodFrom')}</TableCell>}
        {opt?.periodTo && <TableCell>{t('invoiceTable.periodTo')}</TableCell>}
        {opt?.creditNoteInvoiceNo && <TableCell>{t('invoiceTable.creditNoteInvoiceNo')}</TableCell>}
        {opt?.product && <TableCell>{t('invoiceTable.product')}</TableCell>}
        {opt?.invoiceFee && <TableCell>{t('invoiceTable.invoiceFee')}</TableCell>}
        {opt?.billingAddress && <TableCell>{t('invoiceTable.billingAddress')}</TableCell>}
        {opt?.billingPostCode && <TableCell>{t('invoiceTable.billingPostCode')}</TableCell>}
        {opt?.billingPostOffice && <TableCell>{t('invoiceTable.billingPostOffice')}</TableCell>}
        {opt?.billingEmail && <TableCell>{t('invoiceTable.billingEmail')}</TableCell>}
        {opt?.billingPhone && <TableCell>{t('invoiceTable.billingPhone')}</TableCell>}
        {opt?.sumVat && <TableCell>{t('invoiceTable.sumVat')}</TableCell>}
        {opt?.vatPercent && <TableCell>{t('invoiceTable.vatPercent')}</TableCell>}
        <TableCell
          sx={{
            width: '30px',
            padding: '0 5px',
            textAlign: 'center',
            position: 'sticky',
            right: 0,
            background: 'inherit'
          }}
        >
          <EditIcon />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default InvoiceTableHeader;
