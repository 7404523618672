import { Box, Button, Chip, IconButton, Typography } from '@mui/material';
import PrimaryCard from 'components/PrimaryCard/PrimaryCard';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import PrimaryListItem from 'components/PrimaryListItem/PrimaryListItem';
import useAlert from 'hooks/useAlert';
import { ConsentModal } from 'modals';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import settingService from 'services/setting-service';
import userTokenService from 'services/user-tokens.service';

import User from 'types/User';
import UserToken from 'types/UserToken';
import { getHumanFriendlyDateTime } from 'utils/_dateUtil';
import UserTokenTable from './components/UserTokenTable';
import PrimaryLoading from 'components/PrimaryLoading/PrimaryLoading';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';
import Edit from '@mui/icons-material/Edit';
import EditNumberModal from 'components/EditNumberModal/EditNumberModal';
import userService from 'services/user-service';

type UserTokensProps = {
  user: User;
  onUpdate: (user: User) => void;
};

const UserTokens: FC<UserTokensProps> = ({ user, onUpdate }) => {
  const { t } = useTranslation();
  const { addAlert } = useAlert();
  const axiosAlert = useAxiosAlert();
  const [loading, setLoading] = useState<boolean>();
  const [userTokens, setUserTokens] = useState<UserToken[]>();
  const [maxNumberOfDevices, setMaxNumberOfDevices] = useState<number>();
  const [modal, setModal] = useState<number>();
  const [lastUsed, setLastUsed] = useState<Date>(null);
  const [showActiveDevices, setShowActiveDevices] = useState(false);
  const [showNotActiveDevices, setShowNotActiveDevices] = useState(false);

  const getUserTokens = async () => {
    try {
      const { data: _userTokens } = await userTokenService.getUserTokens(user.id);

      setUserTokens(_userTokens);

      // Find token with lates login date
      const lastUsedUserToken = _userTokens.reduce((prev, current) => {
        if (prev === null) {
          return current;
        }
        return prev.lastUsed > current.lastUsed ? prev : current;
      }, null);

      if (lastUsedUserToken) {
        setLastUsed(lastUsedUserToken.lastUsed);
      }
    } catch (error) {
      axiosAlert(error as AxiosError);
    }
  };
  const updateUserMaxActiveLogins = async (value: number) => {
    try {
      const updatedUserQuery = await userService.updateMetadata(user.id, {
        maxActiveLogins: value
      });
      const _user = updatedUserQuery.data;
      _user.userSubscriptions = user.userSubscriptions;
      onUpdate(_user);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.couldNotUpdateUser'));
    }
    setModal(null);
  };

  const updateUserToken = async (userToken: UserToken) => {
    setLoading(true);
    try {
      await userTokenService.update(userToken);
      await getUserTokens();
    } catch (error) {
      axiosAlert(error as AxiosError);
    }
    setLoading(false);
  };

  const getMaxNumberOfDevices = async () => {
    try {
      const { data: _maxNumberOfDevicesSetting } = await settingService.getByKey('MaxActiveLogins');
      setMaxNumberOfDevices(parseInt(_maxNumberOfDevicesSetting.value));
    } catch (error) {
      axiosAlert(error as AxiosError);
    }
  };

  const logoutAllDevices = async () => {
    setLoading(true);
    setModal(null);
    try {
      await userTokenService.logoutAll(user.id);
      await getUserTokens();
      addAlert('success', t('userTokens.alerts.allUsersDevicesLoggedOutSuccess'));
    } catch (error) {
      axiosAlert(error as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const init = async () => {
    try {
      setLoading(true);
      await Promise.all([getUserTokens(), getMaxNumberOfDevices()]);
    } catch (error) {
      axiosAlert(error as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);
  const displayContent = userTokens && maxNumberOfDevices !== undefined;

  const activeDevices = userTokens
    ?.filter((userToken) => userToken.activeDevice === true)
    .sort((a, b) => b.lastUsed.getTime() - a.lastUsed.getTime());
  const notActiveDevices = userTokens
    ?.filter((userToken) => !userToken.activeDevice)
    .sort((a, b) => b.lastUsed.getTime() - a.lastUsed.getTime());

  const customDevicesLimit =
    user?.metadata?.maxActiveLogins !== null &&
    user?.metadata?.maxActiveLogins !== undefined &&
    user?.metadata?.maxActiveLogins !== '' &&
    !isNaN(+user?.metadata?.maxActiveLogins);
  const devicesLimit = customDevicesLimit ? +user?.metadata?.maxActiveLogins : maxNumberOfDevices;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '20px'
        }}
      >
        <PrimaryCard sx={{ p: 0, width: '100%' }}>
          <PrimaryHeader title={t('userTokens.logedInDevices')} />
          {loading && <PrimaryLoading />}
          {displayContent && (
            <>
              <PrimaryListItem
                label={t('userTokens.activeDeviceLimit')}
                value={`${devicesLimit} ${t('common.devices')}`}
                placeholder={t('common.notAdded')}
                actions={
                  <>
                    {customDevicesLimit ? (
                      <Chip
                        sx={{ margin: '-8px 0' }}
                        label={t('common.custom').toLocaleUpperCase()}
                        color='success'
                      />
                    ) : (
                      <Chip
                        sx={{ margin: '-8px 0' }}
                        label={t('common.default').toLocaleUpperCase()}
                        color='info'
                      />
                    )}
                    <IconButton onClick={() => setModal(1)}>
                      <Edit />
                    </IconButton>
                  </>
                }
              />
              <PrimaryListItem
                label={t('userTokens.numberOfActiveDevices', { max: maxNumberOfDevices })}
                value={
                  <>
                    <Typography>{`${activeDevices.length} ${t('common.devices')}`}</Typography>
                    <IconButton onClick={() => setShowActiveDevices(!showActiveDevices)}>
                      {showActiveDevices ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </>
                }
              />
              {showActiveDevices && (
                <UserTokenTable userTokens={activeDevices} updateUserToken={updateUserToken} />
              )}
              <PrimaryListItem
                label={t('userTokens.otherLogedInDevices', { max: maxNumberOfDevices })}
                value={
                  <>
                    <Typography>{`${notActiveDevices.length} ${t('common.devices')}`}</Typography>
                    <IconButton onClick={() => setShowNotActiveDevices(!showNotActiveDevices)}>
                      {showNotActiveDevices ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </>
                }
              />
              {showNotActiveDevices && (
                <UserTokenTable userTokens={notActiveDevices} updateUserToken={updateUserToken} />
              )}
              <PrimaryListItem
                label={t('userTokens.lastActive')}
                value={lastUsed ? getHumanFriendlyDateTime(lastUsed) : t('common.none')}
              />
              <PrimaryListItem
                label={t('userTokens.logOutAllDevices')}
                value={
                  <Button onClick={() => setModal(0)} variant={'outlined'}>
                    {t('common.logOutAll')}
                  </Button>
                }
              />
            </>
          )}
        </PrimaryCard>
      </Box>
      {modal === 0 && (
        <ConsentModal
          title={t('userTokens.logOutAllConsentTitle')}
          content={t('userTokens.logOutAllConsentContent')}
          onCancel={() => setModal(undefined)}
          onConfirm={logoutAllDevices}
          confirmText={t('common.logOutAll')}
        />
      )}
      {modal === 1 && (
        <EditNumberModal
          value={devicesLimit}
          label={t('userTokens.activeDeviceLimit')}
          sxContent={{ minWidth: '400px' }}
          title={t('common.editSetting')}
          max={2}
          onCancel={() => setModal(null)}
          onConfirm={(value) => {
            updateUserMaxActiveLogins(value);
          }}
        />
      )}
    </>
  );
};

export default UserTokens;
