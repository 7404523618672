import { Button, Divider, Paper, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import invoiceService from 'services/invoice-service';
import OcrHistoryList from './OcrHistoryList';

const OcrTab = () => {
  const [log, setLog] = useState<string[]>(null);
  const { t } = useTranslation();

  const axiosAlert = useAxiosAlert();

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files) {
        return;
      }
      const file = e.target.files[0];
      const uploadOcrResult = await invoiceService.uploadOcrFile(file);
      setLog(uploadOcrResult.data);
    } catch (error) {
      if ((error as AxiosError).response?.data?.message) {
        setLog([(error as AxiosError).response?.data?.message]);
      }
      axiosAlert(error as AxiosError, []);
    }
  };

  return (
    <div>
      <Typography variant='h6' sx={{ marginBottom: '10px' }}> {t('invoice.reqisterOcrFile')}</Typography>
      <Button variant='contained' component='label' sx={{ marginBottom: '10px' }}>
        {t('common.uploadFile')}
        <input type='file' hidden onChange={handleFileUpload} />
      </Button>
      {log !== null && (
        <>
          <Divider sx={{ margin: '15px 0' }} />

          <Paper elevation={0} sx={{ backgroundColor: '#000', padding: '20px', margin: '20px 0 ' }}>
            <pre>
              {log.map((e, i) => (
                <p key={i}>{e}</p>
              ))}
            </pre>
          </Paper>
        </>
      )}
      <Divider sx={{ margin: '15px 0' }} />
      <OcrHistoryList />
    </div>
  );
};

export default OcrTab;
