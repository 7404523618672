const numToExactString = (num: number | string, length: number, ph = '0') => {
  let str = num.toString();
  if (str.length > length) {
    str = str.substring(str.length - length);
  } else if (str.length < length) {
    str = str.padStart(length, ph);
  }
  return str;
};

export default numToExactString;
