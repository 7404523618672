import { AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';
import {
  Dispatch,
  SetStateAction,
  useContext,
  createContext,
  FC,
  ReactNode,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import userService from 'services/user-service';
import User from 'types/User';

type UserContextType = {
  setUser: Dispatch<SetStateAction<User | null>>;
  fetchUser: (userId: number) => void;
  user: User | null;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const axiosAlert = useAxiosAlert();
  const { t } = useTranslation();

  const fetchUser = async (userId: number) => {
    try {
      const { data: userData } = await userService.getById(userId);
      setUser(userData);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.couldNotGetUser'));
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, fetchUser }}>{children}</UserContext.Provider>
  );
};
