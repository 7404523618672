import { FC } from 'react';
import { Stack, SxProps } from '@mui/material';

type PageWrapperProps = {
  children?: React.ReactNode;
  padding?: boolean;
  sx?: SxProps;
};

const PageWrapper: FC<PageWrapperProps> = ({ children, padding = false, sx }) => {
  const pageWrapperStyles: SxProps = {
    width: '100%'
  };

  return (
    <Stack
      direction={'column'}
      p={padding ? 2 : 0}
      sx={{
        ...pageWrapperStyles,
        ...sx
      }}
    >
      {children}
    </Stack>
  );
};

export default PageWrapper;
