import './PrimaryLoading.scss';
import { FC } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';

type PrimaryLoadingType = {
    title?: string;
};

const PrimaryLoading: FC<PrimaryLoadingType> = ({ title }) => {
  return (
    <Dialog className='primary-loading' open={true}>
      <DialogContent>
        <div className={'loading-container'}>
          <Typography variant={'h6'} className={'loading-title'}>
            {title}
          </Typography>
          <CircularProgress color={'primary'} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PrimaryLoading;
