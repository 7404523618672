import { TextField } from '@mui/material';
import FormDialog from 'components/FormDialog/FormDialog';
import logger from 'logger/logger';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userService from 'services/user-service';
import User from 'types/User';
import './UserNameModal.scss';

type UserNameModalProps = {
    user: User;
    onConfirm: (name: string) => void;
    onCancel: () => void;
};

type ChangeNameFields = {
    name: string;
};

const UserNameModal: FC<UserNameModalProps> = ({
  user,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeNameFields>({
    defaultValues: { name: user.name || '' },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<ChangeNameFields> = async (fields) => {
    const { name } = fields;

    setLoading(true);
    try {
      await userService.updateUser({
        ...user,
        name: name,
      });

      onConfirm(name);
    } catch (error) {
      logger.error('UpdateUser failed: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t('common.changeName')}
      loading={loading}
      submitText={t('common.change')}
    >
      <div className='dialog-content'>
        <TextField
          sx={{ m: '10px 0' }}
          fullWidth
          label={t('common.name')}
          {...register('name', {
            required: 'name is required',
            minLength: 2,
            maxLength: 50,
          })}
          error={errors.name ? true : false}
        />
      </div>
    </FormDialog>
  );
};

export default UserNameModal;
