import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material';
import { AxiosError } from 'axios';
import useAlert from 'hooks/useAlert';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import invoiceService from 'services/invoice-service';
import { Invoice } from 'types/Invoice';
import InvoiceTable from '../InvoiceTable/InvoiceTable';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import InfoIcon from '@mui/icons-material/Info';

type PaperInvoicesTabProps = {
  getUnhandledInvoices: () => void;
};

const PaperInvoicesTab: FC<PaperInvoicesTabProps> = ({ getUnhandledInvoices }) => {
  const { t } = useTranslation();

  const axiosAlert = useAxiosAlert();
  const { addAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedPaymentIds, setSelectedPaymentIds] = useState<number[]>([]);

  const selectNone = () => {
    setSelectedPaymentIds([]);
  };

  const selectAll = () => {
    const allInvoicesPaymentIds = invoices.map((i) => i.payment.id);
    setSelectedPaymentIds(allInvoicesPaymentIds);
  };

  const printSelected = async () => {
    if (selectedPaymentIds.length === 0) {
      addAlert('warning', t('invoice.noInvoicesSelected'));
      return;
    }
    setLoading(true);
    try {
      const pdfInvoicesQuery = await invoiceService.generatePaperInvoices(selectedPaymentIds);
      const a = document.createElement('a');
      a.href = 'data:application/octet-stream;base64,' + pdfInvoicesQuery.data.base64;
      a.download = pdfInvoicesQuery.data.name;
      a.click();
      getInvoices(); // Sets loading to false
      getUnhandledInvoices();
      addAlert('success', t('invoice.printingSuccess'));
    } catch (e) {
      axiosAlert(e as AxiosError, [], t('invoice.printingFailed'));
      setLoading(false);
    }
  };

  const getInvoices = async () => {
    setLoading(true);
    try {
      // Get direct debit for processing
      const query = await invoiceService.getPaperInvoicesProcessing();
      setInvoices(query.data);
    } catch (error) {
      if ((error as AxiosError).response?.data?.message) {
        axiosAlert(error as AxiosError, []);
      } else {
        addAlert('error', 'alerts.somethingWentWrong');
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <div>
      <Divider />
      {loading && <CircularProgress />}
      {!loading && (
        <InvoiceTable
          selectable={true}
          selectedPaymentIds={selectedPaymentIds}
          toggleSelected={(id) => {
            if (selectedPaymentIds.includes(id)) {
              setSelectedPaymentIds(selectedPaymentIds.filter((i) => i !== id));
            } else {
              setSelectedPaymentIds([...selectedPaymentIds, id]);
            }
          }}
          invoices={invoices}
        />
      )}
      <Divider />
      <Stack sx={{ padding: '20px' }} gap='20px'>
        <Stack direction='row' gap='20px'>
          <Button onClick={selectAll}>
            <CheckCircleOutlineIcon />
            &nbsp;
            {t('invoice.selectAll')}
          </Button>
          <Button onClick={selectNone}>
            <RadioButtonUncheckedIcon />
            &nbsp;
            {t('invoice.selectNone')}
          </Button>
        </Stack>
        <Stack direction='row' gap='20px'>
          <Button
            variant='contained'
            disabled={invoices?.length === 0}
            component='label'
            onClick={printSelected}
          >
            {t('invoice.generatePaperInvoices')}
          </Button>
          <Tooltip title={t('invoice.paperDescription')}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </div>
  );
};

export default PaperInvoicesTab;
