import { SxProps } from '@mui/material';
import { Box } from '@mui/system';
import { FC, ReactNode } from 'react';
import theme from 'styles/theme';

type PageContainerProps = {
  children: ReactNode;
  className?: string;
  sx?: SxProps;
  fullWidth?: boolean;
  centerVertical?: boolean;
  centerHorizontal?: boolean;
  title?: string;
  pagePadding?: boolean;
};

const PageContainer: FC<PageContainerProps> = ({
  children,
  className,
  sx,
  fullWidth = true,
  centerVertical = false,
  centerHorizontal = false,
  pagePadding = false
}) => {
  const sxStyles: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%',
    margin: 0,
    boxSizing: 'border-box',
    flex: 1,
    padding: pagePadding ? '12px' : '0px'
  };

  const pageContent: SxProps = {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    gap: '12px',
    flex: 1,
    maxWidth: '100%',
    alignItems: centerHorizontal ? 'center' : 'flex-start',
    justifyContent: centerVertical ? 'center' : 'flex-start',
    alignContent: centerHorizontal ? 'center' : 'flex-start',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      minWidth: '600px'
    }
  };

  return (
    <>
      <Box
        sx={{ ...sxStyles, ...sx }}
        className={`page-container ${className}`}
        maxWidth={`${fullWidth ? null : 'lg'}`}
      >
        <Box className="page-content" maxWidth={null} sx={pageContent}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default PageContainer;
