import { SxProps } from '@mui/material';
import { PageContainer } from 'components';
import { ROUTE_PATHS } from 'consts/routes';
import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

const Payments: FC = () => {
  // Location
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.PAYMENTS) {
      navigate(ROUTE_PATHS.PAYMENT_SEARCH, { replace: true });
    }
  }, [location]);

  const styledPageContainer: SxProps = {
    height: '100%'
  };

  return (
    <PageContainer sx={styledPageContainer}>
      <Outlet />
    </PageContainer>
  );
};

export default Payments;
