import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Person from '@mui/icons-material/Person';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router';
import routes from 'consts/routes';

type ProfileMenuProps = {
  name: string;
};

const ProfileMenu: FC<ProfileMenuProps> = ({ name }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: '#fff' }}
        endIcon={<Person fontSize={'large'} />}
      >
        {name}
      </Button>
      <Menu
        sx={{ width: '100%' }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(routes.PROFILE.path);
          }}
        >
          {t('common.myProfile')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            signOut();
          }}
        >
          {t('common.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
