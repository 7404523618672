import { CancelationType } from 'types/UserSubscription';

const userSubscriptionCancelationType: { [k: string]: CancelationType } = {
  UNKNOWN: 'UNKNOWN',
  CANCELED_BY_USER: 'USER',
  CANCELED_BY_ADMIN: 'ADMIN',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
};

export default userSubscriptionCancelationType;
