import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import Subscription from 'types/Subscription';
import { useNavigate } from 'react-router';
import { ROUTE_PATHS } from 'consts/routes';

type SubscriptionListProps = {
  subscriptions: Subscription[];
};

const SubscriptionList: FC<SubscriptionListProps> = ({ subscriptions = [] }) => {
  const { t } = useTranslation();

  // Location
  const navigate = useNavigate();

  const handleEditSubscription = (subscription: Subscription) => {
    navigate(ROUTE_PATHS.SUBSCRIPTION.replace(':id', subscription.id.toString()), {
      state: { subscription }
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      {(subscriptions.length > 0 && (
        <TableContainer sx={{ overflowX: 'unset' }}>
          <Table sx={{ width: '100%'}} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>{t('common.name')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.price')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.duration')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.status')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.activeUsers')}</Typography>
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((subscription) => (
                <TableRow key={subscription.id}>
                  <TableCell>
                    <Typography>{subscription.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{subscription.price} kr</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {subscription.duration} {t('common.monthsShort')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      color={subscription.status === 'ACTIVE' ? 'success' : 'default'}
                      label={t(`subscriptionStatus.${subscription.status}`).toUpperCase()}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>{subscription.numberOfActiveUsers}</Typography>
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{
                      width: '30px',
                      textAlign: 'center',
                      position: 'sticky',
                      right: 0,
                      background: 'inherit'
                    }}
                  >
                    <IconButton sx={{ margin: '-8px 0' }} edge='end' onClick={() => handleEditSubscription(subscription)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )) || (
        <Box>
          <Typography>{t('common.theListIsEmpty')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionList;
