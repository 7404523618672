import axios, { AxiosPromise } from 'axios';
import PostenDistributionRoute from 'types/PostenDistributionRoute';
import User from 'types/User';
import UserDistribution, {
  DistributionMessageCode,
  UserDistributionRouteStatus
} from 'types/UserDistribution';

const userDistributionService = {
  get: (userId: number): AxiosPromise<UserDistribution> => {
    return axios.get(`user/distribution/${userId}`);
  },
  addDistributionMessage: (
    userId: number,
    distributionMessage: string,
    distributionMessageCode: DistributionMessageCode,
    distributionMessageDate: Date
  ): AxiosPromise<UserDistribution> => {
    return axios.post(`user/distribution/distribution-message/${userId}`, {
      distributionMessage: distributionMessage,
      distributionMessageCode: distributionMessageCode,
      distributionMessageDate: distributionMessageDate
    });
  },
  create: (userId: number) => {
    return axios.post(`user/distribution/${userId}`);
  },
  updateDistributionMessage: (
    userId: number,
    distributionMessage: string,
    distributionMessageCode: DistributionMessageCode,
    distributionMessageDate: Date
  ): AxiosPromise<UserDistribution> => {
    return axios.patch(`user/distribution/distribution-message/${userId}`, {
      distributionMessage: distributionMessage,
      distributionMessageCode: distributionMessageCode,
      distributionMessageDate: distributionMessageDate
    });
  },
  deleteDistributionMessage: (userId: number): AxiosPromise<boolean> => {
    return axios.delete(`user/distribution/distribution-message/${userId}`);
  },
  getUsersWithDistributionMessage: (): AxiosPromise<User[]> => {
    return axios.get('user/distribution/users-with-distribution-message');
  },
  update: (
    userDistributionId: number,
    userDistribution: UserDistribution
  ): AxiosPromise<UserDistribution> => {
    return axios.patch(`user/distribution/${userDistributionId}`, userDistribution);
  },
  addRouteId: (
    userAddressId: number,
    routeId: string,
    routeStatus: UserDistributionRouteStatus
  ): AxiosPromise<UserDistribution> => {
    return axios.patch(`user/distribution/${userAddressId}/route-id`, {
      routeId: routeId,
      routeStatus: routeStatus
    });
  },
  getMissingUserAddress: (): AxiosPromise<User[]> => {
    return axios.get('user/distribution/missing-user-address');
  },
  getUsersWithInvalidPostCode: (): AxiosPromise<User[]> => {
    return axios.get('user/distribution/users-with-invalid-post-code');
  },
  getUnhandledUserRoutes: (): AxiosPromise<User[]> => {
    return axios.get('user/distribution/unhandled-routes');
  },
  parseDates: (userDistribution: UserDistribution): UserDistribution => {
    if (userDistribution === null || userDistribution === undefined) {
      return userDistribution;
    }
    if (userDistribution.holdFromDate) {
      userDistribution.holdFromDate = new Date(userDistribution.holdFromDate);
    }
    if (userDistribution.holdToDate) {
      userDistribution.holdToDate = new Date(userDistribution.holdToDate);
    }
    return userDistribution;
  },
  getPostenDistributionRoute: (
    userDistributionId: number
  ): AxiosPromise<PostenDistributionRoute> => {
    return axios.get(`user/distribution/${userDistributionId}/posten-distribution-route`);
  }
};

export default userDistributionService;
