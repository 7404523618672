import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from '@mui/material';
import { PageContainer, PaperCard, PaperContent } from 'components';
import NOTIFICATIONS from 'consts/notifications';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import NotificationsIcon from '@mui/icons-material/Notifications';
const NotificationList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageContainer>
      <PaperCard sx={{ width: '100%' }}>
        <PaperContent np>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center' width='150'>
                  <NotificationsIcon />
                </TableCell>
                <TableCell>{t('common.name')}</TableCell>
                <TableCell>{t('common.description')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {NOTIFICATIONS.map((notification) => (
                <TableRow key={notification.slug}>
                  <TableCell align='center'>
                    <Button
                      variant='outlined'
                      size='small'
                      onClick={() => {
                        navigate('/notification/' + notification.slug);
                      }}
                    >
                      {t('common.seeDetails')}
                    </Button>
                  </TableCell>
                  <TableCell>{t('notifications.' + notification.name)}</TableCell>
                  <TableCell>
                    <i>{t('notifications.' + notification.name + 'Description')}</i>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PaperContent>
      </PaperCard>
    </PageContainer>
  );
};

export default NotificationList;
