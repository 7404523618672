import { Tab, Tabs } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TextCategory, { textCategories } from 'types/TextCategory';

type TextCategoryTabsProps = {
  category: TextCategory;
  onCategoryChange: (category: TextCategory) => void;
};

const TextCategoryTabs: FC<TextCategoryTabsProps> = ({ category, onCategoryChange }) => {
  const { t } = useTranslation();

  const textCategoryTabs = textCategories.map((category, index) => {
    return <Tab key={index} label={t(`text.category.${category}`)} value={category} />;
  });

  return (
    <Tabs value={category} onChange={(e, v) => onCategoryChange(v)}>
      {textCategoryTabs}
    </Tabs>
  );
};

export default TextCategoryTabs;
