import { Select, MenuItem } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentMethod from 'types/PaymentMethod';
import User from 'types/User';
import UserInvoiceSettings from 'types/UserInvoiceSettings';

type SelectInvoicePaymentMethodProps = {
  user: User;
  userInvoiceSettings: UserInvoiceSettings;
  onChange: (paymentMethod: PaymentMethod) => void;
  eInvoiceActive: boolean;
  directDebitActive: boolean;
};

const SelectInvoicePaymentMethod: FC<SelectInvoicePaymentMethodProps> = ({
  user,
  userInvoiceSettings,
  onChange,
  eInvoiceActive,
  directDebitActive
}) => {
  const { t } = useTranslation();
  return (
    <Select
      size='small'
      disabled={userInvoiceSettings.invoicePaymentMethodAuto}
      value={userInvoiceSettings.invoicePaymentMethod ?? '--'}
      onChange={(e) => onChange(e.target.value as PaymentMethod)}
    >
      <MenuItem value={'--'} disabled={true}>
        {'-- ' + t('common.none') + ' --'}
      </MenuItem>
      <MenuItem value={'INVOICE_PAPER'}>{t('paymentMethod.INVOICE_PAPER')}</MenuItem>
      <MenuItem
        disabled={
          userInvoiceSettings.useBillingContact
            ? !userInvoiceSettings.billingContactEmail
            : !user.email
        }
        value={'INVOICE_EMAIL'}
      >
        {t('paymentMethod.INVOICE_EMAIL')}
      </MenuItem>
      {eInvoiceActive && (
        <MenuItem
          disabled={
            !userInvoiceSettings.eInvoiceReference
          }
          value={'INVOICE_EINVOICE'}
        >
          {t('paymentMethod.INVOICE_EINVOICE')}
        </MenuItem>
      )}
      {directDebitActive && (
        <MenuItem disabled={!userInvoiceSettings.activeDirectDebit} value={'INVOICE_DD_PAPER'}>
          {t('paymentMethod.INVOICE_DD_PAPER')}
        </MenuItem>
      )}
      {directDebitActive && (
        <MenuItem
          disabled={!userInvoiceSettings.activeDirectDebit}
          value={'INVOICE_DD_BANK_NOTIFICATION'}
        >
          {t('paymentMethod.INVOICE_DD_BANK_NOTIFICATION')}
        </MenuItem>
      )}
      {directDebitActive && (
        <MenuItem
          disabled={
            !userInvoiceSettings.activeDirectDebit ||
            (userInvoiceSettings.useBillingContact
              ? !userInvoiceSettings.billingContactEmail
              : !user.email)
          }
          value={'INVOICE_DD_EMAIL'}
        >
          {t('paymentMethod.INVOICE_DD_EMAIL')}
        </MenuItem>
      )}
      {eInvoiceActive && directDebitActive && (
        <MenuItem
          disabled={
            !userInvoiceSettings.activeDirectDebit ||
            (userInvoiceSettings.useBillingContact
              ? !userInvoiceSettings.billingContactEmail
              : !user.email)
          }
          value={'INVOICE_DD_EINVOICE'}
        >
          {t('paymentMethod.INVOICE_DD_EINVOICE')}
        </MenuItem>
      )}
    </Select>
  );
};

export default SelectInvoicePaymentMethod;
