import { CircularProgress, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { PaperCard, PaperContent, PaperHeader } from 'components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UnhandledUserRoute from 'types/UnhandledUserRoute';
import DistributionRouteSelectModal from 'components/DistributionRouteSelectModal/DistributionRouteSelectModal';
import useAlert from 'hooks/useAlert';
import userDistributionService from 'services/user-distribution-service';
import UnhandledUserRouteList from 'pages/CurrierPage/pages/CurrierRoutesUnhandledUsersPage/components/UnhandledUserRouteList';
import { Info } from '@mui/icons-material';

const UnhandledUserCurrierRoutes: FC = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  // loading useState
  const [loading, setLoading] = useState<boolean>();
  const [unhandledUserRoutes, setUnhandledUserRoutes] = useState<UnhandledUserRoute[]>([]);
  const [selectedUnhandledUserRoute, setSelectedUnhandledUserRoute] =
    useState<UnhandledUserRoute>();

  const getUnhandledUserRoutes = async () => {
    setLoading(true);
    try {
      const { data: usersWithUnhandledUserRoutes } =
        await userDistributionService.getUnhandledUserRoutes();

      const unhandledUserRoutesTemp = usersWithUnhandledUserRoutes.map(
        (userWithUnhandledUserRoutes) => {
          return {
            username: userWithUnhandledUserRoutes.name,
            userId: userWithUnhandledUserRoutes?.id,
            routeId: userWithUnhandledUserRoutes?.userDistribution?.routeId,
            routeStatus: userWithUnhandledUserRoutes?.userDistribution?.routeStatus,
            userAddress: userWithUnhandledUserRoutes?.userAddress,
            userDistribution: userWithUnhandledUserRoutes?.userDistribution
          };
        }
      );
      setUnhandledUserRoutes(unhandledUserRoutesTemp);

      // get data
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // init
    getUnhandledUserRoutes();
  }, []);

  return (
    <>
      <PaperCard
        sx={{
          maxWidth: '1000px'
        }}
      >
        <PaperHeader>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Typography textAlign={'left'} variant='h6'>
              {t('common.usersWithoutCurrier')}
            </Typography>
            <Tooltip title={t('tooltip.unhandledUserRoutes')}>
              <span>
                <IconButton disabled>
                  <Info />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </PaperHeader>
        <PaperContent sx={{ p: 0 }}>
          {!loading && (
            <UnhandledUserRouteList
              onAddRoute={setSelectedUnhandledUserRoute}
              unhandeledUserRoutes={unhandledUserRoutes}
            />
          )}
          {loading && (
            <Stack>
              <CircularProgress />
            </Stack>
          )}
        </PaperContent>
      </PaperCard>
      {selectedUnhandledUserRoute && (
        <DistributionRouteSelectModal
          id={selectedUnhandledUserRoute.userDistribution?.routeId}
          username={selectedUnhandledUserRoute.username}
          userDistribution={selectedUnhandledUserRoute.userDistribution}
          userAddress={selectedUnhandledUserRoute.userAddress}
          onCancel={() => setSelectedUnhandledUserRoute(null)}
          onRouteSelected={() => {
            alert.addAlert('success', t('distribution.alerts.routeAdded'));
            setSelectedUnhandledUserRoute(null);
            getUnhandledUserRoutes();
          }}
        />
      )}
    </>
  );
};

export default UnhandledUserCurrierRoutes;
