import { Button, Stack, SxProps, Typography } from '@mui/material';

import { PageContainer, PaperCard, PaperContent, PaperHeader, PrimaryListItem } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import jobService from 'services/job-service';
import theme from 'styles/theme';

const Jobs: FC = () => {
  const { t } = useTranslation();

  const styledPaperCard: SxProps = {
    maxWidth: '800px',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  };

  const runJob = async (jobName: string) => {
    try {
      await jobService.runJob(jobName);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageContainer>
      <PaperCard sx={styledPaperCard}>
        <PaperHeader>
          <Typography variant='h5'>{t('jobs.title')}</Typography>
        </PaperHeader>
        <PaperContent>
          <Stack sx={{ marginBottom: '30px' }} gap={1}>
            <Typography variant='h5'>{t('jobs.type.subscriptionRenewal')}</Typography>
            <PrimaryListItem
              label={t('jobs.userSubscriptionRenewalHandlerTitle')}
              description={t('jobs.userSubscriptionRenewalHandlerDescription')}
              actions={
                <Button onClick={() => runJob('handleRenewals')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
            <PrimaryListItem
              label={t('jobs.createRenewalQueueTitle')}
              description={t('jobs.createRenewalQueueDescription')}
              actions={
                <Button onClick={() => runJob('createRenewalQueue')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
            <PrimaryListItem
              last
              label={t('jobs.createCancelationQueueTitle')}
              description={t('jobs.createCancelationQueueDescription')}
              actions={
                <Button onClick={() => runJob('createCancelationQueue')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
          </Stack>
          <Stack sx={{ marginBottom: '20px' }} gap={1}>
            <Typography variant='h5'>{t('jobs.type.userTokens')}</Typography>
            <PrimaryListItem
              last
              label={t('jobs.deleteAllExpiredTokensThatsNotActiveDeviceTitle')}
              description={t('jobs.deleteAllExpiredTokensThatsNotActiveDeviceDescription')}
              actions={
                <Button
                  onClick={() => runJob('deleteAllExpiredTokensThatsNotActive')}
                  variant='outlined'
                >
                  {t('jobs.run')}
                </Button>
              }
            />
          </Stack>
          <Stack sx={{ marginBottom: '20px' }} gap={1}>
            <Typography variant='h5'>{t('jobs.type.vipps')}</Typography>
            <PrimaryListItem
              last
              label={t('jobs.queueChargesTitle')}
              description={t('jobs.queueChargesDescription')}
              actions={
                <Button onClick={() => runJob('vippsQueueCharges')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
          </Stack>
          <Stack sx={{ marginBottom: '20px' }} gap={1}>
            <Typography variant='h5'>{t('jobs.type.notifications')}</Typography>
            <PrimaryListItem
              last
              label={t('jobs.cleanUpNotificationsTitle')}
              description={t('jobs.cleanUpNotificationsDescription')}
              actions={
                <Button onClick={() => runJob('cleanUpNotifications')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
            <PrimaryListItem
              last
              label={t('jobs.createUpdateCardNotificationsTitle')}
              description={t('jobs.createUpdateCardNotificationsDescription')}
              actions={
                <Button onClick={() => runJob('createUpdateCardNotifications')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
          </Stack>
          <Stack sx={{ marginBottom: '20px' }} gap={1}>
            <Typography variant='h5'>{t('common.invoices')}</Typography>
            <PrimaryListItem
              last
              label={t('jobs.sendInvoicePaymentReminderTitle')}
              description={t('jobs.sendInvoicePaymentReminderDescription')}
              actions={
                <Button onClick={() => runJob('sendInvoicePaymentReminder')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
            <PrimaryListItem
              last
              label={t('jobs.createInvoicesTitle')}
              description={t('jobs.createInvoicesDescription')}
              actions={
                <Button onClick={() => runJob('createInvoices')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
            <PrimaryListItem
              last
              label={t('jobs.handleDuePaymentsTitle')}
              description={t('jobs.handleDuePaymentsDescription')}
              actions={
                <Button onClick={() => runJob('handleDuePayments')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
            <PrimaryListItem
              last
              label={t('jobs.automaticStopTitle')}
              description={t('jobs.automaticStopDescription')}
              actions={
                <Button onClick={() => runJob('automaticStop')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
            <PrimaryListItem
              last
              label={t('jobs.automaticRefundTitle')}
              description={t('jobs.automaticRefundDescription')}
              actions={
                <Button onClick={() => runJob('automaticRefund')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
            <PrimaryListItem
              last
              label={t('jobs.refreshAllUserInvoiceMethods')}
              description={t('jobs.refreshAllUserInvoiceMethodsDescription')}
              actions={
                <Button onClick={() => runJob('refreshAllUserInvoiceMethods')} variant='outlined'>
                  {t('jobs.run')}
                </Button>
              }
            />
          </Stack>
        </PaperContent>
      </PaperCard>
    </PageContainer>
  );
};

export default Jobs;
