import { FC, useState } from 'react';

import { Checkbox, FormControlLabel, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormDialog from 'components/FormDialog/FormDialog';
import { SubmitHandler, useForm } from 'react-hook-form';

type EditCheckboxModal = {
  onCancel: () => void;
  onConfirm: (value: boolean) => void;
  label?: string;
  title?: string;
  value?: boolean;
  sx?: SxProps;
  sxContent?: SxProps;
};
type FormValues = {
  checkbox: boolean;
};

const EditCheckboxModal: FC<EditCheckboxModal> = ({
  onCancel,
  onConfirm,
  label = '',
  value,
  title = '',
  sx = {},
  sxContent = {}
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, register, setValue, getValues } = useForm<FormValues>({
    defaultValues: { checkbox: value }
  });

  const onSubmit: SubmitHandler<FormValues> = async (fields) => {
    setLoading(true);
    onConfirm(fields.checkbox);
  };

  return (
    <FormDialog
      title={title}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      submitText={t('common.confirmChanges')}
      loading={loading}
      sx={sx}
      sxContent={sxContent}
    >
      <FormControlLabel
        control={<Checkbox defaultChecked={getValues('checkbox')} />}
        {...register('checkbox')}
        onChange={(e, checked) => {
          setValue('checkbox', checked);
        }}
        label={label}
      />
    </FormDialog>
  );
};

export default EditCheckboxModal;
