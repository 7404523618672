import { SxProps, Tab, Tabs, Typography } from '@mui/material';
import PageContent from 'components/PageContent/PageContent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWrapper from 'components/PageWrapper/PageWrapper';
import { ROUTE_PATHS } from 'consts/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Outlet } from 'react-router';

const CurrierPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tab, setTab] = useState<number>();
  const [sxStyles, setSxStyles] = useState<SxProps>({});

  useEffect(() => {
    // Get current path and set tab value
    if (location.pathname.includes(ROUTE_PATHS.CURRIER_ROUTES)) {
      setTab(0);
    } else if (location.pathname.includes(ROUTE_PATHS.CURRIER_MESSAGES)) {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [location]);

  useEffect(() => {
    if (tab === 0) {
      navigate(`${ROUTE_PATHS.CURRIER_ROUTES}`);
    } else if (tab === 1) {
      navigate(`${ROUTE_PATHS.CURRIER_MESSAGES}`);
    } else if (tab === 2) {
      navigate(`${ROUTE_PATHS.CURRIER_ROUTES_UNHANDLED_USERS}`);
    }
    setSxStyles(tab === 0 ? { height: '100%' } : { height: 'auto' });
  }, [tab]);

  return (
    <PageWrapper sx={sxStyles}>
      <PageHeader backToUrl={ROUTE_PATHS.PAPER}>
        <Typography variant={'h6'}>{t('common.currier')}</Typography>
        {tab !== undefined && (
          <Tabs value={tab} onChange={(e, tabValue) => setTab(tabValue)}>
            <Tab label={t('common.currierRoutes')} value={0} />
            <Tab label={t('common.currierMessages')} value={1} />
          </Tabs>
        )}
      </PageHeader>
      <PageContent sx={{ padding: '12px' }}>
        <Outlet />
      </PageContent>
    </PageWrapper>
  );
};

export default CurrierPage;
