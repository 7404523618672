import axios, { AxiosPromise } from 'axios';
import UserToken from 'types/UserToken';

const userTokenService = {
  getUserTokens: (userId: number): AxiosPromise<UserToken[]> => {
    const query = axios.get(`/user-token/${userId}`);

    // Convert the date strings to Date objects
    query.then((res) => {
      res.data.forEach((token: UserToken) => {
        token.expires = new Date(token.expires);
        token.lastUsed = new Date(token.lastUsed);
      });
    });

    return query;
  },
  logoutAll: (userId: number): AxiosPromise => {
    return axios.post(`/user-token/logout-all/${userId}`);
  },
  update: (token: UserToken): AxiosPromise<UserToken> => {
    return axios.put(`/user-token/admin/${token.id}`, token);
  }
};

export default userTokenService;
