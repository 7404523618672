import axios, { AxiosPromise } from 'axios';

const bulkService = {
  getAll: (): AxiosPromise<Bulk[]> => axios.get<Bulk[]>('distribution/bulk'),
  get: (id: number): AxiosPromise<Bulk> => axios.get<Bulk>(`distribution/bulk/${id}`),
  create: (bulk: Bulk) => axios.post<Bulk>('distribution/bulk', bulk),
  update: (bulk: Bulk) => axios.put<Bulk>(`distribution/bulk/${bulk.id}`, bulk),
  delete: (id: number) => axios.delete<Bulk>(`distribution/bulk/${id}`)
};

export default bulkService;
