import axios, { AxiosPromise } from 'axios';
import Setting from 'types/Setting';
import SettingCategory from 'types/SettingCategories';

const settingService = {
  getAll: () => {
    return axios.get<Setting[]>('setting');
  },
  getInitialSettings: (): AxiosPromise<Setting[]> => {
    // Add no cache header to prevent caching from 410 response
    return axios.get<Setting[]>('setting/admin-initial', {
      headers: { 'Cache-Control': 'no-cache' }
    });
  },
  getByKey: (key: string) => {
    return axios.get<Setting>(`setting/${key}`);
  },
  getPublicByKey: (key: string) => {
    return axios.get<Setting>(`setting/public/${key}`);
  },
  getByCategory: (category: SettingCategory) => {
    return axios.get<Setting[]>(`setting/category/${category}`);
  },
  post: (setting: Setting) => {
    return axios.post<Setting>('setting', setting);
  },
  update: (setting: Setting) => {
    return axios.put<Setting>(`setting/${setting.key}`, setting);
  },
  delete: (settingKey: string) => {
    return axios.delete(`setting/${settingKey}`);
  }
};

export default settingService;
