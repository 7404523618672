import './PrimaryCard.scss';
import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import theme from 'styles/theme';

type PrimaryCardProps = {
  children: ReactNode;
  sx?: SxProps;
};

const PrimaryCard: FC<PrimaryCardProps> = ({ sx = {}, children }) => {
  const style: SxProps = {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    width: 'auto',
    padding: '20px',
  };

  return <Box sx={{ ...style, ...sx }}>{children}</Box>;
};

export default PrimaryCard;
