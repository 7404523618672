import axios from 'axios';

const fullDistributionService = {
  getAll() {
    return axios.get('/distribution/full-distribution');
  },
  update(updatedFullDistribution: FullDistributionRoute) {
    return axios.put(
      `/distribution/full-distribution/${updatedFullDistribution.id}`,
      updatedFullDistribution
    );
  },
  post(newFullDistribution: FullDistributionRoute) {
    return axios.post('/distribution/full-distribution', newFullDistribution);
  },
  delete(id: number) {
    return axios.delete(`/distribution/full-distribution/${id}`);
  }
};

export default fullDistributionService;
