import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { FC } from 'react';

type ReportOptionYearProps = {
  value: number;
  label: string;
  onChange: (value: number) => void;
};

const ReportOptionYear: FC<ReportOptionYearProps> = ({ value, label, onChange }) => {
  const thisYear = new Date().getFullYear();
  const year = value || thisYear;
  const years = [];
  {
    for (let i = 0; i < 3; i++) {
      years.push(thisYear - i);
    }
  }

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select value={year} label={label} onChange={(e) => onChange(+e.target.value)}>
        {years.map((y) => (
          <MenuItem key={y} value={y}>
            {y}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReportOptionYear;
