import { Info } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { PaperCard, PaperContent, PaperHeader } from 'components';
import { ROUTE_PATHS } from 'consts/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import userDistributionService from 'services/user-distribution-service';
import User from 'types/User';
import getActiveUserSubscription from 'utils/_getActiveUserSubscription';

const PaperUserManagementNoAddress: FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>();
  const [users, setUsers] = useState<User[]>();

  const getUsers = async () => {
    setLoading(true);

    try {
      const { data: usersData } = await userDistributionService.getMissingUserAddress();

      setUsers(usersData);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <PaperCard>
      <PaperHeader>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Typography variant={'h6'} textAlign={'left'}>
              {t('common.noAddress')}
            </Typography>
            <Tooltip title={t('paperUserManagement.noAddressTooltip')}>
              <span>
                <IconButton disabled>
                  <Info />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
          {users && (
            <Typography>
              {t('common.quantity')} {users.length}
            </Typography>
          )}
        </Stack>
      </PaperHeader>
      <PaperContent np>
        {users && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>{t('common.name')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.userSubscription')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => {
                const activeSubscription = getActiveUserSubscription(user?.userSubscriptions)
                  ?.subscription;

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Link
                        style={{ color: '#fff ' }}
                        to={ROUTE_PATHS.USER.replace(':id', user?.id.toString())}
                      >
                        <Typography
                          sx={{
                            textDecoration: 'none',
                            '&:hover': {
                              color: 'white'
                            }
                          }}
                        >
                          {user.name}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography>{activeSubscription?.name}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        {loading && (
          <Stack p={4} justifyContent={'center'}>
            <CircularProgress />
          </Stack>
        )}
        {(!users || users.length === 0) && !loading && (
          <Stack p={4} textAlign={'center'}>
            <Typography>{t('common.noResultsFound')}</Typography>
          </Stack>
        )}
      </PaperContent>
    </PaperCard>
  );
};

export default PaperUserManagementNoAddress;
