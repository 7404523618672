import axios from 'axios';
import MessageObject from 'types/MessageObject';
import PaperRetailer from 'types/PaperRetailer';

const paperRetailerService = {
  getAll: () => {
    return axios.get<PaperRetailer[]>('/distribution/paper-retailer');
  },
  get: (id: number) => {
    return axios.get<PaperRetailer>(`/distribution/paper-retailer/${id}`);
  },
  create: (paperRetailer: PaperRetailer) => {
    return axios.post<PaperRetailer>('/distribution/paper-retailer', paperRetailer);
  },
  update: (paperRetailer: PaperRetailer) => {
    return axios.put<PaperRetailer>(`/distribution/paper-retailer/${paperRetailer.id}`, paperRetailer);
  },
  delete: (id: number) => {
    return axios.delete<MessageObject>(`/distribution/paper-retailer/${id}`);
  }
};

export default paperRetailerService;
