import { KeyboardArrowLeft } from '@mui/icons-material';
import { IconButton, Paper, Stack, SxProps } from '@mui/material';
import COLORS from 'consts/colors';
import { FC } from 'react';
import { useNavigate } from 'react-router';

type PageHeaderProps = {
  children?: React.ReactNode;
  actions?: React.ReactNode;
  sx?: SxProps;
  backToUrl?: string;
};

const PageHeader: FC<PageHeaderProps> = ({ children, actions, sx, backToUrl }) => {
  const navigate = useNavigate();

  const headerStyles: SxProps = {
    background: COLORS.SECONDARY,
    width: '100%'
  };

  return (
    <Paper sx={{ ...headerStyles, ...sx }}>
      <Stack p={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          {backToUrl && (
            <Stack gap={2}>
              <IconButton sx={{ padding: 0 }} onClick={() => navigate(backToUrl)}>
                <KeyboardArrowLeft />
              </IconButton>
            </Stack>
          )}
          {children}
        </Stack>
        {actions && (
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            {actions}
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default PageHeader;
