import { Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import PrimarySearch from 'components/PrimarySearch/PrimarySearch';
import PrimarySearchPageNav from 'components/PrimarySearchPageNav/PrimarySearchPageNav';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchQuery from 'types/SearchQuery';
import SearchQueryType from 'types/SearchQueryType';
import PageResults from 'types/PageResults';

import PaperCard from 'components/PaperCard/PaperCard';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import PaperContent from 'components/PaperContent/PaperContent';
import { Close, Upload } from '@mui/icons-material';
import PostenDistributionList from 'components/PostenDistributionList/PostenDistributionList';
import PostenDistributionData from 'types/PostenDistributionData';
import postenDistributionService from 'services/posten-distribution-service';
import PostenImportPostCodesModal from 'pages/PostenPage/compontents/PostenImportPostCodesModal/PostenImportPostCodesModal';
import logger from 'logger/logger';

const LIMIT = 20;

type DistributionRouteSearchProps = {
  onClose?: () => void;
};

const PostenDistributionSearch: FC<DistributionRouteSearchProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<SearchQuery>();
  const [loading, setLoading] = useState<boolean>();
  const [modal, setModal] = useState<number>();

  const queryTypes: SearchQueryType[] = [{ key: 'postenId', label: 'P.nr' }];
  const [pageResults, setPageResults] = useState<PageResults<PostenDistributionData>>();

  const changePage = (value: number) => {
    const updatedSearchQuery = { ...searchQuery, page: value };
    setSearchQuery(updatedSearchQuery);

    const offset = (value - 1) * LIMIT;
    searchDistributionRoutes(updatedSearchQuery, LIMIT, offset);
  };

  const init = async () => {
    const offset = searchQuery?.page ? LIMIT * (searchQuery?.page - 1) : 0;

    searchDistributionRoutes(
      {
        query: searchQuery?.query || null,
        filters: searchQuery?.filters || undefined,
        queryType: searchQuery?.queryType || queryTypes[0],
        page: searchQuery?.page || 1
      },
      LIMIT,
      offset
    );
  };

  useEffect(() => {
    init();
  }, []);

  const searchDistributionRoutes = async (
    searchQueryP: SearchQuery,
    limit: number,
    offset: number
  ) => {
    setLoading(true);
    try {
      const { data } = await postenDistributionService.searchPostCodeInfo(
        searchQueryP,
        limit,
        offset
      );
      setPageResults(data);
    } catch (error) {
      logger.error('Search Distribution Routes failed', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PaperCard sx={{ display: 'flex', flexDirection: 'column', flex: '1', overflow: 'hidden' }}>
      {onClose && (
        <PaperHeader>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="h6">{t('distribution.posten.postCodeOverview')}</Typography>
            {onClose && (
              <IconButton
                onClick={() => {
                  onClose();
                }}
              >
                <Close />
              </IconButton>
            )}
          </Stack>
        </PaperHeader>
      )}
      <PaperContent
        sx={{
          overflowY: 'hidden',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem'
        }}
      >
        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
          <PrimarySearch
            defaultQuery={searchQuery?.query}
            queryTypes={queryTypes}
            onSearch={(query, queryType) => {
              searchDistributionRoutes({ ...searchQuery, query, queryType, page: 1 }, LIMIT, 0);
            }}
          />
          {!onClose && (
            <Button variant='outlined' startIcon={<Upload />} onClick={() => setModal(1)}>
              {t('common.import')}
            </Button>
          )}
        </Stack>
        {pageResults && <PostenDistributionList postenDistributionDataArr={pageResults?.results} />}
        {pageResults && (
          <PrimarySearchPageNav
            page={searchQuery?.page || 1}
            totalResults={pageResults?.total}
            onChangePage={changePage}
          />
        )}
        {loading && (
          <Stack>
            <CircularProgress />
          </Stack>
        )}
      </PaperContent>
      {modal === 1 && (
        <PostenImportPostCodesModal
          onCancel={() => setModal(undefined)}
          onUpdate={() => {
            init();
            setModal(undefined);
          }}
        />
      )}
    </PaperCard>
  );
};

export default PostenDistributionSearch;
