import { CircularProgress, Stack, Typography } from '@mui/material';
import { PaperCard, PaperContent, PaperFooter, PaperHeader, PrimarySearch } from 'components';
import PrimarySearchPageNav from 'components/PrimarySearchPageNav/PrimarySearchPageNav';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import polarisDistributionAddressService from 'services/polaris-distribution-address-service';
import PageResults from 'types/PageResults';
import SearchQuery from 'types/SearchQuery';
import SearchQueryType from 'types/SearchQueryType';
import PolarisAddressList from './PolarisAddressList';

const LIMIT = 40;

const PolarisAddressOverview: FC = () => {
  const { t } = useTranslation();

  const queryTypes: SearchQueryType[] = [
    { key: 'street', label: t('common.streetName') },
    { key: 'postCode', label: t('common.postCode') },
    { key: 'routeId', label: t('common.routeId') }
  ];
  const [loading, setLoading] = useState<boolean>();
  const [pageResults, setPageResults] = useState<PageResults<PolarisAddress>>();
  const [searchQuery, setSearchQuery] = useState<SearchQuery>();

  const searchPolarisAddresses = async (
    searchQueryP: SearchQuery,
    limit: number,
    offset: number
  ) => {
    setLoading(true);
    try {
      const { data } = await polarisDistributionAddressService.search(searchQueryP, limit, offset);
      setPageResults(data);

      setSearchQuery({
        query: searchQueryP?.query || null,
        filters: searchQueryP?.filters || undefined,
        queryType: searchQueryP?.queryType || queryTypes[0],
        page: searchQueryP?.page || 1
      });
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const changePage = (value: number) => {
    const updatedSearchQuery = { ...searchQuery, page: value };
    setSearchQuery(updatedSearchQuery);
    const offset = (value - 1) * LIMIT;
    searchPolarisAddresses(updatedSearchQuery, LIMIT, offset);
  };

  const init = async () => {
    const offset = searchQuery?.page ? LIMIT * (searchQuery?.page - 1) : 0;

    searchPolarisAddresses(
      {
        query: searchQuery?.query || null,
        filters: searchQuery?.filters || undefined,
        queryType: searchQuery?.queryType || queryTypes[0],
        page: searchQuery?.page || 1
      },
      LIMIT,
      offset
    );
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <PaperCard
      sx={{
        display: 'flex',
        maxWidth: '1000px',
        flexDirection: 'column',
        flex: '1',
        overflow: 'hidden'
      }}
    >
      <PaperHeader>
        <Stack gap={2} alignItems={'center'} direction={'row'}>
          <Typography variant='h6'>{t('common.addressOverview')}</Typography>
          <PrimarySearch
            defaultQuery={searchQuery?.query}
            queryTypes={queryTypes}
            onSearch={(query, queryType) => {
              searchPolarisAddresses({ ...searchQuery, query, queryType, page: 1 }, LIMIT, 0);
            }}
          />
          <Typography whiteSpace={'nowrap'}>
            {t('common.total')} {pageResults?.total}
          </Typography>
        </Stack>
      </PaperHeader>
      <PaperContent
        np
        sx={{
          overflowY: 'hidden',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem'
        }}
      >
        {pageResults && !loading && <PolarisAddressList polarisAddresses={pageResults.results} />}
        {loading && (
          <Stack p={4} direction={'row'} justifyContent={'center'}>
            <CircularProgress />
          </Stack>
        )}
      </PaperContent>
      <PaperFooter>
        {pageResults && (
          <PrimarySearchPageNav
            page={searchQuery?.page || 1}
            totalResults={pageResults?.total}
            onChangePage={changePage}
            resultsPerPage={40}
          />
        )}
      </PaperFooter>
    </PaperCard>
  );
};

export default PolarisAddressOverview;
