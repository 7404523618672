import { Typography } from '@mui/material';
import { FC } from 'react';
import UserInvoiceSettings from 'types/UserInvoiceSettings';

type BillingContactCardProps = {
  userInvoiceSettings: UserInvoiceSettings;
};

const BillingContactCard: FC<BillingContactCardProps> = ({ userInvoiceSettings }) => {
  return (
    <Typography>
      {userInvoiceSettings.billingContactName || '--'}<br/>
      {userInvoiceSettings.billingContactEmail || '--'}<br/>
      {userInvoiceSettings.billingContactPhone || '--'}<br/>
      {userInvoiceSettings.billingContactAddress || '--'}<br/>
      {userInvoiceSettings.billingContactPostCode  || '--'} {userInvoiceSettings.billingContactPostOffice}
    </Typography>
  );
};
 
export default BillingContactCard;
