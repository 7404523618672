import { Stack, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import FormDialog from 'components/FormDialog/FormDialog';
import PhoneNumberInputCountry from 'components/PhoneNumber/PhoneNumberCountry';
import useAlert from 'hooks/useAlert';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useRef, useState } from 'react';
import {
  SubmitHandler,
  useForm,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userService from 'services/user-service';
import CountryCode from 'types/CountryCode';
import User from 'types/User';

type AddUserModalProps = {
  onConfirm: (user: User) => void;
  onCancel: () => void;
};

type FormValues = {
  phone: string;
  name: string;
  password: string;
  phoneCountryCode: CountryCode;
};

const AddUserModal: FC<AddUserModalProps> = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { addAlert } = useAlert();
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const axiosAlert = useAxiosAlert();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formMethods = useForm<FormValues>();
  const { errors } = formMethods.formState;

  const onSubmit: SubmitHandler<FormValues> = async (fields) => {
    const phone = +fields.phone.split(' ').join('');
    if (isNaN(phone)) {
      addAlert(t('alerts.phoneNotNumber'), 'error');
      return;
    }
    setLoading(true);
    try {
      const addUserQuery = await userService.addUser(
        phone,
        fields.phoneCountryCode,
        fields.name,
        fields.password
      );
      setFormSubmitted(true);
      onConfirm(addUserQuery.data);
    } catch (error) {
      axiosAlert(
        error as AxiosError,
        [
          {
            status: 409,
            message: t('alerts.phoneNumberTaken')
          }
        ],
        t('alerts.somethingWentWrong')
      );
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      phoneInputRef.current?.focus();
    }, 10);
  }, []);

  return (
    <FormDialog
      title={t('common.addUser')}
      onSubmit={formMethods.handleSubmit(onSubmit)}
      onCancel={onCancel}
      submitText={t('common.addUser')}
      loading={loading}
    >
      <Stack gap={'20px'} sx={{ minWidth: '400px' }}>
        <Typography variant='caption' component='label' fontSize={'0.9rem'}>
          {t('common.phone')} *
        </Typography>
        <PhoneNumberInputCountry
          focusInput={false}
          formSubmitted={formSubmitted}
          fieldName='phone'
          register={
            formMethods.register as unknown as UseFormRegister<{
              phone: string;
              phoneCountryCode: CountryCode;
            }>
          }
          formState={formMethods.formState}
          setValue={
            formMethods.setValue as unknown as UseFormSetValue<{
              phone: string;
              phoneCountryCode: CountryCode;
            }>
          }
          getValues={
            formMethods.getValues as unknown as UseFormGetValues<{
              phone: string;
              phoneCountryCode: CountryCode;
            }>
          }
          watch={formMethods.watch}
        />
        <TextField
          {...formMethods.register('name', {
            required: true,
            minLength: { value: 2, message: t('common.tooShort') },
            maxLength: { value: 50, message: t('common.tooLong') }
          })}
          fullWidth
          type={'text'}
          name={'name'}
          placeholder={t('common.name')}
          label={t('common.name')}
          error={errors?.name ? true : false}
          helperText={errors.name ? errors.name.message : ''}
        />
        <TextField
          {...formMethods.register('password', {
            required: true,
            minLength: { value: 6, message: t('common.tooShort') }
          })}
          fullWidth
          type={'password'}
          name={'password'}
          placeholder={t('common.password')}
          label={t('common.password')}
          error={errors?.password ? true : false}
          helperText={errors.password ? errors.password.message : ''}
        />
      </Stack>
    </FormDialog>
  );
};

export default AddUserModal;
