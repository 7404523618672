import { FC, useState } from 'react';
import { Close, InfoOutlined } from '@mui/icons-material';
import { Alert, Box, Button, IconButton, Stack, SxProps } from '@mui/material';
import COLORS from 'consts/colors';

type InfoToggleProps = {
  text: string;
  label?: string;
  sx?: SxProps;
  img?: string;
};

const InfoToggle: FC<InfoToggleProps> = ({ text, label, sx, img }) => {
  const [toggleInfo, setToggleInfo] = useState<boolean>(false);

  const alertStyle: SxProps = {
    cursor: 'pointer !important'
  };

  return (
    <>
      {toggleInfo ? (
        <Alert
          sx={{ ...alertStyle, ...sx }}
          onClick={() => {
            setToggleInfo(false);
          }}
          action={
            <IconButton
              title='test'
              onClick={() => {
                setToggleInfo(false);
              }}
            >
              <Close fontSize='small' />
            </IconButton>
          }
          severity='info'
        >
          <Stack direction={'column'} gap={1}>
            {label && <p style={{ margin: 0 }}>{label}</p>}
            {text}
            {img && (
              <Box sx={{ height: '100px', width: 'auto' }}>
                <img
                  src={img}
                  style={{
                    height: '100%',
                    width: 'auto'
                  }}
                />
              </Box>
            )}
          </Stack>
        </Alert>
      ) : label ? (
        <Button
          onClick={() => {
            setToggleInfo(true);
          }}
          sx={{
            backgroundColor: COLORS.INFO_BACKGROUND,
            color: COLORS.INFO,
            border: 'none',
            textTransform: 'none',
            borderRadius: '20px',
            ['&:hover']: {
              border: 'none'
            }
          }}
          variant='outlined'
          disableElevation
          startIcon={<InfoOutlined />}
        >
          {label}
        </Button>
      ) : (
        <IconButton
          sx={{
            backgroundColor: COLORS.INFO_BACKGROUND,
            color: COLORS.INFO
          }}
          onClick={() => {
            setToggleInfo(true);
          }}
        >
          <InfoOutlined />
        </IconButton>
      )}
    </>
  );
};

export default InfoToggle;
