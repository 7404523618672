import { FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, SxProps, Typography } from '@mui/material';
import { PageContainer, PaperCard, PaperContent, PaperHeader } from 'components';
import { AxiosPromise, AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { useTranslation } from 'react-i18next';
import subscriptionService from 'services/subscription-service';
import userSubscriptionService from 'services/user-subscrption.service';
import SubscriptionList from './components/SubscriptionList/SubscriptionList';
import SubscriptionModal from './components/SubscriptionModal/SubscriptionModal';
import Subscription from 'types/Subscription';

const Subscriptions: FC = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>(null);
  const axiosAlert = useAxiosAlert();
  const { t } = useTranslation();

  const [modal, setModal] = useState<number>();

  const resetModal = () => {
    setModal(null);
  };

  const styles: { [k: string]: SxProps } = {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px',
      alignItems: 'center'
    }
  };

  const getSubscriptions = useCallback(async () => {
    try {
      const { data: subscriptionsRes } = await subscriptionService.get();

      const ps: AxiosPromise<number>[] = [];

      // Get number of active users for each subscription
      subscriptionsRes.forEach((subscription: Subscription) => {
        ps.push(userSubscriptionService.getNumberOfActiveUsers(subscription.id));
      });

      (await Promise.all(ps)).forEach(({ data: numberOfActiveUsers }, index) => {
        subscriptionsRes[index].numberOfActiveUsers = numberOfActiveUsers;
      });

      setSubscriptions(subscriptionsRes);
    } catch (error) {
      axiosAlert(error as AxiosError, [
        {
          status: 500,
          message: t('alerts.couldNotGetSubscriptions')
        }
      ]);
    }
  }, []);

  useEffect(() => {
    if (!subscriptions) {
      getSubscriptions();
    }
  }, [getSubscriptions, subscriptions]);

  return (
    <PageContainer>
      <PaperCard sx={{ width: 'auto' }}>
        <PaperHeader>
          <Box sx={styles.header}>
            <Typography variant='h4'>{t('common.subscriptions')}</Typography>
            <Button variant={'outlined'} onClick={() => setModal(1)}>
              {t('subscription.createNew')}
            </Button>
          </Box>
        </PaperHeader>
        <PaperContent sx={{ width: '800px' }} np>
          {subscriptions ? (
            <SubscriptionList subscriptions={subscriptions} />
          ) : (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress sx={{ margin: '10px 0' }} />
            </div>
          )}
          {modal === 1 && (
            <SubscriptionModal
              onCancel={resetModal}
              onConfirm={() => {
                resetModal();
                getSubscriptions();
              }}
            />
          )}
        </PaperContent>
      </PaperCard>
    </PageContainer>
  );
};

export default Subscriptions;
