import { FC, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import reportService from 'services/report-service';
import { CircularProgress } from '@mui/material';
import COLORS from 'consts/colors';

type SubscriberChartProps = {
  sizeRef: React.MutableRefObject<HTMLImageElement>;
};
const SubscriberChart: FC<SubscriberChartProps> = ({ sizeRef }) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartWidth, setChartWidth] = useState(null);
  const [chartHeight, setChartHeight] = useState(null);
  const [reportData, setReportData] = useState(null);

  let options: Highcharts.Options;
  if (reportData) {
    options = {
      accessibility: { enabled: false },
      colors: [COLORS.SECONDARY_LIGHT],
      chart: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        width: chartWidth,
        height: chartHeight
      },
      title: {
        text: new Date().getFullYear().toString(),
        margin: 50,
        y: 30,
        style: {
          color: '#FFF'
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        itemStyle: {
          color: '#ccc'
        },
        itemHoverStyle: {
          color: '#fff'
        }
      },
      xAxis: {
        categories: [
          'jan',
          'feb',
          'mar',
          'apr',
          'may',
          'jun',
          'jul',
          'aug',
          'sep',
          'oct',
          'nov',
          'dec'
        ],
        labels: {
          style: {
            color: '#bbb'
          }
        }
      },
      yAxis: {
        title: {
          text: null
        },
        labels: {
          style: {
            color: '#bbb'
          }
        }
      },
      series: [
        {
          type: 'line',
          name: 'Subscribers',
          data: reportData
        }
      ]
    };
  }

  useEffect(() => {
    const updateChartSize = () => {
      setChartWidth(sizeRef.current ? sizeRef.current.width : null);
      setChartHeight(sizeRef.current ? sizeRef.current.height : null);
    };
    let resizeEvent: NodeJS.Timeout = null;
    const resizeEventListner = () => {
      clearTimeout(resizeEvent);
      resizeEvent = setTimeout(updateChartSize, 500);
    };

    (async () => {
      const reportDataQuery = await reportService.getSubscriberCountByMonth();
      setReportData(reportDataQuery.data);
      updateChartSize();
      sizeRef.current.onload = updateChartSize; // Make sure to adjust after image is loaded.
      window.addEventListener('resize', resizeEventListner, false);
    })();
    return () => {
      window.removeEventListener('resize', resizeEventListner);
    };
  }, [sizeRef]);

  return (
    <>
      {reportData ? (
        <div
          className="subscriber-chart"
          style={{ position: 'absolute', top: '20px', left: '20px', zIndex: 99, maxWidth: '100%' }}
        >
          <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
        </div>
      ) : (
        <div style={{ position: 'absolute', top: '45%', width: '100%', textAlign: 'center' }}>
          <CircularProgress sx={{ margin: '10px 0' }} />
        </div>
      )}
    </>
  );
};

export default SubscriberChart;
