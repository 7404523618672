import { Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { PaperCard } from 'components';
import { ROUTE_PATHS } from 'consts/routes';
import useAlert from 'hooks/useAlert';
import useAxiosAlert from 'hooks/useAxiosAlert';
import logger from 'logger/logger';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import paymentService from 'services/payment-service';
import userService from 'services/user-service';
import PaymentType from 'types/Payment';
import User from 'types/User';
import PaymentContent from './PaymentContent/PaymentContent';
import PaymentHeader from './PaymentHeader/PaymentHeader';

const Payment = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const [payment, setPayment] = useState<PaymentType>(null);
  const axiosAlert = useAxiosAlert();
  const { addAlert } = useAlert();

  const getPayment = async () => {
    setLoading(true);
    try {
      const { data: paymentData } = await paymentService.getById(parseInt(params.id));
      setPayment(paymentData);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.couldNotGetPayment'));
    } finally {
      setLoading(false);
    }
  };

  const manualCapture = async (completed: Date) => {
    setLoading(true);
    try {
      const { data: updatedPayment }: { data: PaymentType } = await paymentService.manualCapture(
        payment.id,
        completed
      );

      if (!updatedPayment.user) {
        const { data: user }: { data: User } = await userService.getById(updatedPayment.userId);
        updatedPayment.user = user;
      }
      setPayment(updatedPayment);
      t('payment.alerts.captureError');
    } catch (error) {
      logger.error('Capture payment failed.', error);
      axiosAlert(error as AxiosError, [], t('payment.alerts.captureError'));
    } finally {
      setLoading(false);
    }
  };

  const tryCapture = async () => {
    setLoading(true);
    try {
      const { data: updatedPayment }: { data: PaymentType } = await paymentService.tryCapture(
        payment.id
      );

      if (!updatedPayment.user) {
        const { data: user }: { data: User } = await userService.getById(updatedPayment.userId);
        updatedPayment.user = user;
      }
      setPayment(updatedPayment);
      t('payment.alerts.captureError');
    } catch (error) {
      logger.error('Capture payment failed.', error);
      axiosAlert(error as AxiosError, [], t('payment.alerts.captureError'));
    } finally {
      setLoading(false);
    }
  };

  const handleSync = async () => {
    setLoading(true);
    
    try {
      const { data: updatedPayment }: { data: PaymentType } = await paymentService.sync(payment.id);

      if (!updatedPayment.user) {
        const { data: user }: { data: User } = await userService.getById(updatedPayment.userId);
        updatedPayment.user = user;
      }
      setPayment(updatedPayment);
      addAlert(
        'success',
        t('payment.alerts.syncSuccess', { paymentSolution: payment.paymentSolution })
      );
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('payment.alerts.syncError'));
    } finally {
      setLoading(false);
    }
  };

  const handleRefund = async (refundDescription : string) => {
    setLoading(true);
    try {
      const { data: updatedPayment } = await paymentService.createRefund(
        payment.id,
        refundDescription
      );
      updatedPayment.refundDescription = refundDescription;

      if (!updatedPayment.user) {
        const { data: user }: { data: User } = await userService.getById(updatedPayment.userId);
        updatedPayment.user = user;
      }

      setPayment(updatedPayment);
      addAlert('success', t('payment.alerts.refundSuccess'));
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('payment.alerts.refundError'));
    } finally {
      setLoading(false);
    }
  };

  const handleEditRefundDescription = async (refundDescription : string) => {
    setLoading(true);
    try{
      const { data: updatedPayment } = await paymentService.editRefundDescription(payment.id, refundDescription);
      setPayment(updatedPayment);
    } catch(error) {

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPayment();
  }, []);

  return (
    <PaperCard sx={{ minWidth: '800px' }}>
      {payment !== null && (
        <div>
          <PaymentHeader
            payment={payment}
            setLoading={setLoading}
            loading={loading}
            onBack={() => {
              navigate(ROUTE_PATHS.PAYMENTS);
            }}
            tryCapture={tryCapture}
            manualCapture={manualCapture}
            onRefund={handleRefund}
            onExternalSync={handleSync}
          />
          <Divider />
          <PaymentContent loading={loading} payment={payment} onEdit={handleEditRefundDescription} />
        </div>
      )}
    </PaperCard>
  );
};

export default Payment;
