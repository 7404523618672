import Card from 'types/Card';
import { Stack, SxProps, Typography } from '@mui/material';
import CardBrandLogo from 'components/CardBrandLogo/CardBrandLogo';

type CardInfoProps = {
  card: Card;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  logoSx?: SxProps;
  hideExpirey?: boolean;
};

// create functional component CardInfo with props card of type CardInfoProps
const CardInfo = ({ card, variant = 'body1', logoSx, hideExpirey = false }: CardInfoProps) => {
  // Parses the expiry date to a string in the format MM/YY
  const parseDateToMMYY = (_date: Date) => {
    const date = new Date(_date);
    date.setDate(date.getDate() - 1);
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const year = date.getFullYear().toString().slice(2);
    return `${month}/${year}`;
  };

  const cardExpiryDate = parseDateToMMYY(card.expiryDate);

  return (
    <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
      <Typography variant={variant}>{card.maskedPan}</Typography>
      {!hideExpirey && <Typography variant={variant}>{cardExpiryDate}</Typography>}
      <CardBrandLogo cardBrand={card.brand} sx={logoSx} />
    </Stack>
  );
};

export default CardInfo;
