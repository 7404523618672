import './PaperCard.scss';
import { Paper, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

type PaperCardProps = {
  children: ReactNode;
  sx?: SxProps;
  fullWidth?: boolean;
  className?: string;
  variant?: 'outlined' | 'elevation';
};

const PaperCard: FC<PaperCardProps> = ({ children, sx, fullWidth = false, className, variant }) => {
  const styles: SxProps = {
    width: fullWidth ? '100%' : 'auto'
    /*   boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12)` */
  };

  return (
    <Paper variant={variant} className={`paper-card ${className} `} sx={{ ...styles, ...sx }}>
      {children}
    </Paper>
  );
};

export default PaperCard;
