import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentMethod from 'types/PaymentMethod';

type PaymentMethodChipProps = {
  paymentMethod: PaymentMethod;
};

const getStatusColor = (method: PaymentMethod) => {
  switch (method) {
    case 'INVOICE_DD_EMAIL':
    case 'INVOICE_DD_PAPER':
    case 'INVOICE_DD_BANK_NOTIFICATION':
      return 'warning';
    case 'INVOICE_EMAIL':
      return 'info';
    case 'INVOICE_PAPER':
      return 'default';
    case 'INVOICE_EINVOICE':
    case 'INVOICE_DD_EINVOICE':
      return 'success';
    default:
      return 'default';
  }
};

const PaymentMethodChip: FC<PaymentMethodChipProps> = ({ paymentMethod }) => {
  const { t } = useTranslation();

  return (
    <Chip
      sx={{ margin: '-8px 0' }}
      color={getStatusColor(paymentMethod)}
      label={t(`paymentMethod.${paymentMethod}`)}
    />
  );
};

export default PaymentMethodChip;
