import { ChangeEvent, FC, useState } from 'react';
import './ChangePasswordModal.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';
import { Box } from '@mui/system';

type ChangePasswordModalProps = {
  onCancel: () => void;
  onConfirm: (newPassword: string, oldPassword: string) => void;
};

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();

  const [fields, setFields] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: ''
  });

  const confirmChangesHandler = () => {
    const isValid = validate();

    if (isValid) {
      onConfirm(fields.newPassword, fields.oldPassword);
    }
  };

  const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const validate = () => {
    if (fields.newPassword.length < 5 || fields.newPasswordRepeat.length < 5) {
      alert(t('alert.passwordLength'));
      return false;
    }

    if (fields.newPassword != fields.newPasswordRepeat) {
      alert(t('alert.passwordsNotEqual'));
      return false;
    }

    return true;
  };

  return (
    <Dialog open={true}>
      <div className="change-password-dialog">
        <DialogTitle>{capitalizeFirstLetter(t('profile.changePassword'))}</DialogTitle>
        <DialogContent>
          <Box className="inputs">
            <TextField
              label={capitalizeFirstLetter(t('profile.oldPassword'))}
              name={'oldPassword'}
              type={'password'}
              value={fields.oldPassword}
              onChange={inputChangeHandler}
              fullWidth
            />
            <TextField
              label={capitalizeFirstLetter(t('profile.newPassword'))}
              name={'newPassword'}
              type={'password'}
              value={fields.newPassword}
              onChange={inputChangeHandler}
              fullWidth
            />
            <TextField
              label={`${capitalizeFirstLetter(t('common.confirm'))} ${t('profile.newPassword')}`}
              name={'newPasswordRepeat'}
              type={'password'}
              value={fields.newPasswordRepeat}
              onChange={inputChangeHandler}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{t('common.cancel')}</Button>
          <Button onClick={confirmChangesHandler}>{t('common.confirmChanges')}</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ChangePasswordModal;
