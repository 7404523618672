import { Delete, Edit } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { PaperCard, PaperContent, PaperHeader } from 'components';

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BulkModal from './BulkModal';
import { ConsentModal } from 'modals';

type BulkListProps = {
  bulks: Bulk[];
  onBulkChanged: () => void;
  onDeleteBulk: (bulkId: number) => void;
};

const BulkList: FC<BulkListProps> = ({ bulks = [], onBulkChanged, onDeleteBulk }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<number>();
  const [bulkInEdit, setBulkInEdit] = useState<Bulk>();

  return (
    <>
      <PaperCard>
        <PaperHeader>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography textAlign='left' variant={'h6'}>
              {t('common.bulk')}
            </Typography>
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <Typography textAlign='right'>
                {t('common.quantity')}: {bulks.length}
              </Typography>
              <Button
                onClick={() => {
                  setModal(0);
                  setBulkInEdit(undefined);
                }}
              >
                <Typography>
                  {t('common.addBlank', {
                    blank: t('common.bulk')
                  })}
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </PaperHeader>
        <PaperContent np>
          {(bulks.length > 0 && (
            <TableContainer sx={{ overflowX: 'unset' }}>
              <Table sx={{ minWidth: 350 }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography>{t('distribution.ID')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.name')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{t('common.routeId')}</Typography>
                    </TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bulks.map((bulk) => (
                    <TableRow key={bulk.id}>
                      <TableCell>
                        <Typography>{bulk.id}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{bulk.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{bulk.bulkRouteId}</Typography>
                      </TableCell>
                      <TableCell style={{ padding: '0', width: '1%' }}>
                        <IconButton
                          edge='end'
                          onClick={() => {
                            setModal(1);
                            setBulkInEdit(bulk);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ padding: '0', width: '1%' }}>
                        <IconButton
                          edge='end'
                          onClick={() => {
                            setModal(0);
                            setBulkInEdit(bulk);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )) || (
            <Stack sx={{ p: 4 }} direction={'row'} justifyContent={'center'}>
              <Typography>{t('distribution.errors.emptyBulk')}</Typography>
            </Stack>
          )}
        </PaperContent>
      </PaperCard>
      {modal === 0 && (
        <BulkModal
          bulk={bulkInEdit}
          onCancel={() => {
            setModal(undefined);
            setBulkInEdit(undefined);
          }}
          onSaved={() => {
            setModal(undefined);
            setBulkInEdit(undefined);
            onBulkChanged();
          }}
        />
      )}
      {modal === 1 && (
        <ConsentModal
          contentSx={{
            minWidth: '300px'
          }}
          title={t('common.delete')}
          content={t('common.confirmDelete')}
          confirmText={t('common.delete')}
          onConfirm={() => {
            setModal(undefined);
            onDeleteBulk(bulkInEdit?.id);
            setBulkInEdit(undefined);
          }}
          onCancel={() => {
            setModal(undefined);
            setBulkInEdit(undefined);
          }}
        />
      )}
    </>
  );
};

export default BulkList;
