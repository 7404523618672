import { Button, Stack, Typography } from '@mui/material';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import { ROUTE_PATHS } from 'consts/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const DistributionOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PaperCard>
      <PaperHeader>
        <Typography variant='h5'>{t('distribution..')}</Typography>
      </PaperHeader>
      <PaperContent
        sx={{
          width: '500px'
        }}
      >
        <Stack gap={2} flexWrap={'wrap'} direction={'row'} justifyContent={'flex-start'}>
          <Button variant='outlined' onClick={() => navigate(ROUTE_PATHS.CURRIER)}>
            <Typography>{t('common.currierRoutes')}</Typography>
          </Button>
          <Button variant='outlined' onClick={() => navigate(ROUTE_PATHS.BULK)}>
            <Typography>{t('common.bulk')}</Typography>
          </Button>
          <Button variant='outlined' onClick={() => navigate(ROUTE_PATHS.POSTEN)}>
            <Typography>{t('common.posten')}</Typography>
          </Button>
          <Button variant='outlined' onClick={() => navigate(ROUTE_PATHS.POLARIS)}>
            <Typography>{t('common.polaris')}</Typography>
          </Button>
          <Button variant='outlined' onClick={() => navigate(ROUTE_PATHS.PAPER_RETAIL)}>
            <Typography>{t('distribution.paperRetail')}</Typography>
          </Button>
          <Button variant='outlined' onClick={() => navigate(ROUTE_PATHS.PAPER_USER_MANAGEMENT)}>
            <Typography>{t('common.userManagement')}</Typography>
          </Button>
          <Button variant='outlined' onClick={() => navigate(ROUTE_PATHS.FULL_DISTRIBUTION)}>
            <Typography>{t('common.fullDistribution')}</Typography>
          </Button>
        </Stack>
      </PaperContent>
    </PaperCard>
  );
};

export default DistributionOverview;
