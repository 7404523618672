import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DistributionRoute from 'types/DistributionRoute';
import DistributionRouteModal from 'components/DistributionRouteModal/DistributionRouteModal';
import DistributorChip from 'components/DistributorChip/DistributorChip';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'consts/routes';

type DistributionRouteListProps = {
  distributionRoutes: DistributionRoute[];
  onUpdate: (distributionRoute?: DistributionRoute) => void;
};

const DistributionRouteList: FC<DistributionRouteListProps> = ({
  distributionRoutes = [],
  onUpdate
}) => {
  const { t } = useTranslation();
  const [distributionRouteInEdit, setDistributionRouteInEdit] = useState<DistributionRoute>();
  const [modal, setModal] = useState<number>();

  return (
    <>
      {(distributionRoutes.length > 0 && (
        <TableContainer sx={{ overflowX: 'unset' }}>
          <Table sx={{ minWidth: 350 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>{t('distribution.ID')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.name')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.postCode')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.type')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.named')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.subscribers')}</Typography>
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {distributionRoutes.map((distributionRoute) => (
                <TableRow key={distributionRoute.id}>
                  <TableCell>
                    <Typography>{distributionRoute.id}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {distributionRoute.postenDistributionRoute
                        ? distributionRoute?.postenDistributionRoute?.name
                        : distributionRoute.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {distributionRoute.postCodeFrom}
                      {distributionRoute.postCodeTo ? ` - ${distributionRoute.postCodeTo}` : ''}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <DistributorChip distributor={distributionRoute.type} />
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {distributionRoute.named ? t('common.named') : t('common.unamed')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Link
                      style={{ color: '#fff ' }}
                      to={ROUTE_PATHS.CURRIER_ROUTE_USERS.replace(
                        ':id',
                        distributionRoute.id.toString()
                      )}
                    >
                      <Typography>{distributionRoute.numberOfUsers}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell align='right'>
                    <IconButton
                      edge='end'
                      onClick={() => {
                        setDistributionRouteInEdit(distributionRoute);
                        setModal(1);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )) || (
        <Stack sx={{ p: 4 }} direction={'row'} justifyContent={'center'}>
          <Typography>{t('distribution.errors.empty')}</Typography>
        </Stack>
      )}
      {modal === 1 && distributionRouteInEdit && (
        <DistributionRouteModal
          onDelete={() => {
            setModal(null);
            setDistributionRouteInEdit(undefined);
            onUpdate();
          }}
          onCancel={() => {
            setModal(null);
            setDistributionRouteInEdit(undefined);
          }}
          onFinished={(updatedDistributionRoute: DistributionRoute) => {
            setModal(null);
            onUpdate(updatedDistributionRoute);
          }}
          distributionRoute={distributionRouteInEdit}
        />
      )}
    </>
  );
};

export default DistributionRouteList;
