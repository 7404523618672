/* eslint-disable indent */
import { Badge, Box, Tab, Tabs, Typography } from '@mui/material';
import { PageContainer, PaperCard, PaperContent, TabPanel } from 'components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OcrTab from './OcrTab/OcrTab';
import UnpaidInvoicesTab from './UnpaidInvoicesTab/UnpaidInvoicesTab';
import DirectDebitTab from './DirectDebitTab/DirectDebitTab';
import EInvoicesTab from './EInvoicesTab/EInvoicesTab';
import useSettings from 'hooks/useSettings';
import invoiceService, { UnhandledInvoices } from 'services/invoice-service';
import PaperInvoicesTab from './PaperInvoicesTab/PaperInvoicesTab';

const Invoices: FC = () => {
  const { t } = useTranslation();
  const settings = useSettings();
  const [tab, setTab] = useState<number>(0);
  const eInvoiceActive = settings.getValueByKeyAsBoolean('EInvoiceActive');
  const directDebitActive = settings.getValueByKeyAsBoolean('DirectDebitActive');
  const [unhandledInvoices, setUnhandledInvoices] = useState<UnhandledInvoices>(null);

  //const navigate = useNavigate();

  const getUnhandledInvoices = async () => {
    try {
      const result = await invoiceService.getUnhandledInvoices();
      setUnhandledInvoices(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUnhandledInvoices();
  }, []);

  return (
    <PageContainer>
      <PaperCard sx={{ width: '100%' }}>
        <div style={{ padding: '20px 0 0 20px' }}>
          <Typography variant='h4'>{t('common.invoices')}</Typography>
        </div>
        <Box sx={{ width: '100%', borderBottom: '1px solid rgba(255,255,255,0.12)' }}>
          <Tabs value={tab} onChange={(_e, index) => setTab(index)} sx={{ paddingTop: '20px' }}>
            <Tab value={0} label={t('invoice.unpaidInvoices')} />

            <Tab
              value={1}
              label={
                unhandledInvoices?.paper > 0 ? (
                  <Badge badgeContent={unhandledInvoices?.paper} color='primary'>
                    {t('invoice.paperInvoices')}&nbsp;&nbsp;
                  </Badge>
                ) : (
                  t('invoice.paperInvoices')
                )
              }
            />

            {directDebitActive && (
              <Tab
                value={2}
                label={
                  unhandledInvoices?.directDebit > 0 ? (
                    <Badge badgeContent={unhandledInvoices?.directDebit} color='primary'>
                      {t('invoice.directDebit')}&nbsp;&nbsp;
                    </Badge>
                  ) : (
                    t('invoice.directDebit')
                  )
                }
              />
            )}
            {eInvoiceActive && (
              <Tab
                value={3}
                label={
                  unhandledInvoices?.eInvoice > 0 ? (
                    <Badge badgeContent={unhandledInvoices?.eInvoice} color='primary'>
                      {t('invoice.eInvoice')}&nbsp;&nbsp;
                    </Badge>
                  ) : (
                    t('invoice.eInvoice')
                  )
                }
              />
            )}
            <Tab value={4} label={t('invoice.ocr')} />
          </Tabs>
        </Box>

        <PaperContent np>
          <TabPanel value={tab} index={0} sx={{ padding: '0' }}>
            <UnpaidInvoicesTab />
          </TabPanel>

          <TabPanel value={tab} index={1} sx={{ padding: '0' }}>
            <PaperInvoicesTab getUnhandledInvoices={getUnhandledInvoices} />
          </TabPanel>

          {directDebitActive && (
            <TabPanel value={tab} sx={{ padding: '0' }} index={2}>
              <DirectDebitTab getUnhandledInvoices={getUnhandledInvoices} />
            </TabPanel>
          )}
          {eInvoiceActive && (
            <TabPanel value={tab} sx={{ padding: '0' }} index={3}>
              <EInvoicesTab getUnhandledInvoices={getUnhandledInvoices} />
            </TabPanel>
          )}

          <TabPanel value={tab} index={4}>
            <OcrTab />
          </TabPanel>
        </PaperContent>
      </PaperCard>
    </PageContainer>
  );
};

export default Invoices;
