import axios from 'axios';
import StandardReport from 'types/StandardReport';
import SubscriptionDistributionReport from 'types/SubscriptionDistributionReport';

const reportService = {
  getSubscriberCountByMonth: () => axios.get<number[]>('report/subscriber-count-by-month'),
  getUserEmails: () => axios.get<StandardReport>('report/user-emails'),
  getLatestSubscriberCount: () => axios.get('report/latest-subscriber-count'),
  getInvoicesForHandling: () =>
    axios.get<{
      unsentInvoices: number;
      overdueInvoices: number;
    }>('report/invoices-for-handling'),
  getSubscriptionsStartedToday: () => axios.get('report/subscriptions-bought-today'),
  getUsersWithOutSubscription: () => axios.get('report/users-without-subscription'),
  getSubscriptionsCanceledYesterdayCount: () =>
    axios.get('report/subscriptions-canceled-yesterday-count'),
  getSubscribersWithoutPayment: () =>
    axios.get<StandardReport>('report/subscribers-without-payment'),
  getIncomeForMonth: (year: number | string, month: number | string) =>
    axios.get<StandardReport>(`report/income-for-month/${year}/${month}`),
  getPaymentReceivedForMonth: (year: number | string, month: number | string) =>
    axios.get<StandardReport>(`report/payment-received-for-month/${year}/${month}`),
  getPaymentsForMonth: (year: number | string, month: number | string) =>
    axios.get<StandardReport>(`report/payments-for-month/${year}/${month}`),
  getClosingBalance: (year: number | string, month: number | string) =>
    axios.get<StandardReport>(`report/closing-balance/${year}/${month}`),
  getSubscriptionsStarted: (year: number | string, month: number | string) =>
    axios.get<StandardReport>(`report/subscriptions-started/${year}/${month}`),
  usersWithExpiredSubscription: (days: number | string) =>
    axios.get<StandardReport>(`report/users-with-expired-subscription/${days}`),
  getSubscriptionDistribution: () =>
    axios.get<SubscriptionDistributionReport[]>('report/subscription-distribution'),
  getSubscribersPerMunicipality: () =>
    axios.get<StandardReport>('report/subscribers-per-municipality'),
  getSubscribersPerMonth: (year: number | string, month: number | string) =>
    axios.get<StandardReport>(`report/subscribers-per-month/${year}/${month}`),
  getSubscribersWithPaymentFailed: () =>
    axios.get<StandardReport>('report/subscribers-with-payment-failed/'),
  getInvoicesForMonth: (year: number | string, month: number | string) =>
    axios.get<StandardReport>(`report/invoices-per-month/${year}/${month}`),
  getInvoiceCountPerYear: (year: number | string) =>
    axios.get<StandardReport>(`report/invoice-count-per-year/${year}`),
  getCampaignPurchases: (year: number | string, month: number | string) =>
    axios.get<StandardReport>(`report/campaign-purchases/${year}/${month}`)
};

export default reportService;
