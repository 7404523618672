/* eslint-disable indent */
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PaymentSolutionChip from 'components/PaymentSolutionChip/PaymentSolutionChip';
import PaymentStatusChip from 'components/PaymentStatusChip/PaymentStatusChip';
import { ROUTE_PATHS } from 'consts/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Payment from 'types/Payment';
import { dateString } from 'utils';
import EditIcon from '@mui/icons-material/Edit';

type PaymentListProps = {
  payments: Payment[];
};

const PaymentList: FC<PaymentListProps> = ({ payments }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box>
      {payments === null || payments === undefined ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress sx={{ margin: '10px 0' }} />
        </Box>
      ) : payments.length > 0 ? (
        <TableContainer sx={{ overflowX: 'unset' }}>
          <Table sx={{ width: '100%' }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography align='center'>#</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.solution')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('payment.created')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.user')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ minWidth: '80px' }} align='center'>
                    {t('common.price')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('payment.orderType')}</Typography>
                </TableCell>
                <TableCell sx={{ minWidth: '175px' }}>
                  <Typography>{t('payment.servicePeriod')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.status')}</Typography>
                </TableCell>

                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((payment) => (
                <TableRow key={payment.id}>
                  <TableCell>
                    <Typography>{payment?.id}</Typography>
                  </TableCell>
                  <TableCell>
                    <PaymentSolutionChip paymentSolution={payment.paymentSolution} />
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {payment?.created ? dateString(new Date(payment?.created)) : null}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Link
                      style={{ color: '#fff ' }}
                      to={ROUTE_PATHS.USER.replace(':id', payment?.user?.id.toString())}
                    >
                      <Typography
                        sx={{
                          textDecoration: 'none',
                          '&:hover': {
                            color: 'white'
                          }
                        }}
                      >
                        {payment?.user?.archived ? (
                          <i>{t('user.archive.archived')}</i>
                        ) : (
                          payment?.user?.name
                        )}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography align='right'>{Math.round(payment.price).toString()} kr</Typography>
                  </TableCell>
                  <TableCell>
                    {(payment?.orderType && (
                      <Chip
                        sx={{ margin: '-8px 0' }}
                        label={t(`payment.orderTypes.${payment?.orderType}`).toLocaleUpperCase()}
                      />
                    )) || <Typography>--</Typography>}
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {(payment?.fromDate &&
                        `${dateString(payment?.fromDate)} -  ${dateString(payment?.toDate)}`) ||
                        '--'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <PaymentStatusChip status={payment.status} />
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '30px',
                      textAlign: 'center',
                      position: 'sticky',
                      right: 0,
                      background: 'inherit'
                    }}
                  >
                    <IconButton
                      sx={{ margin: '-8px 0' }}
                      edge='end'
                      onClick={() => {
                        navigate(ROUTE_PATHS.PAYMENT.replace(':id', payment.id.toString()));
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box textAlign={'center'} padding={'16px'}>
          <Typography fontSize={'16px'} variant={'caption'}>
            {t('common.noResultsFound')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PaymentList;
