import Card from './Card';
import OrderType from './OrderType';
import PaymentMethod from './PaymentMethod';
import PaymentSolution from './PaymentSolution';
import User from './User';

export type PaymentStatus =
  | 'REFUNDED'
  | 'NOT_CAPTURED'
  | 'CAPTURED'
  | 'CANCELED'
  | 'DUE'
  | 'EXPIRED'
  | 'FAILED'
  | 'PROCESSING'
  | 'PENDING';

export const paymentStatuses: PaymentStatus[] = [
  'REFUNDED',
  'NOT_CAPTURED',
  'CAPTURED',
  'CANCELED',
  'DUE',
  'EXPIRED',
  'FAILED',
  'PROCESSING',
  'PENDING'
];

type Payment = {
  id?: number;
  paymentSolution: PaymentSolution;
  price: number;
  status: PaymentStatus;
  refundedDate?: Date;
  refundDescription?: string;
  userSubscriptionId: number;
  dueDate?: Date;
  userId?: number;
  user?: User;
  created?: Date;
  completed?: Date;
  captureAttemts?: number;
  externalOrderId?: string;
  externalReference?: string;
  externalPaymentId?: string;
  method?: PaymentMethod;
  orderType: OrderType;
  fromDate: Date;
  toDate: Date;
  failedDate?: Date;
  card?: Card;
};

export default Payment;
