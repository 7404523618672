import { CloseRounded, Search } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SearchQueryKey } from 'types/SearchQuery';
import styles from './styles';

type SearchValues = {
  searchQuery: string;
  query: string;
};

type PrimarySearchProps = {
  defaultQuery?: string;
  queryTypes?: SearchQueryKey[];
  onSearch: (query: string, queryType?: SearchQueryKey) => void;
  filterComponent?: ReactNode;
  onSearchQueryUpdate?: (query: string) => void;
};

const PrimarySearch: FC<PrimarySearchProps> = ({
  queryTypes,
  defaultQuery = '',
  onSearch,
  onSearchQueryUpdate
}) => {
  const [searchFocused, setSearchFocused] = useState<boolean>();
  const { register, handleSubmit, watch, setValue } = useForm<SearchValues>({
    defaultValues: {
      searchQuery: defaultQuery
    }
  });
  const { t } = useTranslation();
  const [searchQueryText] = watch(['searchQuery']);

  const [queryType, setQueryType] = useState<SearchQueryKey>(
    queryTypes?.length > 0 ? queryTypes[0] : null
  );

  const onSearchSubmit = (formValues: SearchValues) => {
    const { searchQuery } = formValues;
    onSearch(searchQuery, queryType);
  };

  return (
    <>
      <Box
        onFocus={() => setSearchFocused(true)}
        onBlur={() => setSearchFocused(false)}
        className={`${searchFocused ? 'focused' : ''}`}
        component='form'
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          border: '1px solid rgba(224, 224, 224, 0.5)',
          boxSizing: 'border-box',
          width: '100%',
          ['&.focused']: {
            transitionDelay: '0.1s',
            transition: 'color 0.1s ease-in, border-color 0.1s ease-in',
            color: '#fff',
            borderColor: 'rgba(255, 255, 255, 1)'
          }
        }}
        onSubmit={handleSubmit(onSearchSubmit)}
      >
        <IconButton type='submit' sx={{ p: '10px' }} aria-label='search'>
          <Search />
        </IconButton>
        <Stack
          sx={{ marginRight: '16px', flex: 1, width: '100%' }}
          flexDirection='row'
          gap={1}
          alignItems={'center'}
        >
          <TextField
            fullWidth
            InputProps={{ disableUnderline: true }}
            variant='standard'
            type={'search'}
            onChange={(element: React.ChangeEvent<HTMLInputElement>) => {
              if (onSearchQueryUpdate) {
                onSearchQueryUpdate(element.target.value);
              }
            }}
            placeholder={`${t('common.search')} ${queryType?.label.toLowerCase()}`}
            {...register('searchQuery', {
              required: false
            })}
          />
          {(searchQueryText?.length > 0 && (
            <IconButton
              size={'small'}
              onClick={() => {
                setValue('searchQuery', '');
                onSearch('', queryType);
              }}
            >
              <CloseRounded />
            </IconButton>
          )) || <Box sx={{ width: '34px' }}></Box>}
        </Stack>
        {queryType && queryTypes.length > 1 && (
          <Divider sx={styles.divider} orientation={'vertical'} />
        )}
        {queryType && queryTypes.length > 1 && (
          <Select
            sx={{ p: 1, textDecoration: 'none' }}
            disableUnderline
            value={queryType?.key}
            variant={'standard'}
            onChange={(e: SelectChangeEvent) => {
              const index = Object.values(queryTypes).findIndex(
                (queryType) => queryType.key === e.target.value
              );

              setQueryType(queryTypes[index]);
            }}
          >
            {queryTypes.map((filterItem) => {
              return (
                <MenuItem key={filterItem.key} value={filterItem.key}>
                  {filterItem.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </Box>
    </>
  );
};

export default PrimarySearch;
