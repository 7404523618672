/* eslint-disable indent */
import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Table,
  TableRow,
  IconButton,
  Chip,
  CircularProgress
} from '@mui/material';
import { PaperCard, PaperContent, PaperHeader } from 'components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PostenDistributionRouteModal from './PostenDistributionRouteModal/PostenDistributionRouteModal';
import PostenDistributionRoute from 'types/PostenDistributionRoute';
import postenDistributionService from 'services/posten-distribution-service';
import { Download, Edit } from '@mui/icons-material';

const PostenDistributionRoutes: FC = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<number>();
  const [loading, setLoading] = useState<boolean>();
  const [selectedPostenDistributionRoute, setSelectedPostenDistributionRoute] =
    useState<PostenDistributionRoute>();

  const [postenDistributionRoutes, setPostenDistributionRoutes] =
    useState<PostenDistributionRoute[]>();

  const getPostenDistributionRoutes = async () => {
    setLoading(true);

    try {
      const { data: postenDistributionRoutes } =
        await postenDistributionService.getDistributionRoutes();
      setPostenDistributionRoutes(postenDistributionRoutes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPostenDistributionRoutes();
  }, []);

  const handleDownloadOfPostCodeFiles = async () => {
    setLoading(true);
    try {
      const { data } = await postenDistributionService.downloadPostCodeFiles();

      createDownload(data.base64, data.name);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const createDownload = async (base64: string, name: string) => {
    const a = document.createElement('a');
    a.href = 'data:application/octet-stream;base64,' + base64;
    a.download = name;
    a.click();
  };

  return (
    <>
      <PaperCard>
        <PaperHeader>
          <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
            <Stack direction={'row'} gap={2}>
              <Typography variant={'h5'}>{t('common.postenDistribution')}</Typography>
            </Stack>
            <Stack direction={'row'} gap={2}>
              <Button startIcon={<Download />} onClick={handleDownloadOfPostCodeFiles}>
                {t('common.postCodeFiles')}
              </Button>
              <Button
                onClick={() => {
                  setModal(1);
                }}
              >{`${t('common.add')} ${t('common.route')}`}</Button>
            </Stack>
          </Stack>
        </PaperHeader>
        <PaperContent sx={{ p: 0 }}>
          {loading && <CircularProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>{t('common.name')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.transitNumber')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.dropNumber')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.postCodeStart')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.postCodeEnd')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {t('common.numberOfBlank', {
                      blank: t('common.postCodes').toLowerCase()
                    })}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.deliveryTask')}</Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {postenDistributionRoutes
                ?.sort((a, b) => {
                  // sort on postCodeInfoList first element postenId
                  const aPostenId =
                    a?.postCodeInfoList.length > 0 ? a.postCodeInfoList[0].postenId : '';
                  const bPostenId =
                    b?.postCodeInfoList.length > 0 ? b.postCodeInfoList[0].postenId : '';
                  // compare as numbers
                  return Number(aPostenId) - Number(bPostenId);
                })
                .map((postenDistributionRoute) => (
                  <TableRow key={postenDistributionRoute.id}>
                    <TableCell>
                      <Typography>{postenDistributionRoute.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{postenDistributionRoute.transitNumber}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{postenDistributionRoute.dropNumber}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {postenDistributionRoute?.postCodeInfoList.length > 0
                          ? postenDistributionRoute.postCodeInfoList[0].postenId
                          : '--'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {postenDistributionRoute?.postCodeInfoList.length > 1
                          ? postenDistributionRoute.postCodeInfoList[
                              postenDistributionRoute.postCodeInfoList.length - 1
                            ].postenId
                          : '--'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {postenDistributionRoute?.postCodeInfoList.length ?? 0}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        size='medium'
                        label={String(
                          t(`common.${postenDistributionRoute.deliveryTask}`)
                        ).toLocaleUpperCase()}
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton
                        onClick={() => {
                          setSelectedPostenDistributionRoute(postenDistributionRoute);
                          setModal(1);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {postenDistributionRoutes?.length === 0 && (
            <Stack p={8} justifyContent={'center'} alignItems={'center'}>
              <Typography>{t('common.noResultsFound')}</Typography>
            </Stack>
          )}
        </PaperContent>
      </PaperCard>
      {modal === 1 && (
        <PostenDistributionRouteModal
          postenDistributionRoute={selectedPostenDistributionRoute}
          onCancel={() => {
            setModal(undefined);
            setSelectedPostenDistributionRoute(undefined);
          }}
          onSaved={() => {
            setModal(undefined);
            setSelectedPostenDistributionRoute(undefined);
            getPostenDistributionRoutes();
          }}
          onDeleted={() => {
            setModal(undefined);
            setSelectedPostenDistributionRoute(undefined);
            getPostenDistributionRoutes();
          }}
        />
      )}
    </>
  );
};

export default PostenDistributionRoutes;
