import { FC, useState } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Publish } from '@mui/icons-material';
import postenDistributionService from 'services/posten-distribution-service';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';

type PostenImportPostCodesModalProps = {
  onUpdate: () => void;
  onCancel: () => void;
};

type Fields = {
  filelist: FileList;
};

const PostenImportPostCodesModal: FC<PostenImportPostCodesModalProps> = ({
  onUpdate,
  onCancel
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const { register, handleSubmit, watch } = useForm<Fields>();
  const axiosAlert = useAxiosAlert();
  const filelist = watch('filelist');

  const importTxtFile = async (fields: Fields) => {
    setLoading(true);

    try {
      await postenDistributionService.importPostenPostalCodeInfoTxtFile(fields.filelist[0]);

      onUpdate();
    } catch (error) {
      axiosAlert(error as AxiosError, [
        {
          status: 422,
          message: t('file.wrongFileContent')
        },
        {
          status: 404,
          message: t('file.notFound')
        },
        {
          status: 415,
          message: t('file.wrongFormat')
        },
        {
          status: 500,
          message: t('common.error')
        }
      ]);
    } finally {
      setLoading(false);
    }
  };

  // create a mui dialog
  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>{t('distribution.posten.importPostCodeOverview')}</DialogTitle>
      <form onSubmit={handleSubmit(importTxtFile)}>
        <DialogContent>
          <Stack gap={2} sx={{ mb: 2 }}>
            <Alert severity="info">{t('distribution.posten.uploadInfo')}</Alert>
            {!loading && (
              <Stack gap={2}>
                <Typography>{t('distribution.posten.importPostCodeOverview')} (.tsv, .csv)</Typography>
                <Input
                  type="file"
                  {...register('filelist', { required: true })}
                  inputProps={{ accept: '.txt,.csv' }}
                />
              </Stack>
            )}
          </Stack>
          {loading && (
            <Stack style={{ textAlign: 'center' }}>
              <CircularProgress sx={{ margin: '10px 0' }} />
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button
            disabled={filelist?.length > 0 ? false : true}
            startIcon={<Publish />}
            variant="contained"
            type="submit"
          >
            {t('common.importFile')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PostenImportPostCodesModal;
