import { FC } from 'react';
import PostenDistributionRoute from 'types/PostenDistributionRoute';
import { Typography, Grid, Stack, Chip } from '@mui/material';
import PaperCard from 'components/PaperCard/PaperCard';
import { useTranslation } from 'react-i18next';
import getPostCodesFromTo from 'pages/PostenPage/compontents/PostenDistributionRouteModal/utils/getPostCodesFromTo';

type PostenDistributionRouteInfoProps = {
  postenDistributionRoute: PostenDistributionRoute;
};

const PostenDistributionRouteInfo: FC<PostenDistributionRouteInfoProps> = ({
  postenDistributionRoute
}) => {
  const { t } = useTranslation();
  const postCodesFromTo = getPostCodesFromTo(postenDistributionRoute.postCodeInfoList);

  return (
    <PaperCard variant={'outlined'} sx={{ p: 2, gap: 1, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6">{postenDistributionRoute.name}</Typography>
      <Grid container width={'100%'}>
        <Grid item xs={6}>
          <Typography>{t('common.transitNumber')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{postenDistributionRoute.transitNumber}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography>{t('common.carNumber')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{postenDistributionRoute.carNumber}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography>{t('common.carText')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{postenDistributionRoute.carText}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography>{t('common.dropNumber')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{postenDistributionRoute.dropNumber}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography>{t('common.dropText')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{postenDistributionRoute.dropText}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography>{t('common.dropText2')}:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{postenDistributionRoute.dropText2}</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems={'center'}>
        <Grid item xs={6}>
          <Typography>{t('common.postCodes')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
            <Typography>{t('common.count')}</Typography>
            <Chip label={postenDistributionRoute?.postCodeInfoList?.length ?? 0} />
          </Stack>
        </Grid>
      </Grid>
      <Stack
        sx={{
          height: '70px',
          padding: '10px',
          backgroundColor: 'rgba(255,255,255,0.05)',
          color: 'rgba(255,255,255,0.8)',
          whiteSpace: 'pre',
          overflowY: 'scroll',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          gap: 2
        }}
      >
        {postCodesFromTo?.map((postCodeData, index) => (
          <Chip
            key={index}
            label={
              postCodeData.postCodeFrom +
              (postCodeData.postCodeTo ? ' - ' + postCodeData.postCodeTo : '')
            }
          />
        ))}
        {postenDistributionRoute?.postCodeInfoList?.length < 1 && (
          <Typography>{t('common.noPostCodes')}</Typography>
        )}
      </Stack>
    </PaperCard>
  );
};

export default PostenDistributionRouteInfo;
