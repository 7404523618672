/* eslint-disable indent */
import { Person } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, Typography } from '@mui/material';
import { PrimaryCard, PrimaryListItem } from 'components';
import CardInfo from 'components/CardInfo/CardInfo';
import PaymentMethodChip from 'components/PaymentMethodChip';
import PaymentSolutionChip from 'components/PaymentSolutionChip/PaymentSolutionChip';
import PaymentStatusChip from 'components/PaymentStatusChip/PaymentStatusChip';
import { ROUTE_PATHS } from 'consts/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import userSubscriptionService from 'services/user-subscrption.service';
import Payment from 'types/Payment';
import UserSubscription from 'types/UserSubscription';
import { dateString } from 'utils';
import EditRefundDescriptionModal from '../EditRefundDescriptionModal/EditRefundDescriptionModal';

type PaymentContentProps = {
  payment: Payment;
  loading?: boolean;
  onEdit: (refundDescription: string) => void;
};

const PaymentContent: FC<PaymentContentProps> = ({ payment, loading, onEdit }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<number>();

  const [userSubscription, setUserSubscription] = useState<UserSubscription>(null);

  // Used to link gift subscription to targed user
  const getUserSubscription = async () => {
    const userSubscriptionQuery = await userSubscriptionService.getById(payment.userSubscriptionId);
    setUserSubscription(userSubscriptionQuery.data);
  };

  useEffect(() => {
    if (payment?.orderType === 'GIFT_SUBSCRIPTION') {
      getUserSubscription();
    }
  }, [payment]);

  return (
    <Box>
      {!loading && (
        <Box>
          <PrimaryCard>
            <Link
              style={{ color: '#fff ', borderBottom: '1px solid #fff' }}
              to={`${ROUTE_PATHS.USER.replace(':id', payment?.userId.toString())}`}
            >
              <Person sx={{ position: 'relative', top: '4px' }} />
              <Typography variant='h6' sx={{ display: 'inline-block', marginLeft: '5px' }}>
                {payment?.user?.archived ? (
                  <i>{t('user.archive.archived')}</i>
                ) : (
                  payment?.user?.name
                )}
              </Typography>
            </Link>
            <PrimaryListItem
              label={
                payment?.paymentSolution === 'invoice' ? t('common.invoiceNo') : t('payment.id')
              }
              value={payment.id}
            />
            <PrimaryListItem label={t('common.price')} value={`${payment.price} kr`} />
            <PrimaryListItem
              label={t('payment.paymentMethod')}
              value={<PaymentMethodChip paymentMethod={payment?.method} />}
            />
            {payment?.paymentSolution === 'invoice' && payment?.status === 'REFUNDED' && (
              <PrimaryListItem
                label={t('common.creditNoteNo')}
                value={(payment?.paymentSolution === 'invoice' ? 'R' : '') + payment.id}
              />
            )}
            <PrimaryListItem
              label={t('payment.solution')}
              value={<PaymentSolutionChip paymentSolution={payment.paymentSolution} />}
            />
            {payment.card && (
              <PrimaryListItem label={t('common.card')} value={<CardInfo card={payment.card} />} />
            )}
            <PrimaryListItem
              label={t('common.status')}
              value={<PaymentStatusChip status={payment.status} />}
            />
            <PrimaryListItem
              label={t('payment.orderType')}
              value={
                <Chip
                  label={`${t(`payment.orderTypes.${payment.orderType}`)}`.toLocaleUpperCase()}
                />
              }
            />
            {payment.orderType === 'GIFT_SUBSCRIPTION' && userSubscription && (
              <PrimaryListItem
                label={t('payment.giftSubscriptionFor')}
                value={
                  <Link
                    style={{ color: '#fff ', borderBottom: '1px solid #fff' }}
                    to={`${ROUTE_PATHS.USER.replace(':id', userSubscription.user.id.toString())}`}
                  >
                    <Person sx={{ position: 'relative', top: '4px' }} />
                    <Typography sx={{ display: 'inline-block', marginLeft: '5px' }}>
                      {userSubscription.user.name}
                    </Typography>
                  </Link>
                }
              />
            )}
            {payment.fromDate && (
              <PrimaryListItem
                label={t('payment.servicePeriod')}
                value={`${dateString(payment?.fromDate)} - 
                ${dateString(payment?.toDate)}`}
              />
            )}
            {payment?.paymentSolution === 'invoice' && (
              <PrimaryListItem label={t('common.kidNo')} value={payment.externalPaymentId} />
            )}
            <PrimaryListItem
              {...payment.status !== 'REFUNDED' && {last: true}}
              label={t('payment.externalReference')}
              value={payment.externalReference}
            />
            {payment.status === 'REFUNDED' && (
              <>
                <PrimaryListItem
                  last
                  label={t('payment.refundDescription')}
                  value={
                    <Button variant={'outlined'} onClick={() => setModal(0)}>
                      {t('common.edit')}
                    </Button>
                  }
                />
                <Typography
                  sx={{
                    minHeight: '70px',
                    padding: '10px',
                    backgroundColor: 'rgba(255,255,255,0.05)',
                    color: 'rgba(255,255,255,0.8)',
                    whiteSpace: 'pre'
                  }}
                >
                  {payment.refundDescription}
                </Typography>
              </>
            )}
          </PrimaryCard>
        </Box>
      )}
      {modal === 0 && (
        <EditRefundDescriptionModal
          title={t('payment.refundConsent.title')}
          content={t('payment.refundConsent.content')}
          description = {payment.refundDescription}
          onConfirm={(refundDescription) => {
            onEdit(refundDescription);
            setModal(null);
          }}
          onCancel={() => setModal(null)}
        />
      )}
      {loading && (
        <Box sx={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default PaymentContent;
