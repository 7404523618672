/* eslint-disable max-lines */
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import { PrimaryListItem } from 'components';
import useAlert from 'hooks/useAlert';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SyncIcon from '@mui/icons-material/Sync';
import UserBillingContactModal from '../UserBillingContactModal/UserBillingContactModal';
import useSettings from 'hooks/useSettings';
import SelectInvoicePaymentMethod from './SelectInvoicePaymentMethod';
import UserInvoiceSettings from 'types/UserInvoiceSettings';
import userInvoiceSettingsService from 'services/user-invoice-settings-service';
import { EditSingleStringModal } from 'modals';
import WarningIcon from '@mui/icons-material/Warning';
import BillingContactCard from './BillingContactCard';
import numToExactString from 'utils/numToExactString';

type EditUserInvoiceSettingsProps = {
  user: User;
};

type EditUserInvoiceSettingsModal = 'billingContact' | 'editMandateId';

const EditUserInvoiceSettings: FC<EditUserInvoiceSettingsProps> = ({ user }) => {
  const { t } = useTranslation();
  const { addAlert } = useAlert();
  const { getValueByKeyAsBoolean } = useSettings();
  const directDebitActive = getValueByKeyAsBoolean('DirectDebitActive', 0);
  const eInvoiceActive = getValueByKeyAsBoolean('EInvoiceActive', 0);

  const [userInvoiceSettings, setUserInvoiceSettings] = useState<UserInvoiceSettings>(null);
  const [modal, setModal] = useState<EditUserInvoiceSettingsModal>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const axiosAlert = useAxiosAlert();

  const update = async (updateModel: Partial<UserInvoiceSettings>) => {
    setLoading(true);
    try {
      const result = await userInvoiceSettingsService.update({
        id: userInvoiceSettings.id,
        ...updateModel
      });

      if (userInvoiceSettings.eInvoiceReference) {
        // In case billing contact is updated, also refresh eInvoice reference
        if (
          (updateModel.billingContactPhone &&
            updateModel.billingContactPhone !== userInvoiceSettings.billingContactPhone) ||
          (updateModel.billingContactEmail &&
            updateModel.billingContactEmail !== userInvoiceSettings.billingContactEmail)
        ) {
          await refreshEInvoiceAvailable();
        } else if (updateModel.useBillingContact != userInvoiceSettings.useBillingContact) {
          await refreshEInvoiceAvailable();
        }
      }

      if (
        updateModel.invoicePaymentMethodAuto || // If auto is set
        (updateModel.invoicePaymentMethodAuto === undefined && // If not set, but was set before
          userInvoiceSettings.invoicePaymentMethodAuto)
      ) {
        await refreshAutoPaymentMethod();
      } else {
        setUserInvoiceSettings(result.data);
      }
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.couldNotUpdateSendInvoicesByEmail'));
    }
    setLoading(false);
  };

  const refreshAutoPaymentMethod = async () => {
    setLoading(true);
    try {
      const result = await userInvoiceSettingsService.refreshAutoPaymentMethod(
        userInvoiceSettings.userId
      );
      setUserInvoiceSettings(result.data);
      addAlert('info', t('alerts.invoicePaymentMethodUpdated'));
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.couldNotUpdateSendInvoicesByEmail'));
    }
    setLoading(false);
  };

  const refreshEInvoiceAvailable = async () => {
    try {
      const result = await userInvoiceSettingsService.refreshEInvoiceReference(
        userInvoiceSettings.userId
      );
      setUserInvoiceSettings(result.data);
      addAlert('info', t('alerts.eInvoiceReferenceUpdated'));
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.couldNotUpdateSendInvoicesByEmail'));
    }
  };

  const createInvoiceSettings = async () => {
    setLoading(true);
    try {
      const result = await userInvoiceSettingsService.add(user.id);
      setUserInvoiceSettings(result.data);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.couldNotCreateInvoiceSettings'));
    }
    setLoading(false);
  };

  const getInvoiceSettings = async (init = false) => {
    setLoading(true);
    try {
      const result = await userInvoiceSettingsService.getByUserId(user.id);
      setUserInvoiceSettings(result.data);
    } catch (error) {
      // If init and 404, do nothing
      if (init && (error as AxiosError).response?.status === 404) {
        return;
      }
      axiosAlert(error as AxiosError, [], t('alerts.couldNotGetInvoiceSettings'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoiceSettings(true);
  }, []);

  const paymentMethodWarning = (): ReactNode => {
    if (userInvoiceSettings.invoicePaymentMethodAuto) {
      return null;
    }

    if (
      !userInvoiceSettings.activeDirectDebit &&
      [
        'INVOICE_DD_PAPER',
        'INVOICE_DD_EMAIL',
        'INVOICE_DD_EINVOICE',
        'INVOICE_DD_BANK_NOTIFICATION'
      ].includes(userInvoiceSettings.invoicePaymentMethod)
    ) {
      return (
        <Tooltip
          title={t('invoice.invalidPaymentMethod') + '. ' + t('invoice.directDebitNotActive')}
        >
          <WarningIcon
            color={'error'}
            sx={{ position: 'relative', top: '5px', marginLeft: '5px' }}
          />
        </Tooltip>
      );
    }

    if (
      !userInvoiceSettings.eInvoiceReference &&
      ['INVOICE_EINVOICE', 'INVOICE_DD_EINVOICE'].includes(userInvoiceSettings.invoicePaymentMethod)
    ) {
      return (
        <Tooltip title={t('invoice.invalidPaymentMethod') + '. ' + t('invoice.eInvoiceNotActive')}>
          <WarningIcon
            color={'error'}
            sx={{ position: 'relative', top: '5px', marginLeft: '5px' }}
          />
        </Tooltip>
      );
    }
  };
  const defaultMandateId =
    numToExactString(user.id, 7) === userInvoiceSettings?.directDebitMandateId;
  const billingEmail = userInvoiceSettings?.useBillingContact
    ? userInvoiceSettings.billingContactEmail
    : user.email;
  const billingPhone = userInvoiceSettings?.useBillingContact
    ? userInvoiceSettings.billingContactPhone
    : user.phone;
  const phoneAndEmailAvailable = billingEmail && billingPhone;
  return (
    <div>
      <Typography variant={'h6'} sx={{ marginBottom: '8px' }}>
        {t('common.invoice')}
      </Typography>
      {loading && <CircularProgress />}

      {!userInvoiceSettings && !loading && (
        <Button variant={'contained'} onClick={createInvoiceSettings}>
          {t('invoice.createInvoiceSettings')}
        </Button>
      )}
      {userInvoiceSettings && !loading && (
        <>
          <Typography
            style={{
              fontStyle: 'italic',
              paddingBottom: '20px',
              whiteSpace: 'pre-line',
              color: '#bbb',
              fontSize: '14px'
            }}
          >
            {t('invoice.invoiceDetailsDescription')}
          </Typography>
          <Divider />
          <PrimaryListItem
            label={t('common.billingContact')}
            sx={{ whiteSpace: 'pre-line', textAlign: 'right' }}
            value={
              userInvoiceSettings.useBillingContact ? (
                <BillingContactCard userInvoiceSettings={userInvoiceSettings} />
              ) : (
                '--'
              )
            }
            placeholder={t('common.notAdded')}
            actions={
              <IconButton onClick={() => setModal('billingContact')}>
                <EditIcon />
              </IconButton>
            }
          />
          <PrimaryListItem
            label={t('invoice.invoicePaymentMethod')}
            sx={{ whiteSpace: 'pre-line', textAlign: 'right' }}
            placeholder={t('common.notAdded')}
            actions={
              <Stack gap={2}>
                <SelectInvoicePaymentMethod
                  user={user}
                  userInvoiceSettings={userInvoiceSettings}
                  onChange={(paymentMethod) => update({ invoicePaymentMethod: paymentMethod })}
                  eInvoiceActive={eInvoiceActive}
                  directDebitActive={directDebitActive}
                />

                <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'right'}>
                  <Typography>{t('common.auto')}:</Typography>
                  <Tooltip title={'Velg best tilgjengelig faktura-forsendelse'}>
                    <Switch
                      checked={!!userInvoiceSettings.invoicePaymentMethodAuto}
                      onChange={(e) => update({ invoicePaymentMethodAuto: e.target.checked })}
                    />
                  </Tooltip>
                  {userInvoiceSettings.invoicePaymentMethodAuto ? (
                    <Tooltip title={'Oppdater best tilgjengelig faktura-forsendelse'}>
                      <IconButton onClick={() => refreshAutoPaymentMethod()}>
                        <SyncIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    paymentMethodWarning()
                  )}
                </Stack>
              </Stack>
            }
          />
          <Stack
            gap={2}
            direction={'row'}
            justifyContent={'space-between'}
            sx={{ marginTop: '16px' }}
          >
            {directDebitActive && (
              <Stack gap={2} sx={{ border: '1px solid #555', padding: '20px', width: '44%' }}>
                <Typography variant={'h6'} sx={{ margin: '8px 0' }}>
                  {t('invoice.directDebit')}
                </Typography>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography>{t('common.available')}:</Typography>
                  {userInvoiceSettings.activeDirectDebit ? (
                    <CheckCircleIcon color={'success'} />
                  ) : (
                    <CancelIcon color={'error'} />
                  )}
                </Stack>
                <Divider />
                <Stack direction={'row'} gap={4} justifyContent={'space-between'}>
                  <Typography>{t('invoice.mandateId')}:</Typography>
                  <Typography
                    sx={defaultMandateId ? { fontStyle: 'italic' } : { fontWeight: 'bold' }}
                  >
                    {userInvoiceSettings.directDebitMandateId || '--'}
                  </Typography>
                </Stack>
                <Divider />
                <Stack direction={'row'}>
                  <Button
                    variant={'outlined'}
                    onClick={() => setModal('editMandateId')}
                    sx={{ flex: '1 1 auto' }}
                  >
                    {t('invoice.editMandateId')}
                  </Button>
                  <Tooltip
                    title={
                      'KundeId som er gjort avtale på. NB! Bør ikke endres for uten om å videreføre gammel avtale.'
                    }
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            )}
            {eInvoiceActive && (
              <Stack gap={2} sx={{ border: '1px solid #555', padding: '20px', width: '44%' }}>
                <Typography variant={'h6'} sx={{ margin: '8px 0' }}>
                  {t('invoice.eInvoice')}
                </Typography>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography>{t('common.available')}:</Typography>
                  {userInvoiceSettings.eInvoiceReference ? (
                    <CheckCircleIcon color='success' />
                  ) : (
                    <CancelIcon color='error' />
                  )}
                </Stack>
                <Divider />
                <Stack direction={'row'} gap={4} justifyContent={'space-between'}>
                  <Typography>{t('invoice.eInvoiceReferance')}:</Typography>
                  <Typography>{userInvoiceSettings.eInvoiceReference || '--'}</Typography>
                </Stack>
                <Divider />

                <Stack direction={'row'}>
                  <Tooltip
                    title={phoneAndEmailAvailable ? '' : t('invoice.phoneAndEmailMustBeAvailable')}
                  >
                    <div style={{ flex: '1 1 auto' }}>
                      <Button
                        variant={'outlined'}
                        disabled={!phoneAndEmailAvailable}
                        onClick={() => refreshEInvoiceAvailable()}
                        fullWidth={true}
                      >
                        {t('invoice.fetchEInvoiceReferance')}
                      </Button>
                    </div>
                  </Tooltip>

                  <Tooltip title={'Henter eFaktura-refreanse fra MasterCard.'}>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            )}
          </Stack>

          {modal === 'billingContact' && (
            <UserBillingContactModal
              userInvoiceSettings={userInvoiceSettings}
              onCancel={() => setModal(null)}
              onConfirm={(userInvoiceSettings: UserInvoiceSettings) => {
                if (userInvoiceSettings.useBillingContact) {
                  update({
                    useBillingContact: true,
                    billingContactAddress: userInvoiceSettings.billingContactAddress,
                    billingContactAddress2: userInvoiceSettings.billingContactAddress2,
                    billingContactEmail: userInvoiceSettings.billingContactEmail,
                    billingContactName: userInvoiceSettings.billingContactName,
                    billingContactPhone: userInvoiceSettings.billingContactPhone,
                    billingContactPostCode: userInvoiceSettings.billingContactPostCode,
                    billingContactPostOffice: userInvoiceSettings.billingContactPostOffice
                  });
                } else {
                  update({
                    useBillingContact: false,
                    billingContactAddress: null,
                    billingContactAddress2: null,
                    billingContactEmail: null,
                    billingContactName: null,
                    billingContactPhone: null,
                    billingContactPostCode: null,
                    billingContactPostOffice: null
                  });
                }
                setModal(null);
              }}
            />
          )}
          {modal === 'editMandateId' && (
            <EditSingleStringModal
              title={t('invoice.directDebitMandateId')}
              label={t('invoice.directDebitMandateId')}
              value={userInvoiceSettings.directDebitMandateId}
              min={7}
              max={7}
              onCancel={() => setModal(null)}
              onConfirm={(value) => {
                update({ directDebitMandateId: value });
                setModal(null);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditUserInvoiceSettings;
