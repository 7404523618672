type SettingCategory =
  | 'core'
  | 'email'
  | 'vipps'
  | 'swedbank'
  | 'invoice'
  | 'eInvoice'
  | 'directDebit'
  | 'organization'
  | 'paper'
  | 'analytics'
  | 'newsroomApp';

export const settingCategories: SettingCategory[] = [
  'core',
  'email',
  'vipps',
  'swedbank',
  'invoice',
  'eInvoice',
  'directDebit',
  'organization',
  'paper',
  'analytics',
  'newsroomApp'
];

export default SettingCategory;
