import React, { useEffect, useRef, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import reportService from 'services/report-service';
import SubscriptionDistributionReport from 'types/SubscriptionDistributionReport';
import COLORS from 'consts/colors';

const SubscriptionDistributionChart = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [data, setData] = useState<SubscriptionDistributionReport[]>(null);

  const getData = async () => {
    try {
      const dataQuery = await reportService.getSubscriptionDistribution();
      setData(dataQuery.data);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  const options: Highcharts.Options = {
    colors: [
      COLORS.PRIMARY,
      COLORS.INFO,
      COLORS.ERROR,
      COLORS.SUCCESS,
      COLORS.PRIMARY_DARK,
      COLORS.INFO_DARK,
      COLORS.ERROR_DARK,
      COLORS.SUCCESS_DARK,
      COLORS.PRIMARY_DARK
    ],
    chart: {
      backgroundColor: null,
      width: 260,
      height: 160
    },
    title: {
      text: null
    },
    tooltip: {
      pointFormat: '{series.data.name} <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          color: '#fff',
          enabled: false,
          format: '{point.name}'
        }
      }
    },
    series: [
      {
        type: 'pie',
        //colorByPoint: true,
        borderColor: null,
        data: data === null ? [] : data.map((d) => ({ name: d.name, y: d.count }))
      }
    ]
  };

  return (
    <div>
      {data !== null}
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
    </div>
  );
};

export default SubscriptionDistributionChart;
