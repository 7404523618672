import axios, { AxiosPromise } from 'axios';
import FileReturn from 'types/FileReturn';

const editionFileService = {
  getPostenChanges: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/posten-changes/${editionId}`);
  },
  getPostenTotal: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/posten-total/${editionId}`);
  },
  getPostenDeliveryTask: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/posten-delivery-task/${editionId}`);
  },
  getChangesPdf: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/changes-pdf/${editionId}`);
  },
  getPostenZip: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/posten-zip/${editionId}`);
  },
  getDIChanges: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/di-changes/${editionId}`);
  },
  getDITotal: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/di-total/${editionId}`);
  },
  getPolarisMeld: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/polaris-meld/${editionId}`);
  },
  getPolarisLos: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/polaris-los/${editionId}`);
  },
  getDistributionZip: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/distribution-zip/${editionId}`);
  },
  getPolarisLosq: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/polaris-losq/${editionId}`);
  },
  // Wamac
  getCirculationFile: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/wamac/circulation/${editionId}`);
  },
  getWamacHd: (editionDate: Date): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/wamac/generate-hd/${editionDate}`);
  },
  getWamacTxt: (editionDate: Date): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/wamac/generate-txt/${editionDate}`);
  },
  getWamacInk: (editionDate: Date): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/wamac/generate-ink/${editionDate}`);
  },
  getWamacRel: (editionDate: Date): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/wamac/generate-rel/${editionDate}`);
  },
  getWamacOrd: (editionDate: Date): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/wamac/generate-ord/${editionDate}`);
  },
  getWamacAbroadHeadersFile: (editionDate: Date): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/wamac/generate-abroad-headers/${editionDate}`);
  },
  getWamacZip: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/wamac/generate-all/${editionId}`);
  },
  // Mpc
  getMpcJobFiles: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/mpc/job-files/${editionId}`);
  },
  getMpcAddressFile: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/mpc/address-file/${editionId}`);
  },
  getMpcTFile: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/mpc/t-file/${editionId}`);
  },
  getMpcControlFile: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/mpc/control-file/${editionId}`);
  },
  getMpcAbroadHeadersFile: (editionDate: Date): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/mpc/generate-abroad-headers/${editionDate}`);
  },
  getMpcZip: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/mpc/zip/${editionId}`);
  },
  // Fremag
  getFremagTslFile: (editionId: number): AxiosPromise<FileReturn> => {
    return axios.get(`/edition/files/fremag/tsl-file/${editionId}`);
  }
};

export default editionFileService;
