import './index.scss';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from 'store';
import theme from './styles/theme';
import axios from 'axios';
import config from 'utils/_config';
import { ThemeProvider } from '@mui/material';

import 'i18n';
import { BrowserRouter as Router } from 'react-router-dom';

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>
);
