import { AlertColor } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { uniqeId } from 'utils';
import Alert from 'types/Alert';
import { RootState, alertActions } from 'store';

const useAlert = () => {
  const dispatch = useDispatch();
  const alerts = useSelector((state: RootState) => state.alerts);

  const addAlert = (type: AlertColor = 'success', text = '', timeout = 5000) => {
    const alertId = uniqeId();

    const alert: Alert = {
      id: alertId,
      type,
      text,
      timeout,
      count: 1
    };

    // If the alert is already in the state compare by text, increase the count and reset the timeout
    const existingAlert = alerts.find((a) => a.text === text);

    if (existingAlert) {
      alert.count = existingAlert.count ? existingAlert.count + 1 : 1;
      alert.timeout = timeout;
      dispatch(alertActions.replaceAlert(alert));
    } else {
      dispatch(alertActions.addAlert(alert));
    }

    if (alert.timeout && alert.timeout > 0) {
      setTimeout(() => {
        removeAlert(alert.id);
      }, alert.timeout);
    }
  };

  const removeAlert = (id: string) => {
    dispatch(alertActions.removeAlert(id));
  };

  return { addAlert, removeAlert };
};

export default useAlert;
