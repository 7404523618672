import { Alert, Button, Stack, Typography } from '@mui/material';
import { PaperCard, PaperContent, PaperHeader, PrimaryListItem } from 'components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Edition from 'types/Edition';
import { dateString } from 'utils';
import editionSubscriberSnapshotService from 'services/edition-subscriber-snapshot-service';
import PrimaryTooltip from 'components/PrimaryTooltip/PrimaryTooltip';

type EditionSyncProps = {
  edition: Edition;
  onSynced: (syncedEdition: Edition) => void;
};

const EditionSync: FC<EditionSyncProps> = ({ edition, onSynced }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();

  const lastSynced = edition?.lastSynced
    ? dateString(edition.lastSynced, true)
    : t('edition.notSynced');

  const sync = async () => {
    setLoading(true);

    try {
      const { data: updatedEdition } = await editionSubscriberSnapshotService.sync(edition.id);
      onSynced(updatedEdition);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  const disableInputs = loading || edition?.status === 'PUBLISHED';
  const syncDateIsNewerThanEditionDate = edition?.lastSynced > edition?.date;

  return (
    <PaperCard variant='outlined' sx={{ width: '450px' }}>
      <PaperHeader textAlign='left'>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} gap={0.5} alignItems={'center'}>
            <Typography variant='h6'>{t('edition.subscriberInfo')}</Typography>
            <PrimaryTooltip text={t('edition.syncTooltip')} />
          </Stack>
        </Stack>
      </PaperHeader>
      <PaperContent sx={{ pt: 0 }}>
        <Stack gap={2}>
          <PrimaryListItem label={t('edition.lastRetrieved')} value={lastSynced} />
          {syncDateIsNewerThanEditionDate && (
            <Alert severity='info' sx={{ mt: 2, mb: 2 }}>
              {t('edition.info.syncDateIsNewerThanEditionDate')}
            </Alert>
          )}
          <Button disabled={disableInputs} onClick={sync} variant='outlined'>
            {t('edition.getSubscriberInfo')}
          </Button>
        </Stack>
      </PaperContent>
    </PaperCard>
  );
};

export default EditionSync;
