import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Divider
} from '@mui/material';
import { FC } from 'react';
import UserToken from 'types/UserToken';
import UserTokenRow from './UserTokenRow';
import { useTranslation } from 'react-i18next';

type UserTokenTableProps = {
  userTokens: UserToken[];
  updateUserToken: (userToken: UserToken) => void;
};

const UserTokenTable: FC<UserTokenTableProps> = ({ userTokens, updateUserToken }) => {
  const { t } = useTranslation();
  return userTokens.length === 0 ? (
    <>
      <Typography
        fontStyle={'italic'}
        align="center"
        sx={{ padding: '20px 0' }}
      >
        {t('common.none')}
      </Typography>
      <Divider/>
    </>
  ) : (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('common.name')}</TableCell>
          <TableCell>{t('common.browser')}</TableCell>
          <TableCell>{t('common.expires')}</TableCell>
          <TableCell>{t('common.lastUsed')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {userTokens.map((userToken) => (
          <UserTokenRow key={userToken.id} userToken={userToken} updateUserToken={updateUserToken}/>
        ))}
      </TableBody>
    </Table>
  );
};

export default UserTokenTable;
