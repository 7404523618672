import { Button, Stack, Typography } from '@mui/material';
import { PageContainer } from 'components';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperFooter from 'components/PaperFooter/PaperFooter';
import routes from 'consts/routes';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { RootState } from 'store';
import ErrorSvg from './ErrorSvg';

type ErrorPageProps = {
  code?: string | number;
};

const ErrorPage: FC<ErrorPageProps> = ({ code: codeProp }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const admin = useSelector((state: RootState) => state?.admin);
  const location = useLocation();
  const locationState = location?.state as { code: string | number };
  const code = locationState?.code;
  let message = 'error.somethingWentWrong';

  switch (codeProp) {
    case '404':
      message = 'error.pageNotFound';
      break;
    default:
      break;
  }

  return (
    <PageContainer centerHorizontal centerVertical>
      <PaperCard>
        <PaperContent sx={{ flex: 1, width: '300px', maxWidth: '100%' }}>
          <Stack alignItems='center'>
            <ErrorSvg />
            <Typography textAlign={'center'} variant='h6'>
              {code || codeProp}
            </Typography>
            <Typography textAlign={'center'} variant='h4'>
              {t(message)}
            </Typography>
          </Stack>
        </PaperContent>
        <PaperFooter>
          {admin && (
            <Button onClick={() => navigate(routes.DEFAULT.path)}>
              {t('navigation.backToControlPanel')}
            </Button>
          )}
          {!admin && (
            <Button onClick={() => navigate(routes.LOGIN.path)}>{t('navigation.login')}</Button>
          )}
        </PaperFooter>
      </PaperCard>
    </PageContainer>
  );
};

export default ErrorPage;
