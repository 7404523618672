import { PageContainer } from 'components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import REPORTS from 'consts/reports';
import Report from 'types/Report';

const Reports: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { reportSlug } = useParams();
  const [selectedReport, setSelectedReport] = useState<Report>(undefined);
  if (selectedReport === undefined && reportSlug) {
    const reportQuery = REPORTS.find((r) => r.slug === reportSlug);
    if (reportQuery) {
      setSelectedReport(reportQuery);
    } else {
      navigate('/reports');
    }
  }

  return (
    <PageContainer>
      {selectedReport ? selectedReport.component : <p>{t('reports.noReportSelected')}</p>}
    </PageContainer>
  );
};

export default Reports;
