import { Typography, CircularProgress, Button } from '@mui/material';
import { AxiosError } from 'axios';
import { PaperCard, PaperHeader, PaperContent, PaperFooter } from 'components';
import COLORS from 'consts/colors';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import reportService from 'services/report-service';

const InvoicesForHandling: FC = () => {
  const { t } = useTranslation();
  const [invoicesForHandling, setInvoicesForHandling] = useState<{
    unsentInvoices: number;
    overdueInvoices: number;
  }>(null);
  const axiosAlert = useAxiosAlert();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const invoicesForHandlingQuery = await reportService.getInvoicesForHandling();
        setInvoicesForHandling(invoicesForHandlingQuery.data);
      } catch (error) {
        axiosAlert(error as AxiosError, []);
      }
    })();
  }, []);

  return (
    <PaperCard sx={{ textAlign: 'center', width: '100%', maxWidth: '300px' }}>
      <PaperHeader>
        <Typography variant={'h6'}>{t('dashboard.unsentInvoices')}</Typography>
      </PaperHeader>
      <PaperContent>
        {invoicesForHandling ? (
          <Typography sx={{ fontSize: '5rem' }}>
            {invoicesForHandling.unsentInvoices}
            <span
              style={{
                color: COLORS.ERROR,
                fontSize: '2rem',
                position: 'relative',
                bottom: '1rem'
              }}
            >
              ({invoicesForHandling.overdueInvoices})
            </span>
          </Typography>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress sx={{ margin: '10px 0' }} />
          </div>
        )}
      </PaperContent>

      <PaperFooter style={{ padding: '15px 20px' }}>
        <Button
          onClick={() => {
            navigate('/invoices');
          }}
        >
          {t('common.toInvoices')}
        </Button>
      </PaperFooter>
    </PaperCard>
  );
};

export default InvoicesForHandling;
