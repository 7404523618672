import React, { useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, Box, Typography } from '@mui/material';
import {
  FieldValues,
  FormState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validate } from 'utils';
import CountryCode, { COUNTRY_INFO } from 'types/CountryCode';
import { getCountryFlag } from 'utils/_getCountryCodeFlag';
import User from 'types/User';
import theme from 'styles/theme';

type FormValues = {
  phone: string;
  phoneCountryCode: CountryCode;
};

type PhoneNumberInputProps = {
  focusInput: boolean;
  formSubmitted: boolean;
  fieldName: 'phone' | 'phoneCountryCode';

  setValue: UseFormSetValue<FormValues>;
  getValues: UseFormGetValues<FieldValues>;
  formState: FormState<FieldValues>;
  register: UseFormRegister<FormValues>;
  watch: UseFormWatch<FieldValues>;

  user?: User;
};

const PhoneNumberInputCountry: React.FC<PhoneNumberInputProps> = ({
  focusInput,
  formSubmitted,
  fieldName,

  setValue,
  getValues,
  formState,
  register,
  watch,
  user
}) => {
  const { t } = useTranslation();
  const country = watch('phoneCountryCode');
  const countryInfo = COUNTRY_INFO[(country as keyof typeof COUNTRY_INFO) || 'NO'];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputEvent = event.nativeEvent as InputEvent;
    const cleanedValue = event.target.value.replace(/\D/g, ''); // Remove non-digit characters

    const phoneCountryMax = countryInfo.phoneDigits;

    if (
      cleanedValue.trim().length >= phoneCountryMax[phoneCountryMax.length - 1] &&
      inputEvent.inputType !== 'deleteContentBackward'
    ) {
      setValue(fieldName, cleanedValue.slice(0, phoneCountryMax[phoneCountryMax.length - 1]));
      return;
    }

    setValue(fieldName as 'phone' | 'phoneCountryCode', cleanedValue);
  };

  useEffect(() => {
    if (user) {
      setValue('phone', user.phone.toString());
    }
  }, [user]);

  const sortedCountryCodes = Object.keys(COUNTRY_INFO).sort((a, b) => {
    const codeA = Number(COUNTRY_INFO[a as keyof typeof COUNTRY_INFO].phonePrefix);
    const codeB = Number(COUNTRY_INFO[b as keyof typeof COUNTRY_INFO].phonePrefix);
    return codeA - codeB;
  });

  return (
    <FormControl
      fullWidth
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          overflow: 'hidden'
        }}
      >
        <Select
          {...register('phoneCountryCode', { required: true })}
          value={country}
          defaultValue={user?.phoneCountryCode || 'NO'}
          disabled={formSubmitted}
          sx={{
            flex: '0 0 100px',
            paddingLeft: '8px',
            borderRight: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
              borderRight: '1px solid rgba(0,0,0,0)'
            }
          }}
          MenuProps={{
            sx: {
              ['.MuiPaper-root']: {
                margin: 0,
              }
            }
          }}
        >
          {sortedCountryCodes.map((countryCode, index) => (
            <MenuItem key={index} value={countryCode}>
              {getCountryFlag(countryCode as CountryCode)}{' '}
              {COUNTRY_INFO[countryCode as keyof typeof COUNTRY_INFO].phonePrefix}
            </MenuItem>
          ))}
        </Select>

        <TextField
          id={fieldName}
          inputRef={(input) => {
            if (focusInput && input) {
              input.focus();
            }
          }}
          {...register(fieldName, {
            required: true,
            validate: () => {
              const phoneCountryMax = countryInfo.phoneDigits;

              return validate.phone(getValues(fieldName).replace(/\D/g, ''), phoneCountryMax)
                ? true
                : t('alerts.phoneNumberNotValid').toString();
            }
          })}
          onChange={handleChange}
          sx={{
            flex: 1,
            '&:focus-visible': {
              outline: 'none'
            },
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px white inset'
            }
          }}
          type={'text'}
          placeholder={t('common.phone')}
          className={'field phone-number-input username'}
          disabled={formSubmitted}
          error={formState.errors?.[fieldName] ? true : false}
        />
      </Box>

      <Typography sx={{ color: theme.palette.error.main, fontSize: '0.99rem' }}>
        {formState.errors?.[fieldName] ? formState.errors?.[fieldName].message.toString() : ''}
      </Typography>
    </FormControl>
  );
};

export default PhoneNumberInputCountry;
