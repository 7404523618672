import { IconButton, Tooltip, Typography } from '@mui/material';
import { PageContainer, PaperCard, PaperContent, PaperHeader } from 'components';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import settingService from 'services/setting-service';
import SubscriberChart from './components/SubscriberChart/SubscriberChart';
import ImageIcon from '@mui/icons-material/Image';
import { EditSingleStringModal } from 'modals';
import Setting from 'types/Setting';
import SubscriptionDistributionChart from './components/SubscriptionDistributionChart/SubscriptionDistributionChart';
import SubscriptionsStartedToday from './components/SubscriptionsStartedToday/SubscriptionsStartedToday';
import ActiveSubscriptions from './components/ActiveSubscriptions/ActiveSubscriptions';
import SubscriptionsExpiredYesterday from './components/SubscriptionsExpiredYesterday/SubscriptionsExpiredYesterday';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';
import PurchasesAnalytics from './components/PurchasesAnalytics/PurchasesAnalytics';
import InvoicesForHandling from './components/InvoicesForHandling/InvoicesForHandling';
import useSettings from 'hooks/useSettings';

const modals = {
  editTopImage: 0
};

const StartPage = () => {
  const { t } = useTranslation();

  const axiosAlert = useAxiosAlert();
  const [topImage, setTopImage] = useState<Setting>(null);
  const [hasAnalytics, setHasAnalytics] = useState<boolean>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [openModal, setOpenModal] = useState(-1);

  const { getValueByKeyAsBoolean } = useSettings();
  const paymentSolutionInvoice = getValueByKeyAsBoolean('PaymentSolutionInvoice', 0);

  useEffect(() => {
    (async () => {
      try {
        const topImageSetting = await settingService.getByKey('TopImage');
        setTopImage(topImageSetting.data);
        const platformIdSetting = await settingService.getByKey('AnalyticsPlatformId');
        setHasAnalytics(
          platformIdSetting.data.value !== '' && platformIdSetting.data.value !== null
        );
      } catch (error) {
        axiosAlert(error as AxiosError, []);
      }
    })();
  }, []);

  const updateTopImage = async (value: string) => {
    let topImageSetting;
    if (topImage.default) {
      topImageSetting = await settingService.post({ key: topImage.key, value: value });
    } else {
      topImageSetting = await settingService.update({ key: topImage.key, value: value });
    }
    setTopImage(topImageSetting.data);
    setOpenModal(-1);
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <PageContainer
      sx={{
        maxWidth: '1300px'
      }}
    >
      <PaperCard
        sx={{
          position: 'relative',
          zIndex: 98,
          width: '100%',
          maxWidth: '885px',
          padding: '20px'
        }}
      >
        {topImage && (
          <>
            <img
              ref={imageRef}
              src={topImage.value}
              alt='Top image'
              style={{ maxWidth: '100%', opacity: '0.3' }}
            />
            <SubscriberChart sizeRef={imageRef} />

            <Tooltip title={t('common.changeTopImage')}>
              <IconButton
                sx={{
                  padding: '6px',
                  minWidth: '36px',
                  position: 'absolute',
                  top: '20px',
                  right: '20px',
                  opacity: 0.7,
                  zIndex: 100
                }}
                onClick={() => {
                  setOpenModal(modals.editTopImage);
                }}
              >
                <ImageIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </PaperCard>

      <ActiveSubscriptions />

      <SubscriptionsStartedToday />

      <SubscriptionsExpiredYesterday />

      <PaperCard sx={{ textAlign: 'center', width: '100%', maxWidth: '300px' }}>
        <PaperHeader>
          <Typography variant={'h6'}>{t('common.distribution')}</Typography>
        </PaperHeader>

        <PaperContent sx={{ padding: '34px 20px' }}>
          <SubscriptionDistributionChart></SubscriptionDistributionChart>
        </PaperContent>
      </PaperCard>
      
      {paymentSolutionInvoice && <InvoicesForHandling />}

      {hasAnalytics && <PurchasesAnalytics />}
      <div style={{ height: '100px', width: '100%' }}></div>
      {openModal === modals.editTopImage && (
        <EditSingleStringModal
          value={topImage.value}
          label={topImage.key}
          title={t('common.changeTopImage')}
          max={255}
          onCancel={() => {
            setOpenModal(-1);
          }}
          onConfirm={(value) => {
            updateTopImage(value);
          }}
        />
      )}
    </PageContainer>
  );
};

export default StartPage;
