/* eslint-disable max-lines */
/* eslint-disable indent */
import { Button, Stack, SxProps, Typography } from '@mui/material';
import useAlert from 'hooks/useAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import userService from 'services/user-service';
import User from 'types/User';
import UserList from '../UserList/UserList';
import { PaperCard, PaperContent, PaperFooter, PaperHeader, PrimarySearch } from 'components';
import SearchFilterType, { Filter, SearchFilterGroup } from 'types/SearchFilter';
import SearchQueryType from 'types/SearchQueryType';
import { ROUTE_PATHS } from 'consts/routes';
import PageResults from 'types/PageResults';

import logger from 'logger/logger';

import { useNavigate } from 'react-router';
import Subscription from 'types/Subscription';

import PrimarySearchFilter from 'components/PrimarySearchFilter/PrimarySearchFilter';
import UserSearchFilterType from 'types/UserSearchFilter';
import SearchQuery from 'types/SearchQuery';
import { capitalizeFirstLetter } from 'utils';
import subscriptionService from 'services/subscription-service';
import PrimarySearchPageNav from 'components/PrimarySearchPageNav/PrimarySearchPageNav';
import getNumberOfActiveFilters from 'utils/_getNumberOfActiveFilters';

import SearchFilter from '../../../../components/SearchFilter/SearchFilter';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, userSearchActions } from 'store';
import AddIcon from '@mui/icons-material/Add';
import AddUserModal from '../AddUserModal';

const LIMIT = 20;

type UserSearchModal = 'addUser';

const UserSearch: FC = () => {
  const navigate = useNavigate();

  // State
  const { addAlert } = useAlert();
  const { t } = useTranslation();
  const [pageResults, setPageResults] = useState<PageResults<User>>();
  const [modal, setModal] = useState<UserSearchModal>(null);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>();
  const { searchQuery, searchFilter } = useSelector((state: RootState) => state.userSearch);
  const dispatch = useDispatch();

  const queryTypes: SearchQueryType[] = [
    { key: 'name', label: t('common.name') },
    { key: 'email', label: t('common.email') },
    { key: 'phone', label: t('common.phone') },
    { key: 'id', label: t('common.userId') },
    { key: 'address', label: t('common.address') },
    { key: 'postCode', label: t('common.postCode') }
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const createSearchFilters = (subscriptionsData: Subscription[]) => {
    const subscriptionIdsFilter: Filter[] = subscriptionsData.map((s) => ({
      label: capitalizeFirstLetter(s.name),
      value: s.id,
      checked: true
    }));

    const userSubscriptionStatusesFilter: Filter[] = [
      'ACTIVE',
      'AWAITING_PAYMENT',
      'TO_BE_CANCELED',
      'CANCELED'
    ]
      .filter((u) => u !== 'CANCELED')
      .map((u) => ({
        label: t(`userSubscription.statuses.${u}`),
        value: u,
        checked: true
      }));

    const userSubscriptionPaymentSolution: Filter[] = [
      'vipps',
      'swedbank',
      'invoice',
      'none',
      'external',
      'gift'
    ].map((p) => ({
      label: t(`paymentSolution.${p}`),
      value: p,
      checked: true
    }));

    const tempSearchFilter: SearchFilterType = {
      active: false,
      numberOfActiveFilters: 0,
      searchFilterGroups: [
        {
          key: 'subscriptionIds',
          label: t('common.subscription'),
          filters: subscriptionIdsFilter
        },
        {
          key: 'userSubscriptionStatuses',
          label: t('userSubscription.status'),
          filters: userSubscriptionStatusesFilter
        },
        {
          key: 'userSubscriptionPaymentSolution',
          label: t('payment.solution'),
          filters: userSubscriptionPaymentSolution
        }
      ]
    };

    return tempSearchFilter;
  };

  const init = async () => {
    const { data: subscriptionsData } = await subscriptionService.get();
    setSubscriptions(subscriptionsData);

    if (!searchFilter) {
      dispatch(userSearchActions.setFilter(createSearchFilters(subscriptionsData)));
    }

    const offset = searchQuery?.page ? LIMIT * (searchQuery?.page - 1) : 0;

    searchUsers(
      {
        query: searchQuery?.query || null,
        filters: searchQuery?.filters || undefined,
        queryType: searchQuery?.queryType || queryTypes[0],
        page: searchQuery?.page || 1
      },
      LIMIT,
      offset,
      subscriptionsData
    );
  };

  const searchUsers = async (
    searchQueryP: SearchQuery,
    limit: number,
    offset: number,
    _subscriptions = subscriptions
  ) => {
    try {
      // Loading
      const usersQuery = await userService.search(searchQueryP, limit, offset);

      // Add subscriptions to userSubscriptions
      if (usersQuery.data) {
        usersQuery.data.results = usersQuery.data.results.map((u) => {
          if (u.userSubscriptions && u.userSubscriptions.length > 0) {
            u.userSubscriptions.forEach((us) => {
              if (_subscriptions) {
                const subscription = _subscriptions.find((s) => s.id === us.subscriptionId);

                if (subscription) {
                  us.subscription = subscription;
                }
              }
            });
          }
          return u;
        });
      }

      dispatch(userSearchActions.setQuery({ ...searchQueryP }));
      setPageResults(usersQuery.data);
      // Stop loading
    } catch (error) {
      logger.error('SearchUsers failed: ', error);
      addAlert('error', t('alerts.couldNotGetUsers'));
    }
  };

  const getUserSearchFilter = (searchFilterGroupsP: SearchFilterGroup[]): UserSearchFilterType => {
    const parsedResult: UserSearchFilterType = {};
    searchFilterGroupsP.forEach((sfg) => {
      parsedResult[sfg.key as keyof UserSearchFilterType] = sfg.filters;
    });
    return parsedResult;
  };

  const handleEditUser = (user: User) => {
    navigate(ROUTE_PATHS.USER.replace(':id', user.id.toString()), {
      state: { user }
    });
  };

  const changePage = (value: number) => {
    const updatedSearchQuery = { ...searchQuery, page: value };
    dispatch(userSearchActions.setQuery(updatedSearchQuery));
    const offset = (value - 1) * LIMIT;
    searchUsers(updatedSearchQuery, LIMIT, offset);
  };

  useEffect(() => {
    init();
  }, []);

  const handleFilterSubmit = (_searchFilter: SearchFilterType) => {
    setAnchorEl(null);
    dispatch(
      userSearchActions.setFilter({
        ..._searchFilter,
        active: true,
        numberOfActiveFilters: getNumberOfActiveFilters(_searchFilter.searchFilterGroups)
      })
    );

    searchUsers(
      {
        query: searchQuery?.query || '',
        filters: getUserSearchFilter(_searchFilter?.searchFilterGroups),
        queryType: searchQuery?.queryType || queryTypes[0],
        page: 1
      },
      LIMIT,
      0
    );
  };

  const resetFilter = () => {
    const resetSearchFilter = { ...searchFilter, active: false };
    dispatch(userSearchActions.setFilter(resetSearchFilter));
    searchUsers(
      {
        query: searchQuery.query || '',
        filters: null,
        queryType: searchQuery.queryType || queryTypes[0],
        page: 1
      },
      LIMIT,
      0
    );
  };

  const styledPaperCard: SxProps = {
    maxWidth: '1500px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  };

  const styledPaperContent: SxProps = {
    overflowY: 'auto',
    overflowX: 'auto',
    flex: 1,
    padding: 0
  };

  return (
    <PaperCard sx={styledPaperCard}>
      <PaperHeader>
        <Stack flexDirection='row' justifyContent={'space-between'} alignItems={'center'}>
          <Stack flexDirection='row' gap={2} alignItems={'center'}>
            <Typography variant='h5'>{t('common.users')}</Typography>
            <PrimarySearch
              defaultQuery={searchQuery?.query}
              queryTypes={queryTypes}
              onSearchQueryUpdate={(query) => {
                dispatch(userSearchActions.setQuery({ ...searchQuery, query }));
              }}
              onSearch={(query, queryType) => {
                searchUsers({ ...searchQuery, query, queryType, page: 1 }, LIMIT, 0);
              }}
            />
            <PrimarySearchFilter
              anchorEl={anchorEl}
              onClick={(element) => {
                anchorEl ? setAnchorEl(null) : setAnchorEl(element);
              }}
              isActive={searchFilter?.active}
              onRemoveFilter={resetFilter}
              numberOfActiveFilters={searchFilter?.numberOfActiveFilters}
              title={t('common.subscription')}
              filterComponent={
                <SearchFilter filterSubmit={handleFilterSubmit} searchFilter={searchFilter} />
              }
            />
            {pageResults && (
              <Typography sx={{ padding: '10px', whiteSpace: 'nowrap' }}>
                {t('common.hits') + ': ' + pageResults.total}
              </Typography>
            )}
          </Stack>
          <Button
            onClick={() => {
              setModal('addUser');
            }}
          >
            <AddIcon /> {t('common.addUser')}
          </Button>
        </Stack>
      </PaperHeader>
      <PaperContent sx={styledPaperContent}>
        <UserList users={pageResults?.results} onEdit={handleEditUser} />
      </PaperContent>
      <PaperFooter>
        {pageResults && pageResults?.total > 0 && (
          <PrimarySearchPageNav
            page={searchQuery?.page || 1}
            totalResults={pageResults.total}
            onChangePage={changePage}
          />
        )}
      </PaperFooter>
      {modal === 'addUser' && (
        <AddUserModal onCancel={() => setModal(null)} onConfirm={handleEditUser} />
      )}
    </PaperCard>
  );
};

export default UserSearch;
