import { CircularProgress, Stack, Typography } from '@mui/material';
import PageContent from 'components/PageContent/PageContent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWrapper from 'components/PageWrapper/PageWrapper';
import { ROUTE_PATHS } from 'consts/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import bulkService from 'services/bulk.service';
import BulkList from './components/BulkList';

const BulkPage: FC = () => {
  const { t } = useTranslation();
  const [bulks, setBulks] = useState<Bulk[]>([]);
  const [loading, setLoading] = useState<boolean>();

  console.log('bulks', bulks);

  const getBulks = async () => {
    setLoading(true);
    try {
      const { data } = await bulkService.getAll();
      setBulks(data);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBulks();
  }, []);

  const handleDeleteBulk = async (bulkId: number) => {
    setLoading(true);
    try {
      await bulkService.delete(bulkId);
      await getBulks();
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper>
      <PageHeader backToUrl={ROUTE_PATHS.PAPER}>
        <Typography variant={'h6'}>{t('common.bulk')}</Typography>
      </PageHeader>
      <PageContent sx={{ padding: '12px' }}>
        {loading && (
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <CircularProgress />
          </Stack>
        )}
        {!loading && (
          <BulkList bulks={bulks} onBulkChanged={getBulks} onDeleteBulk={handleDeleteBulk} />
        )}
      </PageContent>
    </PageWrapper>
  );
};

export default BulkPage;
