import { Button, Stack, TextField, Typography } from '@mui/material';
import FormDialog from 'components/FormDialog/FormDialog';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UserInvoiceSettings from 'types/UserInvoiceSettings';
import { validate } from 'utils';

type UserBillingContactModalProps = {
  userInvoiceSettings: UserInvoiceSettings;
  onConfirm: (billingContact: UserInvoiceSettings) => void;
  onCancel: () => void;
};

type UserBillingContactFields = {
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
  address2?: string;
  postCode?: string;
  postOffice?: string;
};

const UserBillingContactModal: FC<UserBillingContactModalProps> = ({
  userInvoiceSettings,
  onConfirm,
  onCancel
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<UserBillingContactFields>({
    defaultValues: {
      name: userInvoiceSettings?.billingContactName,
      email: userInvoiceSettings?.billingContactEmail,
      phone: userInvoiceSettings?.billingContactPhone,
      address: userInvoiceSettings?.billingContactAddress,
      address2: userInvoiceSettings?.billingContactAddress2,
      postCode: userInvoiceSettings?.billingContactPostCode,
      postOffice: userInvoiceSettings?.billingContactPostOffice
    }
  });

  const onSubmit: SubmitHandler<UserBillingContactFields> = async (fields) => {
    const updatedSettings: UserInvoiceSettings = {
      ...userInvoiceSettings,
      useBillingContact: true,
      billingContactName: fields.name,
      billingContactEmail: fields.email,
      billingContactPhone: fields.phone,
      billingContactAddress: fields.address,
      billingContactAddress2: fields.address2,
      billingContactPostCode: fields.postCode,
      billingContactPostOffice: fields.postOffice
    };
    onConfirm(updatedSettings);
  };

  const onDelete = async () => {
    const updatedSettings: UserInvoiceSettings = {
      ...userInvoiceSettings,
      useBillingContact: false,
      billingContactName: null,
      billingContactEmail: null,
      billingContactPhone: null,
      billingContactAddress: null,
      billingContactAddress2: null,
      billingContactPostCode: null,
      billingContactPostOffice: null
    };
    onConfirm(updatedSettings);
  };

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t('common.billingContact')}
      actions={
        <Button
          sx={{ marginRight: 'auto' }}
          color={'error'}
          onClick={onDelete}
          disabled={!userInvoiceSettings.useBillingContact}
        >
          {t('common.remove')}
        </Button>
      }
      submitText={userInvoiceSettings.useBillingContact ? t('common.change') : t('common.add')}
    >
      <div className='dialog-content' style={{ maxWidth: '450px' }}>
        <Stack gap={2}>
          <Typography sx={{ fontSize: '0.8rem', fontStyle: 'italic', opacity: '0.8' }}>
            {t('billingContact.billingContactDescription')}
          </Typography>
          <TextField
            fullWidth
            label={t('common.name') + '*'}
            {...register('name', {
              required: true
            })}
            error={errors.name ? true : false}
          />
          <TextField
            fullWidth
            label={t('common.email')}
            {...register('email')}
            error={errors.email ? true : false}
          />
          <TextField
            fullWidth
            label={t('common.phone')}
            {...register('phone')}
            error={errors.phone ? true : false}
            helperText={t('invoice.billingContactPhoneDescription')}
          />
          <TextField
            fullWidth
            label={t('common.address') + '*'}
            {...register('address', {
              required: true
            })}
            error={errors.address ? true : false}
          />
          <Stack direction={'row'} spacing={2}>
            <TextField
              sx={{ width: '30%' }}
              fullWidth
              label={t('common.postCode') + '*'}
              {...register('postCode', {
                minLength: 2,
                maxLength: 50,
                required: true
              })}
              error={errors.postCode ? true : false}
            />
            <TextField
              sx={{ width: '70%' }}
              fullWidth
              label={t('common.postOffice') + '*'}
              {...register('postOffice', {
                required: true,
                validate: (value) => validate.length(value, 2, 50)
              })}
              onChange={(event) => {
                event.target.value = event.target.value.toUpperCase();
                setValue('postOffice', event.target.value);
              }}
              error={!!errors.postOffice}
            />
          </Stack>
        </Stack>
      </div>
    </FormDialog>
  );
};

export default UserBillingContactModal;
