import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Admin from 'types/Admin';

const initialState = null as Admin;

export const adminSlice = createSlice({
  name: 'adminSlice',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Admin>) => {
      return action.payload;
    },
    remove: () => {
      return initialState;
    }
  }
});

export default adminSlice;
