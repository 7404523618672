import { Edit } from '@mui/icons-material';
import { Box, Chip, IconButton, SxProps, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { PrimaryCard, PrimaryListItem } from 'components';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { ConsentModal } from 'modals';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import subscriptionService from 'services/subscription-service';
import Subscription from 'types/Subscription';
import SubscriptionModal from '../SubscriptionModal/SubscriptionModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAlert from 'hooks/useAlert';

type SubscriptionDetails = {
  subscription: Subscription;
  myPageUrl?: string;
  onEdit: (subscription: Subscription) => void;
};

const SubscriptionDetails: FC<SubscriptionDetails> = ({ subscription, onEdit, myPageUrl }) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(null);
  const axiosAlert = useAxiosAlert();
  const { addAlert } = useAlert();

  const styles: { [k: string]: SxProps } = {
    header: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: '20px'
    }
  };

  const resetModal = () => {
    setModal(null);
  };

  const deleteSubscription = async () => {
    try {
      await subscriptionService.delete(subscription.id);
    } catch (error) {
      axiosAlert(
        error as AxiosError,
        [
          {
            status: 400,
            message: t('alerts.couldNotDeleteSubscription')
          }
        ],
        t('alerts.couldNotDeleteSubscription')
      );
    }

    resetModal();
  };

  const description = subscription.description.replace(/\n\*/g, '\n•');
  return (
    <Box>
      <PrimaryCard>
        <Box sx={styles.header}>
          <Typography variant="h6">{subscription.name}</Typography>
          <IconButton
            onClick={() => {
              setModal(0);
            }}
          >
            <Edit />
          </IconButton>
        </Box>
        <PrimaryListItem label={t('common.id')} value={subscription.id} />
        <PrimaryListItem
          label={t('common.description')}
          sx={{ whiteSpace: 'pre-line' }}
          value={description}
        />
        <PrimaryListItem label={t('common.price')} value={subscription.price + ' kr'} />
        <PrimaryListItem label={t('common.duration')} value={subscription.duration + ' mnd'} />
        <PrimaryListItem
          label={t('common.active')}
          value={
            <Chip
              color={subscription.status === 'ACTIVE' ? 'success' : 'default'}
              label={t(`subscriptionStatus.${subscription.status}`).toUpperCase()}
            />
          }
        />
        <PrimaryListItem
          label={`${t('common.purchaseLink')} (${t('campaign.withoutCampaign').toLowerCase()})`}
          value={
            <IconButton
              disabled={!myPageUrl}
              onClick={() => {
                const url = `${myPageUrl}/order-subscription/select?subscriptionId=${subscription.id}`;
                navigator.clipboard.writeText(url);
                if(subscription.status === 'ACTIVE') {
                  addAlert('success', t('alerts.copiedToClipboard'));
                } else {
                  addAlert('warning', t('alerts.copiedToClipboard') + '\n'+ t('subscription.alerts.subscriptionNotActive'));
                }
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          }
        />
      </PrimaryCard>
      {modal === 0 && (
        <SubscriptionModal
          subscription={subscription}
          onConfirm={(updatedSubscription) => {
            resetModal();
            onEdit(updatedSubscription);
          }}
          onCancel={resetModal}
        />
      )}
      {modal === 1 && (
        <ConsentModal
          title={`${t('subscription.deactivate')} ${t(subscription.name)}`}
          content={`${t('subscription.deactivateConfirmation', {
            numberOfUsers: subscription.numberOfActiveUsers
          })}`}
          onCancel={resetModal}
          onConfirm={deleteSubscription}
        />
      )}
    </Box>
  );
};

export default SubscriptionDetails;
