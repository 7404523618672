import axios, { AxiosPromise } from 'axios';
import PageResults from 'types/PageResults';
import PaymentMethod from 'types/PaymentMethod';
import SearchQuery from 'types/SearchQuery';
import User, { UserMetadata } from 'types/User';

const userService = {
  search: (searchQuery: SearchQuery, limit = 50, offset = 0) => {
    return axios.post<PageResults<User>>(
      `user/search?limit=${limit}&offset=${offset}`,
      searchQuery
    );
  },
  get: (limit = 50, offset = 0) => {
    return axios.get<PageResults<User>>(`user/?limit=${limit}&offset=${offset}`);
  },
  addUser(phone: number, phoneCountryCode: string, name: string, password: string) {
    return axios.post<User>('user/admin-create', {
      phone,
      phoneCountryCode,
      name,
      password
    });
  },
  updateUser: (updatedUser: User, newPassword: string = null) => {
    return axios.put(`user/${updatedUser.id}`, {
      name: updatedUser.name,
      email: updatedUser.email,
      phone: updatedUser.phone,
      note: updatedUser.note,
      phoneCountryCode: updatedUser.phoneCountryCode,
      password: newPassword
    });
  },
  refreshAutoPaymentMethod: (userId: number) => {
    return axios.patch<PaymentMethod>(`user/${userId}/refresh-auto-payment-method`);
  },
  refreshEInvoiceReference: (userId: number) => {
    return axios.patch<User>(`user/${userId}/refresh-e-invoice-reference`);
  },
  refreshDirectDebitMandateId: (userId: number) => {
    return axios.patch<User>(`user/${userId}/refresh-direct-debit-mandateId`);
  },
  updateSendInvociesByEmail: (userId: number, sendInvoicesByEmail: boolean) => {
    return axios.patch<boolean>(`user/${userId}/update-send-invocies-by-email`, {
      sendInvoicesByEmail
    });
  },
  updateInvoicePaymentMethodAuto: (userId: number, invoicePaymentMethodAuto: boolean) => {
    return axios.patch<User>(`user/${userId}/update-invoice-payment-method-auto`, {
      invoicePaymentMethodAuto
    });
  },
  manualUpdateInvoicePaymentMethod: (userId: number, invoicePaymentMethod: PaymentMethod) => {
    return axios.patch<User>(`user/${userId}/manual-update-invoice-payment-method`, {
      invoicePaymentMethod
    });
  },
  getById: (userId: number) => {
    return axios.get(`user/${userId}`);
  },
  archiveUser: (userId: number, archiveReason = ''): AxiosPromise<User> => {
    return axios.patch(`user/${userId}/archive`, { archiveReason });
  },
  updateMetadata: (userId: number, metadata: UserMetadata): AxiosPromise<User> => {
    return axios.patch(`user/${userId}/update-metadata`, metadata);
  },
  deleteMetadata: (userId: number): AxiosPromise<User> => {
    return axios.patch(`user/${userId}/delete-metadata`);
  }
};

export default userService;
