import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { PrimaryListItem } from 'components';
import { FC } from 'react';
import { UserMetadata } from 'types/User';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type UserMetaDataProps = {
  metadata: UserMetadata;
};

type KeyValue = {
  key: string;
  value: string;
};

const UserMetaData: FC<UserMetaDataProps> = ({ metadata }) => {
  const metadataKeyValue: KeyValue[] = [];
  for (const key in metadata) {
    if (Object.prototype.hasOwnProperty.call(metadata, key)) {
      let val = '';
      if(typeof metadata[key] === 'string') {
        val = metadata[key] as string;
      } else if(typeof metadata[key] === 'number') {
        val = metadata[key].toString();
      } else if(typeof metadata[key] === 'boolean') {
        val = metadata ? 'true' : 'false';
      }
      metadataKeyValue.push({
        key: key,
        value: val,
      });
    }
  }

  return (
    <Accordion sx={{backgroundColor: 'rgba(0,0,0,0.5)', marginTop: '20px'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Metadata</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {metadataKeyValue.map(({ key, value }, i) => (
          <PrimaryListItem key={i} label={key} value={value} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default UserMetaData;
