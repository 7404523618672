/* eslint-disable indent */
import {
  Dialog,
  Stack,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Divider,
  CircularProgress
} from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ManageBulkDistributionRoutes from './ManageBulkDistributionRoutes';
import bulkService from 'services/bulk.service';

type BulkModalProps = {
  bulk: Bulk;
  onCancel: () => void;
  onSaved: () => void;
};

type BulkFields = {
  bulkRouteId: string;
  name: string;
  transportRoute: string;
  deliveryPlace: string;
  postCode: string;
  postOffice: string;
  carText: string;
  dropText: string;
  dropText2: string;
  distributionRouteIds: string[];
};

const BulkModal: FC<BulkModalProps> = ({ bulk, onSaved, onCancel }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<BulkFields>({
    defaultValues: {
      bulkRouteId: bulk?.bulkRouteId,
      name: bulk?.name,
      postCode: bulk?.postCode,
      postOffice: bulk?.postOffice,
      transportRoute: bulk?.transportRoute,
      deliveryPlace: bulk?.deliveryPlace,
      carText: bulk?.carText,
      dropText: bulk?.dropText,
      dropText2: bulk?.dropText2,
      distributionRouteIds: bulk?.distributionRouteIds || []
    }
  });

  const distributionRouteIds = watch('distributionRouteIds');
  const formRef = useRef(null);

  const title = !bulk
    ? t('common.addBlank', {
        blank: t('common.bulk')
      })
    : t('common.editBlank', {
        blank: t('common.bulk')
      });

  const addDistributionRouteId = (distributionRouteId: string) => {
    const newDistributionRouteIds = [...distributionRouteIds, distributionRouteId];
    setValue('distributionRouteIds', newDistributionRouteIds);
  };

  const onSubmit = async (data: BulkFields) => {
    try {
      setLoading(true);

      if (bulk) {
        await bulkService.update({ ...data, id: bulk.id });
      } else {
        await bulkService.create(data);
      }

      onSaved();
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack direction={'row'} gap={2}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2} mt={1}>
              <TextField
                required
                {...register('bulkRouteId', {
                  required: true,
                  minLength: 1,
                  maxLength: 5
                })}
                label={t('common.routeId')}
                error={!!errors.bulkRouteId}
              />
              <TextField
                required
                {...register('name', { required: true })}
                label={t('common.name')}
              />
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                gap={2}
                alignItems={'flex-end'}
              >
                <TextField
                  required
                  {...register('postCode', { required: true })}
                  label={t('common.postCode')}
                />
                <TextField
                  required
                  {...register('postOffice', { required: true })}
                  label={t('common.postOffice')}
                />
              </Stack>
              <TextField
                required
                {...register('transportRoute', { required: true })}
                label={t('common.transportRoute')}
              />
              <TextField
                required
                {...register('deliveryPlace', { required: true })}
                label={t('common.deliveryPlace')}
              />
              <TextField {...register('carText', { required: true })} label={t('common.carText')} />
              <TextField {...register('dropText')} label={t('common.dropText')} />
              <TextField {...register('dropText2')} label={t('common.dropText2')} />
            </Stack>
          </form>
          <Divider orientation={'vertical'} flexItem />
          <ManageBulkDistributionRoutes
            distributionRouteIds={distributionRouteIds}
            onAddDistributionRouteId={addDistributionRouteId}
            onRemoveDistributionRouteId={(distributionRouteId) => {
              const newDistributionRouteIds = distributionRouteIds.filter(
                (id) => id !== distributionRouteId
              );
              setValue('distributionRouteIds', newDistributionRouteIds);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} justifyContent={'flex-end'} gap={2}>
          <Button disabled={loading} variant={'outlined'} onClick={() => onCancel()}>
            {t('common.cancel')}
          </Button>
          <Button
            disabled={loading}
            onClick={() =>
              formRef.current &&
              formRef.current.dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true })
              )
            }
            variant={'contained'}
          >
            {loading ? <CircularProgress size={'30px'} /> : t('common.save')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default BulkModal;
