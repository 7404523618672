import { swedbankPayLogo, vippsLogo } from 'styles/theme';
import PaymentSolution from 'types/PaymentSolution';

const getPaymentSoulutionLogo = (paymentSolution: PaymentSolution) => {
  let logo = '';
  switch (paymentSolution) {
    case 'vipps':
      logo = vippsLogo;
      break;
    case 'swedbank':
      logo = swedbankPayLogo;
      break;
    default:
      break;
  }
  return logo;
};

export default getPaymentSoulutionLogo;
