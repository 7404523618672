import axios, { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useAlert from './useAlert';
import { useTranslation } from 'react-i18next';

export type AxiosAlert = {
  status: number;
  message?: string;
  cb?: () => void;
};

const useAxiosAlert = () => {
  const { addAlert } = useAlert();
  const alertsState = useSelector((state: RootState) => state.alerts);
  const { t } = useTranslation();

  const axiosAlert = (error: AxiosError, alerts: AxiosAlert[] = [], defaultMessage?: string) => {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;

      let firedAlert = false;
      alerts.forEach((alert) => {
        if (alert?.status === axiosError?.response?.status) {
          firedAlert = true;

          if (alert.message) {
            addAlert('error', alert.message);
          }

          if (alert.cb) {
            alert.cb();
          }
        }
      });

      if (!error.response && error.request && alertsState.length === 0) {
        addAlert('error', t('alerts.networkError'));
      } else if (!firedAlert) {
        if (defaultMessage) {
          addAlert('error', defaultMessage);
        } else {
          addAlert('error', axiosError.message);
        }
      }
    } else {
      console.error('Error is not axios error');
    }
  };

  return axiosAlert;
};

export default useAxiosAlert;
