import axios, { AxiosPromise } from 'axios';
import PageResults from 'types/PageResults';
import Payment from 'types/Payment';
import SearchQuery from 'types/SearchQuery';

const paymentService = {
  get: () => {
    return axios.get('payment');
  },
  getById: (id: number) => {
    return axios.get(`payment/${id}`);
  },
  getPaymentsWithUser: () => {
    return axios.get('payment/payments-with-user');
  },
  manualCapture: (paymentId: number, completed: Date) => {
    return axios.post(`payment/manual-capture/${paymentId}`, { completed });
  },
  tryCapture: (paymentId: number) => {
    return axios.post(`payment/try-capture/${paymentId}`);
  },
  sync: (paymentId: number) => {
    return axios.put(`payment/sync/${paymentId}`);
  },
  createRefund: (paymentId: number, refundDescription?: string): AxiosPromise<Payment> => {
    return axios.post(`payment/create-refund/${paymentId}`, {refundDescription});
  },
  editRefundDescription: (paymentId: number, refundDescription: string): AxiosPromise<Payment> => {
    return axios.put(`payment/edit-refund-description/${paymentId}`, {refundDescription});
  },
  getUserPayments: (userId: number) => {
    return axios.get(`payment/user/${userId}`);
  },
  search: (filter: SearchQuery, limit = 50, offset = 0) => {
    return axios.post<PageResults<Payment>>(
      `payment/search?limit=${limit}&offset=${offset}`,
      filter
    );
  },
  cancelPayment: (paymentId: number): AxiosPromise<Payment> => {
    return axios.post(`payment/${paymentId}/cancel`);
  }
};

export default paymentService;
