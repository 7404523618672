import { FC } from 'react';
import Edition from 'types/Edition';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { dateString, getNameOfDay } from 'utils';
import { useTranslation } from 'react-i18next';
import EditionStatusChip from 'components/EditionStatusChip/EditionStatusChip';
import { Edit } from '@mui/icons-material';

type EditionListProps = {
  editions: Edition[];
  onEdit?: (edition: Edition) => void;
};

const EditionList: FC<EditionListProps> = ({ editions, onEdit }) => {
  const { t } = useTranslation();

  // sort edtioons by date
  const editionRows = editions
    .sort((a, b) => new Date(a.date).getTime() + new Date(b.date).getTime())
    .map((edition) => {
      return (
        <TableRow key={edition.id}>
          <TableCell>
            <Typography>
              {edition.editionNumber || '--'}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {
                // get tha name of the edtition date like monday, tuesday, etc
                getNameOfDay(edition.date) + ' ' + dateString(edition.date)
              }
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{edition.numberOfPages}</Typography>
          </TableCell>
          <TableCell>
            <EditionStatusChip status={edition.status} />
          </TableCell>
          <TableCell>
            <IconButton onClick={() => onEdit(edition)}>
              <Edit />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography>{t('common.editionNumber')}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{t('edition.date')}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{t('edition.numberOfPages')}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{t('common.status')}</Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{editionRows}</TableBody>
    </Table>
  );
};

export default EditionList;
