import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentStatus } from 'types/Payment';

type PaymentStatusChipProps = {
  status: PaymentStatus;
};

const PaymentStatusChip: FC<PaymentStatusChipProps> = ({ status }) => {
  const { t } = useTranslation();
  const getStatusColor = (status: PaymentStatus) => {
    switch (status) {
      case 'CAPTURED':
        return 'success';
      case 'FAILED':
        return 'error';
      case 'DUE':
        return 'info';
      case 'REFUNDED':
        return 'warning';
      default:
        return 'default';
    }
  };
  return (
    <Chip
      sx={{ margin: '-8px 0' }}
      color={getStatusColor(status)}
      label={t(`payment.status.${status}`).toLocaleUpperCase()}
    />
  );
};

export default PaymentStatusChip;
