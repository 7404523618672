import axios, { AxiosPromise } from 'axios';
import Edition from 'types/Edition';

const editionSubscriberSnapshotService = {
  sync(editionId: number): AxiosPromise<Edition> {
    return axios.patch(`/edition-subscriber-snapshot/edition/${editionId}`);
  }
};

export default editionSubscriberSnapshotService;
