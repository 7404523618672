import { FC, useEffect, useState } from 'react';
import {
  Button,
  Chip,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses
} from '@mui/material';
import { PageContainer, PaperCard, PaperContent, PaperHeader } from 'components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import textService from 'services/text-service';
import { useTranslation } from 'react-i18next';
import { ConsentModal } from 'modals';
import Text from 'types/Text';
import TextCategory from 'types/TextCategory';
import TextCategoryTabs from './components/TextCategoryTabs';
import EditTextModal from './components/EditTextModal';
import maxStringLength from 'utils/maxStringLength';

type TextsModal = 'EditText' | 'DeleteConsent' | 'ResetAllConsent';
const Texts: FC = () => {
  const { t } = useTranslation();
  const [texts, setTexts] = useState<Text[]>([]);
  const [selectedText, setSelectedText] = useState<Text>(null);
  const [openModal, setOpenModal] = useState<TextsModal>(null);
  const [selectedCategory, setSelectedCategory] = useState<TextCategory>('invoice');

  const getTexts = async () => {
    const result = await textService.getAll();
    setTexts(result.data);
  };

  const closeModal = () => {
    setOpenModal(null);
  };

  const updateText = async (value: string) => {
    if (selectedText.default) {
      await textService.post({ key: selectedText.key, value: value });
      await getTexts();
      closeModal();
    } else {
      await textService.update({ key: selectedText.key, value: value });
      await getTexts();
      closeModal();
    }
  };

  const deleteText = async (text: Text) => {
    // TODO: add some loading effect
    await textService.delete(text.key);
    await getTexts();
    closeModal();
  };

  useEffect(() => {
    getTexts();
  }, []);

  const filteredTexts = texts.filter((text) => text.category === selectedCategory);

  const deleteAll = async () => {
    await textService.deleteAll();
    await getTexts();
  };

  // Tooltip with 500px max width and white-space: pre-line
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      placement='left'
      {...props}
      PopperProps={{ style: { whiteSpace: 'pre-line' } }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500
    }
  });
  return (
    <PageContainer>
      <PaperCard sx={{ width: '100%' }}>
        <PaperHeader>
          <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => setOpenModal('ResetAllConsent')}
            >
              {t('common.resetAll')}
            </Button>
          </Stack>
        </PaperHeader>
        <PaperContent sx={{ p: 0 }}>
          <TextCategoryTabs onCategoryChange={setSelectedCategory} category={selectedCategory} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center' width='50'>
                  <EditIcon />
                </TableCell>
                <TableCell width='90'>{t('common.type')}</TableCell>
                <TableCell width='250'>{t('common.key')}</TableCell>
                <TableCell>{t('common.value')}</TableCell>
                <TableCell align='center' width='50'>
                  <DeleteIcon />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTexts.map((text) => (
                <TableRow key={text.key}>
                  <TableCell>
                    <IconButton
                      sx={{ margin: '-8px 0' }}
                      onClick={() => {
                        setSelectedText(text);
                        setOpenModal('EditText');
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {text.default ? (
                      <Chip
                        sx={{ margin: '-8px 0' }}
                        label={t('common.default').toLocaleUpperCase()}
                        color='info'
                      />
                    ) : (
                      <Chip
                        sx={{ margin: '-8px 0' }}
                        label={t('common.custom').toLocaleUpperCase()}
                        color='success'
                      />
                    )}
                  </TableCell>
                  <TableCell>{text.key}</TableCell>
                  <TableCell>
                    <CustomTooltip title={text.value}>
                      <Typography>{maxStringLength(text.value, 50)}</Typography>
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    {!text.default && (
                      <IconButton
                        sx={{ margin: '-8px 0' }}
                        color='error'
                        onClick={() => {
                          setSelectedText(text);
                          setOpenModal('DeleteConsent');
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PaperContent>
      </PaperCard>

      {openModal === 'EditText' && (
        <EditTextModal
          text={selectedText}
          onCancel={closeModal}
          onConfirm={(value: string) => {
            updateText(value);
          }}
        />
      )}
      {openModal === 'DeleteConsent' && (
        <ConsentModal
          title={t('common.delete')}
          content={`Are you sure you want to delete ${selectedText.key}?`}
          onCancel={closeModal}
          onConfirm={() => {
            deleteText(selectedText);
          }}
        />
      )}
      {openModal === 'ResetAllConsent' && (
        <ConsentModal
          title={t('common.resetAll')}
          content={'Are you sure you want to reset all texts?'}
          onCancel={closeModal}
          onConfirm={() => {
            deleteAll();
            closeModal();
          }}
        />
      )}
    </PageContainer>
  );
};

export default Texts;
