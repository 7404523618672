import { Typography, CircularProgress, Button } from '@mui/material';
import { AxiosError } from 'axios';
import { PaperCard, PaperHeader, PaperContent, PaperFooter } from 'components';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import reportService from 'services/report-service';

const SubscriptionsExpiredYesterday: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [subscriptionsCanceledYesterdayCount, setSubscriptionsCanceledYesterdayCount] =
    useState(null);
  const axiosAlert = useAxiosAlert();

  useEffect(() => {
    (async () => {
      try {
        const subscriptionsCanceledYesterdayCountQuery =
          await reportService.getSubscriptionsCanceledYesterdayCount();
        setSubscriptionsCanceledYesterdayCount(subscriptionsCanceledYesterdayCountQuery.data);
      } catch (error) {
        axiosAlert(error as AxiosError, []);
      }
    })();
  }, []);

  return (
    <PaperCard sx={{ textAlign: 'center', width: '100%', maxWidth: '300px' }}>
      <PaperHeader>
        <Typography variant={'h6'}>{t('dashboard.expiredYesterday')}</Typography>
      </PaperHeader>

      <PaperContent>
        {subscriptionsCanceledYesterdayCount ? (
          <Typography sx={{ fontSize: '5rem' }}>
            {subscriptionsCanceledYesterdayCount.count}
          </Typography>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress sx={{ margin: '10px 0' }} />
          </div>
        )}
      </PaperContent>

      <PaperFooter style={{ padding: '15px 20px' }}>
        <Button
          onClick={() => {
            navigate('/report/users-with-expired-subscription');
          }}
        >
          {t('common.viewReport')}
        </Button>
      </PaperFooter>
    </PaperCard>
  );
};

export default SubscriptionsExpiredYesterday;
