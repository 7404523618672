import { FC, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SelectDistributionRoute from 'components/SelectDistributionRoute/SelectDistributionRoute';
import { Close } from '@mui/icons-material';
import UserAddress from 'types/UserAddress';
import UserAddressCard from './components/UserAddressCard/UserAddressCard';
import userDistributionService from 'services/user-distribution-service';
import UserDistribution, { UserDistributionRouteStatus } from 'types/UserDistribution';
import { ConsentModal } from 'modals';

type DistributionRouteSelectModalProps = {
  id?: string;
  username: string;
  userAddress: UserAddress;
  userDistribution: UserDistribution;
  onCancel: () => void;
  onDelete?: () => void;
  onRouteSelected: (id: string, routeStatus: UserDistributionRouteStatus) => void;
};

const DistributionRouteSelectModal: FC<DistributionRouteSelectModalProps> = ({
  id,
  userAddress,
  username,
  userDistribution,
  onCancel,
  onDelete,
  onRouteSelected
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const [modal, setModal] = useState<number>();

  const handleAddExernalId = async (newRouteId: string) => {
    setLoading(true);

    try {
      await userDistributionService.addRouteId(userDistribution.id, newRouteId, 'MANUAL');
      onRouteSelected(newRouteId, 'MANUAL');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={true}>
      <DialogTitle>
        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
          <Typography variant="h5">{t('distribution.selectRoute')}</Typography>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: '800px', minHeight: '60vh' }}>
        <Stack gap={2}>
          <UserAddressCard
            userAddress={userAddress}
            userDistribution={userDistribution}
            username={username}
          />
          <Stack gap={2}>
            {id && (
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography>
                  {t('distribution.currentDistributionRoute')}: {id}
                </Typography>
                <Button
                  onClick={() => {
                    setModal(1);
                  }}
                >
                  {t('distribution.removeRoute')}
                </Button>
              </Stack>
            )}
            {!loading && <SelectDistributionRoute onAddRouteId={handleAddExernalId} />}
            {loading && (
              <Stack alignItems={'center'} p={2}>
                <CircularProgress />
              </Stack>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      {modal === 1 && (
        <ConsentModal
          title={t('distribution.removeRoute')}
          content={t('distribution.confirmRemoveRoute')}
          onConfirm={() => {
            setModal(null);
            onDelete();
          }}
          onCancel={() => {
            setModal(null);
          }}
        />
      )}
    </Dialog>
  );
};

export default DistributionRouteSelectModal;
