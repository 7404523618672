import { Person } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import UserAddressRouteStatusChip from 'components/UserDistributionRouteStatusChip/UserDistributionRouteStatusChip';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import UserAddress from 'types/UserAddress';
import UserDistribution from 'types/UserDistribution';

type UserAddressCardProps = {
  userAddress: UserAddress;
  userDistribution: UserDistribution;
  username: string;
};

const UserAddressCard: FC<UserAddressCardProps> = ({ userAddress, userDistribution, username }) => {
  const { t } = useTranslation();

  return (
    <PaperCard variant="outlined">
      <PaperContent>
        <Stack gap={1}>
          <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <Person />
              <Typography>{username}</Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} gap={1}>
            <Typography>{t('common.address') + ': ' + userAddress.address}</Typography>
            <Typography>{userAddress.postCode}</Typography>
            <Typography>{userAddress.postOffice}</Typography>
          </Stack>
          <Stack direction={'row'} gap={2} alignItems={'center'}>
            <Typography>
              {t('common.route')}: {userDistribution.routeId || t('common.notAdded')}
            </Typography>
            <UserAddressRouteStatusChip routeStatus={userDistribution.routeStatus} />
          </Stack>
        </Stack>
      </PaperContent>
    </PaperCard>
  );
};

export default UserAddressCard;
