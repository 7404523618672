const convertToDateNumber = (num: number) => {
  return num < 10 ? '0' + num.toString() : num.toString();
};

const dateString = (d: Date | string, full = false) => {
  if (!d) {
    return false;
  }

  const date = new Date(d);

  // if full is true then include the minuite and hour
  const dateStringDMY =
    convertToDateNumber(date.getDate()) +
    '.' +
    convertToDateNumber(date.getMonth() + 1) +
    '.' +
    date.getFullYear();
  if (full) {
    return (
      dateStringDMY +
      ' ' +
      convertToDateNumber(date.getHours()) +
      ':' +
      convertToDateNumber(date.getMinutes())
    );
  }

  return dateStringDMY;
};

export default dateString;
