/* eslint-disable indent */
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField
} from '@mui/material';
import { ReactHookDatePicker } from 'components';
import useAlert from 'hooks/useAlert';
import logger from 'logger/logger';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import editionService from 'services/edition-service';
import Edition from 'types/Edition';
import { validate } from 'utils';

type EditionModalProps = {
  edition?: Edition;
  onCancel: () => void;
  onFinished: (edition: Edition) => void;
};

type EditionFields = {
  editionNumber: string;
  numberOfPages: number;
  useInserts: boolean;
  insertsWeightGrams: number;
  date: Date;
  fullDistribution: boolean;
};

const EditionModal: FC<EditionModalProps> = ({ edition, onCancel, onFinished }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const { addAlert } = useAlert();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch
  } = useForm<EditionFields>({
    defaultValues: edition
      ? {
          editionNumber: edition.editionNumber,
          numberOfPages: edition.numberOfPages,
          useInserts: edition.useInserts,
          insertsWeightGrams: edition.insertsWeightGrams,
          date: new Date(edition.date),
          fullDistribution: edition.fullDistribution
        }
      : null
  });

  const useInserts = watch('useInserts');

  const onSubmit = async (fields: EditionFields) => {
    const editionFromFields: Edition = {
      ...edition,
      ...fields,
      numberOfPages: Number(fields.numberOfPages),
      useInserts: Boolean(fields.useInserts),
      insertsWeightGrams: fields.useInserts ? Number(fields.insertsWeightGrams) : 0
    };

    setLoading(true);

    try {
      if (edition) {
        const { data: updatedEdition } = await editionService.update(editionFromFields);
        onFinished(updatedEdition);
      } else {
        const { data: createdEdition } = await editionService.create(editionFromFields);
        onFinished({ ...createdEdition, status: 'DRAFT' });
      }
    } catch (error) {
      addAlert('error', t('alert.somethingWentWrong'));
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const title = edition ? t('edition.edit') : t('edition.add');

  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setDate(minDate.getDate() + 1);

  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          sx={{
            minWidth: '400px'
          }}
        >
          <Stack gap={2}>
            <TextField
              label={t('common.editionNumber')}
              type='string'
              {...register('editionNumber')}
            />
            {!edition && (
              <ReactHookDatePicker
                required={true}
                name={'date'}
                label={t('edition.date')}
                control={control}
                error={errors.date ? true : false}
                minDate={minDate}
                validate={(val: Date) => {
                  return validate.date(val) && currentDate <= val;
                }}
              />
            )}
            <TextField
              label={t('edition.numberOfPages')}
              type='number'
              {...register('numberOfPages')}
            />
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
              <FormControlLabel
                control={<Checkbox defaultChecked={getValues('useInserts') ?? false} />}
                {...register('useInserts')}
                onChange={(e, checked) => {
                  setValue('useInserts', checked);
                }}
                label={t('common.useInserts')}
              />
            </Stack>
            {useInserts && (
              <TextField
                sx={{ flex: 1 }}
                label={t('common.insertsWeightGrams')}
                type='number'
                {...register('insertsWeightGrams')}
                autoFocus={true}
              />
            )}
            <FormControlLabel
              control={<Checkbox defaultChecked={getValues('fullDistribution') ?? false} />}
              {...register('fullDistribution')}
              onChange={(e, checked) => {
                setValue('fullDistribution', checked);
              }}
              label={t('common.fullDistribution')}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          {loading && <CircularProgress />}
          {!loading && (
            <Button type={'submit'}>{edition ? t('edition.update') : t('edition.add')}</Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditionModal;
