import { FC, useEffect, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Chip,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { PageContainer, PaperCard, PaperContent, PaperHeader } from 'components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import settingService from 'services/setting-service';
import Setting from 'types/Setting';
import { useTranslation } from 'react-i18next';
import { ConsentModal, EditSingleStringModal } from 'modals';
import SettingTabs from './components/SettingCategoryTabs';
import SettingCategory from 'types/SettingCategories';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const modals = {
  editSetting: 0,
  deleteSetting: 1
};

const Settings: FC = () => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState<Setting[]>([]);
  const [selectedSetting, setSelectedSetting] = useState<Setting>(null);
  const [openModal, setOpenModal] = useState(-1);
  const [selectedCategory, setSelectedCategory] = useState<SettingCategory>('core');

  const getSettings = async () => {
    const result = await settingService.getAll();
    setSettings(result.data);
  };

  const closeModal = () => {
    setOpenModal(-1);
  };

  const updateSetting = async (value: string) => {
    if (selectedSetting.default) {
      await settingService.post({ key: selectedSetting.key, value: value });
      await getSettings();
      closeModal();
    } else {
      await settingService.update({ key: selectedSetting.key, value: value });
      await getSettings();
      closeModal();
    }
  };

  const deleteSetting = async (setting: Setting) => {
    await settingService.delete(setting.key);
    await getSettings();
    closeModal();
  };

  useEffect(() => {
    getSettings();
  }, []);

  const filteredSettings = settings.filter((setting) => setting.category === selectedCategory);

  return (
    <PageContainer>
      <PaperCard sx={{ width: '100%' }}>
        <PaperHeader>
          <Typography variant={'h6'}>
            <SettingsIcon sx={{ position: 'relative', top: '5px' }} /> {t('common.settings')}
          </Typography>
        </PaperHeader>
        <PaperContent sx={{ p: 0 }}>
          <SettingTabs onCategoryChange={setSelectedCategory} category={selectedCategory} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center' width='50'>
                  <EditIcon />
                </TableCell>
                <TableCell width='90'>{t('common.type')}</TableCell>
                <TableCell width='250'>{t('common.key')}</TableCell>
                <TableCell>{t('common.value')}</TableCell>
                <TableCell align='center' width='50'>
                  <DeleteIcon />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSettings.map((setting) => (
                <TableRow key={setting.key}>
                  <TableCell>
                    <IconButton
                      sx={{ margin: '-8px 0' }}
                      onClick={() => {
                        setSelectedSetting(setting);
                        setOpenModal(modals.editSetting);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {setting.default ? (
                      <Chip
                        sx={{ margin: '-8px 0' }}
                        label={t('common.default').toLocaleUpperCase()}
                        color='info'
                      />
                    ) : (
                      <Chip
                        sx={{ margin: '-8px 0' }}
                        label={t('common.custom').toLocaleUpperCase()}
                        color='success'
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Stack gap={1} direction={'row'} alignItems={'center'}>
                      {setting.key}
                      {setting.description && (
                        <Tooltip title={setting.description} placement='left'>
                          <HelpOutlineIcon />
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>{setting.value}</TableCell>
                  <TableCell>
                    {!setting.default && (
                      <IconButton
                        sx={{ margin: '-8px 0' }}
                        color='error'
                        onClick={() => {
                          setSelectedSetting(setting);
                          setOpenModal(modals.deleteSetting);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PaperContent>
      </PaperCard>

      {openModal === modals.editSetting && (
        <EditSingleStringModal
          value={selectedSetting.value}
          label={selectedSetting.key}
          sxContent={{ minWidth: '400px' }}
          title={t('common.editSetting')}
          max={255}
          onCancel={closeModal}
          onConfirm={(value) => {
            updateSetting(value);
          }}
        ></EditSingleStringModal>
      )}
      {openModal === modals.deleteSetting && (
        <ConsentModal
          title={'common.deleteSetting'}
          content={`Are you sure you want to delete ${selectedSetting.key}?`}
          onCancel={closeModal}
          onConfirm={() => {
            deleteSetting(selectedSetting);
          }}
        />
      )}
    </PageContainer>
  );
};

export default Settings;
