import {
  Stack,
  CircularProgress,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Table,
  Divider
} from '@mui/material';
import { PaperContent } from 'components';
import EditPaperRetailerModal from 'components/EditPaperRetailerModal/EditPaperRetailerModal';
import { ConsentModal } from 'modals';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaperRetailer from 'types/PaperRetailer';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import paperRetailerService from 'services/paper-retailer-service';
import { AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';

type RetailerListModal = 'add' | 'edit' | 'delete';

const RetailerList = () => {
  const { t } = useTranslation();
  const [retailers, setRetailers] = useState<PaperRetailer[]>([]);
  const [retailerToEdit, setRetailerToEdit] = useState<PaperRetailer>(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState<RetailerListModal>(null);

  const axiosAlert = useAxiosAlert();
  const getRetailers = async () => {
    setLoading(true);
    try {
      const retailerQuery: { data: PaperRetailer[] } = await paperRetailerService.getAll();
      setRetailers(retailerQuery.data);
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  const updateRetailer = async (retailer: PaperRetailer) => {
    try {
      await paperRetailerService.update(retailer);
      getRetailers();
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    } finally {
      setModal(null);
    }
  };

  const addRetailer = async (retailer: PaperRetailer) => {
    try {
      await paperRetailerService.create(retailer);
      getRetailers();
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    } finally {
      setModal(null);
    }
  };

  const deleteRetailer = async (retailer: PaperRetailer) => {
    try {
      await paperRetailerService.delete(retailer.id);
      getRetailers();
    } catch (error) {
      axiosAlert(error as AxiosError, [], t('alerts.somethingWentWrong'));
    } finally {
      setModal(null);
    }
  };

  useEffect(() => {
    getRetailers();
  }, []);

  return (
    <div>
      {loading ? (
        <Stack direction={'row'} justifyContent={'center'}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t('common.name')}</TableCell>
                <TableCell>{t('common.address')}</TableCell>
                <TableCell>{t('common.routeId')}</TableCell>
                <TableCell>{t('common.postOffice')}</TableCell>
                <TableCell>{t('common.postCode')}</TableCell>
                <TableCell>{t('common.quantity')}</TableCell>
                <TableCell sx={{ maxWidth: '50px', textAlign: 'center' }}>
                  <EditIcon />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {retailers.map((retailer) => (
                <TableRow key={retailer.id}>
                  <TableCell>{retailer.id}</TableCell>
                  <TableCell>{retailer.name}</TableCell>
                  <TableCell>{retailer.address}</TableCell>
                  <TableCell>{retailer.routeId}</TableCell>
                  <TableCell>{retailer.postOffice}</TableCell>
                  <TableCell>{retailer.postCode}</TableCell>
                  <TableCell>{retailer.quantity}</TableCell>
                  <TableCell sx={{ maxWidth: '50px', textAlign: 'center' }}>
                    <IconButton
                      onClick={() => {
                        setRetailerToEdit(retailer);
                        setModal('edit');
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Divider />

          <PaperContent>
            <Stack spacing={2} direction={'row'}>
              <Button variant={'outlined'} onClick={() => setModal('add')}>
                <AddIcon />
                &nbsp; {t('common.add')} {t('common.retailer')}
              </Button>
            </Stack>
          </PaperContent>
        </>
      )}
      {modal === 'add' && (
        <EditPaperRetailerModal cancel={() => setModal(null)} save={addRetailer} />
      )}
      {modal === 'edit' && (
        <EditPaperRetailerModal
          paperRetailer={retailerToEdit}
          cancel={() => setModal(null)}
          save={updateRetailer}
          deleteRetailer={() => {
            setModal('delete');
          }}
        />
      )}
      {modal === 'delete' && (
        <ConsentModal
          title={t('common.confirm')}
          content={t('common.deleteConfirmation', {
            blank: t('common.theRetailer').toString().toLowerCase()
          })}
          onConfirm={() => {
            deleteRetailer(retailerToEdit);
          }}
          onCancel={() => setModal(undefined)}
        />
      )}
    </div>
  );
};

export default RetailerList;
