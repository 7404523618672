import { TableContainer, Table, Typography, Divider } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Invoice } from 'types/Invoice';
import { InvoiceDisplayOptions } from 'types/InvoiceDisplayOptions';
import { defaultInvoiceDisplayOptions } from './defaultInvoiceDisplayOptions';
import InvoiceFilterSection from './InvoiceFilterSection';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableBody from './InvoiceTableBody';
import { useTranslation } from 'react-i18next';

interface InvoiceTableProps {
  invoices: Invoice[]; // Assuming Invoice is the correct type
  selectable?: boolean;
  selectedPaymentIds?: number[];
  toggleSelected?: (id: number) => void;
}

const InvoiceTable: FC<InvoiceTableProps> = ({
  invoices,
  selectable,
  selectedPaymentIds,
  toggleSelected
}) => {
  const { t } = useTranslation();

  const [invoicesDisplayOptions, setInvoicesDisplayOptions] = useState<InvoiceDisplayOptions>(
    () => {
      const storedOptionsJson = localStorage.getItem('invoicesDisplayOptions');
      if (storedOptionsJson) {
        const storedOptions = JSON.parse(storedOptionsJson);
        return { ...defaultInvoiceDisplayOptions, ...storedOptions };
      }
      return defaultInvoiceDisplayOptions;
    }
  );
  const [showInvoiceButtons, setShowInvoiceButtons] = useState<boolean>(false);

  const handleToggleInvoiceButtons = () => {
    setShowInvoiceButtons((prevState) => !prevState);
  };

  const handleToggleOptions = (options: InvoiceDisplayOptions) => {
    const updatedOptions: InvoiceDisplayOptions = { ...options };
    setInvoicesDisplayOptions(updatedOptions);
  };

  const handleInvoiceDisplay = (key: keyof InvoiceDisplayOptions) => {
    setInvoicesDisplayOptions((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  useEffect(() => {
    localStorage.setItem('invoicesDisplayOptions', JSON.stringify(invoicesDisplayOptions));
  }, [invoicesDisplayOptions]);

  return (
    <TableContainer sx={{ overflowX: 'auto' }}>
      {showInvoiceButtons && (
        <InvoiceFilterSection
          showInvoiceButtons={showInvoiceButtons}
          handleToggleOptions={handleToggleOptions}
          handleInvoiceDisplay={handleInvoiceDisplay}
          invoicesDisplayOptions={invoicesDisplayOptions}
        />
      )}
      <Table>
        <InvoiceTableHeader
          selectable={selectable}
          invoicesDisplayOptions={invoicesDisplayOptions}
          toggleSelectColumns={handleToggleInvoiceButtons}
        />
        <InvoiceTableBody
          invoices={invoices}
          invoicesDisplayOptions={invoicesDisplayOptions}
          selectable={selectable}
          selectedPaymentIds={selectedPaymentIds}
          toggleSelected={toggleSelected}
        />
      </Table>
      {invoices.length === 0 && (
        <>
          <Typography
            sx={{
              fontStyle: 'italic',
              padding: '20px',
              textAlign: 'center',
              opacity: '0.7',
              fontSize: '0.9rem'
            }}
          >
            {t('common.noResultsFound')}
          </Typography>
        </>
      )}
      <Divider />
    </TableContainer>
  );
};

export default InvoiceTable;
