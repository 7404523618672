import { Pagination } from '@mui/material';
import { FC } from 'react';

type PrimarySearchPageNavProps = {
  page: number;
  totalResults: number;
  resultsPerPage?: number;
  onChangePage?: (newPage: number) => void;
};

const PrimarySearchPageNav: FC<PrimarySearchPageNavProps> = ({
  page,
  resultsPerPage = 20,
  totalResults,
  onChangePage
}) => {
  return (
    <Pagination
      count={Math.ceil(totalResults / resultsPerPage)}
      variant="outlined"
      sx={{
        '&>.MuiPagination-ul': {
          justifyContent: 'center'
        }
      }}
      shape={'rounded'}
      page={page}
      onChange={(event: React.ChangeEvent<unknown>, value: number) => onChangePage(value)}
    />
  );
};

export default PrimarySearchPageNav;
