import { Publish } from '@mui/icons-material';
import { Stack, Typography, CircularProgress, Button, Box, Divider } from '@mui/material';
import { PaperCard, PaperHeader, PaperContent, PrimaryListItem } from 'components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PostenImportPostCodesModal from '../PostenImportPostCodesModal/PostenImportPostCodesModal';
import postenDistributionService from 'services/posten-distribution-service';
import InfoToggle from 'components/InfoToggle/InfoToggle';
import { dateString } from 'utils';
import useAlert from 'hooks/useAlert';
import PostenImportBundleFileModal from 'components/PostenImportBundleFileModal';
import postenBundleInfoService from 'services/posten-bundle-info-service';

const PostenCard: FC = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const [loading, setLoading] = useState<boolean>();
  const [info, setInfo] = useState<{
    numberOfPostCodes: number;
    lastImportedAt: string;
  }>();

  const [bundleFileLastImported, setBundleFileLastImported] = useState<string>();

  const [modal, setModal] = useState<number>();

  // init, get info
  const getInfo = async (handleLoading = true) => {
    if (handleLoading) {
      setLoading(true);
    }

    try {
      const { data: infoData } = await postenDistributionService.getInfo();
      setInfo(infoData);
    } catch (error) {
      console.error('error', error);
    } finally {
      if (handleLoading) {
        setLoading(false);
      }
    }
  };

  const getBundleFileLastImported = async (handleLoading = true) => {
    if (handleLoading) {
      setLoading(true);
    }

    try {
      const { data: dateAsString } = await postenBundleInfoService.getBundleInfoLastImportedDate();
      setBundleFileLastImported(dateAsString);
    } catch (error) {
      console.error('error', error);
    } finally {
      if (handleLoading) {
        setLoading(false);
      }
    }
  };

  const init = async () => {
    // Promise all
    setLoading(true);
    try {
      await Promise.all([getInfo(false), getBundleFileLastImported(false)]);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <PaperCard sx={{ display: 'inline-block', width: 'auto' }}>
      <PaperHeader>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography textAlign={'left'} variant='h5'>
            {t('distribution.posten..')}
          </Typography>
        </Stack>
      </PaperHeader>
      <PaperContent>
        <Stack>
          <Box marginBottom={'16px'}>
            <InfoToggle text={t('distribution.posten.postCodeOverViewInfo')} />
          </Box>
          {info && !loading && (
            <Stack gap={2} direction={'row'}>
              <PaperCard variant='outlined'>
                <Stack p={2}>
                  <Typography variant={'h6'}>
                    {t('distribution.posten.postCodeOverview')}
                  </Typography>
                  <PrimaryListItem label={t('common.count')} value={info.numberOfPostCodes} />
                  <PrimaryListItem
                    label={t('distribution.lastImported')}
                    value={dateString(info.lastImportedAt)}
                  />
                  <PrimaryListItem
                    label={t('common.import') + ' ' + t('distribution.posten.postCodeOverview')}
                    value={
                      <Button
                        variant='outlined'
                        startIcon={<Publish />}
                        onClick={() => setModal(1)}
                      >
                        {t('common.importFile')}
                      </Button>
                    }
                  />
                </Stack>
              </PaperCard>
              <Divider orientation='vertical' flexItem />
              <PaperCard variant='outlined'>
                <Stack p={2}>
                  <Typography variant={'h6'}>{t('common.distributionBundle')}</Typography>
                  <PrimaryListItem
                    label={t('distribution.lastImported')}
                    value={bundleFileLastImported ? dateString(bundleFileLastImported) : '--'}
                  />
                  <PrimaryListItem
                    label={t('common.import') + ' ' + t('distribution.posten.bundleFile')}
                    value={
                      <Button
                        variant='outlined'
                        startIcon={<Publish />}
                        onClick={() => setModal(2)}
                      >
                        {t('common.importFile')}
                      </Button>
                    }
                  />
                </Stack>
              </PaperCard>
            </Stack>
          )}
        </Stack>
        {loading && (
          <Stack>
            <CircularProgress />
          </Stack>
        )}
      </PaperContent>
      {modal === 1 && (
        <PostenImportPostCodesModal
          onCancel={() => setModal(null)}
          onUpdate={() => {
            setModal(null);
            alert.addAlert('success', t('file.importSuccess'));
            getInfo();
          }}
        />
      )}
      {modal === 2 && (
        <PostenImportBundleFileModal
          onCancel={() => setModal(null)}
          onUpdate={() => {
            setModal(null);
            alert.addAlert('success', t('file.importSuccess'));
            getInfo();
          }}
        />
      )}
    </PaperCard>
  );
};

export default PostenCard;
