import {
  Dialog,
  Divider,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dateString from 'utils/_dateString';
import CloseIcon from '@mui/icons-material/Close';
import { ROUTE_PATHS } from 'consts/routes';
import COLORS from 'consts/colors';
import Order, { OrderStatus } from 'types/Order';

type InspectInvalidOrdersModalProps = {
  orders: Order[];
  exit: () => void;
};

const InspectInvalidOrdersModal: FC<InspectInvalidOrdersModalProps> = ({ orders, exit }) => {
  const { t } = useTranslation();

  const invalidStatus = (order: Order) => {
    const validStatus: OrderStatus[] = ['COMPLETED', 'CANCELED'];
    if (!validStatus.includes(order.status)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Dialog
      open={true}
      fullWidth
      sx={{
        maxWidth: 'unset',
        ' .MuiDialog-paper': {
          maxWidth: '2500px'
        }
      }}
    >
      <Stack sx={{ padding: '20px' }} direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>{t('dataCheck.invalidOrders')}</Typography>
        <IconButton color='primary' onClick={() => exit()}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('common.userId')}</TableCell>
            <TableCell>{t('common.id')}</TableCell>
            <TableCell>{t('common.status')}</TableCell>
            <TableCell>{t('common.recurringToken')}</TableCell>
            <TableCell>{t('common.uuid')}</TableCell>
            <TableCell>{t('common.orderId')}</TableCell>
            <TableCell>{t('common.paymentSolution')}</TableCell>
            <TableCell>{t('common.price')}</TableCell>
            <TableCell>{t('common.type')}</TableCell>
            <TableCell>{t('common.data')}</TableCell>
            <TableCell>{t('common.subscriptionId')}</TableCell>
            <TableCell>{t('common.campaignId')}</TableCell>
            <TableCell>{t('common.userSubscriptionId')}</TableCell>
            <TableCell>{t('common.createdAt')}</TableCell>
            <TableCell>{t('common.updatedAt')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={order.id}>
              <TableCell>
                <Link href={ROUTE_PATHS.USER.replace(':id', order.userId.toString())}>
                  {order.userId}
                </Link>
              </TableCell>
              <TableCell>{order.id}</TableCell>
              <TableCell sx={invalidStatus(order) ? { color: COLORS.ERROR } : null}>
                {order.status}
              </TableCell>
              <TableCell>{order.recurringToken}</TableCell>
              <TableCell>{order.uuid}</TableCell>
              <TableCell>{order.orderId}</TableCell>
              <TableCell>{order.paymentSolution}</TableCell>
              <TableCell>{order.price}</TableCell>
              <TableCell>{order.type}</TableCell>
              <TableCell>{JSON.stringify(order.data)}</TableCell>
              <TableCell>{order.subscriptionId}</TableCell>
              <TableCell>{order.campaignId}</TableCell>
              <TableCell>{order.userSubscriptionId}</TableCell>
              <TableCell>{dateString(order.createdAt)}</TableCell>
              <TableCell>{dateString(order.updatedAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Dialog>
  );
};

export default InspectInvalidOrdersModal;
