import { Box, Button, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import routes from 'consts/routes';
import useAuth from 'hooks/useAuth';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';
import queryString from 'utils/_queryString';

import './Login.scss';
import theme from 'styles/theme';
import { PageContainer, PaperCard, PaperContent, PaperHeader } from 'components';
import useAxiosAlert from 'hooks/useAxiosAlert';

type FormValues = {
  email: string;
  password: string;
};

const Login: FC = () => {
  const admin = useSelector((state: RootState) => state.admin);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<FormValues>();
  const axiosAlert = useAxiosAlert();
  const { signInAdmin } = useAuth();

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    try {
      await signInAdmin(formData.email, formData.password);
    } catch (err) {
      axiosAlert(
        err as AxiosError,
        [
          { status: 400, message: t('alerts.invalidEmailOrPassword') },
          { status: 401, message: t('alerts.incorrectEmailOrPassword') },
          { status: 429, message: t('alerts.tooManyLoginAttempts') }
        ],
        t('alerts.somethingWentWrong')
      );
    }
  };

  useEffect(() => {
    if (admin) {
      const returnPath = queryString.get('returnPath');
      const redirect = returnPath || routes.DEFAULT.path;
      navigate({
        pathname: redirect
      });
    }
  }, [admin, history]);

  if (!admin) {
    return (
      <>
        <PageContainer className="login-page" centerHorizontal centerVertical>
          <PaperCard className='login-form'>
            <PaperHeader sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '200px', display: 'flex', justifyContent: 'center' }}>
                <img style={{ width: '100%' }} src="/assets/newsroom-logo-white.svg" />
              </Box>
            </PaperHeader>
            <PaperContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  className="login-form-container"
                  sx={{
                    backgroundColor: theme.palette.secondary.main
                  }}
                >
                  <TextField label={'Email'} {...register('email')} />
                  <TextField label={'Password'} type={'password'} {...register('password')} />
                  <div className="login-form-actions">
                    <Button type="submit" variant="contained" size="large">
                      {t('common.login')}
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => navigate('/forgot-password')}
                    >
                      {t('password.forgotPassword')}
                    </Button>
                  </div>
                </Box>
              </form>
            </PaperContent>
          </PaperCard>
        </PageContainer>
      </>
    );
  } else {
    return null;
  }
};

export default Login;
