import { Chip } from '@mui/material';
import { FC } from 'react';

interface InvoiceDisplayButtonProps {
  buttonText: string;
  checked: boolean;
  invoiceDisplay: () => void;
}

const InvoiceDisplayButton: FC<InvoiceDisplayButtonProps> = (props) => {
  return (
    /*<Button variant={props.checked? 'contained': 'outlined'} onClick={props.invoiceDisplay}>{props.buttonText}</Button>*/
    <Chip color='primary' size='small' label={props.buttonText} variant={props.checked? 'filled': 'outlined'} onClick={props.invoiceDisplay}/>
  );
};

export default InvoiceDisplayButton;