import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'types/Text';

type EditTextModalProps = {
  text: Text;
  onConfirm: (value: string) => void;
  onCancel: () => void;
};

const EditTextModal: FC<EditTextModalProps> = ({ text, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(text.value);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const insertVariable = (variable: string) => () => {
    // Get cursor position
    const textField = textAreaRef?.current;
    if (textField === undefined) {
      // Insert variable at cursor position
      setValue(value + variable);
    } else {
      const startPos = textField.selectionStart ?? 0;

      // Insert variable at cursor position
      setValue((prevValue) => {
        const start = prevValue.slice(0, startPos);
        const end = prevValue.slice(startPos);
        return start + variable + end;
      });
      const newPos = startPos + variable.length;
      setTimeout(() => {
        textField.focus();
        textField.setSelectionRange(newPos, newPos);
      }, 0);
    }
  };
  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>
        {t('common.edit')} {text.key}
      </DialogTitle>
      <DialogContent>
        {text.variables && (
          <Typography>
            {t('common.variables')}:
            {text.variables.map((v) => (
              <Button
                key={v}
                size='small'
                sx={{ textTransform: 'none' }}
                onClick={insertVariable('{{' + v + '}}')}
              >
                {'{{' + v + '}}'}
              </Button>
            ))}
          </Typography>
        )}
        <TextField
          inputRef={textAreaRef}
          multiline
          fullWidth
          inputProps={{ style: { resize: 'vertical' } }}
          rows={10}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <Button onClick={() => onConfirm(value)} variant='outlined'>
          {t('common.saveChanges')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTextModal;
