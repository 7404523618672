import { FC } from 'react';
import { Stack, Button, Divider } from '@mui/material';
import { InvoiceDisplayOptions } from 'types/InvoiceDisplayOptions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useTranslation } from 'react-i18next';
import { defaultInvoiceDisplayOptions } from './defaultInvoiceDisplayOptions';
import InvoicesDisplayButton from './InvoiceDisplayButton';
import COLORS from 'consts/colors';

interface InvoiceFilterSectionProps {
  invoicesDisplayOptions: InvoiceDisplayOptions;
  showInvoiceButtons: boolean;
  handleToggleOptions: (options: InvoiceDisplayOptions) => void;
  handleInvoiceDisplay: (key: keyof InvoiceDisplayOptions) => void;
}

const InvoiceFilterSection: FC<InvoiceFilterSectionProps> = (props) => {
  const { t } = useTranslation();

  const handleToggleAll = () => {
    const newDisplayOptions = { ...defaultInvoiceDisplayOptions };
    for (const key in newDisplayOptions) {
      newDisplayOptions[key as keyof InvoiceDisplayOptions] = true;
    }
    props.handleToggleOptions(newDisplayOptions);
  };

  const invoiceButtons = props.invoicesDisplayOptions
    ? Object.entries(props.invoicesDisplayOptions).map(
      ([key, value]) =>
        typeof value === 'boolean' && (
          <InvoicesDisplayButton
            key={key}
            checked={value}
            buttonText={t(`invoiceTable.${key}`)}
            invoiceDisplay={() => props.handleInvoiceDisplay(key as keyof InvoiceDisplayOptions)}
          />
        )
    )
    : null;

  const handleToggleNone = () => {
    const newDisplayOptions = { ...defaultInvoiceDisplayOptions };
    for (const key in newDisplayOptions) {
      newDisplayOptions[key as keyof InvoiceDisplayOptions] = false;
    }
    props.handleToggleOptions(newDisplayOptions);
  };
  return (
    <>
      <Stack sx={{ bgcolor: COLORS.TABLE_HEADER }}>
        <Stack sx={{ padding: '20px', gap: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
          {invoiceButtons}
        </Stack>
        <Stack sx={{ padding: '0 20px 20px 20px', gap: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
          <Button
            onClick={() => props.handleToggleOptions(defaultInvoiceDisplayOptions)}
            color='primary'
          >
            <CheckCircleOutlineIcon />
            {t('common.default')}
          </Button>
          <Button onClick={handleToggleAll} color='primary'>
            <CheckCircleOutlineIcon />
            {t('invoice.selectAll')}
          </Button>
          <Button onClick={handleToggleNone} color='primary'>
            <RadioButtonUncheckedIcon />
            {t('invoice.selectNone')}
          </Button>
        </Stack>
        <Divider />
      </Stack>
    </>
  );
};

export default InvoiceFilterSection;
