/* eslint-disable max-lines */
import { Download, FolderZip, FolderZipOutlined, Help } from '@mui/icons-material';
import { Alert, Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { PaperCard, PaperContent, PaperHeader } from 'components';
import AlertsModal from 'components/AlertsModal';
import useAxiosAlert from 'hooks/useAxiosAlert';
import useSettings from 'hooks/useSettings';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import editionFileService from 'services/edition-file-service';
import Edition from 'types/Edition';
import FileReturn from 'types/FileReturn';

type EditionFilesProps = {
  edition: Edition;
  previousEdition?: Edition;
  subscribersSynced: boolean;
};

const EditionFiles: FC<EditionFilesProps> = ({ edition, previousEdition, subscribersSynced }) => {
  const { t } = useTranslation();
  const { getValueByKey } = useSettings();
  const [loading, setLoading] = useState<boolean>();
  const axiosAlert = useAxiosAlert();
  const [printingPressWarnings, setPrintingPressWarnings] = useState<string[]>([]);
  const [displayPrintingPressWarnings, setDisplayPrintingPressWarnings] = useState<boolean>();

  const createDownload = async (base64: string, name: string) => {
    const a = document.createElement('a');
    a.href = 'data:application/octet-stream;base64,' + base64;
    a.download = name;
    a.click();
  };

  const printingPressFormat = getValueByKey('PrintingPressFormat');
  const usesPolarisDistribution = getValueByKey('UsesPolarisDistribution');

  const downloadPostenChanges = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getPostenChanges(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadChangesPdf = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getChangesPdf(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadDIChanges = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getDIChanges(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadPostenTotal = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getPostenTotal(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadPostenDeliveryTask = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getPostenDeliveryTask(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadPostenZip = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getPostenZip(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadDITotal = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getDITotal(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadPolarisMeld = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getPolarisMeld(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadPolarisLos = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getPolarisLos(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadPolarisLosq = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getPolarisLosq(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadDistributionZip = async () => {
    setLoading(true);
    try {
      const pdf = await editionFileService.getDistributionZip(edition.id);
      createDownload(pdf.data.base64, pdf.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadCirculation = async () => {
    setLoading(true);
    try {
      const textFile = await editionFileService.getCirculationFile(edition.id);
      createDownload(textFile.data.base64, textFile.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadFremagTsl = async () => {
    setLoading(true);
    try {
      const textFile = await editionFileService.getFremagTslFile(edition.id);
      createDownload(textFile.data.base64, textFile.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadPrintingShop = async (
    variant: 'HD' | 'TXT' | 'REL' | 'INK' | 'ORD' | 'ABROAD_HEADERS_FILE' | 'ALL'
  ) => {
    setLoading(true);
    try {
      let response: AxiosResponse<FileReturn>;
      if (variant === 'HD') {
        response = await editionFileService.getWamacHd(edition.date);
      } else if (variant === 'TXT') {
        response = await editionFileService.getWamacTxt(edition.date);
      } else if (variant === 'REL') {
        response = await editionFileService.getWamacRel(edition.date);
      } else if (variant === 'INK') {
        response = await editionFileService.getWamacInk(edition.date);
      } else if (variant === 'ORD') {
        response = await editionFileService.getWamacOrd(edition.date);
      } else if (variant === 'ABROAD_HEADERS_FILE') {
        response = await editionFileService.getWamacAbroadHeadersFile(edition.date);
      } else if (variant === 'ALL') {
        response = await editionFileService.getWamacZip(edition.id);
      }

      setPrintingPressWarnings(response.data.warnings);
      createDownload(response.data.base64, response.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadButton = (
    label: string,
    action: () => void,
    icon?: ReactNode,
    variant: 'contained' | 'outlined' | 'text' = 'outlined'
  ) => {
    return (
      <Button
        onClick={action}
        disabled={loading || !subscribersSynced}
        variant={variant}
        endIcon={icon || <Download />}
      >
        {label}
      </Button>
    );
  };

  // MPC
  const downloadJobFiles = async () => {
    setLoading(true);
    try {
      const response = await editionFileService.getMpcJobFiles(edition.id);
      setPrintingPressWarnings(response.data.warnings);
      createDownload(response.data.base64, response.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadAddressFile = async () => {
    setLoading(true);
    try {
      const response = await editionFileService.getMpcAddressFile(edition.id);
      setPrintingPressWarnings(response.data.warnings);
      createDownload(response.data.base64, response.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadTFile = async () => {
    setLoading(true);
    try {
      const response = await editionFileService.getMpcTFile(edition.id);
      setPrintingPressWarnings(response.data.warnings);
      createDownload(response.data.base64, response.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadMpcControlFile = async () => {
    setLoading(true);
    try {
      const response = await editionFileService.getMpcControlFile(edition.id);
      setPrintingPressWarnings(response.data.warnings);
      createDownload(response.data.base64, response.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const dowloadMpcZip = async () => {
    setLoading(true);
    try {
      const response = await editionFileService.getMpcZip(edition.id);
      setPrintingPressWarnings(response.data.warnings);
      createDownload(response.data.base64, response.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const downloadMpcBanderole = async () => {
    setLoading(true);
    try {
      const response = await editionFileService.getMpcAbroadHeadersFile(edition.date);
      setPrintingPressWarnings(response.data.warnings);
      createDownload(response.data.base64, response.data.name);
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PaperCard variant='outlined'>
        <PaperHeader sx={{ boxSizing: 'border-box' }}>
          <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'}>
            <Tooltip title={t('edition.tooltip.filesDescription')}>
              <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Typography variant='h6'>{t('edition.files')}</Typography>
                <Help />
              </Stack>
            </Tooltip>
          </Stack>
        </PaperHeader>
        <PaperContent>
          <Stack gap={2}>
            {!previousEdition && (
              <Alert severity='info'>{t('edition.info.noPreviousEdition')}</Alert>
            )}
            <Stack gap={2}>
              <Typography variant='h6'>{t('distributors.posten')}</Typography>
              <Stack direction={'row'} gap={2}>
                {downloadButton(t('common.changes'), downloadPostenChanges)}
                {downloadButton(t('common.total'), downloadPostenTotal)}
                {downloadButton(t('common.deliveryTask'), downloadPostenDeliveryTask)}
                {downloadButton('ZIP', downloadPostenZip, <FolderZip />, 'contained')}
              </Stack>
            </Stack>
            <Divider />
            {usesPolarisDistribution === '1' && (
              <Stack gap={2}>
                <Typography variant='h6'>{t('common.distribution2')}</Typography>
                <Stack direction={'row'} gap={2}>
                  {downloadButton(t('common.changes'), downloadDIChanges)}
                  {downloadButton(t('common.total'), downloadDITotal)}
                  {downloadButton('MELD', downloadPolarisMeld)}
                  {downloadButton('LOS', downloadPolarisLos)}
                  {downloadButton('LOSQ', downloadPolarisLosq)}
                  {downloadButton('ZIP', downloadDistributionZip, <FolderZip />, 'contained')}
                </Stack>
              </Stack>
            )}
            {usesPolarisDistribution === '1' && <Divider />}
            {printingPressFormat === '1' && (
              <>
                <Stack gap={2}>
                  <Stack
                    direction={'row'}
                    gap={2}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography variant='h6'>{t('edition.printingShop')}</Typography>
                  </Stack>
                  <Stack direction={'row'} gap={2}>
                    {downloadButton(t('edition.circulation'), downloadCirculation)}
                    {downloadButton('HD', () => downloadPrintingShop('HD'))}
                    {downloadButton('TXT', () => downloadPrintingShop('TXT'))}
                    {downloadButton('REL', () => downloadPrintingShop('REL'))}
                    {downloadButton('INK', () => downloadPrintingShop('INK'))}
                    {downloadButton('ORD', () => downloadPrintingShop('ORD'))}
                    {downloadButton('Banderole', () => downloadPrintingShop('ABROAD_HEADERS_FILE'))}
                    {downloadButton(
                      'ZIP',
                      () => downloadPrintingShop('ALL'),
                      <FolderZip />,
                      'contained'
                    )}
                  </Stack>

                  {printingPressWarnings.length > 0 && (
                    <Alert severity='warning' onClose={() => setPrintingPressWarnings([])}>
                      {t('edition.warning.filesGeneratedWithWarnings')}
                      <Button
                        size='small'
                        color='warning'
                        onClick={() => setDisplayPrintingPressWarnings(true)}
                      >
                        {t('common.showWarnings')}
                      </Button>
                    </Alert>
                  )}
                </Stack>
                <Divider />
              </>
            )}
            {printingPressFormat === '2' && (
              <Stack gap={2}>
                <Stack
                  direction={'row'}
                  gap={2}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography variant='h6'>{t('edition.printingShop')}</Typography>
                </Stack>
                <Stack direction={'row'} gap={2}>
                  {downloadButton(
                    t('edition.mpc.jobFiles'),
                    () => downloadJobFiles(),
                    <FolderZipOutlined />
                  )}
                  {downloadButton(t('edition.mpc.addressFile'), () => downloadAddressFile())}
                  {downloadButton(t('edition.mpc.tFile'), () => downloadTFile())}
                  {downloadButton(t('edition.mpc.controlFile'), () => downloadMpcControlFile())}
                  {downloadButton('Banderole', () => downloadMpcBanderole())}
                  {downloadButton('ZIP', () => dowloadMpcZip(), <FolderZip />, 'contained')}
                </Stack>
                {printingPressWarnings.length > 0 && (
                  <Alert severity='warning' onClose={() => setPrintingPressWarnings([])}>
                    {t('edition.warning.filesGeneratedWithWarnings')}
                    <Button
                      size='small'
                      color='warning'
                      onClick={() => setDisplayPrintingPressWarnings(true)}
                    >
                      {t('common.showWarnings')}
                    </Button>
                  </Alert>
                )}
              </Stack>
            )}
            {printingPressFormat === '3' && (
              <Stack gap={2}>
                <Stack
                  direction={'row'}
                  gap={2}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography variant='h6'>{t('edition.printingShop')}</Typography>
                </Stack>
                <Stack direction={'row'} gap={2}>
                  {downloadButton('TSL', () => downloadFremagTsl())}
                </Stack>
                {printingPressWarnings.length > 0 && (
                  <Alert severity='warning' onClose={() => setPrintingPressWarnings([])}>
                    {t('edition.warning.filesGeneratedWithWarnings')}
                    <Button
                      size='small'
                      color='warning'
                      onClick={() => setDisplayPrintingPressWarnings(true)}
                    >
                      {t('common.showWarnings')}
                    </Button>
                  </Alert>
                )}
              </Stack>
            )}
            <Divider />
            <Stack gap={2}>
              <Typography variant='h6'>{t('edition.changesOverview')}</Typography>
              <Stack direction={'row'} gap={2}>
                {downloadButton(t('common.changes'), downloadChangesPdf)}
              </Stack>
            </Stack>
          </Stack>
        </PaperContent>
      </PaperCard>
      {displayPrintingPressWarnings && (
        <AlertsModal
          texts={printingPressWarnings}
          title={t('common.warnings')}
          severity='warning'
          onClose={() => setDisplayPrintingPressWarnings(false)}
        />
      )}
    </>
  );
};

export default EditionFiles;
